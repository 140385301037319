import { RHFControl, RHFSelect } from 'components/common/form';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { GET_SPONSORSHIP_CONDITIONS } from './beneficiary-options-queries';
import { useQuery } from '@apollo/client';
import { useContext, useMemo } from 'react';
import AuthContext from 'context/authContext';

export const HealthConditionCard = ({ sectionRef, sideNavLink }) => {
  const { organization } = useContext(AuthContext);

  const { data: conditionsData } = useQuery(GET_SPONSORSHIP_CONDITIONS, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });

  const conditionsOptions = useMemo(
    () =>
      conditionsData?.sponsorship_condition?.map(({ condition, id }) => {
        return {
          label: condition,
          value: id
        };
      }),
    [conditionsData]
  );

  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Health Condition</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Container className="p-0 d-flex flex-column g-3">
          <Row className="g-3">
            <Col xs={12} sm={6}>
              <RHFSelect
                name="healthCondition"
                label="Condition"
                options={conditionsOptions}
                required
              />
            </Col>

            <Col xs={12} sm={12}>
              <RHFControl label="Health Notes" name="healthNotes" />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

HealthConditionCard.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
