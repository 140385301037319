import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFControl } from 'components/common/form';
import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle } from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const CommentForm = forwardRef(
  ({ onSubmit, loading, initialValues, isEdit, onCancel }, ref) => {
    const methods = useForm({
      mode: 'onBlur',
      reValidateMode: 'onChange',
      defaultValues: initialValues ?? {
        comment: ''
      },
      resolver: yupResolver(
        Yup.object().shape({
          comment: Yup.string().required('Required Field')
        })
      )
    });
    const { handleSubmit, reset } = methods;
    useImperativeHandle(ref, () => ({
      reset: () => {
        reset();
      }
    }));
    return (
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RHFControl
          name="comment"
          rows={3}
          className="border-0 rounded-bottom-0 resize-none"
          as="textarea"
          noErrorMessage
        />
        {/* <Form.Control
        as="textarea"
        className="border-0 rounded-bottom-0 resize-none"
        rows={3}
      /> */}
        <Flex
          justifyContent="between"
          alignItems="center"
          className="bg-light rounded-bottom p-2 mt-1"
        >
          <LoadingButton
            loading={loading}
            size="sm"
            color="primary"
            type="submit"
          >
            Save
          </LoadingButton>
          {isEdit && (
            <Button
              size="sm"
              variant="falcon-danger"
              className="bg-transparent border-0 shadow-none "
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
          {/* <ul className="list-inline mb-0">
                  <li className="list-inline-item mr-1">
                    <Form.Group controlId="file-attachment">
                      <Form.Label className="link-600 px-2 transition-base rounded cursor-pointer mb-0">
                        <FontAwesomeIcon icon="paperclip" />
                      </Form.Label>
                      <Form.Control type="file" className="d-none" />
                    </Form.Group>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Link
                      to="#!"
                      className="link-600 px-2 transition-base rounded"
                    >
                      <FontAwesomeIcon icon="at" />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-1">
                    <Form.Group controlId="file-image">
                      <Form.Label className="link-600 px-2 transition-base rounded cursor-pointer mb-0">
                        <FontAwesomeIcon icon="image" />
                      </Form.Label>
                      <Form.Control
                        type="file"
                        className="d-none"
                        accept="image/*"
                      />
                    </Form.Group>
                  </li>
                </ul> */}
        </Flex>
      </FormProvider>
    );
  }
);

CommentForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialValues: PropTypes.object,
  isEdit: PropTypes.bool,
  onCancel: PropTypes.func
};

export default CommentForm;
