import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import KanbanColumnHeader from './KanbanColumnHeader';
import TaskCard from './TaskCard';
import AddAnotherForm from './AddAnotherForm';
import classNames from 'classnames';
import { KanbanContext } from 'context/Context';
import StrictModeDroppable from './StrictModeDroppable';

const KanbanColumn = ({ kanbanColumnItem }) => {
  const { id, name, items } = kanbanColumnItem;
  const [showForm, setShowForm] = useState(false);
  const formViewRef = useRef(null);
  console.log({ kanbanColumnItem });
  const {
    kanbanState: { kanbanItems },
    kanbanDispatch,
    selectedMember
  } = useContext(KanbanContext);

  const itemsFiltered = useMemo(() => {
    let tmp;
    if (Object.keys(selectedMember).length)
      tmp = items.filter(item => item.managerId === selectedMember.id);
    else tmp = items;
    return tmp.length;
  }, [selectedMember]);

  const handleSubmit = cardData => {
    const targetList = kanbanItems.find(item => item.id === id);
    const cardId = targetList.items.length
      ? Math.max(...targetList.items.map(item => item.id)) + 1
      : `${targetList.id}0`;
    const newCard = {
      id: cardId,
      title: cardData.title
    };
    const isEmpty = !Object.keys(cardData).length;

    if (!isEmpty) {
      kanbanDispatch({
        type: 'ADD_TASK_CARD',
        payload: { targetListId: id, newCard }
      });
      setShowForm(false);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      formViewRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500);

    return clearTimeout(timeout);
  }, [showForm]);
  return (
    <div
      className={classNames('kanban-column', {
        'form-added': showForm
      })}
    >
      <KanbanColumnHeader
        id={id}
        title={name}
        itemCount={itemsFiltered || 0}
        background={kanbanColumnItem.color}
      />
      <StrictModeDroppable droppableId={`${id}`} type="KANBAN">
        {provided => (
          <>
            <div
              style={{ backgroundColor: kanbanColumnItem.color }}
              ref={provided.innerRef}
              {...provided.droppableProps}
              id={`container-${id}`}
              className="kanban-items-container scrollbar"
            >
              {items.map((task, index) => {
                if (Object.keys(selectedMember).length) {
                  return (
                    task.managerId === selectedMember.id && (
                      <TaskCard key={task.id} index={index} task={task} />
                    )
                  );
                } else
                  return <TaskCard key={task.id} index={index} task={task} />;
              })}
              {
                <AddAnotherForm
                  onSubmit={handleSubmit}
                  type="card"
                  showForm={showForm}
                  setShowForm={setShowForm}
                />
              }
              {provided.placeholder}
              <div ref={formViewRef}></div>
            </div>
            {/* {!showForm && (
              <div className="kanban-column-footer">
                <IconButton
                  size="sm"
                  variant="link"
                  className="d-block w-100 btn-add-card text-decoration-none text-600"
                  icon="plus"
                  iconClassName="me-2"
                  onClick={() => setShowForm(true)}
                >
                  Add Project
                </IconButton>
              </div>
            )} */}
          </>
        )}
      </StrictModeDroppable>
    </div>
  );
};

KanbanColumn.propTypes = {
  kanbanColumnItem: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    name: PropTypes.string,
    items: PropTypes.arrayOf(TaskCard.propTypes.task),
    color: PropTypes.string
  })
};

export default KanbanColumn;
