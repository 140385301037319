import { Card, Col, Container, Row } from 'react-bootstrap';
import BranchForm from './BranchForm';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import { useContext } from 'react';
import AuthContext from 'context/authContext';
import useUpload from 'hooks/useUpload';

// ------------------------------

const INSERT_BRANCH = gql`
  mutation InsertBranch(
    $address: jsonb = ""
    $city: String = ""
    $currency_id: uuid = ""
    $location_id: uuid = ""
    $logo: String = ""
    $name: String = ""
    $organisation_id: uuid = ""
    $phone: String = ""
  ) {
    insert_branches_one(
      object: {
        address: $address
        city: $city
        currency_id: $currency_id
        location_id: $location_id
        logo: $logo
        name: $name
        organisation_id: $organisation_id
        phone: $phone
      }
    ) {
      address
      city
      created_at
      currency_id
      id
      location_id
      logo
      name
      phone
      organisation_id
    }
  }
`;

// ------------------------------

export default function CreateBranch() {
  const navigate = useNavigate();

  const { organization } = useContext(AuthContext);

  const [insertBranch, { loading }] = useMutation(INSERT_BRANCH, {
    refetchQueries: ['GetUser'],
    onCompleted: () => {
      toast.success('Branch Created');
      navigate(paths.branchesSetting);
    }
  });
  const { upload, loading: uploadLoading } = useUpload();

  const onSubmit = async values => {
    let logo = values.logo;
    try {
      if (logo && typeof logo !== 'string') {
        const profileUrl = await upload(logo);
        logo = profileUrl;
      }
    } catch (e) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }
    insertBranch({
      variables: {
        ...values,
        address: '',
        organisation_id: organization.id,
        logo: logo
      }
    });
  };

  return (
    <Container fluid className="px-0">
      <Row>
        <Col sm={12} md={7}>
          <Card>
            <Card.Body>
              <BranchForm
                loading={loading || uploadLoading}
                onSubmit={onSubmit}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
