import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { weProjectProgressData } from 'data/weraise-data/data';
import { parseRouteParams } from 'helpers/utils';
import paths from 'routes/paths';
import LoadingContainer from 'components/common/LoadingContainer';
import dayjs from 'dayjs';

/* -------------------------------------------------------------------------- */
export const GET_PROJECT_REPORTS_QUERY = gql`
  query GetProjectReports($projectId: uuid = "") {
    project_reports(
      where: { project_id: { _eq: $projectId } }
      order_by: { created_at: desc }
    ) {
      description
      title
      url
      video_url
      created_at
      updated_at
      id
      project_id
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

const ProjectReports = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <Card>
      <FalconCardHeader
        title={<h4 className="mt-2 fw-bold">Project Progress</h4>}
        light={false}
        titleTag="div"
        menuClassName="align-items-center"
        endEl={
          <Button
            onClick={() =>
              navigate(
                parseRouteParams(paths.createProjectReport, {
                  id
                })
              )
            }
          >
            Add Report
          </Button>
        }
      />
      <Card.Body className="bg-100">
        <Query query={GET_PROJECT_REPORTS_QUERY} variables={{ projectId: id }}>
          {({ loading, error, data }) => {
            if (loading) return <LoadingContainer />;
            if (error)
              return <p className="text-center fs-0">Error! {error.message}</p>;
            if (!data.project_reports.length)
              return <p className="text-center fs-0">No Reports Found</p>;
            return data.project_reports.map((item, index) => {
              const formattedDate = dayjs(item.created_at).format(
                'DD MMM YYYY'
              );
              const userFullName = `${item.user?.first_name} ${item.user?.last_name}`;
              return (
                <Card
                  key={item.id}
                  onClick={() => navigate(`${item.id}`)}
                  className={classNames('cursor-pointer', {
                    'mb-3': index !== weProjectProgressData?.length - 1
                  })}
                >
                  <Card.Body>
                    <p className="text-primary fs-0 fw-semi-bold ">
                      {item.title}
                    </p>
                    <div
                      className="fs-0 text-truncate"
                      style={{
                        maxHeight: 60
                      }}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <Flex justifyContent={'between'}>
                      <p className="mb-0 fw-medium text-1000">
                        {formattedDate}
                      </p>
                      <p className="mb-0">
                        <FontAwesomeIcon
                          icon={'user'}
                          className="me-2 text-1000"
                        />
                        {userFullName}
                      </p>
                    </Flex>
                  </Card.Body>
                </Card>
              );
            });
          }}
        </Query>
      </Card.Body>
    </Card>
  );
};

export default ProjectReports;
