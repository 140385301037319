import { gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import Section from 'components/common/Section';
import { toast } from 'react-toastify';
import ProjectReportForm from './ProjectReportForm';
import { GET_PROJECT_REPORTS_QUERY } from '../ProjectReports';
import { useAuthContext } from 'context/authContext';

/* -------------------------------------------------------------------------- */
const CREATE_PROJECT = gql`
  mutation CreateProjectReport(
    $title: String!
    $description: String!
    # $labels: jsonb!
    $video_url: String!
    $project_id: uuid!
    $created_by: uuid!
  ) {
    insert_project_reports_one(
      object: {
        video_url: $video_url
        project_id: $project_id
        # labels: $labels
        title: $title
        description: $description
        created_by: $created_by
      }
    ) {
      description
      title
      url
      video_url
      created_at
      updated_at
      id
      project_id
      user {
        id
        first_name
        last_name
      }
    }
  }
`;

/* -------------------------------------------------------------------------- */
const CreateProjectReport = () => {
  const navigate = useNavigate();
  const { id: projectId } = useParams();
  const { user } = useAuthContext();
  const [createProjectReport, { loading }] = useMutation(CREATE_PROJECT, {
    onCompleted: () => {
      navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong! Please try again.');
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingProjectReports = cache.readQuery({
        query: GET_PROJECT_REPORTS_QUERY,
        variables: {
          projectId
        }
      });
      const newProjectReport = data.insert_project_reports_one;
      if (!existingProjectReports) return;
      cache.writeQuery({
        query: GET_PROJECT_REPORTS_QUERY,
        variables: {
          projectId
        },
        data: {
          project_reports: [
            newProjectReport,
            ...existingProjectReports.project_reports
          ]
        }
      });
    }
  });

  const onSubmit = data => {
    createProjectReport({
      variables: {
        title: data.title,
        description: data.description,
        video_url: data.videoUrl,
        project_id: projectId,
        // labels: data.labels,
        created_by: user.id
      }
    });
  };
  return (
    <Section
      className={'py-0 mx-auto font-sans-serif '}
      style={{ maxWidth: '820px' }}
    >
      <ProjectReportForm onSubmit={onSubmit} loading={loading} />
    </Section>
  );
};

export default CreateProjectReport;
