import { faStoreAltSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import BranchCard from './BranchCard';
import { Button, Container } from 'react-bootstrap';
import { ReactComponent as BuildingSvg } from 'assets/img/icons/ri_building-2-fill.svg';
import { useNavigate } from 'react-router-dom/dist';
import paths from 'routes/paths';
import { gql, useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import { Can } from 'routes/permissions/Can';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';

// -----------------------------------

export const GET_BRANCHES = gql`
  query GetBranches($organisation_id: uuid = "") {
    branches(where: { organisation_id: { _eq: $organisation_id } }) {
      address
      city
      created_at
      currency_id
      id
      location_id
      logo
      name
      organisation_id
      phone
      is_active
      is_main
    }
  }
`;

// -----------------------------------

export default function Branches() {
  const navigate = useNavigate();
  const loading = false;
  const { organization, isOrganizationAdmin } = useContext(AuthContext);
  const { data } = useQuery(GET_BRANCHES, {
    variables: {
      organisation_id: organization.id
    }
  });
  const { user } = useContext(AuthContext);

  const handleCreateBranch = () => {
    navigate(paths.createBranch);
  };

  const branchesUserRoles = user.users_roles.filter(
    userRole => userRole.branch !== null
  );

  const organizationBranches = data?.branches;

  return (
    <Container fluid className="px-0">
      <div className="bg-white p-3 flex-wrap d-inline-block w-100">
        <div className="bg-whited-flex h-100 d-flex float-sm-start">
          <BuildingSvg />
          <h5 className="h-100 mb-0 ms-2 fw-semi-bold">
            Organization Branches
          </h5>
        </div>
        <Can
          I={userActions.create}
          a={permissionsKeyMap.settings.children.branches}
        >
          <Button
            className="float-sm-end border-0 shadow-sm"
            variant="outline-dark"
            onClick={handleCreateBranch}
          >
            <span className="text-primary">+</span> Add Branch
          </Button>
        </Can>

        <div></div>
      </div>
      <div className=" mt-3">
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-wrap gap-3 mt-3 justify-content-center justify-content-md-start">
            {isOrganizationAdmin
              ? organizationBranches?.map(item => {
                  return (
                    <BranchCard
                      isActive={item.is_active}
                      key={item.id}
                      id={item.id}
                      name={item?.name}
                      image={item?.logo}
                    />
                  );
                })
              : branchesUserRoles?.map(item => {
                  return (
                    <BranchCard
                      isActive={item.is_active}
                      key={item.id}
                      id={item.id}
                      name={item?.name}
                      image={item?.logo}
                    />
                  );
                })}

            {organizationBranches?.length === 0 && (
              <div className="d-flex flex-column align-items-center justify-content-center w-100 mt-3">
                <FontAwesomeIcon
                  icon={faStoreAltSlash}
                  size="5x"
                  className="text-400"
                />
                <h4 className="text-400 mt-3">No Available Branches</h4>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
}
