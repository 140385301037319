import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { FormProvider, RHFSelect } from 'components/common/form';
import LoadingButton from 'components/common/LoadingButton';
import { useMemo, useContext } from 'react';
import AuthContext from 'context/authContext';

/* -------------------------------------------------------------------------- */
const GET_BRANCH_STAFF = gql`
  query GetBranchStaff($branchId: uuid = "") {
    users_roles(where: { branch_id: { _eq: $branchId } }) {
      is_active
      is_approved
      company_email
      permissions
      created_at
      updated_at
      branch_id
      id
      organisation_id
      role_id
      user_id
      user {
        first_name
        last_name
        id
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

function SelectStaffModal({ show, onHide, loading, initialValues, onSubmit }) {
  const { selectedBranchId } = useContext(AuthContext);
  const { data: branchStaffData, loading: branchStaffLoading } = useQuery(
    GET_BRANCH_STAFF,
    {
      variables: { branchId: selectedBranchId }
    }
  );

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      assignedStaff: initialValues?.assignedStaff ?? ''
    }
  });
  const { handleSubmit } = methods;
  // const assignedStaff = watch('assignedStaff');

  const branchStaffOptions = useMemo(() => {
    if (!branchStaffData) return [];
    return branchStaffData.users_roles.map(({ user }) => ({
      label: `${user.first_name} ${user.last_name}`,
      value: user.id
    }));
  }, [branchStaffData]);

  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Select Assigned Staff</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <RHFSelect
            name="assignedStaff"
            label="Staff"
            options={branchStaffOptions}
            placeholder="Select Staff"
            isLoading={branchStaffLoading}
            required
          />
          <div className="d-flex mt-3 justify-content-end">
            <LoadingButton
              type="submit"
              className="px-4"
              variant="primary"
              loading={loading}
            >
              Save
            </LoadingButton>
          </div>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
}

SelectStaffModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func
};

export default SelectStaffModal;
