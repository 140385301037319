import { gql, useMutation } from '@apollo/client';
import AuthContext from 'context/authContext';
import React, { useContext, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FormProvider, RHFControl } from 'components/common/form';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const UPDATE_ORGANIZATION_STRIPE = gql`
  mutation MyMutation(
    $organisation_id: ID = ""
    $stripe_key: String = ""
    $stripe_secret: String = ""
  ) {
    updateStripeCredentials(
      organisation_id: $organisation_id
      stripe_key: $stripe_key
      stripe_secret: $stripe_secret
    ) {
      message
    }
  }
`;

const paymentSettingsScheme = Yup.object().shape({
  stripeKey: Yup.string().required('Required Field'),
  stripeSecret: Yup.string().required('Required Field')
});

function PaymentSettings() {
  const { organization } = useContext(AuthContext);

  const paymentSettingsResolver = yupResolver(paymentSettingsScheme);

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      stripeKey: '',
      stripeSecret: ''
    },
    resolver: paymentSettingsResolver
  });

  const { handleSubmit, reset } = methods;

  const [updatePaymentSettings] = useMutation(UPDATE_ORGANIZATION_STRIPE, {
    refetchQueries: ['GetUser'],
    onCompleted: () => {
      toast.success('Stripe Payment information Updated successfully.');
    },
    onError: err => {
      console.log(err);
      toast.error('Something went wrong, please try again');
    }
  });

  useEffect(() => {
    reset({
      stripeKey: organization.stripe_key,
      stripeSecret: organization.stripe_secret
    });
  }, [organization]);

  const onSubmit = data => {
    updatePaymentSettings({
      variables: {
        organisation_id: organization.id,
        stripe_key: data.stripeKey,
        stripe_secret: data.stripeSecret
      }
    });
  };

  return (
    <Card>
      <Card.Header className="fs-2">Payment Settings</Card.Header>
      <Card.Body>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md={12}>
              <RHFControl
                label={'Stripe Key'}
                name={'stripeKey'}
                placeholder={'Stripe Key'}
                required
              />
            </Col>
            <Col md={12} className="mt-2">
              <RHFControl
                label={'Stripe Secret'}
                name={'stripeSecret'}
                placeholder={'Stripe Key'}
                required
              />
            </Col>
          </Row>
          <Button type="submit" className="mt-3 text-center">
            Submit
          </Button>
        </FormProvider>
      </Card.Body>
    </Card>
  );
}

export default PaymentSettings;
