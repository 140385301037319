import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import {
  AttachmentsItems,
  ListItemData,
  SoftBadeItem
} from '../ProjectCommonComponents';
import { getDurationAgo, getRelativePath } from 'helpers/utils';
import IconButton from 'components/common/IconButton';
import paths from 'routes/paths';
import CompletionFeedback from './CompletionFeedback';
import { useAuthContext } from 'context/authContext';

const ProjectCompletionReport = ({ data }) => {
  const navigate = useNavigate();
  const { isAdmin } = useAuthContext();

  const {
    user,
    project,
    completion_date,
    no_of_beneficiary,
    cities,
    created_at,
    project_cost,
    administration_cost,
    video_url,
    feedback,
    country
  } = data;

  const userFullName = `${user?.first_name} ${user?.last_name}`;
  const formattedDate = dayjs(created_at).format('DD MMM, YYYY | hh:mm A');
  const durationAgo = getDurationAgo(created_at);
  const formattedStartDate = dayjs(project.start_date).format('DD/MM/YYYY');
  const formattedCompletionDate = dayjs(completion_date).format('DD/MM/YYYY');

  const attachments = [
    {
      id: '1',
      title: 'winter.jpg',
      type: 'image/jpeg',
      size: 121212
    },
    { id: '2', title: 'winter.png', type: 'image/jpeg', size: 1212 },
    {
      id: '3',
      title: 'title.zip',
      type: 'application/zip',
      size: 21212
    },
    {
      id: '4',
      title: 'doc.pdf',
      type: 'application/pdf',
      size: 121432121
    },
    { id: '5', title: 'data.cvs', type: 'text/csv', size: 121212 },
    {
      id: '6',
      title: 'winter 1.png',
      type: 'image/jpeg',
      size: 1212
    },
    {
      id: '7',
      title: 'title.zip',
      type: 'application/zip',
      size: 21212
    },
    {
      id: '8',
      title: 'tutorial.mp4',
      type: 'video/mp4',
      size: 12122243121
    }
  ];

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-100 d-flex justify-content-between align-items-center">
          <Card.Title className="fw-medium mb-0">
            <FontAwesomeIcon icon={'paperclip'} className="me-2" />
            {project.id} | {project.project_name}
          </Card.Title>
          <IconButton
            className=""
            variant="falcon-default"
            onClick={() =>
              navigate(
                getRelativePath(paths.editCompleteReport, paths.completeProject)
              )
            }
            icon="edit"
          >
            Edit
          </IconButton>
        </Card.Header>
        <Card.Body>
          <Flex
            justifyContent={'between'}
            className="flex-column flex-md-row align-items-start align-items-md-center"
          >
            <Flex alignItems={'center'}>
              <Avatar src={user?.profile_pic} name={userFullName} size="2xl" />
              <div className="ms-2">
                <p className="mb-0">{userFullName}</p>
                {/* <p className="mb-0 text-500">Project Manager</p> */}
              </div>
            </Flex>
            <p className="mb-0 text-1000 mt-3 mt-md-0 w-100 w-md-auto text-end">
              {formattedDate} <i>({durationAgo})</i>
            </p>
          </Flex>
          <hr />
          <div className="font-base">
            <ListItemData title="Creation Date" value={formattedStartDate} />
            <ListItemData
              title="Completion Date"
              value={formattedCompletionDate}
            />
            <ListItemData
              title="Number of Beneficiaries"
              value={no_of_beneficiary}
            />
            <ListItemData title="Country" value={country} />
            <ListItemData
              title="City(ies)"
              content={
                <div className="w-75">
                  <SoftBadeItem data={cities} />
                </div>
              }
            />
            <ListItemData
              title="Total Cost"
              value={`$${project_cost.toFixed(2).toLocaleString()}`}
            />
            <ListItemData
              title="Administration cost"
              value={`$${administration_cost.toFixed(2).toLocaleString()}`}
            />
            <ListItemData
              title="Project Feedback"
              innerHtml={feedback}
              direction="column"
            />
            <ListItemData
              title="Video"
              content={
                <a target="_blank" href={video_url} rel="noreferrer">
                  {video_url}
                </a>
              }
              direction="column"
            />
          </div>
          <AttachmentsItems
            attachments={attachments}
            deleteItem={id => console.log(id)}
            downloadAllClick={() => console.log('Download all')}
          />
        </Card.Body>
      </Card>
      {isAdmin && <CompletionFeedback data={data} />}
    </>
  );
};

ProjectCompletionReport.propTypes = {
  data: PropTypes.string
};

export default ProjectCompletionReport;
