import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';

function LogoUploadPreview({
  onChange,
  files,
  previewUrl,
  setFiles,
  label = 'Your Logo'
}) {
  const maxSize = 1048576;
  const errorMessage = 'File type not Supported';
  const isFileError = false;
  const [isInputMounted, setIsInputMounted] = useState(true);
  useEffect(
    function remountInput() {
      if (!isInputMounted) {
        setIsInputMounted(true);
      }
    },
    [isInputMounted]
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxSize,
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  // if (fileRejections.length > 0) {
  //   isFileError = fileRejections.length > 0;
  //   errorMessage =
  //     fileRejections[0].errors[0].code == 'file-invalid-type'
  //       ? 'File type is not Supported please upload only image '
  //       : 'File size is greater then 1MB';
  // }

  return (
    <>
      <div
        {...getRootProps({
          className: classNames({
            'dropzone-area py-6': !(previewUrl || files?.[0])
          })
        })}
      >
        <input {...getInputProps({ onChange })} />
        {previewUrl || files?.[0] ? (
          <Flex direction="column" alignItems="center">
            <Image
              rounded
              width="100"
              height="auto"
              src={previewUrl || files[0].preview}
              alt={previewUrl || files[0].path}
            />
            <p
              style={{ textDecoration: 'underline' }}
              className="text-primary fw-semi-bold mt-1 mb-0"
            >
              Replace Logo
            </p>
          </Flex>
        ) : (
          <Flex justifyContent="center">
            <img src={cloudUpload} alt="" width={25} className="me-2" />
            <p className="fs-0 mb-0 text-700">{label}</p>
          </Flex>
        )}
      </div>
      {isFileError && <div className="text-danger mt-2">{errorMessage}</div>}
    </>
  );
}

LogoUploadPreview.propTypes = {
  onChange: PropTypes.func.isRequired,
  files: PropTypes.array,
  previewUrl: PropTypes.string,
  setFiles: PropTypes.func.isRequired,
  label: PropTypes.string
};

export default LogoUploadPreview;
