import IconButton from 'components/common/IconButton';
import React from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import image from 'assets/img/we-raise-images/front-end/thank_you_video_thum.png';

const Page7 = () => {
  return (
    <Card className="rounded-5 mt-5 mb-5 mb-md-11 we-card-shadow">
      <Card.Body className="p-4">
        <div className="position-relative">
          <IconButton
            icon="far fa-play-circle"
            className="p-0 bg-transparent text-400 hover-100 border-0 shadow-none center-item fs-8 lh-1 hover-text-primary"
          />
          <Image src={image} fluid className="w-100" />
        </div>
        <div className="mt-4 text-center">
          <p
            className="font-patrick text-primary mb-2"
            style={{ fontSize: '42px' }}
          >
            A Huge Thank You!
          </p>
          <p className="fs-0">
            Mr.Ahmed, Your contribution mean a lot and will be put to good use
            in making a difference. we send your donation receipt to your
            register email <a href="mailto:ahmed@email.com">ahmed@email.com</a>
          </p>
        </div>
        <IconButton
          className="w-100 p-2 text-uppercase"
          icon="share-square"
          iconClassName="me-2"
        >
          Share the good
        </IconButton>
        <Button
          className="w-100 mt-3 p-2 border-400 bg-200 hover-bg-primary text-capitalize"
          variant="outline-secondary"
        >
          Help us by giving us your feedback
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Page7;
