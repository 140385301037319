import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFControl, RHFSelect } from 'components/common/form';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { PROJECT_STATUS_COMPLETED } from '../ProjectsList';

/* -------------------------------------------------------------------------- */
const STATUS_APPROVED = 'Approved';
const STATUS_REJECTED = 'Rejected';

const statusOptions = [
  { label: STATUS_APPROVED, value: STATUS_APPROVED },
  { label: STATUS_REJECTED, value: STATUS_REJECTED }
];

/* -------------------------------------------------------------------------- */
const feedbackScheme = Yup.object().shape({
  status: Yup.string().required('Required'),
  comment: Yup.string()
});
/* -------------------------------------------------------------------------- */
const UPDATE_PROJECT_COMPLETION_MUTATION = gql`
  mutation UpdateProjectCompletion(
    $admin_comment: String = ""
    $status: String = ""
    $id: uuid = ""
    $_set: projects_set_input = { status: "" }
    $project_id: uuid = ""
  ) {
    update_project_completion_by_pk(
      pk_columns: { id: $id }
      _set: { admin_comment: $admin_comment, status: $status }
    ) {
      id
      completion_date
      no_of_beneficiary
      cities
      currency_id
      project_cost
      administration_cost
      created_at
      updated_at
      project_id
      video_url
      files
      feedback
      country
      status
      admin_comment
      project {
        id
        project_name
        start_date
      }
      user {
        first_name
        last_name
      }
    }
    update_projects_by_pk(pk_columns: { id: $project_id }, _set: $_set) {
      case_study
      country
      feasibility_study
      project_charter
      project_name
      status
      end_date
      start_date
      average_beneficiaries_cost
      donation_amount
      target_amount
      num_of_beneficiaries
      cities
      project_description
      created_at
      updated_at
      branch_id
      currency_id
      id
      location_id
      manager_id
      organization_id
      package_category_id
      package_category {
        category_name
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

export default function CompletionFeedback({ data }) {
  const [updateProjectCompletion, { loading }] = useMutation(
    UPDATE_PROJECT_COMPLETION_MUTATION,
    {
      onCompleted: () => {
        toast.success('Project Completion Updated Successfully');
      },
      onError: error => {
        toast.error(error.message);
      }
    }
  );
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      status: data.status ?? '',
      comment: data.admin_comment ?? ''
    },
    resolver: yupResolver(feedbackScheme)
  });
  const { handleSubmit } = methods;
  const onSubmit = ({ comment, status }) => {
    updateProjectCompletion({
      variables: {
        id: data.id,
        status: status,
        admin_comment: comment,
        project_id: data.project_id,
        _set:
          status === STATUS_APPROVED ? { status: PROJECT_STATUS_COMPLETED } : {}
      }
    });
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card className="mb-3">
        <Card.Header>
          <Card.Title className="fw-medium mb-0">Project Status</Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Row className="g-3">
            <Col sm={12}>
              <RHFSelect
                name="status"
                label="Status"
                placeholder="Status"
                options={statusOptions}
                required
              />
            </Col>
            <Col sm={12}>
              <RHFControl
                name="comment"
                label="Comment"
                placeholder="Comment"
                as="textarea"
                rows={5}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Flex
            justifyContent={'between'}
            className="flex-column flex-md-row align-items-center"
          >
            <p className="mb-0 fs-2 fw-medium">Update Project</p>
            <LoadingButton
              loading={loading}
              className="w-215px mt-2 mt-md-0"
              type="submit"
            >
              Submit
            </LoadingButton>
          </Flex>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}

CompletionFeedback.propTypes = {
  data: PropTypes.object.isRequired
};
