import { useAuthContext } from 'context/authContext';
import { useEffect, useState } from 'react';

export default function useBranchPermission() {
  const [where, setWhere] = useState({});
  const { is_main, organization, selectedBranchId } = useAuthContext();
  useEffect(() => {
    if (is_main === true) {
      setWhere({ organisation_id: { _eq: organization.id } });
    } else {
      setWhere({ branch_id: { _eq: selectedBranchId } });
    }
  }, [selectedBranchId]);

  return {
    where
  };
}
