import { gql, useMutation } from '@apollo/client';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import TableRowAction from 'components/common/TableRowAction';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { KanbanContext } from 'context/Context';
import { useAuthContext } from 'context/authContext';
import { members } from 'data/kanban';
import { getDurationAgo } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useContext, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import CommentForm from './CommentForm';
import { toast } from 'react-toastify';
import { GET_PROJECT_BY_ID } from './KanbanModal';

const CREATE_PROJECT_COMMENT = gql`
  mutation CreateProjectComment(
    $comment: String = ""
    $project_id: uuid = ""
    $user_id: uuid = ""
  ) {
    insert_project_comments_one(
      object: { comment: $comment, project_id: $project_id, user_id: $user_id }
    ) {
      comment
      created_at
      id
      project_id
      user {
        last_name
        first_name
        id
      }
    }
  }
`;

const EDIT_PROJECT_COMMENT = gql`
  mutation EditProjectComment($comment: String = "", $id: uuid = "") {
    update_project_comments_by_pk(
      pk_columns: { id: $id }
      _set: { comment: $comment }
    ) {
      comment
      created_at
      id
      project_id
      user {
        last_name
        first_name
        id
      }
    }
  }
`;

const DELETE_PROJECT_COMMENT = gql`
  mutation DeleteProjectComment($id: uuid = "") {
    delete_project_comments_by_pk(id: $id) {
      id
      project_id
    }
  }
`;

const ModalCommentContent = ({ comments }) => {
  const { user: loggedInUser } = useAuthContext();
  const {
    kanbanState: { kanbanModal }
  } = useContext(KanbanContext);
  const createFormRef = useRef();
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [createProjectComment, { loading: createLoading }] = useMutation(
    CREATE_PROJECT_COMMENT,
    {
      onCompleted: () => {
        createFormRef.current.reset();
        toast.success('Comment created successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      },
      update: (cache, { data: { insert_project_comments_one } }) => {
        const { projects_by_pk } = cache.readQuery({
          query: GET_PROJECT_BY_ID,
          variables: {
            projectId: insert_project_comments_one.project_id
          }
        });

        cache.writeQuery({
          query: GET_PROJECT_BY_ID,
          variables: {
            projectId: insert_project_comments_one.project_id
          },
          data: {
            projects_by_pk: {
              ...projects_by_pk,
              project_comments: [
                insert_project_comments_one,
                ...projects_by_pk.project_comments
              ]
            }
          }
        });
      }
    }
  );
  const [editProjectComment, { loading: editLoading }] = useMutation(
    EDIT_PROJECT_COMMENT,
    {
      onCompleted: () => {
        hideEditForm();
        toast.success('Comment edited successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteProjectComment, { loading: deleteLoading }] = useMutation(
    DELETE_PROJECT_COMMENT,
    {
      onCompleted: () => {
        hideDeleteConfirmation();
        toast.success('Comment deleted successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      },
      update: (cache, { data: { delete_project_comments_by_pk } }) => {
        const { projects_by_pk } = cache.readQuery({
          query: GET_PROJECT_BY_ID,
          variables: {
            projectId: delete_project_comments_by_pk.project_id
          }
        });

        cache.writeQuery({
          query: GET_PROJECT_BY_ID,
          variables: {
            projectId: delete_project_comments_by_pk.project_id
          },
          data: {
            projects_by_pk: {
              ...projects_by_pk,
              project_comments: projects_by_pk.project_comments.filter(
                comment => comment.id !== delete_project_comments_by_pk.id
              )
            }
          }
        });
      }
    }
  );

  const onSubmitCreate = ({ comment }) => {
    createProjectComment({
      variables: {
        comment,
        project_id: kanbanModal.modalContent.id,
        user_id: loggedInUser.id
      }
    });
  };

  const onSubmitEdit = ({ comment }) => {
    editProjectComment({
      variables: {
        comment,
        id: selectedCommentId
      }
    });
  };

  const onSubmitDelete = () => {
    deleteProjectComment({
      variables: {
        id: selectedCommentId
      }
    });
  };

  const handleEdit = id => {
    setSelectedCommentId(id);
    setShowEditForm(true);
  };

  const handleDelete = id => {
    setSelectedCommentId(id);
    setShowDeleteConfirmation(true);
  };

  const hideDeleteConfirmation = () => {
    setSelectedCommentId(null);
    setShowDeleteConfirmation(false);
  };

  const hideEditForm = () => {
    setSelectedCommentId(null);
    setShowEditForm(false);
  };

  const userName = `${loggedInUser.first_name} ${loggedInUser.last_name}`;

  return (
    <>
      <ConfirmationModal
        show={showDeleteConfirmation}
        onHide={hideDeleteConfirmation}
        handleConfirm={onSubmitDelete}
        loading={deleteLoading}
        title="Delete Comment"
        body="Are you sure you want to delete this comment?"
      />
      <Flex>
        <Avatar
          src={loggedInUser.profile_img}
          name={userName}
          className="me-2"
          size="l"
        />
        <div className="flex-1 fs--1">
          <div className="position-relative border rounded mb-3">
            <CommentForm
              onSubmit={onSubmitCreate}
              loading={createLoading}
              ref={createFormRef}
            />
          </div>
        </div>
      </Flex>

      {comments?.map(({ comment, id, user, created_at }) => {
        const { first_name, last_name } = user;
        const name = `${first_name} ${last_name}`;
        const durationAgo = getDurationAgo(created_at);
        if (selectedCommentId === id && showEditForm) {
          return (
            <Flex key={id} className="mb-3">
              <Avatar src={members[3].img} className="me-2" size="l" />
              <div className="flex-1 fs--1">
                <div className="position-relative border rounded mb-3">
                  <CommentForm
                    onSubmit={onSubmitEdit}
                    loading={editLoading}
                    initialValues={{ comment }}
                    onCancel={hideEditForm}
                    isEdit
                  />
                </div>
              </div>
            </Flex>
          );
        }

        return (
          <Flex key={id} className="mb-3">
            <Link to="/user/profile">
              <Avatar src={user.profile_img} name={name} size="l" />
            </Link>
            <div className="flex-1 ms-2 fs--1">
              <Flex className="mb-1 bg-200 rounded-3" justifyContent="between">
                <p className="p-2">
                  <Link to="/user/profile" className="fw-semi-bold">
                    {name}
                  </Link>{' '}
                  {comment}
                </p>
                {loggedInUser.id === user.id && (
                  <div>
                    <TableRowAction
                      onCLickDelete={() => handleDelete(id)}
                      onCLickEdit={() => handleEdit(id)}
                    />
                  </div>
                )}
              </Flex>
              {/* <Link to="#!"> Like </Link> &bull;
            <Link to="#!"> Reply </Link> &bull;  */}
              {durationAgo}
            </div>
          </Flex>
        );
      })}
    </>
  );
};

ModalCommentContent.propTypes = {
  comments: PropTypes.array
};

export default ModalCommentContent;
