import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_URL
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // const token = localStorage.getItem(LOCAL_STORE_ACCESS_TOKEN);
  // const tokenType = localStorage.getItem(LOCAL_STORE_TOKEN_TYPE);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // authorization: token ? `${tokenType} ${token}` : '',
      'x-hasura-admin-secret': process.env.REACT_APP_HASURA_ADMIN_SECRET
    }
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-first'
    }
  }
});

export default client;
