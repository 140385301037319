import { gql, useLazyQuery } from '@apollo/client';
import LoadingContainer from 'components/common/LoadingContainer';
import { calculateAge } from 'helpers/utils';
import useBranchPermission from 'hooks/useBranchPermission';
import React, { useEffect, useMemo } from 'react';
import { BENEFICIARY_STATUS_UNSPONSORED } from './beneficiary-form/BeneficiaryForm';
import SponsorShipList from './sponsorship-content/SponsorShipList';

const GET_BENEFACTORS_BY_STATUS = gql`
  query GetBeneficiariesUnSponsored($where: orphans_bool_exp!) {
    orphans(order_by: { created_at: desc }, where: $where) {
      first_name
      middle_name
      gender
      country
      DOB
      gender
      sponsorship_condition {
        condition
      }
      sponsorship_priority {
        priority_type
      }
      sponsorship_type {
        type
      }
      id
      last_name
      image
    }
  }
`;

const UnSponsoredList = () => {
  const { where } = useBranchPermission();

  const [getBeneficiariesByStatus, { data, loading }] = useLazyQuery(
    GET_BENEFACTORS_BY_STATUS
  );

  useEffect(() => {
    if (Object.keys(where).length > 0) {
      getBeneficiariesByStatus({ variables: { where } });
      where.status = { _eq: BENEFICIARY_STATUS_UNSPONSORED };
    }
  }, [getBeneficiariesByStatus, where]);

  const tableData = useMemo(
    () =>
      data?.orphans.map(
        ({
          id,
          first_name,
          middle_name,
          last_name,
          DOB,
          country,
          sponsorship_priority,
          sponsorship_type,
          gender
        }) => ({
          id,
          name: `${first_name} ${middle_name ?? ''} ${last_name}`,
          age: calculateAge(DOB),
          location: country,
          sponsor: '-',
          renewal: ' -',
          priority: sponsorship_priority?.priority_type,
          type: sponsorship_type?.type,
          gender
        })
      ) ?? [],
    [data]
  );
  if (loading) return <LoadingContainer />;
  return (
    <SponsorShipList
      data={tableData}
      title="Unsponsored"
      initialLayout="cardview"
    />
  );
};

export default UnSponsoredList;
