import { gql, useQuery } from '@apollo/client';
import LoadingContainer from 'components/common/LoadingContainer';
import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';
import { useParams } from 'react-router-dom';

export const BeneficiaryContext = createContext({
  donor: null
});

/* -------------------------------------------------------------------------- */
export const GET_BENEFICIARY = gql`
  query GetBeneficiary($id: uuid = "") {
    orphans_by_pk(id: $id) {
      DOB
      branch_id
      city
      cost
      country
      created_at
      duration
      first_name
      last_name
      gender
      guardian_id
      health_notes
      id
      simple_id
      image
      inspiration
      language
      last_name
      location_condition
      location_id
      middle_name
      organisation_id
      sponsorship_condition_id
      sponsorship_priority_id
      sponsorship_type_id
      status
      updated_at
      guardian {
        first_name
        guardian_father_status {
          name
        }
        guardian_mother_status {
          name
        }
        guardian_relationShip {
          name
        }
        middle_name
        last_name
        phone
        created_at
        email
        address
      }
      sponsorships {
        id
        donar {
          id
          address
          consented_date
          first_name
          first_name
          email
          gift_aid_elgibility
          gift_aid_file
          id
          last_name
          lead_source
          user {
            id
            first_name
            last_name
          }
        }
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

export function BeneficiaryContextProvider({ children }) {
  const { id } = useParams();
  const {
    data: beneficiaryData,
    loading: beneficiaryLoading
    // refetch
  } = useQuery(GET_BENEFICIARY, {
    variables: { id }
  });

  // const getBeneficiaryData = () => {
  //   refetch();
  // };

  return (
    <BeneficiaryContext.Provider
      value={{
        beneficiary: beneficiaryData?.orphans_by_pk ?? {}
      }}
    >
      {beneficiaryLoading ? <LoadingContainer /> : children}
    </BeneficiaryContext.Provider>
  );
}

BeneficiaryContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useBeneficiaryContext = () => {
  const context = useContext(BeneficiaryContext);
  if (context === undefined) {
    throw new Error(
      'useDonorContext must be used within a BeneficiaryContextProvider'
    );
  }
  return context;
};

export default BeneficiaryContext;
