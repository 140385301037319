import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFControl } from 'components/common/form';

const schema = Yup.object().shape({
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number'
    )
    .min(8, 'Must be at least 8 characters')
    .required('Required Field'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
});

const PasswordResetForm = ({ onSubmit, loading }) => {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      confirmPassword: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, watch } = methods;
  const { password, confirmPassword } = watch();

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={3} direction="column" className="mt-3">
        <RHFControl
          name="password"
          type="password"
          placeholder="New Password"
        />
        <RHFControl
          name="confirmPassword"
          type="password"
          placeholder="Confirm Password"
        />

        <LoadingButton
          type="submit"
          className="w-100"
          disabled={!password || !confirmPassword}
          loading={loading}
        >
          Set password
        </LoadingButton>
      </Flex>
    </FormProvider>
  );
};

PasswordResetForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default PasswordResetForm;
