import { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import GiftAidApplicationsList from 'components/gift-aid/gift-aid-applications';
import UnclaimedTransactions from 'components/gift-aid/unclaimed-transactions';
import GiftAidApplicationDetails from 'components/gift-aid/application-details';
import {
  weTableClaimedGiftAid,
  weTableUnclaimedTransactions
} from 'data/weraise-data/sponsored-list-data';
import UserContext from './../../context/UserContext';
import { getRelativePath } from 'helpers/utils';
import paths from 'routes/paths';
import PermissionWrapper from 'routes/PermissionWrapper';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';

export default function GiftAid() {
  const [giftAidData, setGiftAidData] = useState({
    claimed: weTableClaimedGiftAid,
    unclaimed: weTableUnclaimedTransactions
  });

  return (
    <UserContext.Provider value={{ giftAidData, setGiftAidData }}>
      <Routes>
        <Route
          path={getRelativePath(paths.giftAidApplicationsList, paths.giftAid)}
          element={<GiftAidApplicationsList />}
        />
        <Route
          path={getRelativePath(paths.giftAidApplicationCreate, paths.giftAid)}
          element={
            <PermissionWrapper
              permissionKey={permissionsKeyMap.giftAid.children.giftAid}
              action={userActions.create}
            >
              <UnclaimedTransactions />
            </PermissionWrapper>
          }
        />
        <Route
          path={getRelativePath(paths.giftAidApplicationDetails, paths.giftAid)}
          element={<GiftAidApplicationDetails />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={getRelativePath(paths.giftAidApplicationsList, paths.giftAid)}
            />
          }
        />
      </Routes>
    </UserContext.Provider>
  );
}
