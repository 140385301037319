import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Row, Col } from 'react-bootstrap';
import { gql, useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import dayjs from 'dayjs';

/* -------------------------------------------------------------------------- */
const GET_DONARS_COUNT = gql`
  query GetdonarsCount($where: donars_bool_exp!) {
    donars_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`;

const GET_DONATION_AMOUNT = gql`
  query GetdonationAmount($organisation_id: uuid!) {
    donation_amount_aggregate(
      where: { organisation_id: { _eq: $organisation_id } }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */
const TotalStatItem = ({ stat }) => {
  return (
    <Col xs={4} md={2}>
      <h6 className="pb-1 text-700">{stat.title}</h6>
      <p className="font-sans-serif lh-1 mb-1 fs-2">{stat.amount}</p>
    </Col>
  );
};

TotalStatItem.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  index: PropTypes.number,
  lastIndex: PropTypes.number
};

const TotalStat = () => {
  const { organization } = useContext(AuthContext);

  const now = dayjs();
  const startOfWeek = now.startOf('week').add(1, 'day').toISOString(); // Start of the week
  const endOfWeek = now.endOf('week').add(1, 'day').toISOString(); // End of the week

  const { data: donarsCount } = useQuery(GET_DONARS_COUNT, {
    variables: {
      where: {
        organisation_id: {
          _eq: organization.id
        }
      }
    }
  });

  const { data: donationsAmount } = useQuery(GET_DONATION_AMOUNT, {
    variables: {
      organisation_id: organization.id
    }
  });

  const { data: donorsThisWeek } = useQuery(GET_DONARS_COUNT, {
    variables: {
      where: {
        organisation_id: {
          _eq: organization.id
        },
        created_at: { _gte: startOfWeek, _lte: endOfWeek }
      }
    }
  });

  const totalStats = [
    {
      title: 'Total Donors',
      amount: donarsCount?.donars_aggregate?.aggregate?.count
    },
    {
      title: 'Total Donations',
      amount: `$${donationsAmount?.donation_amount_aggregate?.aggregate?.sum?.amount}`
    },
    {
      title: 'New Donors this week',
      amount: donorsThisWeek?.donars_aggregate?.aggregate?.count
    }
  ];

  return (
    <Card className="py-3 mb-3">
      <Card.Body className="py-3">
        <Row className="g-0">
          {totalStats.map((stat, index) => (
            <TotalStatItem
              key={stat.title}
              stat={stat}
              index={index}
              lastIndex={totalStats.length - 1}
            />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

TotalStat.propTypes = {
  totalStats: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default TotalStat;
