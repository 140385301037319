import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import SchoolModalForm from './SchoolModalForm';

function AddSchoolModal({ show, onSubmit, isSubmitLoading, onHide, title }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title className="modal-header-title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-3 ps-3 pe-3">
        <SchoolModalForm
          onSubmit={onSubmit}
          isSubmitLoading={isSubmitLoading}
        />
      </Modal.Body>
    </Modal>
  );
}
AddSchoolModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string
};
export default AddSchoolModal;
