import countriesData from '../countries.json';

export const getCountriesOptions = () =>
  Object.keys(countriesData).map(country => ({
    label: country,
    value: country
  }));
export const getCitiesOptions = countryName =>
  countriesData[countryName]?.map(city => ({
    label: city,
    value: city
  })) || [];
