import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';

function DonorCard({ editable, handleChangeClick, name, score, image }) {
  return (
    <Flex
      className={'border p-3 rounded-3'}
      alignItems="center"
      justifyContent={'between'}
    >
      <Flex alignItems={'center'}>
        <Avatar src={image} name={name} size="3xl" />
        <div className="ms-3">
          <p className="mb-0 fw-medium">{name}</p>
          <p className="mb-0 fs--1 text-400 fw-medium">
            Score: <span className="text-success">{score ?? 0}</span>
          </p>
        </div>
      </Flex>
      {editable && (
        <IconButton
          onClick={handleChangeClick}
          icon={'pen'}
          variant="outline-primary"
        >
          <span className="d-none d-md-inline-block">Change</span>
        </IconButton>
      )}
    </Flex>
  );
}

DonorCard.propTypes = {
  editable: PropTypes.bool,
  handleChangeClick: PropTypes.func,
  name: PropTypes.string,
  score: PropTypes.number,
  image: PropTypes.string
};

export default DonorCard;
