import IconButton from 'components/common/IconButton';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import paths from 'routes/paths';
import { useNavigate } from 'react-router-dom';
import { Can } from 'routes/permissions/Can';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';

const SponsoredHeader = ({
  selectedRowIds,
  setLayout,
  layout,
  selectedItems,
  handleTicketsSearch,
  totalItems,
  title,
  sponsorLick
}) => {
  const navigate = useNavigate();
  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">
            {title} ({totalItems})
          </h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative table-input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Search by name"
              onChange={e => handleTicketsSearch(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-end px-x1">
        {Object.keys(selectedRowIds).length > 0 || selectedItems.length > 0 ? (
          <div className="d-flex">
            <Form.Select size="sm" aria-label="Bulk actions">
              <option>Bulk Actions</option>
              <option value="refund">Refund</option>
              <option value="delete">Delete</option>
              <option value="archive">Archive</option>
            </Form.Select>
            <Button
              type="button"
              variant="falcon-default"
              size="sm"
              className="ms-2"
            >
              Apply
            </Button>
          </div>
        ) : (
          <div id="orders-actions">
            <Can
              I={userActions.create}
              a={permissionsKeyMap.sponsorship.children.sponsorships}
            >
              <IconButton
                variant="primary"
                size="sm"
                icon="link"
                transform="shrink-3"
                className="me-2"
                onClick={sponsorLick}
                // disabled
              >
                <span className="ms-1 d-none d-lg-inline-block">Sponsor</span>
              </IconButton>
            </Can>
            <Can
              I={userActions.create}
              a={permissionsKeyMap.sponsorship.children.beneficiaries}
            >
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="child"
                transform="shrink-3"
                className="me-2"
                onClick={() => navigate(paths.addBeneficiary)}
              >
                <span className="ms-1 d-none d-lg-inline-block">
                  Add Beneficiary
                </span>
              </IconButton>
            </Can>
            {/* <IconButton
              variant="falcon-default"
              size="sm"
              icon="filter"
              transform="shrink-3"
              className="me-2"
              iconAlign="middle"
            >
              <span className="d-none d-sm-inline-block ms-2">Filter</span>
            </IconButton> */}
            <IconButton
              variant="falcon-default"
              size="sm"
              icon={layout !== 'listView' ? 'list' : 'th-large'}
              transform="shrink-3"
              iconAlign="middle"
              onClick={() =>
                setLayout(preState =>
                  preState !== 'listView' ? 'listView' : 'cardView'
                )
              }
            >
              <span className="ms-2">
                {layout !== 'listView' ? 'List' : 'Card'}
              </span>
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
};

SponsoredHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  layout: PropTypes.string,
  title: PropTypes.string,
  setLayout: PropTypes.func,
  selectedItems: PropTypes.array,
  handleTicketsSearch: PropTypes.func,
  sponsorLick: PropTypes.func,
  totalItems: PropTypes.number
};

export default SponsoredHeader;
