import * as Yup from 'yup';

export const donorScheme = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),

  addressLine1: Yup.string().required('Required Field'),
  addressLine2: Yup.string(),
  city: Yup.string().required('Required Field'),
  country: Yup.string().required('Required Field'),
  postcode: Yup.string().required('Required Field'),
  isPrimary: Yup.boolean().required('Required Field'),

  email: Yup.string().email().required('Required Field'),

  phoneNumber: Yup.string().required('Required Field'),
  type: Yup.string().required('Required Field')
});
