import * as Yup from 'yup';

export const packageScheme = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  currency: Yup.string().required('Required Field'),
  targetAmount: Yup.number()
    .typeError('Enter a valid number')
    .required('Required Field'),
  description: Yup.string(),
  // redirectUrl: Yup.string().matches(
  //   /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
  //   'Enter correct url'
  // ),
  redirectUrl: Yup.string().when('openRedirectUrl', {
    is: true,
    then: () =>
      Yup.string().matches(
        /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
        'Enter correct url'
      )
  })
});
