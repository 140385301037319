import React from 'react';
import PropTypes from 'prop-types';
import { Nav, Row, Col } from 'react-bootstrap';
import { getFlatRoutes } from 'helpers/utils';
import NavbarNavLink from './NavbarNavLink';

const MainProjectDropdown = ({ items }) => {
  const routes = getFlatRoutes(items);

  return (
    <Row>
      <Col xs={6}>
        <Nav className="flex-column">
          {routes.unTitled.map(route => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
          <NavbarNavLink label="Somalia" title="Somalia" />
          {routes.somalia.map(route => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
          <NavbarNavLink label="Kenya" title="Kenya" />
          {routes.kenya.map(route => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
        </Nav>
      </Col>
      <Col xs={6}>
        <Nav className="flex-column">
          <NavbarNavLink label="United Kingdom" title="United Kingdom" />
          {routes.uk.map(route => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
          <NavbarNavLink label="Ethiopia" title="Ethiopia" />
          {routes.ethiopia.map(route => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
          <NavbarNavLink label="Yemen" title="Yemen" />
          {routes.yemen.map(route => (
            <NavbarNavLink key={route.name} route={route} />
          ))}
        </Nav>
      </Col>
    </Row>
  );
};

MainProjectDropdown.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      name: PropTypes.string.isRequired,
      to: PropTypes.string,
      children: PropTypes.array
    }).isRequired
  ).isRequired
};

export default MainProjectDropdown;
