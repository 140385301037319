import imageMember3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';

export const shoppingCartItems = [
  {
    id: 1,
    title: 'Initiated',
    amount: 43.6,
    barWidth: 50,
    items: ' Session: <span class ="text-600">6817</span> ',
    variant: 'primary',
    iconColor: 'success',
    icon: 'caret-up'
  },
  {
    id: 2,
    title: 'Abandonment rate',
    amount: 13.11,
    barWidth: 25,
    items: '<span class ="text-600">44</span>  of 61',
    variant: 'danger',
    iconColor: 'danger',
    icon: 'caret-up'
  },
  {
    id: 3,
    title: 'Bounce rate',
    amount: 12.11,
    barWidth: 35,
    items: '<span class ="text-600">8</span>  of 61',
    variant: 'primary',
    iconColor: 'success',
    icon: 'caret-up'
  },
  {
    id: 4,
    title: 'Completion rate',
    amount: 43.6,
    barWidth: 43,
    items: '<span class ="text-600">18</span>  of 179',
    variant: 'primary',
    iconColor: 'danger',
    icon: 'caret-down'
  },
  {
    id: 5,
    title: 'Revenue Rate',
    amount: 60.5,
    barWidth: 60,
    items: '<span class ="text-600">18</span>  of 179',
    variant: 'primary',
    iconColor: 'success',
    icon: 'caret-up'
  }
];

export const todoList = [
  {
    id: 1,
    task: 'Design a facebook ad',
    color: 'primary'
  },
  {
    id: 2,
    task: 'Analyze Data',
    color: 'secondary'
  },
  {
    id: 3,
    task: 'Youtube campaign',
    color: 'success'
  },
  {
    id: 4,
    task: 'Assign 10 employee',
    color: 'warning'
  },
  {
    id: 5,
    task: 'Meeting at 12',
    color: 'danger'
  },
  {
    id: 6,
    task: 'Meeting at 10',
    color: 'info'
  }
];

export const autoCompleteInitialItem = [
  {
    id: 9,
    url: '/user/profile',
    catagories: 'suggestionMembers',
    icon: {
      img: <Avatar src={''} rounded="circle" size="xl" name={name} />,
      size: 'l'
    },
    title: 'Antony Hopkins',
    text: 'Brain Trust'
  },
  {
    id: 10,
    url: '/user/profile',
    catagories: 'suggestionMembers',
    icon: {
      img: imageMember3,
      size: 'l'
    },
    title: 'Emma Watson',
    text: 'Google'
  }
];

export const weProjectProgressData = [
  {
    id: '101',
    title: 'HY Orphanage | Foundation Construction',
    description:
      'Ut rutrum cursus lacinia. Praesent consectetur semper nunc, sit amet rutrum dui commodo a. Lorem ipsum dolor.',
    date: '25 September, 2022',
    ownerName: 'Ali Ibrahim'
  },
  {
    id: '102',
    title: 'HY Orphanage | Foundation Construction',
    description:
      'Ut rutrum cursus lacinia. Praesent consectetur semper nunc, sit amet rutrum dui commodo a. Lorem ipsum dolor.',
    date: '25 September, 2022',
    ownerName: 'Ali Ibrahim'
  },
  {
    id: '103',
    title: 'HY Orphanage | Foundation Construction',
    description:
      'Ut rutrum cursus lacinia. Praesent consectetur semper nunc, sit amet rutrum dui commodo a. Lorem ipsum dolor.',
    date: '25 September, 2022',
    ownerName: 'Ali Ibrahim'
  },
  {
    id: '104',
    title: 'HY Orphanage | Foundation Construction',
    description:
      'Ut rutrum cursus lacinia. Praesent consectetur semper nunc, sit amet rutrum dui commodo a. Lorem ipsum dolor.',
    date: '25 September, 2022',
    ownerName: 'Ali Ibrahim'
  },
  {
    id: '105',
    title: 'HY Orphanage | Foundation Construction',
    description:
      'Ut rutrum cursus lacinia. Praesent consectetur semper nunc, sit amet rutrum dui commodo a. Lorem ipsum dolor.',
    date: '25 September, 2022',
    ownerName: 'Ali Ibrahim'
  }
];

export const weDonationPackages = [
  {
    reference: 'B00001',
    title: 'Food Package',
    branch: 'Help Yateem UK',
    status: 'active'
  },
  {
    reference: 'B00002',
    title: 'Food Package',
    branch: 'Help Yateem UK',
    status: 'inactive'
  },
  {
    reference: 'B00003',
    title: 'Adam Abdullah Ali',
    branch: 'Help Yateem UK',
    status: 'active'
  },
  {
    reference: 'B00004',
    title: 'Ahmed Zaid Adel',
    branch: 'Help Yateem Yemen',
    status: 'active'
  },
  {
    reference: 'B00005',
    title: 'Food Package',
    branch: 'Help Yateem Somalia',
    status: 'inactive'
  },
  {
    reference: 'B00006',
    title: 'Ayman Sameh Reda',
    branch: 'Help Yateem UK',
    status: 'inactive'
  },
  {
    reference: 'B00007',
    title: 'Food Package',
    branch: 'Help Yateem UK',
    status: 'active'
  },
  {
    reference: 'B00008',
    title: 'Ayman Sameh Reda',
    branch: 'Help Yateem Sudan',
    status: 'active'
  },
  {
    reference: 'B00009',
    title: 'Ahmed Zaid Adel',
    branch: 'Help Yateem Somalia',
    status: 'active'
  }
];

export const weUrgentAppeals = [
  {
    reference: 'B00001',
    title: 'Iftar Package',
    branch: 'Help Yateem UK',
    status: 'active'
  },
  {
    reference: 'B00002',
    title: 'Sponsor Package',
    branch: 'Help Yateem UK',
    status: 'inactive'
  },
  {
    reference: 'B00003',
    title: 'Orphanages  Package',
    branch: 'Help Yateem UK',
    status: 'active'
  },
  {
    reference: 'B00004',
    title: 'Food Package Ye',
    branch: 'Help Yateem Yemen',
    status: 'active'
  },
  {
    reference: 'B00005',
    title: 'Food Package So',
    branch: 'Help Yateem Somalia',
    status: 'inactive'
  },
  {
    reference: 'B00006',
    title: 'Food Package So',
    branch: 'Orphanages  Package',
    status: 'inactive'
  },
  {
    reference: 'B00007',
    title: 'Food Package UK',
    branch: 'Help Yateem UK',
    status: 'active'
  },
  {
    reference: 'B00008',
    title: 'Food Package Eg',
    branch: 'Help Yateem Sudan',
    status: 'active'
  },
  {
    reference: 'B00009',
    title: 'Food Package So',
    branch: 'Help Yateem Somalia',
    status: 'active'
  },
  {
    reference: 'B00010',
    title: 'Food Package So',
    branch: 'Help Yateem Somalia',
    status: 'active'
  },
  {
    reference: 'B00011',
    title: 'Food Package So',
    branch: 'Help Yateem Somalia',
    status: 'active'
  },
  {
    reference: 'B00012',
    title: 'Food Package So',
    branch: 'Help Yateem Somalia',
    status: 'active'
  },
  {
    reference: 'B00013',
    title: 'Food Package So',
    branch: 'Help Yateem Somalia',
    status: 'active'
  }
];
