import { yupResolver } from '@hookform/resolvers/yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFControl } from 'components/common/form';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  subject: Yup.string().required('Required Field'),
  message: Yup.string().required('Required Field')
});

function AddLog({ show, onSubmit, onHide, title, loading }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      subject: '',
      message: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit } = methods;

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-header-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3 ps-3 pe-3">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
          >
            <Flex gap={3} direction="column" className="mt-3">
              <Row>
                <Col md={12}>
                  <RHFControl
                    name="subject"
                    label="Subject"
                    placeholder="Subject"
                    required
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <RHFControl
                    as="textarea"
                    name="message"
                    label="Message"
                    placeholder="Message"
                    required
                  />
                </Col>
              </Row>

              <LoadingButton
                style={{ width: '100%', color: 'white' }}
                variant="primary"
                loading={loading}
                disabled={loading}
                className="w-100"
                type="submit"
              >
                {'Submit'}
              </LoadingButton>
            </Flex>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}

AddLog.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  confirmText: PropTypes.string,
  onSubmit: PropTypes.func
};
export default AddLog;
