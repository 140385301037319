import Flex from 'components/common/Flex';
import TableRowAction from 'components/common/TableRowAction';
import { useContext, useState, useEffect } from 'react';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import paths from 'routes/paths';
import { gql, useMutation, useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import { parseRouteParams } from 'helpers/utils';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import ExportModal from 'components/common/ExportModal';
import { GET_BRANCHES } from 'components/settings/branches/Branches';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

export const GET_URGENT_APPEALS_BY_ORGANIZATION = gql`
  query GetUrgentAppealsByOrganization($organizationId: uuid!) {
    urgent_appeals(where: { organisation_id: { _eq: $organizationId } }) {
      title
      id
      organisation_id
      branch_id
      description
      targeted_amount
      is_display
    }
  }
`;

const DELETE_URGENT_APPEAL = gql`
  mutation DeletePackage($id: uuid!) {
    delete_urgent_appeals_by_pk(id: $id) {
      id
    }
  }
`;

const CHANGE_DISPLAY_STATUS = gql`
  mutation ChangeDisplayStatus($id: uuid!, $isDisplay: Boolean!) {
    update_urgent_appeals_by_pk(
      pk_columns: { id: $id }
      _set: { is_display: $isDisplay }
    ) {
      branch_id
      created_at
      description
      id
      images
      is_display
      package_id
      organisation_id
      targeted_amount
      updated_at
      title
    }
  }
`;

const UrgentAppeals = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { organization } = useContext(AuthContext);
  const [finalData, setFinalData] = useState([]);
  const { data, loading } = useQuery(GET_URGENT_APPEALS_BY_ORGANIZATION, {
    variables: { organizationId: organization.id }
  });
  const [showExportModal, setShowExportModal] = useState(false);
  const { data: branches } = useQuery(GET_BRANCHES, {
    variables: {
      organisation_id: organization.id
    }
  });
  const [deleteUrgentAppeal, { loading: deleteLoading }] = useMutation(
    DELETE_URGENT_APPEAL,
    {
      onCompleted: () => {
        handleDeleteModalHide();
        toast.success('Urgent Appeal deleted successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      },
      update: (cache, { data }) => {
        const existingUrgentAppeals = cache.readQuery({
          query: GET_URGENT_APPEALS_BY_ORGANIZATION,
          variables: { organizationId: organization.id }
        });
        if (!existingUrgentAppeals) return;
        const newUrgentAppeals = existingUrgentAppeals.urgent_appeals.filter(
          donationPackage =>
            donationPackage.id !== data.delete_urgent_appeals_by_pk.id
        );
        cache.writeQuery({
          query: GET_URGENT_APPEALS_BY_ORGANIZATION,
          variables: { organizationId: organization.id },
          data: { urgent_appeals: newUrgentAppeals }
        });
      }
    }
  );
  useEffect(() => {
    const dataTmp = data?.urgent_appeals.map(itemAppeal => {
      const branch = branches?.branches?.find(
        item => item.id === itemAppeal.branch_id
      ).name;
      return { ...itemAppeal, branch };
    });
    setFinalData(dataTmp);
  }, [data, branches]);

  const navigate = useNavigate();
  const columns = [
    {
      accessor: 'id',
      Header: 'Reference',
      cellProps: {
        className: 'py-2 fw-medium '
      }
    },
    {
      accessor: 'title',
      Header: 'Appeal Title',
      cellProps: {
        className: 'py-2 fw-medium '
      },
      Cell: rowData => {
        const { title } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <Button
              variant="link"
              // onClick={() => setModalShow(true)}
              className="fw-medium fs--1 p-0 text-1100 hover-primary"
            >
              {title}
            </Button>
          </Flex>
        );
      }
    },
    {
      accessor: 'branch',
      Header: 'Branch',
      cellProps: {
        className: 'py-2 fw-medium'
      }
    },
    {
      accessor: 'status',
      Header: 'Display (Max 6)',
      cellProps: {
        className: 'fw-medium  py-2'
      },
      Cell: rowData => {
        const { is_display, id } = rowData.row.original;
        return <DisplayToggle id={id} checked={is_display} />;
      }
    },
    {
      accessor: 'id_d',
      Header: '',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end fw-medium py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row;
        return (
          <TableRowAction
            onCLickEdit={() =>
              navigate(parseRouteParams(paths.editUrgentAppeal, { id }))
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.donation.children.urgentAppeals}
          />
        );
      }
    }
  ];
  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };
  const handleDeleteModalHide = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    deleteUrgentAppeal({
      variables: {
        id: selectedId
      }
    });
  };
  const handleExport = () => {
    setShowExportModal(true);
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleDeleteModalHide}
        loading={deleteLoading}
        title="Delete Urgent Appeal"
        handleConfirm={handleConfirmDelete}
        body="Are you sure you want to delete selected urgent appeal?"
      />
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {finalData && (
            <CustomAdvanceTable
              data={finalData}
              columns={columns}
              title={'Urgent Appeals'}
              showSearchInput
              searchInputPlaceHolder="Search"
              showTablePagination={false}
              perPage={10}
              addButtonLabel="Create New Appeal"
              onClickAddNew={() => navigate(paths.createUrgentAppeal)}
              loading={loading}
              addNew
              hasExport
              handleExport={handleExport}
              subject={permissionsKeyMap.donation.children.urgentAppeals}
            />
          )}
        </Col>
      </Row>
      <ExportModal
        data={finalData || []}
        columns={columns.slice(0, columns.length - 1)}
        show={showExportModal}
        onHide={() => setShowExportModal(false)}
        fileName={'Urgent Appeals'}
      />
    </>
  );
};

const DisplayToggle = ({ checked, id }) => {
  const [changeDisplayStatus, { loading }] = useMutation(
    CHANGE_DISPLAY_STATUS,
    {
      onCompleted: () => {
        toast.success('Urgent Appeal display status changed successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const handleChange = e => {
    changeDisplayStatus({
      variables: {
        id,
        isDisplay: e.target.checked
      }
    });
  };

  return (
    <Form.Check
      type="switch"
      defaultChecked={checked}
      checked={checked}
      value={checked}
      onChange={handleChange}
      disabled={loading}
    />
  );
};

DisplayToggle.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string
};

export default UrgentAppeals;
