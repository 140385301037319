import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';

import Flex from 'components/common/Flex';
import {
  FormProvider,
  RHFControl,
  RHFPhoneInput,
  RHFSelect
} from 'components/common/form';
import { donorScheme } from './donor-form/donorScheme';
import { titleOptions } from 'components/authenitcation/registration/OwnerRegistrationForm';
import { useEffect, useMemo, useState } from 'react';
import {
  getCitiesOptions,
  getCountriesOptions
} from 'data/options/countris-and-cities';
import { numberTypeOptions } from 'components/donor/donor-form/PhoneNumberInputs';
import LoadingButton from 'components/common/LoadingButton';

const DonorForm = ({ onSubmit, handleBack }) => {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      type: 'personal',
      addressLine1: '',
      addressLine2: '',
      city: '',
      country: '',
      postcode: '',
      isPrimary: true
    },
    resolver: yupResolver(donorScheme)
  });
  const { handleSubmit, watch, setValue } = methods;

  const submit = data => {
    onSubmit(data);
  };

  const country = watch('country');
  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const citiesOptions = useMemo(() => {
    if (country) {
      return getCitiesOptions(country);
    }
    return [];
  }, [country]);

  const [addressType, setAddressType] = useState('UK_Address');
  const [postCode, setPostCode] = useState('');
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAddresses = async postCode => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `https://api.getaddress.io/autocomplete/${postCode}?api-key=${process.env.REACT_APP_GET_ADDRESS_APIKEY}&all=true`
      );

      const data = await response.json();
      setAddressList(data.suggestions);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addressOptions = addressList?.map(({ address, id }) => ({
    label: address,
    value: id
  }));

  const fetchAddressDetails = async addressId => {
    try {
      const response = await fetch(
        `https://api.getaddress.io/get/${addressId}?api-key=${process.env.REACT_APP_GET_ADDRESS_APIKEY}`
      );

      const data = await response.json();

      setSelectedAddress(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleManualAddressEntry = () => {
    setAddressType('Non-UK_Address');
    setSelectedAddress(null); // Clear the selected address when switching to manual entry
  };

  const handleAddressSelect = e => {
    fetchAddressDetails(e?.value);
  };

  useEffect(() => {
    if (selectedAddress) {
      setValue('addressLine1', selectedAddress.line_1);
      setValue('addressLine2', selectedAddress.line_2);
      setValue('city', selectedAddress.town_or_city);
      setValue('country', 'United Kingdom');
      setValue('postcode', selectedAddress.postcode);
    }
  }, [selectedAddress, setValue]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchAddresses(postCode);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(submit)}>
      <Card className="we-card-shadow">
        <Card.Header className="p-4">
          <Card.Title className="text-uppercase mb-0">
            Enter your information
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-4 pt-0 pb-3">
          <Row className="g-3">
            <Col md="6">
              <RHFSelect
                name="title"
                label="Title"
                options={titleOptions}
                required
              />
            </Col>
            <Col md="6" className="d-none d-md-block" />
            <Col md="6">
              <RHFControl
                name="firstName"
                placeholder="First name"
                label="First name"
                required
              />
            </Col>
            <Col md="6">
              <RHFControl
                name="lastName"
                placeholder="Last name"
                label="Last name"
                required
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12} className="mb-4">
              <RHFControl
                name={'email'}
                type="email"
                placeholder={'Email'}
                label={'Email'}
                required
              />
            </Col>
            <Col md={6} className="mb-3">
              <RHFPhoneInput name={'phoneNumber'} />
            </Col>
            <Col md={3}>
              <RHFSelect name={'type'} options={numberTypeOptions} required />
            </Col>
          </Row>
          <hr />

          <Card.Body>
            <Flex direction="row" gap={2} className="mb-3">
              <Button
                variant={
                  addressType === 'UK_Address'
                    ? 'outline-primary'
                    : 'outline-secondary'
                }
                onClick={() => setAddressType('UK_Address')}
              >
                UK Address
              </Button>{' '}
              <Button
                variant={
                  addressType === 'Non-UK_Address'
                    ? 'outline-primary'
                    : 'outline-secondary'
                }
                onClick={() => setAddressType('Non-UK_Address')}
              >
                Non-UK Address
              </Button>{' '}
            </Flex>

            {addressType === 'UK_Address' && (
              <>
                <Form.Label> Post Code </Form.Label>
                <InputGroup className="mb-4">
                  <Form.Control
                    type="text"
                    placeholder="Enter Post Code"
                    onChange={e => setPostCode(e.target.value)}
                    onKeyDown={handleKeyDown}
                    required
                  />

                  <LoadingButton
                    loading={isLoading}
                    variant="primary"
                    onClick={() => fetchAddresses(postCode)}
                  >
                    Search
                  </LoadingButton>
                </InputGroup>

                {!!addressList.length && (
                  <RHFSelect
                    name="type"
                    label="Select your address"
                    options={addressOptions}
                    required
                    onChange={handleAddressSelect}
                    className="mb-2"
                  />
                )}
              </>
            )}

            {(addressType === 'Non-UK_Address' || selectedAddress) && (
              <>
                <Row className="g-3">
                  <Col md="12">
                    <RHFControl
                      name="postcode"
                      label="Post Code"
                      placeholder="add Zip/post Code"
                      required
                    />
                  </Col>
                  <Col md="12">
                    <RHFControl
                      name="addressLine1"
                      label="First Line Address"
                      placeholder="add Address Line 1"
                      required
                    />
                  </Col>
                  <Col md="12">
                    <RHFControl
                      name="addressLine2"
                      label="Second Line Address"
                      placeholder="add Address Line 2"
                    />
                  </Col>
                  <Col md="6">
                    <RHFSelect
                      label="Country"
                      name="country"
                      options={countriesOptions}
                      required
                    />
                  </Col>{' '}
                  <Col md="6">
                    <RHFSelect
                      label="City"
                      name="city"
                      options={citiesOptions}
                      disabled={!country}
                      required
                    />
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>

          {addressType === 'UK_Address' && (
            <Button
              variant="link"
              className="text-primary mx-auto"
              onClick={handleManualAddressEntry}
            >
              Enter Address Manually{' '}
            </Button>
          )}

          {/* <Card.Body className="p-0 m-0">
            <Row className="g-3">
              <Col md="12">
                <RHFControl
                  name={'postcode'}
                  label="Post Code"
                  placeholder="add Zip/post Code"
                  required
                />
              </Col>
              <Col md="12">
                <RHFControl
                  name={'addressLine1'}
                  label="Address Line 1"
                  placeholder="add Address Line 1"
                  required
                />
              </Col>
              <Col md="12">
                <RHFControl
                  name={'addressLine2'}
                  label="Address Line 2"
                  placeholder="add Address Line 2"
                />
              </Col>
              <Col md={6}>
                <RHFSelect
                  label={'Country'}
                  name={'country'}
                  options={countriesOptions}
                  required
                />
              </Col>
              <Col md={6}>
                <RHFSelect
                  label={'City'}
                  name={'city'}
                  options={citiesOptions}
                  disabled={!country}
                  required
                />
              </Col>
            </Row>
          </Card.Body> */}
        </Card.Body>
      </Card>
      <Flex
        justifyContent="between"
        alignItems="center"
        className="mt-4 flex-column flex-md-row"
      >
        <Button
          variant="outline-secondary"
          className="h-52 bg-300 hover-bg-primary border-0 w-215px"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button className="h-52 border-0 w-215px mt-3 mt-md-0" type="submit">
          NEXT
        </Button>
      </Flex>
    </FormProvider>
  );
};
DonorForm.propTypes = {
  onSubmit: PropTypes.func,
  handleBack: PropTypes.func
};
export default DonorForm;
