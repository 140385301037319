import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import corner1 from 'assets/img/icons/spot-illustrations/corner-3.png';
import Background from 'components/common/Background';
import CardDropdown from 'components/common/CardDropdown';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import AuthContext from 'context/authContext';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AddLinkFormModal from './AddLinkFormModal';
import { INSERT_SHORTCUT } from 'components/beneficiaries/mutations';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_SHORTCUT } from 'components/beneficiaries/queries';

// eslint-disable-next-line no-unused-vars
const Greetings = ({ data, showBg = true, name }) => {
  const [show, setShow] = useState(false);

  const [addShortcut] = useMutation(INSERT_SHORTCUT, {
    refetchQueries: ['GetShortcut'],
    onCompleted: () => {
      toast.success('Orphans Added Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });
  const { organization } = useContext(AuthContext);

  const { data: shortcutData } = useQuery(GET_SHORTCUT, {
    fetchPolicy: 'network-only',
    variables: {
      organisation_id: organization.id
    }
  });
  const onSubmit = data => {
    addShortcut({
      variables: {
        link: data.link,
        name: data.title,
        organisation_id: organization.id,
        description: data.description
      }
    });
    setShow(false);
  };
  return (
    <Card className="h-100">
      {showBg && (
        <Background image={corner1} className="rounded-soft bg-card" />
      )}
      <FalconCardHeader
        title={
          <div>
            <h2 className="text-primary">
              <FontAwesomeIcon icon="lightbulb" /> Hello, {name} !
            </h2>
            <h6 className="text-600">
              Here are some quick links for you to start
            </h6>
          </div>
        }
        light={false}
        titleTag="div"
        className="pb-1"
        menuClassName="align-items-start"
        endEl={
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                className="text-primary"
                onClick={() => {
                  setShow(true);
                }}
              >
                Add
              </Dropdown.Item>
            </div>
          </CardDropdown>
        }
      />

      <Card.Body className="z-index-1">
        <Row className="g-2 h-100 align-items-end">
          {shortcutData?.shortcuts.map((item, index) => {
            return (
              <Col sm={6} md={5} xl key={index}>
                <Flex className="position-relative">
                  <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                    <FontAwesomeIcon
                      icon={'fa-solid fa-link'}
                      className={'text-primary'}
                    />
                  </div>
                  <div className="flex-1">
                    <Link to={item.link} className="stretched-link text-800">
                      <h6 className="mb-0">{item.name}</h6>
                    </Link>
                    <p className="mb-0 fs--2 text-500 ">{item.description}</p>
                  </div>
                </Flex>
              </Col>
            );
          })}
          <Col sm={6} md={5} xl>
            <Flex className="position-relative">
              <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                <FontAwesomeIcon
                  icon={'fa-solid fa-link'}
                  className={'text-primary'}
                />
              </div>
              <div className="flex-1">
                <Link
                  to={'#'}
                  className="stretched-link text-800"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  <h6 className="mb-0">{'Add Short Cut'}</h6>
                </Link>
                {/* <p className="mb-0 fs--2 text-500 ">{'Add'}</p> */}
              </div>
            </Flex>
          </Col>
          {/* {data.map(({ icon, color, title, text, link, action, subject }) => {
            return (
              <Can key={title} I={action} a={subject}>
                <Col sm={6} md={5} xl>
                  <Flex className="position-relative">
                    <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                      <FontAwesomeIcon
                        icon={icon}
                        className={`text-${color}`}
                      />
                    </div>
                    <div className="flex-1">
                      <Link to={link} className="stretched-link text-800">
                        <h6 className="mb-0">{title}</h6>
                      </Link>
                      <p className="mb-0 fs--2 text-500 ">{text}</p>
                    </div>
                  </Flex>
                </Col>
              </Can>
            );
          })} */}
        </Row>
      </Card.Body>
      {show === true && (
        <AddLinkFormModal
          onHide={() => {
            setShow(false);
          }}
          show={show}
          onSubmit={onSubmit}
        />
      )}
    </Card>
  );
};

Greetings.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    })
  ),
  showBg: PropTypes.bool,
  name: PropTypes.string
};

export default Greetings;
