import { useContext, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Dropdown, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import AppContext, { KanbanContext } from 'context/Context';
import { useAuthContext } from 'context/authContext';
import LabelFormModal from './LabelFormModal';
import { GET_PROJECT_BY_ID } from './KanbanModal';

export const GET_LABELS = gql`
  query GetLabels($organizationId: uuid!) {
    labels(where: { organsiation_id: { _eq: $organizationId } }) {
      id
      title
      created_at
      updated_at
      organsiation_id
    }
  }
`;

const CREATE_PROJECT_LABEL = gql`
  mutation CreateProjectLabel($projectId: uuid!, $labelId: uuid!) {
    insert_project_labels_one(
      object: { project_id: $projectId, label_id: $labelId }
    ) {
      id
      label {
        id
        title
      }
    }
  }
`;

const CREATE_PROJECT_LABEL_WITH_LABEL = gql`
  mutation CreateProjectLabelWithLabel(
    $projectId: uuid!
    $labelTitle: String!
    $organizationId: uuid!
  ) {
    insert_project_labels_one(
      object: {
        project_id: $projectId
        label: {
          data: { title: $labelTitle, organsiation_id: $organizationId }
        }
      }
    ) {
      id
      label {
        id
        title
      }
    }
  }
`;

const DELETE_PROJECT_LABEL = gql`
  mutation DeleteProjectLabel($id: uuid!) {
    delete_project_labels_by_pk(id: $id) {
      id
    }
  }
`;

const ModalLabelContent = ({ projectLabels }) => {
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const {
    kanbanState: { kanbanModal }
  } = useContext(KanbanContext);
  const [hoveredId, setHoveredId] = useState(null);
  const [showLabelFormModal, setShowLabelFormModal] = useState(false);
  const { organization } = useAuthContext();
  const { data: labelsData } = useQuery(GET_LABELS, {
    variables: { organizationId: organization?.id }
  });
  const [createProjectLabel] = useMutation(CREATE_PROJECT_LABEL, {
    update: (cache, { data }) => {
      const { projects_by_pk } = cache.readQuery({
        query: GET_PROJECT_BY_ID,
        variables: { projectId: kanbanModal.modalContent?.id }
      });
      cache.writeQuery({
        query: GET_PROJECT_BY_ID,
        variables: { projectId: kanbanModal.modalContent?.id },
        data: {
          projects_by_pk: {
            ...projects_by_pk,
            project_labels: [
              ...projects_by_pk.project_labels,
              data.insert_project_labels_one
            ]
          }
        }
      });
    }
  });
  const [deleteProjectLabel] = useMutation(DELETE_PROJECT_LABEL, {
    update: (cache, { data }) => {
      const { projects_by_pk } = cache.readQuery({
        query: GET_PROJECT_BY_ID,
        variables: { projectId: kanbanModal.modalContent?.id }
      });
      cache.writeQuery({
        query: GET_PROJECT_BY_ID,
        variables: { projectId: kanbanModal.modalContent?.id },
        data: {
          projects_by_pk: {
            ...projects_by_pk,
            project_labels: projects_by_pk.project_labels.filter(
              projectLabel =>
                projectLabel.id !== data.delete_project_labels_by_pk.id
            )
          }
        }
      });
    }
  });
  const [
    createProjectLabelWithLabel,
    { loading: createProjectLabelWithLabelLoading }
  ] = useMutation(CREATE_PROJECT_LABEL_WITH_LABEL, {
    onCompleted: () => {
      setShowLabelFormModal(false);
    },
    update: (cache, { data }) => {
      const { projects_by_pk } = cache.readQuery({
        query: GET_PROJECT_BY_ID,
        variables: { projectId: kanbanModal.modalContent?.id }
      });
      cache.writeQuery({
        query: GET_PROJECT_BY_ID,
        variables: { projectId: kanbanModal.modalContent?.id },
        data: {
          projects_by_pk: {
            ...projects_by_pk,
            project_labels: [
              ...projects_by_pk.project_labels,
              data.insert_project_labels_one
            ]
          }
        }
      });
    }
  });

  const unSelectedLabels = useMemo(() => {
    return (
      labelsData?.labels?.filter(
        label =>
          !projectLabels?.some(
            projectLabel => projectLabel?.label?.id === label?.id
          )
      ) ?? []
    );
  }, [labelsData, projectLabels]);

  const handleCreateProjectLabel = labelId => {
    createProjectLabel({
      variables: {
        projectId: kanbanModal.modalContent?.id,
        labelId
      }
    });
  };

  const handleCreateProjectLabelWithLabel = ({ title }) => {
    createProjectLabelWithLabel({
      variables: {
        projectId: kanbanModal.modalContent?.id,
        labelTitle: title,
        organizationId: organization?.id
      }
    });
  };

  const handleDeleteProjectLabel = id => {
    deleteProjectLabel({ variables: { id } });
  };

  return (
    <Flex>
      <LabelFormModal
        show={showLabelFormModal}
        onHide={() => setShowLabelFormModal(false)}
        onSubmit={handleCreateProjectLabelWithLabel}
        loading={createProjectLabelWithLabelLoading}
      />
      {projectLabels.map(({ label, id }) => (
        <div
          key={id}
          onMouseEnter={() => {
            setHoveredId(id);
          }}
          onMouseLeave={() => {
            setHoveredId(null);
          }}
          className="position-relative me-2 rounded"
          style={{
            border: hoveredId === id ? '1px solid #1c4f93' : '1px solid #d5e5fa'
          }}
        >
          <SoftBadge className=" py-2">{label?.title}</SoftBadge>
          {hoveredId === id && (
            <button
              className="position-absolute btn p-0 rounded-circle d-flex align-items-center justify-content-center text-white border-primary  top-0 end-0 me-n1 mt-n1 tex-primary"
              style={{
                height: 12,
                width: 12,
                fontSize: 10,
                backgroundColor: '#1c4f93'
              }}
              // size="sm"
              onClick={() => handleDeleteProjectLabel(id)}
              title="Remove label"
            >
              x
            </button>
          )}
        </div>
      ))}
      <Dropdown drop={isRTL ? 'start' : 'end'}>
        <Dropdown.Toggle
          variant="secondary"
          size="sm"
          className="px-2 fsp-75 bg-400 border-400 dropdown-caret-none"
        >
          <FontAwesomeIcon icon="plus" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <h6 className="dropdown-header py-0 px-3 mb-0">Select Label</h6>
          <Dropdown.Divider />
          <div className="px-3">
            {unSelectedLabels.length === 0 && (
              <p className="text-500 fs--1 mb-2">No labels</p>
            )}
            {unSelectedLabels.map(label => (
              <Dropdown.Item
                as="button"
                className={'badge-soft-primary rounded-1 mb-2'}
                key={label.id}
                onClick={() => handleCreateProjectLabel(label.id)}
              >
                {label.title}
              </Dropdown.Item>
            ))}
          </div>
          <Dropdown.Divider />
          <div className="px-3">
            <Button
              variant="outline-secondary"
              size="sm"
              className="d-block w-100 border-400"
              onClick={() => setShowLabelFormModal(true)}
            >
              Create label
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </Flex>
  );
};

ModalLabelContent.propTypes = {
  projectLabels: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.shape({
        title: PropTypes.string
      })
    })
  )
};

export default ModalLabelContent;
