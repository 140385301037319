import { Card, Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useMemo, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFControl,
  RHFFileUpload,
  RHFSelect
} from 'components/common/form';
import { urgentAppealScheme } from './urgentAppealScheme';
import LoadingButton from 'components/common/LoadingButton';
import { GET_PACKAGES_BY_ORGANIZATION } from 'components/donations/donation-packages';
import AuthContext from 'context/authContext';

const UrgentAppealForm = ({ onSubmit, loading, isEdit, initialValues }) => {
  const { organization } = useContext(AuthContext);
  const { data: packagesData, loading: packagesLoading } = useQuery(
    GET_PACKAGES_BY_ORGANIZATION,
    {
      variables: { organizationId: organization.id }
    }
  );
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialValues ?? {
      title: '',
      packageId: '',
      targetAmount: '',
      description: '',
      images: ''
    },
    resolver: yupResolver(urgentAppealScheme)
  });
  const { handleSubmit: RHFHandleSubmit } = methods;
  const packagesOptions = useMemo(() => {
    if (!packagesData) return [];
    return packagesData?.packages.map(item => ({
      label: item.package_title,
      value: item.id
    }));
  }, [packagesData]);

  const handleSubmit = data => {
    const { title, packageId, targetAmount, description, images } = data;
    onSubmit({
      title,
      package_id: packageId,
      targeted_amount: targetAmount,
      description,
      images
    });
  };

  return (
    <Row className="pt-3 gx-3">
      <Col xs={12} lg={9}>
        <FormProvider
          onSubmit={RHFHandleSubmit(handleSubmit)}
          methods={methods}
        >
          <Card className="border shadow-none mb-3">
            <Card.Header>
              <Card.Title>Urgent Appeal</Card.Title>
            </Card.Header>
            <Card.Body>
              <Container className="px-0 d-flex flex-column gap-3">
                <RHFControl
                  name="title"
                  label="Title"
                  placeholder="Title"
                  required
                />
                <Row xs={1} md={2}>
                  <Col>
                    <RHFSelect
                      name="packageId"
                      label="Select Package"
                      required
                      options={packagesOptions}
                      loading={packagesLoading}
                    />
                  </Col>
                  <Col>
                    <RHFControl
                      name="targetAmount"
                      label="Targeted Donations Amount"
                      placeholder="125"
                      required
                    />
                  </Col>
                </Row>

                <RHFControl
                  name="description"
                  placeholder="Description"
                  label="Description"
                  type="text"
                  as="textarea"
                  rows={5}
                />

                <RHFFileUpload name="images" label="Upload Images" />
                <Row className="justify-content-end px-3">
                  <LoadingButton
                    variant="primary"
                    className="w-100 w-sm-25"
                    type="submit"
                    loading={loading}
                  >
                    {isEdit ? 'Update' : 'Create'}
                  </LoadingButton>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </FormProvider>
      </Col>
    </Row>
  );
};

UrgentAppealForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.objectOf({
    title: PropTypes.string,
    packageId: PropTypes.string,
    targetAmount: PropTypes.number,
    description: PropTypes.string,
    images: PropTypes.string
  })
};

export default UrgentAppealForm;
