import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const KanbanContext = createContext({
  KanbanColumns: [],
  kanbanTasks: []
});

export default AppContext;
