import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
const MAX_FILE_SIZE = 10048576;

const organizationRegistrationScheme = Yup.object().shape({
  logo: Yup.mixed().test({
    message: "File too big, can't exceed 10MB",
    test: file => {
      if (!file) return true;
      const isValid = file?.size < MAX_FILE_SIZE;
      return isValid;
    }
  }),
  name: Yup.string().required('Required Field'),
  country: Yup.string().required('Required Field'),
  city: Yup.string().required('Required Field'),
  currency: Yup.string().required('Required Field'),
  address1: Yup.string().required('Required Field'),
  address2: Yup.string(),
  postCode: Yup.string().required('Required Field'),
  email: Yup.string().required('Required Field'),
  charityNumber: Yup.string()
});

const organizationRegistrationResolver = yupResolver(
  organizationRegistrationScheme
);

export { organizationRegistrationResolver };
