import { gql } from '@apollo/client';

export const CREATE_SCHOOL = gql`
  mutation MyMutation(
    $branch_id: uuid = ""
    $city: String = ""
    $country: String = ""
    $organisation_id: uuid = ""
    $school_address: String = ""
    $school_city: String = ""
    $school_document: jsonb = ""
    $school_headteacher: String = ""
    $school_email: String = ""
    $school_name: String = ""
    $school_number: String = ""
    $serial_number: String = ""
  ) {
    insert_schools_one(
      object: {
        branch_id: $branch_id
        city: $city
        country: $country
        organisation_id: $organisation_id
        school_address: $school_address
        school_city: $school_city
        school_document: $school_document
        school_headteacher: $school_headteacher
        school_email: $school_email
        school_name: $school_name
        school_number: $school_number
        serial_number: $serial_number
      }
    ) {
      id
      serial_number
      school_number
      school_address
      school_city
      school_email
      school_name
      created_at
      country
      school_document
    }
  }
`;

export const ADD_SCHOOL_STUDENT = gql`
  mutation AddSchoolStudent(
    $branch_id: uuid = ""
    $organisation_id: uuid = ""
    $orphan_id: uuid = ""
    $school_id: uuid = ""
    $user_id: uuid = ""
    $start_date: date!
    $end_date: date!
  ) {
    insert_school_students_one(
      object: {
        branch_id: $branch_id
        organisation_id: $organisation_id
        orphan_id: $orphan_id
        school_id: $school_id
        user_id: $user_id
        start_date: $start_date
        end_date: $end_date
      }
    ) {
      branch_id
      id
      organisation_id
      orphan_id
      school {
        school_city
        school_number
        school_name
        school_headteacher
        school_email
        school_document
        school_category_id
        school_address
        organisation_id
        id
        # country {
        #   name
        #   id
        # }
        branch_id
        serial_number
      }
      user {
        last_name
        first_name
      }
      start_date
      end_date
    }
  }
`;

export const Edit_SCHOOL_STUDENT = gql`
  mutation EditSchoolStudent(
    $id: uuid = ""
    $school: uuid = ""
    $startDate: date = ""
    $endDate: date = ""
  ) {
    update_school_students_by_pk(
      pk_columns: { id: $id }
      _set: { school_id: $school, start_date: $startDate, end_date: $endDate }
    ) {
      id
      school_id
      start_date
      end_date
    }
  }
`;

export const ADD_SCHOOL_STUDENT_BY_ORPHAN_ID = gql`
  mutation AddSchoolStudent(
    $branch_id: uuid = ""
    $organisation_id: uuid = ""
    $orphan_id: uuid = ""
    $school_id: uuid = ""
    $user_id: uuid = ""
  ) {
    insert_school_students_one(
      object: {
        branch_id: $branch_id
        organisation_id: $organisation_id
        orphan_id: $orphan_id
        school_id: $school_id
        user_id: $user_id
      }
    ) {
      orphan {
        id
        first_name
        last_name
        gender
        DOB
        created_at
        inspiration
        status
      }
    }
  }
`;

export const DELETE_SCHOOL_STUDENT = gql`
  mutation DeleteSchoolStudent($id: uuid = "") {
    delete_school_students(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export const ADD_SCHOOL_STUDENTS = gql`
  mutation AddSchoolStudents(
    $objects: [school_students_insert_input!] = {
      branch_id: ""
      end_date: ""
      organisation_id: ""
      orphan_id: ""
      school_id: ""
      start_date: ""
      user_id: ""
    }
  ) {
    insert_school_students(objects: $objects) {
      returning {
        orphan {
          id
          gender
          DOB
          first_name
          created_at
          inspiration
        }
      }
    }
  }
`;

export const DELETE_SCHOOL = gql`
  mutation DeleteSChool($id: uuid = "") {
    delete_schools_by_pk(id: $id) {
      id
    }
  }
`;

export const ADD_SCHOOL_REPORT = gql`
  mutation AddSchoolReport(
    $branch_id: uuid = ""
    $comment: String = ""
    $evaluation: String = ""
    $organisation_id: uuid = ""
    $orphan_id: uuid = ""
    $reporter: String = ""
    $school_id: uuid = ""
    $user_id: uuid = ""
    $report_date: date = ""
    $report_name: String = ""
    $report_document: String = ""
  ) {
    insert_school_reports_one(
      object: {
        branch_id: $branch_id
        organisation_id: $organisation_id
        orphan_id: $orphan_id
        school_id: $school_id
        user_id: $user_id
        reporter: $reporter
        evaluation: $evaluation
        comment: $comment
        report_name: $report_name
        report_document: $report_document
        report_date: $report_date
      }
    ) {
      id
      evaluation
      reporter
      comment
      created_at
      report_name
      report_document
      user {
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_SCHOOL_REPORT = gql`
  mutation UpdateSchoolReport(
    $id: uuid = ""
    $school_id: uuid = ""
    $evaluation: String = ""
    $comment: String = ""
    $report_name: String = ""
    $report_document: String = ""
  ) {
    update_school_reports_by_pk(
      pk_columns: { id: $id }
      _set: {
        school_id: $school_id
        evaluation: $evaluation
        comment: $comment
        report_name: $report_name
        report_document: $report_document
      }
    ) {
      id
      evaluation
      reporter
      comment
      report_name
      report_document
      created_at
      school_id
      user {
        first_name
        last_name
      }
    }
  }
`;

export const UPDATE_SCHOOL_BY_ID = gql`
  mutation UpdateSchoolByID(
    $id: uuid = ""
    $school_address: String = ""
    $school_city: String = ""
    $school_email: String = ""
    $school_headteacher: String = ""
    $school_name: String = ""
    $school_number: String = ""
    $serial_number: String = ""
    $country: String = ""
    $school_document: jsonb = ""
  ) {
    update_schools_by_pk(
      pk_columns: { id: $id }
      _set: {
        school_address: $school_address
        school_city: $school_city
        school_email: $school_email
        school_headteacher: $school_headteacher
        school_name: $school_name
        school_number: $school_number
        serial_number: $serial_number
        country: $country
        school_document: $school_document
      }
    ) {
      id
      serial_number
      school_number
      school_address
      school_city
      school_email
      school_name
      created_at
      country
      school_document
    }
  }
`;
export const DELETE_SCHOOL_STUDENT_BY_SCHOOL = gql`
  mutation DeleteSchoolStudentBySchool($id: uuid = "") {
    delete_school_students(where: { orphan_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETE_SCHOOL_REPORT_BY_ID = gql`
  mutation DeleteSChoolReportByID($id: uuid = "") {
    delete_school_reports_by_pk(id: $id) {
      id
    }
  }
`;

export const ADD_MEDICAL_REPORT = gql`
  mutation AddMedicalReport(
    $branch_id: uuid = ""
    $hosptial: String = ""
    $orphan_id: uuid = ""
    $user_id: uuid = ""
    $organisation_id: uuid = ""
    $medical_note: String = ""
    $inspection_date: date = ""
    $review_date: date = ""
  ) {
    insert_medical_reports_one(
      object: {
        branch_id: $branch_id
        hosptial: $hosptial
        orphan_id: $orphan_id
        user_id: $user_id
        organisation_id: $organisation_id
        medical_note: $medical_note
        inspection_date: $inspection_date
        review_date: $review_date
        doctor_name: "N/A"
        medical_document: "N/A"
      }
    ) {
      hosptial
      id
      medical_note
      review_date
      inspection_date
    }
  }
`;

export const UPDATE_MEDICAL_REPORT = gql`
  mutation UpdateMedicalReport(
    $id: uuid = ""
    $medical_note: String = ""
    $review_date: date = ""
    $inspection_date: date = ""
    $hosptial: String = ""
  ) {
    update_medical_reports_by_pk(
      pk_columns: { id: $id }
      _set: {
        medical_note: $medical_note
        review_date: $review_date
        inspection_date: $inspection_date
        hosptial: $hosptial
      }
    ) {
      hosptial
      id
      medical_note
      review_date
      inspection_date
    }
  }
`;

export const DELETE_MEDICAL_REPORT = gql`
  mutation DeleteMedicalReport($id: uuid = "") {
    delete_medical_reports_by_pk(id: $id) {
      id
    }
  }
`;

export const ADD_GALLERY = gql`
  mutation MyMutation(
    $branch_id: uuid = ""
    $gallery_image: String = ""
    $organisation_id: uuid = ""
    $orphan_id: uuid = ""
    $user_id: uuid = ""
    $image_description: String = ""
    $registered_date: date = ""
  ) {
    insert_gallery_one(
      object: {
        branch_id: $branch_id
        gallery_image: $gallery_image
        organisation_id: $organisation_id
        orphan_id: $orphan_id
        user_id: $user_id
        image_description: $image_description
        image_location: "N/A"
        registered_date: "2021-01-20"
      }
    ) {
      id
      gallery_image
      user {
        first_name
        last_name
      }
      created_at
      image_description
    }
  }
`;

export const DELETE_GALLERY_IMAGE = gql`
  mutation DeleteGallery($id: uuid = "") {
    delete_gallery_by_pk(id: $id) {
      id
    }
  }
`;

export const DELETE_SPONSORSHIP = gql`
  mutation DeleteSponsorship($id: uuid = "") {
    delete_sponsorship_by_pk(id: $id) {
      id
    }
  }
`;

export const INSERT_MOSQUE = gql`
  mutation InsertMosque(
    $organisation_id: uuid = ""
    $name: String = ""
    $serial_number: String = ""
    $address: String = ""
    $city: String = ""
    $email: String = ""
    $phone: String = ""
    $country: String = ""
    $document: String = ""
  ) {
    insert_mosques_one(
      object: {
        organisation_id: $organisation_id
        name: $name
        serial_number: $serial_number
        address: $address
        city: $city
        email: $email
        phone: $phone
        country: $country
        document: $document
      }
    ) {
      id
      organisation_id
      name
      serial_number
      address
      city
      email
      phone
      country
      document
    }
  }
`;

export const INSERT_MOSQUE_ORPHAN = gql`
  mutation AddMosqueOrphan(
    $objects: [mosque_orphan_insert_input!] = { mosque_id: "", orphan_id: "" }
  ) {
    insert_mosque_orphan(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_MOSQUE_ORPHAN = gql`
  mutation DeleteMOsqueOrphan($id: uuid = "") {
    delete_mosque_orphan(where: { mosque_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_MOSQUE = gql`
  mutation UpdateMosque(
    $id: uuid = ""
    $address: String = ""
    $city: String = ""
    $email: String = ""
    $name: String = ""
    $phone: String = ""
    $serial_number: String = ""
    $country: String = ""
    $document: String = ""
  ) {
    update_mosques_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        serial_number: $serial_number
        address: $address
        city: $city
        email: $email
        phone: $phone
        country: $country
        document: $document
      }
    ) {
      id
      organisation_id
      name
      serial_number
      address
      city
      email
      phone
      country
      document
    }
  }
`;

export const ADD_MOSQUE_ORPHAN_BY_ORPHAN_ID = gql`
  mutation AddMosqueOrphan($orphan_id: uuid = "", $mosque_id: uuid = "") {
    insert_mosque_orphan_one(
      object: { orphan_id: $orphan_id, mosque_id: $mosque_id }
    ) {
      orphan {
        id
        first_name
        last_name
        gender
        DOB
        created_at
        inspiration
        status
      }
    }
  }
`;

export const DELETE_MOSQUE_ORPHAN_BY_ORPHAN = gql`
  mutation DeleteMosqueOrphan($id: uuid = "") {
    delete_mosque_orphan(where: { orphan_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETE_MOSQUE = gql`
  mutation DeleteMosque($id: uuid = "") {
    delete_mosques_by_pk(id: $id) {
      id
    }
    delete_mosque_orphan(where: { mosque_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETE_HOSPITAL = gql`
  mutation DeleteHospital($id: uuid = "") {
    delete_hospitals_by_pk(id: $id) {
      id
    }
    delete_hospital_orphan(where: { hospital_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const INSERT_HOSPITAL_ORPHAN = gql`
  mutation AddHospitalOrphan(
    $objects: [hospital_orphan_insert_input!] = {
      hospital_id: ""
      orphan_id: ""
    }
  ) {
    insert_hospital_orphan(objects: $objects) {
      affected_rows
    }
  }
`;

export const ADD_Hospital_ORPHAN_BY_ORPHAN_ID = gql`
  mutation AddHospitalOrphan($orphan_id: uuid = "", $hospital_id: uuid = "") {
    insert_hospital_orphan_one(
      object: { orphan_id: $orphan_id, hospital_id: $hospital_id }
    ) {
      orphan {
        id
        first_name
        last_name
        gender
        DOB
        created_at
        inspiration
        status
      }
    }
  }
`;

export const INSERT_HOSPITAL = gql`
  mutation InsertHospital(
    $organisation_id: uuid = ""
    $name: String = ""
    $serial_number: String = ""
    $address: String = ""
    $city: String = ""
    $email: String = ""
    $phone: String = ""
    $country: String = ""
    $document: String = ""
  ) {
    insert_hospitals_one(
      object: {
        organisation_id: $organisation_id
        name: $name
        serial_number: $serial_number
        address: $address
        city: $city
        email: $email
        phone: $phone
        country: $country
        document: $document
      }
    ) {
      organisation_id
      name
      serial_number
      address
      city
      email
      phone
      country
      document
    }
  }
`;

export const DELETE_HOSPITAL_ORPHAN = gql`
  mutation DeleteHospitalOrphan($id: uuid = "") {
    delete_hospital_orphan(where: { hospital_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const DELETE_HOSPITAL_ORPHAN_BY_ORPHAN = gql`
  mutation DeleteHospitalOrphan($id: uuid = "") {
    delete_hospital_orphan(where: { orphan_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_HOSPITAL = gql`
  mutation UpdateHospital(
    $id: uuid = ""
    $name: String = ""
    $serial_number: String = ""
    $address: String = ""
    $city: String = ""
    $email: String = ""
    $phone: String = ""
    $country: String = ""
    $document: String = ""
  ) {
    update_hospitals_by_pk(
      pk_columns: { id: $id }
      _set: {
        name: $name
        serial_number: $serial_number
        address: $address
        city: $city
        email: $email
        phone: $phone
        country: $country
        document: $document
      }
    ) {
      id
      organisation_id
      name
      serial_number
      address
      city
      email
      phone
      country
      document
    }
  }
`;

export const DELETE_ORPHANAGE = gql`
  mutation DeleteOrphanage($id: uuid = "") {
    delete_orphanages_by_pk(id: $id) {
      id
    }
    delete_orphan_orphanage(where: { orphanage_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const INSERT_ORPHANAGE = gql`
  mutation InsertOrphanage(
    $location_id: uuid = ""
    $name: String = ""
    $organisation_id: uuid = ""
  ) {
    insert_orphanages_one(
      object: {
        location_id: $location_id
        organisation_id: $organisation_id
        name: $name
      }
    ) {
      id
    }
  }
`;

export const INSERT_ORPHANAGE_ORPHAN = gql`
  mutation AddOrphanagesOrphan(
    $objects: [orphan_orphanage_insert_input!] = {
      orphanage_id: ""
      orphan_id: ""
    }
  ) {
    insert_orphan_orphanage(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_ORPHANAGE_ORPHAN = gql`
  mutation DeleteOrphanageOrphan($id: uuid = "") {
    delete_orphan_orphanage(where: { orphanage_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

export const UPDATE_ORPHANAGE = gql`
  mutation UpdateOrphanage(
    $id: uuid = ""
    $location_id: uuid = ""
    $name: String = ""
  ) {
    update_orphanages_by_pk(
      pk_columns: { id: $id }
      _set: { location_id: $location_id, name: $name }
    ) {
      id
    }
  }
`;

export const INSERT_SHORTCUT = gql`
  mutation InsertShortcut(
    $link: String = ""
    $name: String = ""
    $organisation_id: uuid = ""
    $description: String = ""
  ) {
    insert_shortcuts_one(
      object: {
        link: $link
        name: $name
        organisation_id: $organisation_id
        description: $description
      }
    ) {
      id
    }
  }
`;

export const UPDATE_CONTACT = gql`
  mutation UpdateContact(
    $where: contact_bool_exp!
    $title: String!
    $first_name: String!
    $last_name: String!
    $phone: String!
    $email: String!
  ) {
    update_contact(
      where: $where
      _set: {
        title: $title
        first_name: $first_name
        last_name: $last_name
        phone: $phone
        email: $email
      }
    ) {
      returning {
        id
        email
        first_name
        last_name
        phone
        title
      }
    }
  }
`;

export const INSERT_CONTACT = gql`
  mutation InsertContact($object: contact_insert_input!) {
    insert_contact_one(object: $object) {
      id
      email
      first_name
      last_name
      phone
      title
    }
  }
`;

export const INSERT_LOG = gql`
  mutation InsertLog($object: all_logs_insert_input!) {
    insert_all_logs_one(object: $object) {
      id
      module_reference_id
      organisation_id
      weraise_module_id
      subject
      message
      created_at
    }
  }
`;
