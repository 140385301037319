import SoftBadge from 'components/common/SoftBadge';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
// import { userTableData } from 'data/weraise-data/donar-data';
import { gql, useMutation, useQuery } from '@apollo/client';
import ExportModal from 'components/common/ExportModal';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import AuthContext, { rolesMap } from 'context/authContext';
import { parseRouteParams } from 'helpers/utils';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

export const GET_USER_ROLE = gql`
  query GetUserRole($organisation_id: uuid = "") {
    users_roles(where: { organisation_id: { _eq: $organisation_id } }) {
      user {
        first_name
        last_name
        email
      }
      branch_id
      role_id
      role {
        name
      }
      branch {
        name
      }
      id
      is_active
    }
  }
`;

const DELETE_USER_ROLE = gql`
  mutation DeleteUserRole($id: uuid = "") {
    delete_users_roles(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

const UsersList = () => {
  const navigate = useNavigate();
  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return name;
        // <Flex alignItems="center">
        //   <Link
        //     to={`/donors/${id}`}
        //     className="fw-medium ms-2 text-1100 hover-primary"
        //   >
        //     {name}
        //   </Link>
        // </Flex>
      }
    },
    {
      accessor: 'email',
      Header: 'Email',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return (
          <a className="fw-medium text-600" href={'mailto:' + email}>
            {email}
          </a>
        );
      }
    },
    {
      accessor: 'branch',
      Header: 'Branch',
      cellProps: {
        className: 'fw-medium text-600 py-2'
      }
    },
    {
      accessor: 'role',
      Header: 'Role',
      cellProps: {
        className: 'fw-medium text-600 py-2'
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium py-2'
      },

      Cell: rowData => {
        const { status: isActive } = rowData.row.original;
        return (
          <SoftBadge pill bg={isActive ? 'success' : 'danger'}>
            {isActive ? 'Active' : 'Inactive'}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'id',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id, isOrganizationAdmin } = rowData.row.original;
        if (isOrganizationAdmin) return null;
        return (
          <TableRowAction
            onCLickEdit={() =>
              navigate(parseRouteParams(paths.editUser, { id }))
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.settings.children.users}
          />
        );
      }
    }
  ];
  const [selectedId, setSelectedId] = useState(null);
  const { organization } = useContext(AuthContext);
  const [userTableData, setUserTableData] = useState([]);
  const [showExportModal, setShowExportModal] = useState(false);

  const { data } = useQuery(GET_USER_ROLE, {
    variables: {
      organisation_id: organization.id
    },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    const tmp = data?.users_roles.map(item => {
      return {
        id: item.id,
        name: item.user.first_name + ' ' + item.user.last_name,
        email: item.user.email,
        branch: item.branch?.name,
        isOrganizationAdmin: item.role_id === rolesMap.organisationAdmin.id,
        isBranchAdmin: item.role_id === rolesMap.admin.id,
        role: item.role.name,
        status: item.is_active
      };
    });
    setUserTableData(tmp);
  }, [data]);

  const [deleteUserRole, { loading: deleteLoading }] = useMutation(
    DELETE_USER_ROLE,
    {
      onCompleted: () => {
        setShowDeleteModal(false);
        toast.success('Delete Successfully.');
      },
      onError: err => {
        console.log(err);
        toast.error('Something went wrong! Please try again');
      },
      update: (cache, { data: { delete_users_roles } }) => {
        const existingUserRoles = cache.readQuery({
          query: GET_USER_ROLE,
          variables: { organisation_id: organization.id }
        });
        if (!existingUserRoles) return;

        const newRoles = existingUserRoles.users_roles.filter(
          roles => roles.id !== delete_users_roles.returning[0].id
        );
        cache.writeQuery({
          query: GET_USER_ROLE,
          variables: { organisation_id: organization.id },
          data: {
            users_roles: [...newRoles]
          }
        });
      }
    }
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };
  const handleDeleteModalHide = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    deleteUserRole({
      variables: {
        id: selectedId
      }
    });
  };
  const handleExport = () => {
    setShowExportModal(true);
  };
  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleDeleteModalHide}
        loading={deleteLoading}
        title="Delete Donor"
        handleConfirm={handleConfirmDelete}
        body="Are you sure you want to delete selected donor?"
      />
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {userTableData && (
            <CustomAdvanceTable
              data={userTableData || []}
              columns={columns}
              title="User list"
              addNew
              hasFilters
              hasExport
              handleExport={handleExport}
              addButtonLabel="New"
              showSearchInput
              searchInputPlaceHolder="Search Users"
              onClickAddNew={() => navigate(paths.createNewUser)}
              subject={permissionsKeyMap.settings.children.users}
            />
          )}
        </Col>
      </Row>
      <ExportModal
        data={userTableData || []}
        columns={columns.slice(0, columns.length - 1)}
        show={showExportModal}
        onHide={() => setShowExportModal(false)}
        fileName={'User List'}
      />
    </>
  );
};

export default UsersList;
