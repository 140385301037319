import { gql, useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import DonorFeedbackFormModal from './relationManagement/DonorFeedbackFormModal';
import { format } from 'date-fns';
import { useAuthContext } from '../../context/authContext';
import { GET_DONOR_NOTES_COUNT } from './queries';

// ----------------------------------------------------

const INSERT_DONOR_FEEDBACK = gql`
  mutation InsertDonorFeedback(
    $description: String = ""
    $donar_id: uuid = ""
    $user_id: uuid = ""
    $priority: String = ""
  ) {
    insert_donars_feedback_one(
      object: {
        description: $description
        donar_id: $donar_id
        user_id: $user_id
        priority: $priority
      }
    ) {
      description
      donar_id
      user {
        first_name
        last_name
      }
      id
      priority
      created_at
    }
  }
`;

const GET_DONOR_FEEDBACK = gql`
  query GetDonorFeedback($donar_id: uuid = "") {
    donars_feedback(
      where: { donar_id: { _eq: $donar_id } }
      order_by: { created_at: desc }
    ) {
      description
      donar_id
      id
      priority
      created_at
      user {
        first_name
        last_name
      }
    }
  }
`;

const DELETE_DONOR_FEEDBACK = gql`
  mutation DeleteDOnorFeedback($id: uuid = "") {
    delete_donars_feedback_by_pk(id: $id) {
      id
    }
  }
`;

const UPDATE_DONOR_FEEDBACK = gql`
  mutation UpdateDonorFeedback(
    $id: uuid = ""
    $user_id: uuid = ""
    $description: String = ""
    $priority: String = ""
  ) {
    update_donars_feedback_by_pk(
      pk_columns: { id: $id }
      _set: {
        description: $description
        priority: $priority
        user_id: $user_id
      }
    ) {
      description
      donar_id
      id
      user {
        first_name
        last_name
      }
      priority
      created_at
    }
  }
`;

// ----------------------------------------------------

const DonorFeedback = () => {
  const {
    user: { id: userId }
  } = useAuthContext();

  const columns = ({
    setShowFeedbackModel,
    setFeedback,
    setShowConfirmation,
    setDeleteId
  }) => [
    // {
    //   accessor: 'ref',
    //   Header: 'Ref.',
    //   cellProps: {
    //     className: 'py-2'
    //   },
    //   Cell: rowData => (
    //     <Link to="#">{rowData.row.original.id.split('-')[0]}</Link>
    //   )
    // },
    {
      accessor: 'staff_name',
      Header: 'Staff Name',
      cellProps: { className: 'py-2' },
      Cell: rowData =>
        rowData.row.original.user
          ? `${rowData.row.original.user.first_name} ${rowData.row.original.user.last_name}`
          : '---'
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => (
        <p className="text-truncate mb-0" style={{ maxWidth: '400px' }}>
          {format(
            new Date(rowData.row.original.created_at),
            'dd-MM-yyyy, hh:mm a'
          )}
        </p>
      )
    },
    {
      accessor: 'description',
      Header: 'Note',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => (
        <p className="text-truncate mb-0" style={{ maxWidth: '400px' }}>
          {rowData.row.original.description}
        </p>
      )
    },
    {
      accessor: 'priority',
      Header: 'Priority',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => (
        <p
          className={classNames('mb-0', {
            'text-danger': rowData.row.original.priority === 'Important'
          })}
        >
          {rowData.row.original.priority}
        </p>
      )
    },
    {
      accessor: 'id',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row;
        return (
          <TableRowAction
            onCLickEdit={() => {
              setShowFeedbackModel(true);
              setFeedback(rowData.row.values);
            }}
            onCLickDelete={() => {
              setDeleteId(id);
              setShowConfirmation(true);
            }}
            subject={permissionsKeyMap.donor.children.donors}
          />
        );
      }
    }
  ];
  const { id } = useParams();

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [showAddFeedbackModel, setShowFeedbackModel] = useState(false);
  const [feedback, setFeedback] = useState({});
  const { refetch: refetchDonorNotesCount } = useQuery(GET_DONOR_NOTES_COUNT, {
    variables: { donor_id: id }
  });
  const { data: donorFeedbackData } = useQuery(GET_DONOR_FEEDBACK, {
    variables: { donar_id: id }
  });
  const [insertDonarsFeedback, { loading: addFeedbackModel }] = useMutation(
    INSERT_DONOR_FEEDBACK,
    {
      onCompleted: () => {
        setShowFeedbackModel(false);
        refetchDonorNotesCount();
      },
      update: (cache, { data: { insert_donars_feedback_one } }) => {
        const { donars_feedback } = cache.readQuery({
          query: GET_DONOR_FEEDBACK,
          variables: { donar_id: id }
        });
        if (!donars_feedback) return;

        cache.writeQuery({
          query: GET_DONOR_FEEDBACK,
          variables: { donar_id: id },
          data: {
            donars_feedback: [insert_donars_feedback_one, ...donars_feedback]
          }
        });
      }
    }
  );

  const [updateDonorFeedback] = useMutation(UPDATE_DONOR_FEEDBACK, {
    onCompleted: () => {
      setFeedback({});
      setShowFeedbackModel(false);
      toast.success('Feedback updated successfully!');
    }
  });

  const handleAddNew = () => {
    setShowFeedbackModel(true);
  };

  const handleCreateDonorFeedbackComment = values => {
    if (Object.keys(feedback).length !== 0) {
      updateDonorFeedback({
        variables: {
          id: values.id,
          user_id: userId,
          description: values.description,
          priority: values.priority
        }
      });
    } else
      insertDonarsFeedback({
        variables: {
          ...values,
          donar_id: id,
          user_id: userId
        }
      });
  };

  const [deleteDonorFeedback] = useMutation(DELETE_DONOR_FEEDBACK, {
    onCompleted: () => {
      toast.success('Feedback deleted successfully');
      setShowConfirmation(false);
      refetchDonorNotesCount();
    },
    update: (cache, { data: { delete_donars_feedback_by_pk } }) => {
      const { donars_feedback } = cache.readQuery({
        query: GET_DONOR_FEEDBACK,
        variables: { donar_id: id }
      });
      if (!donars_feedback) return;

      cache.writeQuery({
        query: GET_DONOR_FEEDBACK,
        variables: { donar_id: id },
        data: {
          donars_feedback: donars_feedback.filter(
            item => item.id !== delete_donars_feedback_by_pk.id
          )
        }
      });
    }
  });

  return (
    <>
      {showAddFeedbackModel && (
        <DonorFeedbackFormModal
          show={showAddFeedbackModel}
          onHide={() => setShowFeedbackModel(false)}
          onSubmit={handleCreateDonorFeedbackComment}
          loading={addFeedbackModel}
          isEdit={feedback ? true : false}
          initialValues={feedback}
        />
      )}
      {showConfirmation && (
        <ConfirmationModal
          show={showConfirmation}
          handleConfirm={() => {
            deleteDonorFeedback({ variables: { id: deleteId } });
          }}
          onHide={() => setShowConfirmation(false)}
          title={'Confirmation'}
          body="You sure to delete this feedback?"
        />
      )}

      {/*Donor Feedback And Rating Section */}
      {/* <Card className="mb-3">
        <Card.Header className="p-4 pb-0">
          <Card.Title className="fs-2 fw-bold">Feedback & Ratings</Card.Title>
        </Card.Header>
        <Card.Body className="p-4">
          {weFeedBackData.map(item => (
            <Flex key={item.id} justifyContent={'between'}>
              <p className="text-800">{item.label}</p>
              <p className="text-800">
                <FontAwesomeIcon
                  icon={'circle'}
                  className={`me-2 ${item.iconColor}`}
                />
                {item.value}/{item.baseValue}
              </p>
            </Flex>
          ))}
        </Card.Body>
      </Card> */}

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {donorFeedbackData?.donars_feedback && (
            <CustomAdvanceTable
              data={donorFeedbackData?.donars_feedback}
              columns={columns({
                setShowFeedbackModel,
                setFeedback,
                setDeleteId,
                setShowConfirmation
              })}
              title="Client Notes"
              showSearchInput
              searchInputPlaceHolder="Search"
              addNew
              onClickAddNew={handleAddNew}
              perPage={10}
              subject={permissionsKeyMap.donor.children.donors}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DonorFeedback;
