import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import LoadingContainer from 'components/common/LoadingContainer';
import { parseRouteParams } from 'helpers/utils';
import { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'routes/paths';
import SelectBeneficiaryModal from '../beneficiaries/sponsorships/sponsorship-form/SelectBeneficiaryModal';
import DonorSponsorshipModal from './modals/DonorSponsorshipModal';

const GET_SPONSORSHIPS_BY_DONOR = gql`
  query GetSponsorshipByDonor($donorId: uuid = "") {
    sponsorship(where: { donar_id: { _eq: $donorId } }) {
      stipulation
      theme
      duration
      renewal
      created_at
      start_date
      updated_at
      donar_id
      id
      orphan_id
      orphan {
        country
        first_name
        gender
        last_name
        middle_name
        image
        DOB
        id
      }
    }
  }
`;

const DonorSponsorships = () => {
  const { id } = useParams();
  const { data: sponsorshipsData, loading: sponsorshipsLoading } = useQuery(
    GET_SPONSORSHIPS_BY_DONOR,
    {
      variables: { donorId: id }
    }
  );
  const navigate = useNavigate();
  const [showSponsorshipModal, setShowSponsorshipModal] = useState(false);
  const [showSponsorship, setShowSponsorship] = useState(false);
  const [sponsorshipId, setSponsorshipId] = useState('');
  const [orphanId, setOrphanId] = useState(null);
  const hideSponsorshipModal = () => {
    setShowSponsorshipModal(false);
  };
  const handleSelectBeneficiarySubmit = selectedBeneficiaryId => {
    navigate(
      parseRouteParams(
        paths.createNewSponsorship,
        {},
        {
          beneficiaryId: selectedBeneficiaryId,
          donorId: id
        }
      )
    );
  };
  return sponsorshipsLoading ? (
    <LoadingContainer />
  ) : (
    <>
      <SelectBeneficiaryModal
        onHide={hideSponsorshipModal}
        show={showSponsorshipModal}
        onSubmit={handleSelectBeneficiarySubmit}
      />

      <Card className="mb-3">
        <Card.Header>
          <Flex
            justifyContent={'between'}
            alignItems="center"
            className="flex-column flex-md-row"
          >
            <Form.Group
              as={Flex}
              className="align-items-start align-items-md-center w-100 w-md-50 flex-column flex-md-row mb-3 mb-md-0"
              controlId="active_sponsorships"
            >
              <Form.Label
                className="mb-2 mb-md-0 me-3"
                style={{ minWidth: 'fit-content' }}
              >
                Active Sponsorships
              </Form.Label>

              <Form.Control type="text" placeholder="Search" />
            </Form.Group>

            <div>
              <IconButton
                size="sm"
                icon={'filter'}
                variant="falcon-default"
                className="me-3"
                iconClassName={'me-1'}
              >
                Filter
              </IconButton>
              <IconButton
                size="sm"
                icon={'external-link-alt'}
                iconClassName={'me-1'}
                variant="falcon-default"
              >
                Export
              </IconButton>
            </div>
          </Flex>
        </Card.Header>
      </Card>
      {sponsorshipsData?.sponsorship?.length === 0 && (
        <Card className="mb-3">
          <Card.Body>
            <div className="text-center">
              <h5 className="mb-3 text-500 fw-medium">No Sponsorships Found</h5>
              <Button
                onClick={() => setShowSponsorshipModal(true)}
                variant="falcon-default align-self-end mt-2 mt-md-0"
              >
                Create Sponsorship
              </Button>
            </div>
          </Card.Body>
        </Card>
      )}
      {sponsorshipsData?.sponsorship?.map(({ orphan, id, start_date }) => {
        const {
          first_name,
          last_name,
          middle_name,
          country,
          image,
          id: orphanId
        } = orphan;
        const name = first_name + ' ' + middle_name ?? '' + ' ' + last_name;
        const date = new Date(start_date).toLocaleDateString();
        return (
          <Card className="mb-3" key={id}>
            <FalconCardHeader
              title={
                <div className="align-items-md-center d-md-flex">
                  <p className="fw-medium text-900 mb-0">Orphan Sponsorship</p>
                  <IconButton
                    icon="edit"
                    variant="link"
                    className="text-400"
                    transform="shrink-3"
                  />
                  {/* {status?.map(item => (
                    <SoftBadge
                      key={item.id}
                      pill
                      bg={item.bg}
                      className="me-2 text-uppercase"
                    >
                      {item.label}
                    </SoftBadge>
                  ))} */}
                </div>
              }
              light={false}
              titleTag="div"
              className="py-2"
              menuClassName="align-items-center"
              endEl={
                <IconButton
                  icon="pen"
                  variant="link"
                  className="text-800 p-0 lh-1"
                  transform="shrink-3"
                  // onClick={() =>
                  //   navigate(
                  //     parseRouteParams(paths.createNewSponsorFrom, {
                  //       id,
                  //       sponsorId: id
                  //     })
                  //   )
                  // }
                />
              }
            />
            <Card.Body className="pt-0">
              <div className="d-md-flex justify-content-between">
                <div className="d-flex align-items-center mt-2 mt-md-0">
                  <div>
                    <Avatar src={image} name={name} size="3xl" />
                  </div>
                  <div className="ms-2">
                    <p className="fw-medium mb-2 text-900">{name}</p>
                    <Flex alignItems={'center'} className="flex-wrap">
                      <p className="me-2 mb-0 fs--2 text-600">
                        <FontAwesomeIcon
                          icon={'map-marker-alt'}
                          className="me-1 fs--1 text-800"
                        />
                        {country}
                      </p>
                      <p className="me-2 mb-0 fs--2 text-600">
                        <FontAwesomeIcon
                          icon={'fa-bullhorn'}
                          className="me-1 fs--1 text-800"
                        />
                        Reminders on
                      </p>
                      <p className="me-2 mb-0 fs--2 text-600">
                        <FontAwesomeIcon
                          icon={'calendar-alt'}
                          className="me-1 fs--1 text-800"
                        />
                        {date} -
                      </p>
                      <p className="me-2 mb-0 fs--2 text-600">
                        <FontAwesomeIcon
                          icon={'money-bill'}
                          className="me-1 fs--1 text-800"
                        />
                        $2,500.0
                      </p>
                    </Flex>
                  </div>
                </div>
                <Button
                  onClick={() => {
                    setShowSponsorship(true);
                    setSponsorshipId(id);
                    setOrphanId(orphanId);
                  }}
                  variant="falcon-default align-self-end mt-2 mt-md-0"
                >
                  View Sponsorship
                </Button>
              </div>
            </Card.Body>
          </Card>
        );
      })}
      {showSponsorship && (
        <DonorSponsorshipModal
          setModalShow={setShowSponsorship}
          modalShow={showSponsorship}
          orphanId={orphanId}
          onClick={() =>
            navigate(
              parseRouteParams(paths.createNewSponsorFrom, {
                id,
                sponsorId: sponsorshipId
              })
            )
          }
        />
      )}
    </>
  );
};

export default DonorSponsorships;
