import { getRelativePath } from 'helpers/utils';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import paths from 'routes/paths';
import ProjectNav from './ProjectNav';
import ProjectReports from './ProjectReports';
import ProjectCompleteRoutes from './project-completion-component/ProjectCompleteRoutes';
import ProjectProposal from './projectProposal/ProjectProposal';
import CreateProjectReport from './projectReport/CreateProjectReport';
import EditProjectReport from './projectReport/EditProjectReport';
import ProjectReportDetails from './projectReport/ProjectReportDetails';
import PermissionWrapper from 'routes/PermissionWrapper';
import { projectRoutes } from 'routes/routes';

const ProjectLayout = () => {
  return (
    <div className="py-0">
      <Row className="g-3">
        <Col md={3}>
          <ProjectNav />
        </Col>
        <Col md={9}>
          <Routes>
            <Route
              path={getRelativePath(paths.projectReports, paths.projectDetails)}
              element={
                <PermissionWrapper
                  permissionKey={projectRoutes.key}
                  type={'browse'}
                >
                  <ProjectReports />
                </PermissionWrapper>
              }
            />
            <Route
              path={getRelativePath(
                paths.projectProposal,
                paths.projectDetails
              )}
              element={
                <PermissionWrapper
                  permissionKey={projectRoutes.key}
                  type={'browse'}
                >
                  <ProjectProposal />
                </PermissionWrapper>
              }
            />

            <Route
              path={getRelativePath(
                paths.projectReportDetails,
                paths.projectDetails
              )}
              element={
                <PermissionWrapper
                  permissionKey={projectRoutes.key}
                  type={'browse'}
                >
                  <ProjectReportDetails />
                </PermissionWrapper>
              }
            />
            <Route
              path={getRelativePath(
                paths.createProjectReport,
                paths.projectDetails
              )}
              element={
                <PermissionWrapper
                  permissionKey={projectRoutes.key}
                  type={'insert'}
                >
                  <CreateProjectReport />
                </PermissionWrapper>
              }
            />
            <Route
              path={getRelativePath(
                paths.editProjectReport,
                paths.projectDetails
              )}
              element={
                <PermissionWrapper
                  permissionKey={projectRoutes.key}
                  type={'edit'}
                >
                  <EditProjectReport />
                </PermissionWrapper>
              }
            />
            <Route
              path={
                getRelativePath(paths.completeProject, paths.projectDetails) +
                '/*'
              }
              element={<ProjectCompleteRoutes />}
            />
            {/* <Route
              path="*"
              element={
                <Navigate
                  to={getRelativePath(
                    paths.projectInProgress,
                    paths.projectDetails
                  )}
                  replace
                />
              }
            /> */}
          </Routes>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectLayout;
