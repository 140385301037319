import React, { useEffect, useState } from 'react';
import { Container, Image, Navbar } from 'react-bootstrap';
import logo from 'assets/img/we-raise-images/Help-Yateem.png';
import Section from 'components/common/Section';
import frontEndFooterLeft from 'assets/img/we-raise-images/frontEndFooterLeft.png';
import frontEndFooterRight from 'assets/img/we-raise-images/frontEndFooterRight.png';
import Page1 from './Page1';
import Flex from 'components/common/Flex';
import Page2 from './Page2';
import { Link } from 'react-router-dom';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';
import Page7 from './Page7';
import WRFrontEndTopNav from './WRFrontEndTopNav';
import { firstPageContents, stages } from './homeData';

const Home = () => {
  const [stage, setStage] = useState(stages.Donation1);
  const [showContent, setShowContent] = useState(
    firstPageContents.donationType
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [stage]);

  useEffect(() => {
    document.body.style.backgroundColor = '#fff';
  }, []);

  return (
    <div>
      <Navbar expand="lg" sticky="top" className="wr-fe-nav">
        <Container className="px-0 px-md-3">
          <Navbar.Brand
            as={Link}
            onClick={() => {
              setStage(stages.Donation1);
              setShowContent(firstPageContents.donationType);
            }}
            to="/home"
          >
            <Image src={logo} className="wr-fe-logo" />
          </Navbar.Brand>
        </Container>
      </Navbar>
      <WRFrontEndTopNav
        setStage={setStage}
        stage={stage}
        setShowContent={setShowContent}
      />
      <Section
        className="pt-0 pt-md-3 pb-0 mx-auto"
        style={{
          maxWidth: stage === stages.Donation2 ? '900px' : '740px'
        }}
      >
        {/* <Form> */}
        {stage === stages.Donation1 && (
          <Page1
            setStage={setStage}
            showContent={showContent}
            setShowContent={setShowContent}
          />
        )}
        {stage === stages.Donation2 && <Page2 setStage={setStage} />}
        {stage === stages.Donation3 && <Page3 setStage={setStage} />}
        {stage === stages.Details1 && <Page4 setStage={setStage} />}
        {stage === stages.Details2 && <Page5 setStage={setStage} />}
        {stage === stages.Payment && <Page6 setStage={setStage} />}
        {stage === stages.PayNow && <Page7 />}
        {/* </Form> */}

        <footer>
          {stage !== stages.Donation2 && stage !== stages.PayNow && (
            <Flex className="flex-column flex-md-row justify-content-between align-items-center mt-4 mt-md-5">
              <Image
                src={frontEndFooterLeft}
                height="61px"
                className="mb-4 mb-md-0"
              />
              <Image
                src={frontEndFooterRight}
                height="84px"
                style={{ maxWidth: '362px' }}
                className="w-100 h-100"
              />
            </Flex>
          )}

          <div className="text-center mt-4">
            <p className="mb-0 fw-medium">
              <a
                href="https://islamic-relief.org/privacy-statement/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>{' '}
              | Other ways to donate (Telephone, post & bank transfer)
            </p>
            <p className="fw-medium">
              © Copyrights {new Date().getFullYear()} Islamic Relief Worldwide,
              Inc. All rights reserved. Registered Charity No. 328158
            </p>
          </div>
        </footer>
      </Section>
    </div>
  );
};

export default Home;
