/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import AdvanceTableGiftAid from 'components/common/advance-table/AdvanceTableGiftAid';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapperGiftAid';
import AdvanceTableFooter from './AdvanceTableFooter';
import AdvanceTablePagination from './AdvanceTablePagination';
import AdvanceTableSearchBox from './AdvanceTableSearchBox';
import { Can } from 'routes/permissions/Can';
import { userActions } from 'routes/permissions/permissionKeys';

const CustomAdvanceTable = props => {
  const {
    columns = [],
    data = [],
    title = 'Selection Example',
    addButtonLabel = 'New',
    addButtonIcon = 'plus',
    showSearchInput = false,
    searchInputPlaceHolder = 'Search by name',
    onClickAddNew = () => {},
    rowClickEvent = () => {},
    addNew = true,
    content = () => {},
    rowsPerPageSelection = false,
    viewAllBtn = true,
    perPage = 5,
    showTablePagination = false,
    showFilterButton = true,
    showExportButton = true,
    showFooterContent = false,
    footerContent,
    hideBulkActions = false,
    isEditable = false,
    isAdd = false,
    editId = null,
    handleRowChange = () => {},
    handleSave = () => {},
    selection = true,
    handleExport = () => {},
    exportLoading = false,
    subject
  } = props;

  function BulAction({ selectedRowIds, globalFilter, setGlobalFilter }) {
    const objectLength = Object.keys(selectedRowIds).length;
    return (
      <Row className="flex-between-center mb-3 ">
        <Col
          sm="auto"
          className="d-md-flex align-items-center justify-content-md-start pe-md-0"
        >
          <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
            {objectLength > 0
              ? 'You have selected ' +
                objectLength +
                ` row${objectLength > 1 ? 's' : ''}`
              : title}
          </h5>

          {showSearchInput && (
            <div className="ms-md-4 mt-2 mt-md-0">
              <AdvanceTableSearchBox
                className="table-input-search-width"
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                placeholder={searchInputPlaceHolder}
                showSearchIcon={false}
              />
            </div>
          )}
        </Col>
        <Col sm="auto" className="text-md-end ps-md-0 mt-3 mt-md-0">
          {Object.keys(selectedRowIds).length > 0 && !hideBulkActions ? (
            <div className="d-flex">
              <Form.Select size="sm" aria-label="Bulk actions">
                <option>Bulk Actions</option>
                <option value="refund">Refund</option>
                <option value="delete">Delete</option>
                <option value="archive">Archive</option>
              </Form.Select>
              <Button
                type="button"
                variant="falcon-default"
                size="sm"
                className="ms-2"
              >
                Apply
              </Button>
            </div>
          ) : (
            <div id="orders-actions">
              {showExportButton ? content(selectedRowIds) : null}
              {addNew && (
                <Can I={userActions.create} a={subject}>
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon={addButtonIcon}
                    iconClassName="text-primary"
                    transform="shrink-3"
                    onClick={onClickAddNew}
                    className="me-2"
                  >
                    <span className="text-primary d-none d-lg-inline-block ms-2">
                      {addButtonLabel}
                    </span>
                  </IconButton>
                </Can>
              )}
              {showFilterButton ? (
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="filter"
                  transform="shrink-3"
                  className="me-2"
                >
                  <span className="d-none d-lg-inline-block ms-2">Filter</span>
                </IconButton>
              ) : null}
              {showExportButton ? (
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={handleExport}
                >
                  <span className="d-none d-lg-inline-block ms-2">
                    {exportLoading ? 'Loading' : 'Export'}
                  </span>
                </IconButton>
              ) : null}
              {!showExportButton ? content(selectedRowIds) : null}
            </div>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Card className="border shadow-none">
      <Card.Body>
        <AdvanceTableWrapper
          columns={columns}
          data={data}
          sortable
          selection={selection}
          pagination
          perPage={perPage}
          selectionColumnWidth={30}
        >
          <BulAction table />
          <AdvanceTableGiftAid
            table
            headerClassName="bg-200 text-900 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden',
              size: 'sm'
            }}
            rowClickEvent={rowClickEvent}
            showFooterContent={showFooterContent}
            isEditable={isEditable}
            isAdd={isAdd}
            editId={editId}
            handleRowChange={handleRowChange}
            handleSave={handleSave}
            handleExport={handleExport}
          />
          <div className="mt-3">
            {!showFooterContent ? (
              <>
                {showTablePagination ? (
                  <AdvanceTablePagination table />
                ) : (
                  <AdvanceTableFooter
                    rowCount={data.length}
                    table
                    rowInfo
                    navButtons
                    rowsPerPageSelection={rowsPerPageSelection}
                    viewAllBtn={viewAllBtn}
                    perPage={perPage}
                  />
                )}
              </>
            ) : null}
          </div>
          {footerContent || ''}
        </AdvanceTableWrapper>
      </Card.Body>
    </Card>
  );
};

CustomAdvanceTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
  addButtonLabel: PropTypes.string,
  addButtonIcon: PropTypes.string,
  searchInputPlaceHolder: PropTypes.string,
  showSearchInput: PropTypes.bool,
  onClickAddNew: PropTypes.func,
  rowClickEvent: PropTypes.func,
  addNew: PropTypes.bool,
  content: PropTypes.node,
  rowsPerPageSelection: PropTypes.bool,
  viewAllBtn: PropTypes.bool,
  showTablePagination: PropTypes.bool,
  perPage: PropTypes.number,
  showExportButton: PropTypes.bool,
  showFilterButton: PropTypes.bool,
  showFooterContent: PropTypes.bool,
  footerContent: PropTypes.node,
  isEditable: PropTypes.bool,
  isAdd: PropTypes.bool,
  editId: PropTypes.number,
  handleRowChange: PropTypes.func,
  handleSave: PropTypes.func,
  handleExport: PropTypes.func,
  exportLoading: PropTypes.bool
};

export default CustomAdvanceTable;
