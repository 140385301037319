import { useLazyQuery, useMutation } from '@apollo/client';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import AuthContext from 'context/authContext';
import dayjs from 'dayjs';
import { parseRouteParams } from 'helpers/utils';
import useBranchPermission from 'hooks/useBranchPermission';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import { DELETE_ORPHANAGE } from '../mutations';
import { GET_ORPHANAGE_LIST } from '../queries';

function OrphanageList() {
  const mosqueColumns = [
    {
      accessor: 'id',
      Header: 'ID',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'Address',
      Header: 'Location',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { orphanages_location } = rowData.row.original;

        return <>{orphanages_location.location_name}</>;
      }
    },
    {
      accessor: 'sponsor',
      Header: 'Sponsor',
      cellProps: {
        className: 'py-2'
      },
      Cell: () => {
        return <>{organization.organisation_name}</>;
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickEdit={() =>
              navigate(
                parseRouteParams(paths.editOrphanage, {
                  id
                })
              )
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.sponsorship.children.mosque}
          />
        );
      }
    }
  ];

  const navigate = useNavigate();

  const { organization } = useContext(AuthContext);
  const { where } = useBranchPermission();
  const { id } = useParams();
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [getOrphanage, { data: orphanageData }] =
    useLazyQuery(GET_ORPHANAGE_LIST);

  useEffect(() => {
    if (id) {
      where.mosque_id = { _eq: id };
    }
    getOrphanage({
      variables: { where }
    });
  }, [where]);

  const [deleteOrphanage] = useMutation(DELETE_ORPHANAGE, {
    refetchQueries: ['GetOrphanage'],
    onCompleted: () => {
      toast.success('Orphanage Deleted Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const onDelete = () => {
    // delete call
    deleteOrphanage({ variables: { id: selectedId } });
    setShowDeleteModal(false);
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        handleConfirm={onDelete}
        title="Delete Orphanage"
        body="Are you sure you want to delete this Orphanage?"
      />
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {orphanageData?.orphanages && (
            <CustomAdvanceTable
              data={orphanageData?.orphanages}
              columns={mosqueColumns}
              title="Orphanage"
              addButtonLabel="Add Orphanage"
              onClickAddNew={() => {
                navigate(paths.addOrphanage);
              }}
              // loading={loading}
              addNew
              addButtonIcon={'plus'}
              subject={permissionsKeyMap.sponsorship.children.orphanage}
            />
          )}
        </Col>
      </Row>
    </>
  );
}

export default OrphanageList;
