import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'routes/paths';
import UrgentAppealForm from './urgent-appeal-form/UrgentAppealForm';
import LoadingContainer from 'components/common/LoadingContainer';
import { useMemo } from 'react';

const UPDATE_URGENT_APPEAL = gql`
  mutation UpdateUrgentAppel(
    $branch_id: uuid = ""
    $organisation_id: uuid = ""
    $description: String = ""
    $title: String = ""
    $targeted_amount: Int = ""
    $images: jsonb = ""
    $id: uuid = ""
    $package_id: uuid = ""
  ) {
    update_urgent_appeals_by_pk(
      pk_columns: { id: $id }
      _set: {
        description: $description
        title: $title
        targeted_amount: $targeted_amount
        images: $images
        package_id: $package_id
      }
    ) {
      title
      id
      organisation_id
      branch_id
      description
      targeted_amount
      package_id
      is_display
    }
  }
`;

const GET_URGENT_APPEAL = gql`
  query GetUrgentAppeal($id: uuid = "") {
    urgent_appeals_by_pk(id: $id) {
      title
      targeted_amount
      package_id
      images
      id
      description
    }
  }
`;

function EditUrgentAppeal() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, loading: dataLoading } = useQuery(GET_URGENT_APPEAL, {
    variables: { id }
  });
  const [updateUrgentAppeal, { loading: updateLoading }] = useMutation(
    UPDATE_URGENT_APPEAL,
    {
      onCompleted: () => {
        toast.success('Urgent appeal updated successfully');
        navigate(paths.urgentAppeals);
      },
      onError: error => {
        toast.error(error.message);
        console.log(error);
      }
    }
  );
  const onSubmit = data => {
    updateUrgentAppeal({
      variables: {
        id,
        ...data
      }
    });
  };
  const initialValues = useMemo(() => {
    if (data) {
      const { title, targeted_amount, package_id, images, description } =
        data.urgent_appeals_by_pk;
      return {
        title,
        targetAmount: targeted_amount,
        packageId: package_id,
        images,
        description
      };
    }
    return {};
  }, [data]);
  return dataLoading ? (
    <LoadingContainer />
  ) : (
    <UrgentAppealForm
      onSubmit={onSubmit}
      loading={updateLoading}
      isEdit
      initialValues={initialValues}
    />
  );
}

export default EditUrgentAppeal;
