import Avatar, { AvatarGroup } from 'components/common/Avatar';
import PropTypes from 'prop-types';
import { Dropdown, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { gql, useMutation } from '@apollo/client';
import { useContext } from 'react';
import AddProjectMember from './AddProjectMember';
import BoardMembers from './BoardMembers';
import Flex from 'components/common/Flex';
import { GET_PROJECT_BY_ID } from './KanbanModal';
import { KanbanContext } from 'context/Context';

/* -------------------------------------------------------------------------- */
const DELETE_PROJECT_MEMBER = gql`
  mutation DeleteProjectMember($memberId: uuid!) {
    delete_project_members(where: { member_id: { _eq: $memberId } }) {
      returning {
        id
      }
    }
  }
`;

const Members = ({
  avatarSize = 'l',
  users,
  showMember = 4,
  addMember,
  className
}) => {
  const {
    kanbanState: { kanbanModal },
    setSelectedMember
  } = useContext(KanbanContext);
  const [deleteProjectMember] = useMutation(DELETE_PROJECT_MEMBER, {
    onCompleted: () => {
      toast.success('Member removed successfully');
    },
    update: (cache, { data: { delete_project_members } }) => {
      const { projects_by_pk } = cache.readQuery({
        query: GET_PROJECT_BY_ID,
        variables: {
          projectId: kanbanModal.modalContent.id
        }
      });
      cache.writeQuery({
        query: GET_PROJECT_BY_ID,
        variables: {
          projectId: kanbanModal.modalContent.id
        },
        data: {
          projects_by_pk: {
            ...projects_by_pk,
            project_members: projects_by_pk.project_members.filter(
              member => member.id !== delete_project_members.returning[0].id
            )
          }
        }
      });
    }
  });
  const handleDeleteProjectMember = memberId => {
    deleteProjectMember({
      variables: {
        memberId
      }
    });
  };
  return (
    <div className={className}>
      <AvatarGroup>
        {users.slice(0, showMember).map((user, index) =>
          !addMember ? (
            <div
              className={classNames('position-relative p-0', {
                'ms-n1': index > 0
              })}
              key={user.id}
              onClick={() => {
                setSelectedMember(user);
              }}
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={`member-${user.id}`}>{user.name}</Tooltip>
                }
              >
                <div className="w-100 h-100">
                  <Avatar
                    src={user.img}
                    name={user.name}
                    // className="me-2"
                    size={avatarSize}
                  />
                </div>
              </OverlayTrigger>
            </div>
          ) : (
            <Dropdown key={user.id}>
              <Dropdown.Toggle
                as={Nav.Link}
                className={classNames(
                  'dropdown-caret-none p-0 position-relative ',
                  {
                    'ms-n1': index > 0
                  }
                )}
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`member-${user.id}`}>{user.name}</Tooltip>
                  }
                >
                  <div className="w-100 h-100">
                    <Avatar
                      src={user.img}
                      name={user.name}
                      // className="me-2"
                      size={avatarSize}
                    />
                  </div>
                </OverlayTrigger>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-md px-0 py-3">
                <Flex alignItems="center" className="px-3">
                  <Avatar
                    src={user.img}
                    name={user.name}
                    className="me-2"
                    size="2xl"
                  />
                  {/* <Link to="/user/profile">
                </Link> */}
                  <div className="flex-1">
                    <h6 className="mb-0">
                      {/* <Link className="text-900" to="/user/profile"> */}
                      {user.name}
                      {/* </Link> */}
                    </h6>
                    <p className="fs--2 mb-0 text-500">{user.role}</p>
                  </div>
                </Flex>
                <Dropdown.Divider />
                {/* <Dropdown.Item>Member Rule</Dropdown.Item> */}
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => handleDeleteProjectMember(user.id)}
                >
                  Remove Member
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )
        )}
        {users.length > showMember && (
          <BoardMembers
            users={users.slice(showMember)}
            avatarSize={avatarSize}
            showMember={showMember}
          />
        )}
        {addMember && (
          <AddProjectMember
            currentMembers={users}
            avatarSize={avatarSize}
            showMember={showMember}
          />
        )}
      </AvatarGroup>
    </div>
  );
};

Members.propTypes = {
  avatarSize: PropTypes.string,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      img: PropTypes.string,
      role: PropTypes.string
    })
  ).isRequired,
  showMember: PropTypes.number,
  addMember: PropTypes.bool,
  className: PropTypes.string
};

export default Members;
