import React, { useContext, useMemo } from 'react';
import { Card, Col, Image, Row, Form, Button } from 'react-bootstrap';
import img from 'assets/img/we-raise-images/front-end/gift-aid-logo_png-1.png';
import { stages } from './homeData';
import PropTypes from 'prop-types';
import DonationContext from 'context/DonationContextProvider';
import { useQuery } from '@apollo/client';
import {
  FEE_TYPE_FIXED,
  FEE_TYPE_PERCENTAGE,
  GET_TRANSACTION_FEES
} from 'components/donations/donation-settings/TransactionFees';
import { useParams } from 'react-router-dom';
import { calculatePercentageFee } from 'components/add-donation/DonationConfig';

const Page3 = ({ setStage }) => {
  const { setGiftAid, setTransactionFee, setTransactionFeeAmount } =
    useContext(DonationContext);
  const { organizationId } = useParams();

  const { data: transactionFeesData } = useQuery(GET_TRANSACTION_FEES, {
    variables: {
      organisation_id: organizationId
    }
  });
  const { donationPackages, urgentAppeal } = useContext(DonationContext);
  const transactionFee = useMemo(() => {
    let totalAmount = 0;
    donationPackages.forEach(item => {
      if (item.selectedAmount.value === 'custom') {
        totalAmount += Number(item.customAmount);
      } else {
        totalAmount += Number(item.selectedAmount.label);
      }
    });

    urgentAppeal.forEach(() => {
      totalAmount += 10;
    });
    let transactionTmp = 0;

    if (transactionFeesData) {
      const total = transactionFeesData.transaction_fee.reduce((acc, curr) => {
        if (curr.fee_type === FEE_TYPE_PERCENTAGE) {
          acc = calculatePercentageFee(totalAmount, curr.amount);
        } else if (curr.fee_type === FEE_TYPE_FIXED) {
          acc += curr.amount;
        }
        transactionTmp = acc;
      }, 0);
      if (transactionTmp === 0) {
        transactionTmp = total;
      }
      setTransactionFeeAmount(transactionTmp);
      return transactionTmp;
    } else {
      return 0;
    }
  }, [transactionFeesData]);

  return (
    <>
      <Card className="mb-4 we-card-shadow">
        <Card.Body className="pb-2 p-4">
          <Row className="g-3">
            <Col md={8} className="order-1 order-md-0">
              <p className="fs-1 fw-bold text-uppercase">
                Gift Aid your donation
              </p>
              <p className="fw-medium mb-0" style={{ fontSize: '15px' }}>
                Add 25% more to your donation at no cost to you. A Gift Aid
              </p>
              <p style={{ fontSize: '15px' }}>
                declaration allows us to claim tax back on your gift
              </p>
              <p className="fw-medium" style={{ fontSize: '15px' }}>
                your gift aid of{' '}
                <span className="text-primary fw-bold">$10,000</span> will be{' '}
                <span className="text-primary fw-bold">$12,000</span> with no
                extra to you
              </p>
              <Form.Check
                type="checkbox"
                id="defaultCheckbox_1"
                label="I am a UK taxpayer, Reclaim gift aid on my donation"
                onChange={item => {
                  setGiftAid(item.target.checked);
                }}
              />
            </Col>
            <Col md={4} className="align-self-center order-0 order-md-1">
              <Image src={img} fluid />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-4 we-card-shadow">
        <Card.Body className="pb-2 p-4">
          <p className="fs-2 fw-bold">Help Us Cover Transaction Fees</p>
          <Form.Check
            type="checkbox"
            id="defaultCheckbox_2"
            label={'Cover A $' + transactionFee + ' Transaction Cost'}
            onChange={item => {
              setTransactionFee(item.target.checked);
            }}
          />
        </Card.Body>
      </Card>

      <Button
        size="lg"
        className="w-100 h-52"
        onClick={() => setStage(stages.Details1)}
      >
        continue
      </Button>
    </>
  );
};

Page3.propTypes = {
  setStage: PropTypes.func.isRequired
};

export default Page3;
