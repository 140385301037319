import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RHFSelect } from 'components/common/form';

const SPONSORSHIP_DURATION_MONTHLY = 'monthly';
const SPONSORSHIP_DURATION_ANNUALLY = 'annually';

export const SponsorshipDurationCard = ({ sectionRef, sideNavLink }) => {
  const options = [
    {
      label: 'Monthly',
      value: SPONSORSHIP_DURATION_MONTHLY
    },
    {
      label: 'Annually',
      value: SPONSORSHIP_DURATION_ANNUALLY
    }
  ];

  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Sponsorship Duration</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Container className="p-0 d-flex flex-column g-3">
          <Row className="gx-3">
            <Col xs={12} sm={6}>
              <RHFSelect
                name="duration"
                label="Select Duration"
                options={options}
                required
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

SponsorshipDurationCard.propTypes = {
  values: PropTypes.object.isRequired,
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
