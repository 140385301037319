import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const userScheme = Yup.object().shape({
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),
  branch: Yup.string().required('Required Field'),
  email: Yup.string().required('Required Field'),
  password: Yup.string().when('isEdit', {
    is: false,
    then: () =>
      Yup.string
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
          'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number'
        )
        .min(8, 'Must be at least 8 characters')
        .required('Required Field')
  }),
  confirmPassword: Yup.string().when('isEdit', {
    is: false,
    then: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required Field')
  })
});

const userResolver = yupResolver(userScheme);

export { userResolver };
