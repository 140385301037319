import classNames from 'classnames';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { Button, Card, Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RHFCheck, RHFPhoneInput, RHFSelect } from 'components/common/form';

export const numberTypeOptions = [
  { value: 'personal', label: 'Personal' },
  { value: 'home', label: 'Home' },
  { value: 'office', label: 'Office' },
  { value: 'office_2', label: 'Office 2' }
];

const PhoneNumberInputs = ({ addNewPhone, phoneNumbers, deletePhone }) => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <Card.Title className="fw-medium">Phone Number</Card.Title>
      </Card.Header>
      <Card.Body className="pb-1">
        <Row>
          {phoneNumbers.map((phone, index) => (
            <Col key={index} md={12}>
              <Row className="g-3">
                <Col md={6} controlId={`phone-${1}`} className="mb-3">
                  <RHFPhoneInput name={`phoneNumbers.${index}.phoneNumber`} />
                </Col>
                <Col md={3}>
                  <RHFSelect
                    name={`phoneNumbers.${index}.type`}
                    options={numberTypeOptions}
                    required
                  />
                </Col>
                <Col md={3}>
                  <Flex justifyContent="between">
                    <div className="mt-2">
                      <RHFCheck
                        name={`phoneNumbers.${index}.isPrimary`}
                        label="Primary"
                      />
                    </div>
                    {index !== 0 && (
                      <IconButton
                        variant="light"
                        icon="trash"
                        className={classNames(
                          'mb-1 bg-transparent border-0 shadow-none',
                          { 'v-hidden': phone.isDefault }
                        )}
                        iconAlign="left"
                        transform="shrink-3"
                        iconClassName="text-danger me-2"
                        onClick={() => deletePhone(index)}
                        disabled={phone.isDefault}
                      >
                        Delete
                      </IconButton>
                    )}
                  </Flex>
                </Col>
              </Row>
              {index !== phoneNumbers?.length - 1 && <hr className="my-2" />}
            </Col>
          ))}
        </Row>
      </Card.Body>
      <div className="p-3 pt-0">
        <Button
          variant="light"
          className="bg-transparent border-0 fs--1 fs-md-0 shadow-none text-primary p-1"
          onClick={addNewPhone}
        >
          Add New Phone Number
        </Button>
      </div>
    </Card>
  );
};

PhoneNumberInputs.propTypes = {
  addNewPhone: PropTypes.func,
  phoneNumbers: PropTypes.array,
  deletePhone: PropTypes.func
};

export default PhoneNumberInputs;
