import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { GET_LOGS, GET_WERAISE_MODULE } from '../queries';
import { Button, Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import AuthContext from 'context/authContext';
import { useParams } from 'react-router-dom';
import { INSERT_LOG } from '../mutations';
import AddLog from './AddLog';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

const Logs = ({ moduleType }) => {
  const { organization } = useContext(AuthContext);
  const { id } = useParams();

  const [showAddLog, setShowAddLog] = useState(false);

  const { data: weraiseData } = useQuery(GET_WERAISE_MODULE, {
    variables: {
      where: {
        name: { _eq: moduleType }
      }
    }
  });

  const { data: logsData } = useQuery(GET_LOGS, {
    variables: {
      where: {
        weraise_module_id: { _eq: weraiseData?.weraise_module[0]?.id },
        organisation_id: { _eq: organization.id },
        module_reference_id: { _eq: id }
      }
    }
  });

  const [addLog] = useMutation(INSERT_LOG, {
    onCompleted: () => {
      toast.success('Log Added Successfully');
      setShowAddLog(false);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    },
    update: (cache, data) => {
      const newData = data.data.insert_all_logs_one;
      const existingData = cache.readQuery({
        query: GET_LOGS,
        variables: {
          where: {
            weraise_module_id: { _eq: weraiseData?.weraise_module[0]?.id },
            organisation_id: { _eq: organization.id },
            module_reference_id: { _eq: id }
          }
        }
      });

      if (existingData) {
        cache.writeQuery({
          query: GET_LOGS,
          variables: {
            where: {
              weraise_module_id: { _eq: weraiseData?.weraise_module[0]?.id },
              organisation_id: { _eq: organization.id },
              module_reference_id: { _eq: id }
            }
          },
          data: {
            all_logs: [newData, ...existingData.all_logs]
          }
        });
      }
    }
  });

  const submitAddLog = data => {
    addLog({
      variables: {
        object: {
          weraise_module_id: weraiseData?.weraise_module[0]?.id,
          organisation_id: organization.id,
          module_reference_id: id,
          ...data
        }
      }
    });
  };

  return (
    <Card className="mb-3">
      <FalconCardHeader
        title={<h4 className="fw-bold"> Log </h4>}
        light={false}
        titleTag="div"
        className="pb-1"
        menuClassName="align-items-start"
        endEl={<Button onClick={() => setShowAddLog(true)}>Add Update</Button>}
      />

      <Card.Body>
        {logsData?.all_logs?.map(log => (
          <div key={log.id}>
            <Row>
              <Col>
                <div>
                  <p className="mb-1">
                    {' '}
                    {dayjs(log.created_at).format('DD/MM/YYYY')}
                  </p>
                  <p className="mb-1 h6"> {log.subject} </p>
                  <p className="mb-1">{log.message}</p>
                </div>
              </Col>
            </Row>
            <hr />{' '}
          </div>
        ))}
      </Card.Body>

      {showAddLog && (
        <AddLog
          show={showAddLog}
          onSubmit={data => {
            submitAddLog(data);
          }}
          onHide={() => {
            setShowAddLog(false);
          }}
          title={'Add Log'}
          loading={false}
        />
      )}
    </Card>
  );
};

Logs.propTypes = {
  moduleType: PropTypes.string
};

export default Logs;
