import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Modal, Row } from 'react-bootstrap';
import Flex from './Flex';
import { FormProvider, RHFCheck, RHFDatePicker } from './form';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LoadingButton from './LoadingButton';
import { useState } from 'react';

const filterScheme = Yup.object().shape({
  dateFilter: Yup.string()
});

export default function FilterModal({ show, onHide, applyFilter }) {
  const [dateRangeValue, setDateRangeValue] = useState('');

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(filterScheme)
  });

  const { handleSubmit } = methods;

  const filterSubmit = data => {
    applyFilter(dateRangeValue, data);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <FormProvider methods={methods} onSubmit={handleSubmit(filterSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title> Filter </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="fs-1 fw-bold">Date</p>
          <FormGroup>
            <Flex direction="column" gap={4}>
              <RHFCheck
                label="Today"
                name="today"
                type="radio"
                onChange={() => setDateRangeValue('today')}
                checked={dateRangeValue === 'today'}
              />
              <RHFCheck
                label="This Week"
                name="week"
                type="radio"
                value="week"
                onChange={() => setDateRangeValue('week')}
                checked={dateRangeValue === 'week'}
              />
              <RHFCheck
                label="This Month"
                name="month"
                type="radio"
                id="month"
                onChange={() => setDateRangeValue('month')}
                checked={dateRangeValue === 'month'}
              />
              <RHFCheck
                label="Date Range"
                name="select_date"
                type="radio"
                id="dateRange"
                onChange={() => setDateRangeValue('select_date')}
                checked={dateRangeValue === 'select_date'}
              />

              {dateRangeValue === 'select_date' && (
                <Row>
                  <Col>
                    <RHFDatePicker
                      name="startDate"
                      label="Start Date"
                      required
                    />
                  </Col>

                  <Col>
                    <RHFDatePicker name="endDate" label="End Date" required />
                  </Col>
                </Row>
              )}
            </Flex>{' '}
          </FormGroup>{' '}
        </Modal.Body>
        <Modal.Footer>
          <Flex direction="row" gap={2}>
            <LoadingButton
              transform="shrink-3"
              type="submit"
              //   loading={loading}
            >
              Filter
            </LoadingButton>

            <Button variant="light" onClick={onHide}>
              Cancel
            </Button>
          </Flex>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  );
}

FilterModal.propTypes = {
  onHide: PropTypes.func,
  show: PropTypes.bool,
  applyFilter: PropTypes.func
};
