import { gql, useMutation } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';
import LoadingContainer from 'components/common/LoadingContainer';
import Section from 'components/common/Section';
import { toast } from 'react-toastify';
import ProjectReportForm from './ProjectReportForm';

/* -------------------------------------------------------------------------- */
const EDIT_PROJECT_REPORT_MUTATION = gql`
  mutation EditProjectReport(
    $id: uuid = ""
    $video_url: String = ""
    $title: String = ""
    $description: String = ""
  ) {
    update_project_reports_by_pk(
      pk_columns: { id: $id }
      _set: { title: $title, video_url: $video_url, description: $description }
    ) {
      description
      title
      url
      video_url
      created_at
      updated_at
      id
      project_id
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
const GET_PROJECT_REPORT_QUERY = gql`
  query GetProjectReport($id: uuid = "") {
    project_reports_by_pk(id: $id) {
      description
      title
      url
      video_url
      created_at
      updated_at
      id
      project_id
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */
const EditProjectReport = () => {
  const navigate = useNavigate();
  const { reportId } = useParams();
  const [editProjectReport, { loading: editProjectReportLoading }] =
    useMutation(EDIT_PROJECT_REPORT_MUTATION, {
      onCompleted: () => {
        navigate(-1);
      },
      onError: error => {
        toast.error('Something went wrong! Please try again.');
        console.log(error);
      }
    });

  const onSubmit = data => {
    editProjectReport({
      variables: {
        title: data.title,
        description: data.description,
        video_url: data.videoUrl,
        id: reportId
      }
    });
  };
  return (
    <Query query={GET_PROJECT_REPORT_QUERY} variables={{ id: reportId }}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingContainer />;
        if (error)
          return <p className="text-center fs-0">Error! {error.message}</p>;

        return (
          <Section
            className={'py-0 mx-auto font-sans-serif '}
            style={{ maxWidth: '820px' }}
          >
            <ProjectReportForm
              onSubmit={onSubmit}
              loading={editProjectReportLoading}
              initialValues={data.project_reports_by_pk}
            />
          </Section>
        );
      }}
    </Query>
  );
};

export default EditProjectReport;
