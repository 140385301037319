/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import {
  weSponsorModalTabTableData,
  weSponsorshipDetails,
  weSponsorshipSchemes
} from 'data/weraise-data/donor-data';
import TableRow from 'components/we-raise-common/session-by-browser/TableRow';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';
import { gql, useQuery } from '@apollo/client';

const columns = [
  {
    accessor: 'date',
    Header: 'Date',
    cellProps: {
      className: 'ps-0 text-900 fw-medium py-2'
    },
    headerProps: {
      className: 'ps-0 fw-semi-bold'
    }
  },
  {
    accessor: 'summary',
    Header: 'Summary',
    cellProps: {
      className: 'text-500 fw-medium py-2'
    },
    headerProps: {
      className: 'fw-semi-bold'
    }
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    headerProps: {
      className: 'pe-0 text-end fw-semi-bold'
    },
    cellProps: {
      className: 'pe-0 text-end text-primary py-2'
    }
  }
];
const GET_SPONSOR = gql`
  query GetSponser($orphanId: uuid = "") {
    sponsorship(where: { orphan_id: { _eq: $orphanId } }) {
      donar_id
      start_date
      stipulation
      renewal
      theme
      duration
      donar {
        id
        first_name
        email
        last_name
        id
        phone
        title
        organisation {
          organisation_name
        }
      }
      order_detail {
        amount
      }
    }
  }
`;

const Sponsor = ({ data }) => (
  <Card className="mb-3">
    <Card.Body>
      <Flex justifyContent="between" alignItems="center" className={'mb-3'}>
        <h6 className="mb-0">ID : {data.id.split('-')[3]}</h6>
        <SoftBadge pill bg="primary" className="me-2 align-self-center">
          <FontAwesomeIcon icon="user" className="me-1" /> {data.type}
        </SoftBadge>
      </Flex>
      <div className="text-center">
        <Avatar
          src={data.image}
          rounded="circle"
          size="4xl"
          className="border border-5 border-white rounded-circle shadow-sm mb-2"
        />

        <p className="mb-1 fs--1 text-primary fw-medium">
          {data.title + ' ' + data.first_name + ' ' + data.last_name}
        </p>
        <p className="mb-0 fs--2 text-700 font-base">{'Sponsor'}</p>
      </div>
      <hr className="text-200" />
      <Flex justifyContent={'between'} alignItems="center">
        <p className="mb-0 fs--2 fw-medium text-600">
          {data.organisation.organisation_name}
        </p>
        <p className="mb-0 fs--2 fw-medium">{data.email[0].email}</p>
      </Flex>
    </Card.Body>
  </Card>
);

const SponsorModalTabTable = ({ columns, data }) => {
  return (
    <Card className="px-3" style={{ minHeight: '230px' }}>
      <AdvanceTableWrapper
        columns={columns}
        data={data}
        sortable={false}
        pagination
        perPage={7}
      >
        <AdvanceTable
          table
          headerClassName=" text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            // striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
      </AdvanceTableWrapper>
    </Card>
  );
};

const DonorSponsorshipModal = ({
  modalShow,
  setModalShow,
  editPath,
  orphanId
}) => {
  const [key, setKey] = useState('contributions');

  const { data: sponsers } = useQuery(GET_SPONSOR, {
    variables: {
      orphanId
    }
  });

  useEffect(() => {}, [sponsers]);

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter" as="h5">
          SP10056 - Orphan Sponsorship - OR10113
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-scroll" style={{ maxHeight: '80vh' }}>
        <Row className="g-3 mb-3">
          {sponsers &&
            sponsers?.sponsorship?.map(sponsor => (
              <Col key={sponsor.donar_id} md={6}>
                <Sponsor data={sponsor.donar} />
              </Col>
            ))}
        </Row>
        <Card className="mb-3">
          <Card.Body>
            <p className="mb-2 fs-0 fw-medium text-1000">Sponsorship Details</p>
            <div className="border-top">
              <Table size="sm" className="mb-0">
                <tbody>
                  {[].map((item, index) => (
                    <TableRow
                      endToEnd={true}
                      key={item.label}
                      data={item}
                      isLastItem={index === weSponsorshipDetails?.length - 1}
                      showIcon={false}
                      firstClassName="fw-medium text-600"
                      secondClassName="fw-medium text-900"
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>
        <Card className="mb-3">
          <Card.Body>
            <p className="mb-2 fs-0 fw-medium text-1000">Schemes</p>
            <div className="border-top">
              <Table size="sm" className="mb-0">
                <tbody>
                  {weSponsorshipSchemes.map((item, index) => (
                    <TableRow
                      endToEnd={true}
                      key={item.label}
                      data={item}
                      isLastItem={index === weSponsorshipSchemes?.length - 1}
                      showIcon={false}
                      firstClassName="fw-medium text-600"
                      secondClassName="fw-medium text-900"
                    />
                  ))}
                </tbody>
              </Table>
            </div>
          </Card.Body>
        </Card>

        <Tabs
          id="donar_sponsorship_tabs"
          activeKey={key}
          onSelect={k => setKey(k)}
          className="border-0"
        >
          <Tab eventKey="contributions" title="Contributions" className="pt-3">
            <SponsorModalTabTable
              data={weSponsorModalTabTableData}
              columns={columns}
            />
          </Tab>
          <Tab eventKey="donations" title="Donations" className="pt-3">
            <SponsorModalTabTable
              data={weSponsorModalTabTableData}
              columns={columns}
            />
          </Tab>
          <Tab eventKey="transfers_in" title="Transfers In" className="pt-3">
            <SponsorModalTabTable
              data={weSponsorModalTabTableData}
              columns={columns}
            />
          </Tab>
          <Tab eventKey="transfers_out" title="Transfers Out" className="pt-3">
            <SponsorModalTabTable
              data={weSponsorModalTabTableData}
              columns={columns}
            />
          </Tab>
        </Tabs>
        {editPath && (
          <IconButton
            onClick={editPath}
            className="mt-3 w-100"
            icon={'pencil-alt'}
          >
            Edit
          </IconButton>
        )}
      </Modal.Body>
    </Modal>
  );
};

DonorSponsorshipModal.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  setModalShow: PropTypes.func.isRequired,
  editPath: PropTypes.func,
  orphanId: PropTypes.string
};

export default DonorSponsorshipModal;
