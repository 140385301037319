import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import AppContext, { KanbanContext } from 'context/Context';
import React, { useContext, useEffect, useMemo } from 'react';
import { CloseButton, Col, Dropdown, Modal, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Members from './Members';
import ModalActivityContent from './ModalActivityContent';
import ModalAttachmentContent from './ModalAttachmentContent';
import ModalCommentContent from './ModalCommentContent';
import ModalLabelContent from './ModalLabelContent';
import ModalMediaContent from './ModalMediaContent';
import ModalSidebar from './ModalSidebar';

export const GET_PROJECT_BY_ID = gql`
  query GetProjectById($projectId: uuid!) {
    projects_by_pk(id: $projectId) {
      case_study
      feasibility_study
      project_labels {
        id
        label {
          id
          title
        }
      }
      project_charter
      project_name
      status
      end_date
      start_date
      average_beneficiaries_cost
      donation_amount
      target_amount
      num_of_beneficiaries
      project_members {
        id
        user {
          id
          first_name
          last_name
          # profile_image
        }
      }
      userByManagerId {
        id
        first_name
        last_name
      }
      project_description
      created_at
      updated_at
      id
      country
      project_comments(order_by: { created_at: desc }) {
        created_at
        comment
        id
        user {
          id
          first_name
          last_name
        }
      }
    }
  }
`;

const KanbanModal = () => {
  const [getProjectById, { data: projectData }] =
    useLazyQuery(GET_PROJECT_BY_ID);
  const {
    kanbanState: { kanbanModal },
    kanbanDispatch
  } = useContext(KanbanContext);
  const {
    config: { isRTL }
  } = useContext(AppContext);

  useEffect(() => {
    if (kanbanModal.modalContent?.id) {
      getProjectById({
        variables: {
          projectId: kanbanModal.modalContent.id
        }
      });
    }
  }, [kanbanModal, getProjectById]);

  const handleClose = () => {
    kanbanDispatch({ type: 'TOGGLE_KANBAN_MODAL' });
  };
  const projectMembers = useMemo(() => {
    if (!projectData) return [];
    return projectData.projects_by_pk.project_members.map(({ user }) => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      img: user.profile_pic
    }));
  }, [projectData]);

  const managerName = useMemo(() => {
    if (!projectData) return '';
    return `${projectData.projects_by_pk.userByManagerId.first_name} ${projectData.projects_by_pk.userByManagerId.last_name}`;
  }, [projectData]);

  return (
    <Modal
      show={kanbanModal.show}
      size="lg"
      onHide={handleClose}
      contentClassName="border-0"
      dialogClassName="mt-6"
    >
      {kanbanModal.modalContent?.image && (
        <div className="position-relative overflow-hidden py-8">
          <Background
            image={kanbanModal.modalContent?.image.url}
            className="rounded-top-lg"
          />
        </div>
      )}
      <div className="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
        <CloseButton
          onClick={handleClose}
          className="btn btn-sm btn-circle d-flex flex-center transition-base"
        />
      </div>
      <Modal.Body className="p-0">
        <div className="bg-light rounded-top-lg px-4 py-3">
          <h4 className="mb-1 fs--1">
            {projectData?.projects_by_pk?.project_name}
          </h4>
          <p className="fs--2 mb-0">
            Added by{' '}
            <Link to="#!" className="text-600 fw-semi-bold">
              {managerName}
            </Link>
          </p>
        </div>
        <div className="p-4">
          <Row>
            <Col lg={9}>
              <ModalMediaContent title="Members" icon="user">
                <Members
                  users={projectMembers}
                  addMember
                  showMember={6}
                  avatarSize="xl"
                />
              </ModalMediaContent>

              <ModalMediaContent title="Labels" icon="tag">
                <ModalLabelContent
                  projectLabels={
                    projectData?.projects_by_pk?.project_labels ?? []
                  }
                />
              </ModalMediaContent>

              <ModalMediaContent title="Description" icon="align-left">
                <p className="text-word-break fs--1">
                  {projectData?.projects_by_pk?.project_description}
                </p>
              </ModalMediaContent>

              <ModalMediaContent
                title="Attachments"
                icon="paperclip"
                headingClass="d-flex justify-content-between"
                headingContent={
                  <Dropdown drop={isRTL ? 'start' : 'end'}>
                    <Dropdown.Toggle
                      variant="falcon-default"
                      size="sm"
                      className="dropdown-caret-none fs--2"
                    >
                      <FontAwesomeIcon icon="plus" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item>Computer</Dropdown.Item>
                      <Dropdown.Item>Google Drive</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item>Attach Link</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              >
                <ModalAttachmentContent />
              </ModalMediaContent>

              <ModalMediaContent
                title="Comments"
                icon={['far', 'comment']}
                headingClass="mb-3"
              >
                <ModalCommentContent
                  comments={projectData?.projects_by_pk?.project_comments}
                />
              </ModalMediaContent>

              <ModalMediaContent
                title="Activity"
                icon="list-ul"
                headingClass="mb-3"
                isHr={false}
              >
                <ModalActivityContent />
              </ModalMediaContent>
            </Col>
            <Col lg={3}>
              <ModalSidebar />
            </Col>
          </Row>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default KanbanModal;
