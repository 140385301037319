import { RHFCheck } from 'components/common/form';
import PropTypes from 'prop-types';
import { userActions } from 'routes/permissions/permissionKeys';

export default function PermissionItem({ data, watch }) {
  // const browsePermission = actions.find(({ name }) => name === 'browse');
  const { label, children } = data;
  // const restPermissions = actions.filter(({ name }) => name !== 'browse');

  return (
    <div className="">
      {/* <h5 className="mb-0">{title}</h5> */}
      <div className="d-flex justify-content-between mb-3">
        <h5 className="mb-0">{label}</h5>
        {/* <RHFCheck name={`${parentKey}`} /> */}
      </div>
      <div className="mx-5">
        {children.map(({ key: childKey, label, actions }) => {
          const watchRead = watch(
            `permissions.${childKey}.${userActions.read}`
          );
          const actionsWithoutRead = actions.filter(
            actionName => actionName !== userActions.read
          );
          return (
            <div key={`${childKey}`} className="mb-3">
              <div className="d-flex justify-content-between mb-2">
                <h6 className="mb-0">{label}</h6>
                <RHFCheck
                  name={`permissions.${childKey}.${userActions.read}`}
                />
              </div>
              {actionsWithoutRead.length > 0 && watchRead && (
                <div className="d-flex gap-3">
                  {actionsWithoutRead.map(actionName => (
                    <RHFCheck
                      key={`${childKey}.${actionName}`}
                      name={`permissions.${childKey}.${actionName}`}
                      label={actionName}
                      type="checkbox"
                    />
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {/* {watchBrowse && (
        <div className="d-flex">
          {restPermissions.map(({ name, label }, index) => (
            <div key={index} className="mx-2">
              <RHFCheck
                name={`${parentName}.${name}`}
                label={label}
                type="checkbox"
              />
            </div>
          ))}
        </div>
      )} */}
    </div>
  );
}

PermissionItem.propTypes = {
  watch: PropTypes.func,
  data: PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
        actions: PropTypes.array
      })
    )
  })
};
