import bgNavbar from 'assets/img/generic/bg-navbar.png';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import Logo from 'components/common/Logo';
import { navbarBreakPoint, topNavbarBreakpoint } from 'config';
import AppContext from 'context/Context';
import { capitalize } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect } from 'react';
import { Col, Nav, Navbar, Row } from 'react-bootstrap';
import routes, { organizationSettingRoutes } from 'routes/routes';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import useParentPermissions from 'hooks/useParentPermissions';
import { useAuthContext } from 'context/authContext';

const NavbarVertical = ({ type }) => {
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu
    }
  } = useContext(AppContext);
  const { shouldShowParent } = useParentPermissions();
  const { isOrganizationAdmin } = useAuthContext();

  // const permissions = user.users_roles.find(
  //   item => item.branch_id === selectedBranchId
  // );
  // const finalRoutes = routes.filter(item => {
  //   if (
  //     permissions === undefined ||
  //     !permissions.permissions ||
  //     permissions.permissions[item.key] === undefined
  //   ) {
  //     return true;
  //   } else {
  //     if (permissions.permissions[item.key].browse === true) return true;
  //     else return false;
  //   }
  // });
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add('navbar-vertical-collapsed');
    } else {
      HTMLClassList.remove('navbar-vertical-collapsed');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove('navbar-vertical-collapsed-hover');
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );
  const filteredRoutes = routes.filter(route => shouldShowParent(route.key));

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      variant="light"
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo at="navbar-vertical" width={40} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === 'vibrant'
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : 'none'
        }}
      >
        <div className="navbar-vertical-content scrollbar">
          <Nav className="flex-column" as="ul">
            {type === 'organization' && isOrganizationAdmin ? (
              <>
                <Fragment key={organizationSettingRoutes.label}>
                  {!organizationSettingRoutes.labelDisable && (
                    <NavbarLabel
                      label={capitalize(organizationSettingRoutes.label)}
                    />
                  )}
                  <NavbarVerticalMenu
                    routes={organizationSettingRoutes.children}
                  />
                </Fragment>
              </>
            ) : (
              <>
                {filteredRoutes.map(route => (
                  <Fragment key={route.label}>
                    {!route.labelDisable && (
                      <NavbarLabel label={capitalize(route.label)} />
                    )}
                    <NavbarVerticalMenu routes={route.children} />
                  </Fragment>
                ))}
              </>
            )}
            {}
          </Nav>

          <>
            {navbarPosition === 'combo' && (
              <div className={`d-${topNavbarBreakpoint}-none`}>
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr my-2" />
                </div>
              </div>
            )}
          </>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string
};

export default NavbarVertical;
