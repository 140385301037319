import { gql, useQuery } from '@apollo/client';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
// import FalconCardHeader from 'components/common/FalconCardHeader';
// import IconButton from 'components/common/IconButton';
// import Account from 'components/common/profile/account';
import SoftBadge from 'components/common/SoftBadge';
import TableRowAction from 'components/common/TableRowAction';
// import { useDonorContext } from 'context/donorContext';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

/* -------------------------------------------------------------------------- */
const GET_DONOR_ORDERS = gql`
  query GetOrders($donorId: uuid = "") {
    orders(
      where: { donar_id: { _eq: $donorId } }
      order_by: { created_at: desc }
    ) {
      gift_aid_eligible
      payment_status
      donation_amount
      subtotal
      transaction_fees
      created_at
      updated_at
      branch_id
      donar_id
      id
      organisation_id
      payment_method_id
      order_details {
        package {
          package_title
        }
      }
    }
  }
`;

const DonorGiving = () => {
  const { id } = useParams();
  const { data: orderData, loading: ordersLoading } = useQuery(
    GET_DONOR_ORDERS,
    {
      variables: { donorId: id }
    }
  );
  // const [activeSlider, setActiveSlider] = useState(0);
  // const [activeSlider2, setActiveSlider2] = useState(0);
  // const data = [1, 2, 3];

  const { pathname } = useLocation();

  const columns = [
    {
      accessor: 'id',
      Header: 'Ref',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return <Link to={`${pathname}/${id}`}>{id}</Link>;
      }
    },
    {
      accessor: 'item',
      Header: 'Item',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { order_details } = rowData.row.original;
        return (
          <div>
            {order_details.map((item, index) => {
              return (
                <p
                  className={classNames('m-0', {
                    'mb-1': index !== order_details.length - 1
                  })}
                  key={index}
                >
                  {item.package.package_title}
                </p>
              );
            })}
          </div>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        const date = new Date(created_at).toLocaleDateString('en-GB');
        return <span>{date}</span>;
      }
    },
    {
      accessor: 'pay_method',
      Header: 'Pay Method',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: () => {
        return (
          <SoftBadge pill bg={'success'} className="me-2">
            Completed
            {/* <FontAwesomeIcon icon={rowData.row.original.icon} /> */}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'donation_amount',
      Header: 'Amount',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { donation_amount } = rowData.row.original;
        return <span>${donation_amount.toFixed(2)}</span>;
      }
    },
    {
      accessor: 'idx',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row;
        return (
          <TableRowAction
            onCLickEdit={() => console.log(id)}
            onCLickDelete={() => console.log(id)}
            subject={permissionsKeyMap.donor.children.donors}
          />
        );
      }
    }
  ];
  // const { donor } = useDonorContext();
  return (
    <>
      {/* <Row className="g-3 mb-3">
        <Col md={4}>
          <Account user={donor} score={false} type={'Donor'} />
        </Col>
        <Col md={6}>
          <Card>
            <Carousel
              controls={false}
              interval={2000}
              className="donor-carousel"
              style={{
                height: 'inherit'
              }}
              onSlide={id => setActiveSlider(id)}
            >
              {data?.map(item => (
                <Carousel.Item key={item}>
                  <FalconCardHeader
                    title={
                      <p className="fs-0 mb-0">
                        Regular Donations{' '}
                        <FontAwesomeIcon
                          icon={'question-circle'}
                          className="text-400 ms-2"
                        />
                      </p>
                    }
                    light={false}
                    titleTag="div"
                    className="pb-1"
                    menuClassName="align-items-start"
                    endEl={
                      <IconButton
                        icon="pen"
                        variant="falcon-default"
                        className="fs--1 bg-transparent shadow-none p-0"
                      />
                    }
                  />

                  <Card.Body className="pt-0 pb-5">
                    <p className="fs-2 text-primary fw-medium mb-2">
                      $250.00 <span className="fs--1 text-700">(RG10001)</span>
                    </p>
                    <p className="mb-2">
                      <FontAwesomeIcon
                        icon={'user'}
                        className="text-primary me-1"
                      />
                      Regular Giving - Monthly
                    </p>
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={'credit-card'}
                        className="text-primary me-1"
                      />
                      Credit Card xx241
                    </p>
                  </Card.Body>
                </Carousel.Item>
              ))}
            </Carousel>
            <p className="mb-0 donor-carousel-counter fw-medium">
              {activeSlider + 1} of {data.length}
            </p>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Carousel
              controls={false}
              interval={2500}
              className="donor-carousel"
              onSlide={id => setActiveSlider2(id)}
            >
              {data?.map(item => (
                <Carousel.Item key={item}>
                  <FalconCardHeader
                    title={
                      <p className="fs-0 mb-0">
                        Pledges{' '}
                        <FontAwesomeIcon
                          icon={'question-circle'}
                          className="text-400 ms-2"
                        />
                      </p>
                    }
                    light={false}
                    titleTag="div"
                    className="pb-1"
                    menuClassName="align-items-start"
                    endEl={
                      <IconButton
                        icon="pen"
                        variant="falcon-default"
                        className="fs--1 bg-transparent shadow-none p-0"
                      />
                    }
                  />

                  <Card.Body className="pt-0 pb-5">
                    <p className="fs-2 text-primary fw-medium mb-2">
                      $950.00{' '}
                      <span className="fs--1 text-700">/ $ 1,000.00</span>
                    </p>

                    <p className="mb-2">
                      <FontAwesomeIcon
                        icon={'credit-card'}
                        className="text-primary me-1"
                      />
                      PL100002 - Pledge
                    </p>
                    <p className="mb-0">
                      <FontAwesomeIcon
                        icon={'check-circle'}
                        className="text-success me-1"
                      />
                      Completed
                    </p>
                  </Card.Body>
                </Carousel.Item>
              ))}
            </Carousel>
            <p className="mb-0 donor-carousel-counter fw-medium">
              {activeSlider2 + 1} of {data.length}
            </p>
          </Card>
        </Col>
      </Row> */}

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {orderData?.orders && (
            <CustomAdvanceTable
              data={orderData?.orders}
              columns={columns}
              title="Invoices"
              showSearchInput
              searchInputPlaceHolder="Search"
              addNew={false}
              loading={ordersLoading}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DonorGiving;
