import React, { useEffect, useMemo, useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { KanbanContext } from 'context/Context';
import currentUserAvatar from 'assets/img/team/3.jpg';

import {
  members,
  labels,
  attachments,
  comments,
  activities
} from 'data/kanban';
import { kanbanReducer } from 'reducers/kanbanReducer';
import { useQuery } from '@apollo/client';
import {
  GET_BRANCH_PROJECTS,
  PROJECT_STATUS_COMPLETED,
  PROJECT_STATUS_PROPOSED,
  PROJECT_STATUS_INPROGRESS,
  PROJECT_STATUS_REJECTED
} from '../ProjectsList';
import { useAuthContext } from 'context/authContext';

const KanbanProvider = ({ children }) => {
  const { selectedBranchId } = useAuthContext();
  const { data: projectsData } = useQuery(GET_BRANCH_PROJECTS, {
    variables: {
      branchId: selectedBranchId
    }
  });

  const kanbanItems = useMemo(() => {
    const proposedProjectsData = projectsData?.projects.filter(
      project => project.status === PROJECT_STATUS_PROPOSED
    );
    const proposedProjectsItems = proposedProjectsData?.map(project => ({
      managerId: project.userByManagerId.id,
      id: project.id,
      title: project.project_name
      // user: project.user.name,
      // labels: [
      //   { type: 'success', text: 'New' },
      //   { type: 'primary', text: 'Goal' }
      // ],
      // attachments: [{ type: 'doc', name: 'test.txt' }],
      // checklist: { totalCount: 10, completed: 2 },
      // members: [{ name: 'Shophie', url: team3 }]
    }));
    const inProgressProjectsData = projectsData?.projects.filter(
      project => project.status === PROJECT_STATUS_INPROGRESS
    );
    const inProgressProjectsItems = inProgressProjectsData?.map(project => ({
      managerId: project.userByManagerId.id,
      id: project.id,
      title: project.project_name
    }));
    const completedProjectsData = projectsData?.projects.filter(
      project => project.status === PROJECT_STATUS_COMPLETED
    );
    const completedProjectsItems = completedProjectsData?.map(project => ({
      managerId: project.userByManagerId.id,
      id: project.id,
      title: project.project_name
    }));
    const rejectedProjectsData = projectsData?.projects.filter(
      project => project.status === PROJECT_STATUS_REJECTED
    );
    const rejectedProjectsItems = rejectedProjectsData?.map(project => ({
      managerId: project.userByManagerId.id,
      id: project.id,
      title: project.project_name
    }));
    return [
      {
        id: 1,
        name: 'Proposed Projects',
        color: '#bbc1c7',
        items: proposedProjectsItems ?? []
      },
      {
        id: 2,
        name: 'In progress',
        color: '#fad464',
        items: inProgressProjectsItems ?? []
      },
      {
        id: 3,
        name: 'Completed',
        color: '#7ee094',
        items: completedProjectsItems ?? []
      },
      {
        id: 4,
        name: 'Rejected',
        color: '#eb6370',
        items: rejectedProjectsItems ?? []
      }
    ];
  }, [projectsData]);

  const initData = {
    members: members,
    labels: labels,
    attachments: attachments,
    kanbanItems: kanbanItems,
    comments: comments,
    activities: activities,
    kanbanModal: {
      show: false,
      projectId: null
    }
  };

  const currentUser = {
    name: 'Emma',
    avatarSrc: currentUserAvatar,
    profileLink: '/user/profile',
    institutionLink: '#!'
  };

  const [kanbanState, kanbanDispatch] = useReducer(kanbanReducer, initData);
  useEffect(() => {
    if (projectsData) {
      kanbanDispatch({
        type: 'SET_KANBAN_ITEMS',
        payload: kanbanItems
      });
    }
  }, [kanbanItems, projectsData]);
  const [selectedMember, setSelectedMember] = useState({});
  return (
    <KanbanContext.Provider
      value={{
        kanbanState,
        kanbanDispatch,
        currentUser,
        selectedMember,
        setSelectedMember
      }}
    >
      {children}
    </KanbanContext.Provider>
  );
};

KanbanProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default KanbanProvider;
