import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const TableRow = ({
  data,
  endToEnd = false,
  isLastItem,
  showIcon = true,
  firstClassName,
  secondClassName
}) => {
  const { icon, label, value, symbol, color, progress, progressValue } = data;

  return (
    <tr className={isLastItem ? 'border-100' : ''}>
      <td
        className={classNames({
          'ps-0 py-2': endToEnd,
          'py-3': !endToEnd
        })}
      >
        <Flex alignItems="center">
          {icon && <img src={icon} alt={label} width={16} />}
          <h6 className={`${icon && 'ms-2'} ${firstClassName} text-600 mb-0`}>
            {label}
          </h6>
        </Flex>
      </td>
      <td
        className={classNames({
          'pe-0 py-2': endToEnd,
          'py-3': !endToEnd
        })}
      >
        <Flex
          alignItems="center"
          justifyContent={!progressValue ? 'end' : 'start'}
        >
          {showIcon && (
            <FontAwesomeIcon
              icon="circle"
              className={`text-${color} fs--2 me-2`}
            />
          )}

          <h6 className={`${secondClassName} text-700 fw-normal mb-0`}>
            {value}
            {symbol ? symbol : null}
          </h6>
        </Flex>
      </td>
      {progressValue && (
        <td className="py-3">
          <Flex alignItems="center" justifyContent="end">
            <FontAwesomeIcon
              icon={progress ? 'caret-up' : 'caret-down'}
              className={progress ? 'text-success' : 'text-danger'}
            />
            <h6 className="fs--2 text-700 mb-0 ms-2">{progressValue}</h6>
          </Flex>
        </td>
      )}
    </tr>
  );
};

TableRow.propTypes = {
  data: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    progress: PropTypes.bool,

    color: PropTypes.string,
    symbol: PropTypes.string,
    progressValue: PropTypes.string
  }).isRequired,
  endToEnd: PropTypes.bool,
  isLastItem: PropTypes.bool,
  showIcon: PropTypes.bool,
  firstClassName: PropTypes.string,
  secondClassName: PropTypes.string
};

export default TableRow;
