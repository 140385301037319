import user1 from 'assets/img/we-raise-images/DonarImage.png';
export const weUserImage = user1;
export const weUserImage2 =
  'https://getnames.net/wp-content/uploads/2018/03/Islamic-Pakistani-boy-names-with-Urdu-meanings.jpg';

export const userTableData = [
  {
    name: 'Anna Doe',
    email: 'anna@example.com',
    status: 'Active',
    variant: 'success',
    id: '1289',
    branch: 'Help Yateem UK'
  },
  {
    name: 'Homer Simpson',
    email: 'homer@example.com',
    status: 'Active',
    variant: 'success',
    id: '1290',
    branch: 'Help Yateem Somalia'
  },
  {
    name: 'Oscar Doe',
    email: 'oscar@example.com',
    status: 'Inactive',
    variant: 'secondary',
    id: '1291',
    branch: 'Help Yateem Kenia'
  },
  {
    name: 'Emily Mathew',
    email: 'emily@gmail.com',
    status: 'Active',
    variant: 'success',
    id: '1292',
    branch: 'Help Yateem yemen '
  }
];

export const weDonarTableData = [
  {
    name: 'Anna',
    email: 'anna@example.com',
    phone: '(201) 200-1851',
    score: 198,
    variant: 'warning',
    address: 'Monthly Donation - Bread 4 Yemen',
    id: '1289',
    age: 8,
    location: 'Jordan',
    gender: 'Male',
    sponsor: 'Ahmed Amin',
    sp_validity: '12/21/2022',
    priority: 'Immediate',
    type: 'Orphan'
  },
  {
    name: 'Homer',
    email: 'homer@example.com',
    phone: '(201) 200-1851',
    score: 1968,
    variant: 'info',
    address: 'Onetime Donation - Zakat',
    id: '1290',
    age: 12,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '12/21/2022',
    priority: 'Modrate',
    type: 'Orphan'
  },
  {
    name: 'Oscar',
    email: 'oscar@example.com',
    phone: '(201) 200-1851',
    score: 98,
    variant: 'danger',
    address: 'Onetime Donation - Build Orphanage Centre',
    id: '1291',
    age: 12,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '',
    priority: 'Modrate',
    type: 'Orphan'
  },
  {
    name: 'Emily',
    email: 'emily@example.com',
    phone: '(201) 200-1851',
    score: 998,
    variant: 'primary',
    address: 'Onetime Donation - Water Relief',
    id: '1292',
    age: 12,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '12/21/2022',
    priority: '',
    type: 'Orphan'
  },
  {
    name: 'Jara',
    email: 'jara@example.com',
    phone: '(201) 200-1851',
    score: 3938,
    variant: 'success',
    address: 'Onetime Donation - Sadaqah jariyah',
    id: '1293',
    age: 12,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '12/21/2022',
    priority: 'Immediate',
    type: 'Orphan'
  },

  {
    name: 'Anna',
    email: 'anna@example.com',
    phone: '(201) 200-1851',
    score: 118,
    variant: 'warning',
    address: 'Monthly Donation - Bread 4 Yemen',
    id: '12891',
    age: 18,
    location: 'Jordan',
    gender: 'Male',
    sponsor: 'Ahmed Amin',
    sp_validity: '12/21/2022',
    priority: 'Immediate',
    type: 'Orphan'
  },
  {
    name: 'Homer',
    email: 'homer@example.com',
    phone: '(201) 200-1851',
    score: 19683,
    variant: 'info',
    address: 'Onetime Donation - Zakat',
    id: '12901',
    age: 19,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '12/21/2022',
    priority: 'Modrate',
    type: 'Orphan'
  },
  {
    name: 'Oscar',
    email: 'oscar@example.com',
    phone: '(201) 200-1851',
    score: 981,
    variant: 'danger',
    address: 'Onetime Donation - Build Orphanage Centre',
    id: '12911',
    age: 22,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '',
    priority: 'Modrate',
    type: 'Orphan'
  },
  {
    name: 'Emily',
    email: 'emily@example.com',
    phone: '(201) 200-1851',
    score: 9981,
    variant: 'primary',
    address: 'Onetime Donation - Water Relief',
    id: '12921',
    age: 25,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '12/21/2022',
    priority: '',
    type: 'Orphan'
  },
  {
    name: 'Jara',
    email: 'jara@example.com',
    phone: '(201) 200-1851',
    score: 39382,
    variant: 'success',
    address: 'Onetime Donation - Sadaqah jariyah',
    id: '12932',
    age: 20,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '12/21/2022',
    priority: 'Immediate',
    type: 'Orphan'
  },
  {
    name: 'Emily',
    email: 'emily@example.com',
    phone: '(201) 200-1851',
    score: 9981,
    variant: 'primary',
    address: 'Onetime Donation - Water Relief',
    id: '12921',
    age: 25,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '12/21/2022',
    priority: '',
    type: 'Orphan'
  },
  {
    name: 'Jara',
    email: 'jara@example.com',
    phone: '(201) 200-1851',
    score: 39382,
    variant: 'success',
    address: 'Onetime Donation - Sadaqah jariyah',
    id: '12932',
    age: 20,
    location: 'Jordan 2',
    gender: 'Female',
    sponsor: 'Ahmed Jhon',
    sp_validity: '12/21/2022',
    priority: 'Immediate',
    type: 'Orphan'
  }
];

export const weDonarNotifications = [
  {
    id: 1,
    title: 'Acknowledge New Donors',
    linkFor: 'products',
    type: 'primary'
  },
  {
    id: 2,
    title: 'Follow up on overdue offline Recurring',
    linkFor: 'payments'
  }
];

export const weDonarItems = ({
  totalDonors,
  newDonor,
  monthlyHighest,
  weeklyHighest
}) => {
  return [
    {
      title: 'Total Donors',
      amount: '<strong>' + totalDonors + '</strong>',
      subAmount: '775',
      type: 'standard',
      percent: 21.8
    },
    {
      title: 'New Donors <span class="fw-normal fs--2">This Month</span>',
      amount: '<strong>' + newDonor + '</strong>',
      subAmount: '109.65 ',
      type: 'down',
      percent: 21.8
    },
    {
      title:
        (monthlyHighest?.donar?.first_name ?? ' ') +
        ' ' +
        (monthlyHighest?.donar?.last_name ?? '') +
        ' <span class="fw-normal fs--2">This Month</span>',
      amount: '<strong>' + (monthlyHighest?.donation_amount ?? 0) + '</strong>',
      subAmount: '109.65 ',
      type: 'down',
      percent: 21.8
    },
    {
      title:
        (weeklyHighest?.donar?.first_name ?? ' ') +
        ' ' +
        (weeklyHighest?.donar?.last_name ?? '') +
        ' <span class="fw-normal fs--2">This Week</span>',
      amount: '<strong>' + (weeklyHighest?.donation_amount ?? 0) + '</strong>',
      subAmount: '109.65 ',
      type: 'down',
      percent: 21.8
    }
  ];
};

export const weBeneficiariesItems = ({ totalBeneficiaries }) => [
  {
    title: 'Orphans & Familys',
    amount: '<strong>' + totalBeneficiaries + '</strong>',
    subAmount: totalBeneficiaries,
    type: 'up',
    percent: 21.8
  },
  {
    title: 'Sponsored Families',
    amount: '<strong>22</strong>',
    subAmount: '15',
    type: 'standard',
    percent: 21.8
  },
  {
    title: 'Sponsored children',
    amount: '<strong>184</strong>',
    subAmount: '202',
    type: 'pending',
    percent: 21.8
  },
  {
    title: 'New Donors <span class="fw-normal fs--2">This Month</span>',
    amount: '<strong>130</strong>',
    subAmount: '109.65 ',
    type: 'down',
    percent: 21.8
  },
  {
    title: 'Critical Cases',
    amount: '<strong>75</strong>',
    // subAmount: '109.65 ',
    type: 'warning',
    showContent: false
    // percent: 21.8
  }
];

export const weDonationItems = ({ packages }) => [
  {
    title: 'Total Packages',
    amount: '<strong>' + packages.length ?? 0 + '</strong>',
    subAmount: '202',
    type: 'up',
    percent: 21.8
  },
  {
    title: 'Active Packages',
    amount:
      '<strong>' + packages.filter(item => item.status === 'active').length ??
      0 + '</strong>',
    subAmount: '15',
    type: 'standard',
    percent: 21.8
  },
  {
    title: 'Inactive Packages',
    amount:
      '<strong>' +
      (packages.length -
        packages.filter(item => item.status === 'active').length) +
      '</strong>',
    subAmount: '202',
    type: 'pending',
    percent: 21.8
  }
];

export const weDonorInvoiceData = [
  {
    ref: 'OR1187',
    item: 'Reacuring Sponsor..',
    date: '12/13/2022 21:43',
    status: 'On hold',
    icon: 'bars',
    variant: 'warning',
    pay_method: 'Stipe',
    amount: '$9502',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1189',
    item: 'zakat Donation',
    date: '12/21/2022 23:02',
    status: 'Refund',
    icon: 'ban',
    variant: 'secondary',
    pay_method: 'PayPal',
    amount: '$9501',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Call',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1190',
    item: 'Water Relaf',
    date: '12/22/2022 19:10',
    status: 'Cancel',
    icon: 'times',
    variant: 'danger',
    pay_method: 'Bank Transfer',
    amount: '$9504',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1191',
    item: 'Sadaka Donation',
    date: '12/25/2022 08:00',
    status: 'Processing',
    icon: 'bars',
    variant: 'primary',
    pay_method: 'Cash',
    amount: '$9509',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1192',
    item: 'Sadaka Donation',
    date: '12/26/2022 16:53',
    status: 'Success',
    icon: 'check',
    variant: 'success',
    pay_method: 'PayPal',
    amount: '$9506',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Document',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1189',
    item: 'zakat Donation',
    date: '12/21/2022 23:02',
    status: 'Refund',
    icon: 'ban',
    variant: 'secondary',
    pay_method: 'PayPal',
    amount: '$9501',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1190',
    item: 'Water Relaf',
    date: '12/22/2022 19:10',
    status: 'Cancel',
    icon: 'times',
    variant: 'danger',
    pay_method: 'Bank Transfer',
    amount: '$9504',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1191',
    item: 'Sadaka Donation',
    date: '12/25/2022 08:00',
    status: 'Processing',
    icon: 'bars',
    variant: 'primary',
    pay_method: 'Cash',
    amount: '$9509',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'SMS',
    title: 'Donation Receipt - (SMS)'
  },
  {
    ref: 'OR1192',
    item: 'Sadaka Donation',
    date: '12/26/2022 16:53',
    status: 'Success',
    icon: 'check',
    variant: 'success',
    pay_method: 'PayPal',
    amount: '$9506',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1190',
    item: 'Water Relaf',
    date: '12/22/2022 19:10',
    status: 'Cancel',
    icon: 'times',
    variant: 'danger',
    pay_method: 'Bank Transfer',
    amount: '$9504',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1191',
    item: 'Sadaka Donation',
    date: '12/25/2022 08:00',
    status: 'Processing',
    icon: 'bars',
    variant: 'primary',
    pay_method: 'Cash',
    amount: '$9509',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1192',
    item: 'Sadaka Donation',
    date: '12/26/2022 16:53',
    status: 'Success',
    icon: 'check',
    variant: 'success',
    pay_method: 'PayPal',
    amount: '$9506',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Document',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1189',
    item: 'zakat Donation',
    date: '12/21/2022 23:02',
    status: 'Refund',
    icon: 'ban',
    variant: 'secondary',
    pay_method: 'PayPal',
    amount: '$9501',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1190',
    item: 'Water Relaf',
    date: '12/22/2022 19:10',
    status: 'Cancel',
    icon: 'times',
    variant: 'danger',
    pay_method: 'Bank Transfer',
    amount: '$9504',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  },
  {
    ref: 'OR1191',
    item: 'Sadaka Donation',
    date: '12/25/2022 08:00',
    status: 'Processing',
    icon: 'bars',
    variant: 'primary',
    pay_method: 'Cash',
    amount: '$9509',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'SMS',
    title: 'Donation Receipt - (SMS)'
  },
  {
    ref: 'OR1192',
    item: 'Sadaka Donation',
    date: '12/26/2022 16:53',
    status: 'Success',
    icon: 'check',
    variant: 'success',
    pay_method: 'PayPal',
    amount: '$9506',
    pay_sys: 'Stipe - xx1254',
    donation: 'L3M/D1',
    type: 'Email',
    title: 'Donation Receipt - (Email)'
  }
];

export const donationInfo = [
  {
    id: 1,
    statusColor: 'text-primary',
    title: 'Average Donations',
    amount: '$820.50',
    notification: 5,
    lastDonation: '08/07/2022 13:00'
  },
  {
    id: 2,
    statusColor: 'text-warning',
    title: 'Donated to date',
    amount: '$1,825.50',
    notification: 0,
    lastDonation: ''
  },
  {
    id: 3,
    statusColor: 'text-success',
    title: 'Frequency',
    amount: '',
    info: 'Moderate',
    notification: 0,
    donationCount: 12
  }
];

export const weDonorGivingData = [
  {
    label: 'Sadaka - Somalia',
    name: 'Sadaka - Somalia',
    value: 50.3,
    caret: 'fas fa-caret-down text-danger',
    color: 'primary',
    symbol: '%'
  },
  {
    label: 'Zakat - Yameen',
    name: 'Zakat - Yameen',
    value: 30.1,
    caret: 'fas fa-caret-up text-success',
    color: 'success',
    symbol: '%'
  },
  {
    label: 'Sponsorship',
    name: 'Sponsorship',
    value: 20.6,
    caret: 'fas fa-caret-up text-success',
    color: 'info',
    symbol: '%'
  },
  {
    label: 'Water Relief - Yameen',
    name: 'Water Relief - Yameen',
    value: 10.6,
    caret: 'fas fa-caret-up text-success',
    color: 'danger',
    symbol: '%'
  }
];

export const weRelatedContacts = [
  {
    id: 1,
    name: 'Ahmed Adel',
    avatar: {
      src: weUserImage,
      size: '2xl',
      status: 'online'
    },
    role: 'Co-Worker'
  },
  {
    id: 2,
    name: 'Hassan Mohamed',
    avatar: {
      src: weUserImage,
      size: '2xl',
      status: 'online'
    },
    role: 'Project Manager'
  },
  {
    id: 3,
    name: 'Yasser Amin',
    avatar: {
      src: weUserImage,
      size: '2xl',
      status: 'away'
    },
    role: 'Co-Worker'
  },
  {
    id: 4,
    name: 'Mohamed Ali',
    avatar: {
      src: weUserImage,
      size: '2xl',
      status: 'offline'
    },
    role: 'Co-Worker'
  },
  {
    id: 5,
    name: 'Osama Zaid',
    avatar: {
      src: weUserImage,
      size: '2xl',
      status: 'offline'
    },
    role: 'Co-Worker'
  },
  {
    id: 6,
    name: 'Bucky Robert',
    avatar: {
      src: weUserImage,
      size: '2xl',
      status: 'offline'
    },
    role: 'Co-Worker'
  },
  {
    id: 7,
    name: 'Tom Hanks',
    avatar: {
      src: weUserImage,
      size: '2xl',
      status: 'offline'
    },
    role: 'Co-Worker'
  }
];

export const weActiveSponsorshipsData = [
  {
    id: 1,
    title: 'SP10055 - Orphan Sponsorship - Ch10098',
    name: 'Yasmin Zaid',
    status: [
      { id: '1.1', label: 'Active', bg: 'success' },
      { id: '1.2', label: 'FixedTerm', bg: 'warning' },
      { id: '1.3', label: 'Pack Avaialbe', bg: 'primary' }
    ],
    location: 'Yaman',
    reminder: 'Reminders on',
    date: 'Jun 2022 - Jun 2025',
    amount: '$2,500.0'
  },
  {
    id: 2,
    title: 'SP10003 - Orphan Sponsorship - Ch10022',
    name: 'Zahran Amir',
    status: [
      { id: '2.1', label: 'Active', bg: 'success' },
      { id: '2.2', label: 'FixedTerm', bg: 'warning' },
      { id: '2.3', label: 'Pack Avaialbe', bg: 'primary' }
    ],
    location: 'Yaman',
    reminder: 'Reminders on',
    date: 'Jun 2022 - Jun 2025',
    amount: '$2,500.0 . $60.0 / M'
  },
  {
    id: 3,
    title: 'SP10089 - Orphan Sponsorship - Ch10092',
    name: 'Ibrahim Amir',
    status: [
      { id: '3.1', label: 'Active', bg: 'success' },
      { id: '5.3', label: 'Pack Avaialbe', bg: 'primary' }
    ],
    location: 'Yaman',
    reminder: 'Reminders on',
    date: 'Jun 2022 - Jun 2025',
    amount: '$60.0 / M'
  }
];

export const weBeneficiaryData = [
  {
    ref: 'FEED005',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED006',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED007',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED008',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED009',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED010',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED005',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED006',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED007',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED008',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED009',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  },
  {
    ref: 'FEED010',
    name: 'Zain Bassam Al Zaheri',
    age: 20,
    location: 'Jordan',
    gender: 'Male'
  }
];

export const weSponsorshipDetails = [
  {
    label: 'Commitment Type',
    value: 'Open ended'
  },
  {
    label: 'Time Frame',
    value: '01/01/2022 to 01/01/2023'
  },
  {
    label: 'Contributions',
    value: '$2,500.0'
  },
  {
    label: 'Donations Total',
    value: '$5,500.0'
  }
];

export const weSponsorshipSchemes = [
  {
    label: 'Sponsoring Component(s)',
    value: 'General ($25.00)'
  },
  {
    label: 'Due for renewal on',
    value: '31/08/2022'
  },
  {
    label: 'Location',
    value: 'Jordon'
  },
  {
    label: 'Theme',
    value: 'Child Welfare'
  },
  {
    label: 'Stipulation',
    value: 'Sadaqah'
  }
];

export const weSponsorModalTabTableData = [
  {
    date: '13/05/2020',
    summary: 'Contrbution SP1000022-1-22-05',
    amount: '$50.00'
  },
  {
    date: '13/05/2020',
    summary: 'Contrbution SP1000022-1-22-05m',
    amount: '$50.00'
  },
  {
    date: '13/05/2020',
    summary: 'Contrbution SP1000022-1-22-05m',
    amount: '$50.00'
  },
  {
    date: '13/05/2020',
    summary: 'Contrbution SP1000022-1-22-05m',
    amount: '$50.00'
  },
  {
    date: '13/05/2020',
    summary: 'Contrbution SP1000022-1-22-05',
    amount: '$50.00'
  }
];

export const weTaskList = [
  {
    id: 1,
    task: 'Call Contact regarding water relief campaign',
    color: 'primary'
  },
  {
    id: 2,
    task: 'Donation Request',
    color: 'secondary'
  },
  {
    id: 3,
    task: 'email Yamen campaign',
    color: 'success'
  },
  {
    id: 4,
    task: 'Request Sponsorship',
    color: 'warning'
  },
  {
    id: 5,
    task: 'Meeting at 12',
    color: 'danger'
  },
  {
    id: 6,
    task: 'Meeting at 10',
    color: 'info'
  }
];

export const weRelationManagementData = [
  {
    id: '1',
    reporter: 'Ibrahim Amir',
    date: '12/13/2022 21.43',
    description:
      " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
  },
  {
    id: '2',
    reporter: 'Ibrahim Amir',
    date: '12/13/2022 21.43',
    description:
      " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
  },
  {
    id: '3',
    reporter: 'Ibrahim Amir',
    date: '12/13/2022 21.43',
    description:
      " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
  },
  {
    id: '4',
    reporter: 'Ibrahim Amir',
    date: '12/13/2022 21.43',
    description:
      " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
  },
  {
    id: '5',
    reporter: 'Ibrahim Amir',
    date: '12/13/2022 21.43',
    description:
      " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
  },
  {
    id: '6',
    reporter: 'Ibrahim Amir',
    date: '12/13/2022 21.43',
    description:
      " is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
  }
];

export const weGiftEidData = [
  {
    id: '1',
    ref: 'DE0005',
    date: '12/13/2022',
    startOn: '12/02/2022',
    endOn: '-',
    eligibility: true,
    declared_via: 'Phone (Inbound)',
    feed: 'I must stay updated with the latest news regarding the projects I donated.',
    priority: 'Important',
    color: 'text-danger'
  },
  {
    id: '2',
    ref: 'DE0005',
    date: '12/13/2022',
    startOn: '12/02/2022',
    endOn: '29/02/2022',
    eligibility: true,
    declared_via: 'Phone (Inbound)',
    feed: 'I must stay updated with the latest news regarding the projects I donated.',
    priority: 'Fair',
    color: 'text-warning'
  },
  {
    id: '3',
    ref: 'DE0005',
    date: '12/13/2022',
    startOn: '12/02/2022',
    endOn: '-',
    eligibility: true,
    declared_via: 'Phone (Inbound)',
    feed: 'I must stay updated with the latest news regarding the projects I donated.',
    priority: 'Important',
    color: 'text-info'
  },
  {
    id: '4',
    ref: 'DE0005',
    date: '12/13/2022',
    startOn: '12/02/2022',
    endOn: '-',
    eligibility: true,
    declared_via: 'Phone (Inbound)',
    feed: 'I must stay updated with the latest news regarding the projects I donated.',
    priority: 'Ok',
    color: 'text-success'
  }
];

export const weFeedBackData = [
  {
    id: 1,
    label: 'General',
    value: 9,
    baseValue: 10,
    iconColor: 'text-success'
  },
  {
    id: 2,
    label: 'Satisfaction',
    value: 9,
    baseValue: 10,
    iconColor: 'text-info'
  },
  {
    id: 3,
    label: 'The rep.',
    value: 9,
    baseValue: 10,
    iconColor: 'text-danger'
  },
  {
    id: 4,
    label: 'Communications',
    value: 9,
    baseValue: 10,
    iconColor: 'text-warning'
  }
];

export const weAddSponsorData = [
  {
    id: 1,
    title: 'Family Sponsorship',
    details: 'sponsor a family in need, entire orphaned and vulnerable families'
  },
  {
    id: 2,
    title: 'Orphan Sponsorship',
    details:
      'sponsor an orphan, un child welfare and support them trough our Orphan Sponsorship'
  }
];
