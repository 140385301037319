import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';

import { Modal } from 'react-bootstrap';

import SchoolModalForm from './SchoolModalForm';
import { GET_SCHOOL_STUDENT_BY_ID } from '../queries';

import LoadingContainer from '../../common/LoadingContainer';

function EditSchoolModal({
  show,
  id,
  onSubmit,
  isSubmitLoading,
  onHide,
  title
}) {
  const { data, loading } = useQuery(GET_SCHOOL_STUDENT_BY_ID, {
    fetchPolicy: 'no-cache',
    variables: { id }
  });

  const formInitData = {};
  if (data) {
    const { school_students_by_pk: schoolStudentData } = data;
    formInitData.school = schoolStudentData.school_id;
    formInitData.startDate = schoolStudentData.start_date;
    formInitData.endDate = schoolStudentData.end_date;
  }

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className="modal-header">
        <Modal.Title className="modal-header-title">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-3 ps-3 pe-3">
        {loading ? (
          <LoadingContainer />
        ) : (
          <SchoolModalForm
            actionType="EDIT"
            initValues={formInitData}
            onSubmit={onSubmit}
            isSubmitLoading={isSubmitLoading}
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
EditSchoolModal.propTypes = {
  show: PropTypes.bool,
  id: PropTypes.string,
  onSubmit: PropTypes.func,
  isSubmitLoading: PropTypes.bool,
  onHide: PropTypes.func,
  title: PropTypes.string
};
export default EditSchoolModal;
