import image0 from 'assets/img/we-raise-images/front-end/cart_image_0.png';
import image1 from 'assets/img/we-raise-images/front-end/cart_image_1.png';
import image2 from 'assets/img/we-raise-images/front-end/cart_image_2.png';
import image3 from 'assets/img/we-raise-images/front-end/cart_image_3.png';
import image4 from 'assets/img/we-raise-images/front-end/cart_image_4.png';
import image5 from 'assets/img/we-raise-images/front-end/cart_image_5.png';
import image6 from 'assets/img/we-raise-images/front-end/cart_image_6.png';
import image7 from 'assets/img/we-raise-images/front-end/cart_image_7.png';

export const stages = {
  Donation1: 1,
  Donation2: 2,
  Donation3: 3,
  Details1: 4,
  Details2: 5,
  Payment: 6,
  PayNow: 7
};

export const firstPageContents = {
  donationType: 'donation-type',
  donationCause: 'Cause',
  donationAmount: 'donation_amount'
};

export const donationOptions = [
  { id: 1, title: 'Zakat' },
  { id: 2, title: 'Winter Appeal' },
  { id: 3, title: 'Sadaqah' },
  { id: 4, title: 'Yemen Appeal' },
  { id: 5, title: 'Random' },
  { id: 6, title: 'Wherever Needed Most' },
  { id: 7, title: 'Syria Crisis Appeal' },
  { id: 8, title: 'Food Appeal' },
  { id: 9, title: 'Orphanage' },
  { id: 10, title: 'Health Appeal' },
  { id: 11, title: 'Sponsorship' },
  { id: 12, title: 'Child Wilfare' }
];

export const countryOptions = [
  { value: '1', label: 'Pakistan' },
  { value: '2', label: 'United State' },
  { value: '3', label: 'United Kingdom' },
  { value: '4', label: 'All' }
];

export const donationCurrencyData = [
  { value: '1', label: 'USD', symbol: '$' },
  { value: '2', label: 'PR', symbol: 'Rs' },
  { value: '3', label: 'EUR', symbol: '£' }
];

export const amountsOptions = [
  { id: 50, label: '50' },
  { id: 100, label: '100' },
  { id: 150, label: '150' },
  { id: 'other', label: 'Other' }
];

export const projectItems = [
  {
    id: 1,
    projectName: 'Water Project',
    value: '£65.00',
    image: image0
  },
  {
    id: 2,
    projectName: 'Food Project',
    value: '£65.00',
    image: image2
  },
  {
    id: 3,
    projectName: 'ORPHAN SPONSORSHIP',
    value: '£65.00',
    image: image3
  },
  {
    id: 4,
    projectName: 'Water Project',
    value: '£65.00',
    image: image1
  },
  {
    id: 5,
    projectName: 'Food Project',
    value: '£65.00',
    image: image5
  },
  {
    id: 6,
    projectName: 'Water Project',
    value: '£65.00',
    image: image4
  },
  {
    id: 7,
    projectName: 'Water Project',
    value: '£65.00',
    image: image6
  },
  {
    id: 8,
    projectName: 'Water Project',
    value: '£65.00',
    image: image7
  },
  {
    id: 9,
    projectName: 'Water Project',
    value: '£65.00',
    image: image0
  },
  {
    id: 10,
    projectName: 'Food Project',
    value: '£65.00',
    image: image1
  },
  {
    id: 11,
    projectName: 'Water Project',
    value: '£65.00',
    image: image2
  },
  {
    id: 12,
    projectName: 'Water Project',
    value: '£65.00',
    image: image3
  },
  {
    id: 13,
    projectName: 'Water Project',
    value: '£65.00',
    image: image4
  },
  {
    id: 14,
    projectName: 'Water Project',
    value: '£65.00',
    image: image5
  }
];

export const howYouFindUsOptions = [
  { label: 'Facebook', value: 'fb' },
  { label: 'Instagram', value: 'ig' },
  { label: 'Linkedin', value: 'li' },
  { label: 'Others', value: 'ot' }
];

export const donationInfoOption = [
  {
    id: 1,
    donationType: 'Orphan sponsorship',
    donationAmount: '1,000 USD',
    charge: 'Transaction fees',
    chargeAmount: '10 USD',
    editAble: true
  },
  {
    id: 2,
    donationType: 'Urgent Food Appeal',
    donationAmount: '50 USD',
    charge: 'Transaction fees',
    chargeAmount: '1 USD',
    editAble: false
  }
];
