import { useQuery } from '@apollo/client';
import { faStoreAltSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BranchCard from 'components/settings/branches/BranchCard';
import { GET_BRANCHES } from 'components/settings/branches/Branches';
import AuthContext from 'context/authContext';
import React, { useContext, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom/dist';
import paths from 'routes/paths';

export default function BranchesSelection() {
  const navigate = useNavigate();

  // const handleCreateBranch = () => {
  //   navigate(paths.branchCreate);
  // };

  const {
    user,
    selectBranch,
    selectAdminBranch,
    isOrganizationAdmin,
    organization
    // is_main
  } = useContext(AuthContext);

  const { data, loading } = useQuery(GET_BRANCHES, {
    variables: {
      organisation_id: organization.id
    },
    skip: !isOrganizationAdmin
  });

  const branchesUserRoles = user.users_roles.filter(
    userRole => userRole.branch !== null
  );
  const renderAdminBranches = () =>
    data?.branches.map(branch => (
      <BranchCard
        key={branch.id}
        name={branch.name}
        image={branch.logo}
        organization={true}
        handleClick={() => {
          selectAdminBranch({
            selectedBranchId: branch.id,
            is_main: branch.is_main
          });
          navigate(paths.dashboard);
        }}
        isButton
        isActive={branch.is_active}
      />
    ));
  const renderBranches = () =>
    branchesUserRoles?.map(userRole => {
      return (
        <BranchCard
          isActive={userRole.is_active}
          key={userRole.id}
          // id={branch.id}
          name={userRole?.branch.name}
          image={userRole?.branch.logo}
          organization={true}
          handleClick={() => {
            selectBranch({ userRole, is_main: userRole?.branch.is_main });
            navigate(paths.dashboard);
          }}
          isButton
        />
      );
    });

  useEffect(() => {
    if (isOrganizationAdmin && data?.branches.length === 1) {
      navigate(paths.dashboard);
    } else if (!isOrganizationAdmin && branchesUserRoles?.length === 1) {
      navigate(paths.dashboard);
    }
  }, [
    branchesUserRoles?.length,
    isOrganizationAdmin,
    data?.branches.length,
    navigate
  ]);

  return (
    <Container fluid className="px-0">
      <div className="bg-white p-3 flex-wrap d-inline-block w-100">
        <div className="bg-whited-flex h-100 d-flex float-sm-start">
          <h5 className="h-100 mb-0 ms-2 fw-semi-bold">
            Organization Branches
          </h5>
        </div>
        {/* {isOrganizationAdmin && (
          <Button
            className="float-sm-end border-0 shadow-sm"
            variant="outline-dark"
            onClick={handleCreateBranch}
          >
            <span className="text-primary">+</span> Add Branch
          </Button>
        )} */}
        <div></div>
      </div>
      <div className=" mt-3">
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-wrap gap-3 mt-3 justify-content-center justify-content-md-start">
            {isOrganizationAdmin ? renderAdminBranches() : renderBranches()}
            {branchesUserRoles.length === 0 && (
              <div className="d-flex flex-column align-items-center justify-content-center w-100 mt-3">
                <FontAwesomeIcon
                  icon={faStoreAltSlash}
                  size="5x"
                  className="text-400"
                />
                <h4 className="text-400 mt-3">No Available Branches</h4>
              </div>
            )}
          </div>
        )}
      </div>
    </Container>
  );
}
