import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionContext,
  Card,
  Col,
  ListGroup,
  Row,
  useAccordionButton
} from 'react-bootstrap';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useForm } from 'react-hook-form';
import {
  FormProvider,
  RHFControl,
  RHFDatePicker
} from 'components/common/form';
import LoadingButton from 'components/common/LoadingButton';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

export const UPDATE_CONTACT_PREFERENCES = gql`
  mutation EditDonor(
    $id: uuid!
    $consented_date: timestamp = ""
    $consented_via: String = ""
    $contact_preferences: jsonb = ""
    $remark: String = ""
  ) {
    update_donars_by_pk(
      pk_columns: { id: $id }
      _set: {
        consented_date: $consented_date
        consented_via: $consented_via
        contact_preferences: $contact_preferences
        remark: $remark
      }
    ) {
      address
      consented_date
      consented_via
      contact_preferences
      created_at
      declaration_source
      email
      first_name
      gift_aid_elgibility
      gift_aid_file
      id
      last_name
      lead_source
      organisation_id
      phone
      remark
      title
      user_id
      type
      donor_category_id
    }
  }
`;

const accessOptions = [
  { value: 'none', label: 'None' },
  { value: 'all', label: 'All' }, // default access option
  { value: 'admin_only', label: 'Admin Only' }
];
function ContextAwareToggle({
  setValue,
  children,
  eventKey,
  callback,
  donorData
}) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  const currentPreference =
    donorData?.donars_by_pk?.contact_preferences[eventKey];

  const defaultSelectedOption = currentPreference
    ? accessOptions.find(option => option.value === currentPreference)
    : accessOptions[1];

  const handleChange = selectedOption => {
    const newValue = selectedOption?.value || '';
    setValue(`contact_preferences.${eventKey}`, newValue, {
      shouldValidate: true
    });
  };

  return (
    <Flex justifyContent={'between'}>
      <IconButton
        type="button"
        variant="btn-info"
        className={`bg-transparent border-0 shadow-none px-0 text-${
          isCurrentEventKey ? 'primary' : '900'
        }`}
        onClick={decoratedOnClick}
        icon={isCurrentEventKey ? 'caret-up' : 'caret-down'}
        iconAlign="left"
        transform="shrink-3"
      >
        {children}
      </IconButton>
      <ReactSelect
        className="w-50 w-md-25"
        closeMenuOnSelect={true}
        options={accessOptions}
        placeholder="Select..."
        classNamePrefix="react-select"
        onChange={handleChange}
        defaultValue={defaultSelectedOption}
      />
    </Flex>
  );
}

const ItemList = ({ title, options, setValue, eventKey, donorData }) => {
  const currentValue = donorData?.donars_by_pk?.contact_preferences[eventKey];
  const selectedOption =
    options.find(option => option.value === currentValue) || null;

  // Handle change
  const handleChange = selectedOption => {
    const newValue = selectedOption?.value || '';
    setValue(`contact_preferences.${eventKey}`, newValue, {
      shouldValidate: true
    });
  };

  return (
    <ListGroup.Item className="border-0 px-0">
      <Flex justifyContent="between" alignItems="center">
        <p className="mb-0 ps-2">
          <FontAwesomeIcon
            icon="circle"
            style={{
              fontSize: 6,
              marginBottom: 3,
              marginRight: 8
            }}
          />
          {title}
        </p>
        <ReactSelect
          className="w-50 w-md-25"
          closeMenuOnSelect={true}
          options={options}
          placeholder="Select..."
          classNamePrefix="react-select"
          onChange={handleChange}
          defaultValue={selectedOption}
        />
      </Flex>
    </ListGroup.Item>
  );
};

const ContactPreference = ({ donorData }) => {
  const emailOptions = [
    {
      id: 1,
      title: 'New Campaign',
      access: accessOptions,
      eventKey: 'newCampaign'
    },
    {
      id: 2,
      title: 'Donation Reminders',
      access: accessOptions,
      eventKey: 'donationReminders'
    }
  ];

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange'
  });

  const { handleSubmit, setValue, reset } = methods;

  useEffect(() => {
    reset({
      consented_date: new Date(donorData?.donars_by_pk?.consented_date),
      consented_via: donorData?.donars_by_pk?.consented_via,
      remark: donorData?.donars_by_pk?.remark,
      contact_preferences: donorData?.donars_by_pk?.contact_preferences
    });
  }, [donorData, reset]);

  const { id } = useParams();

  const [editDonar, { loading: editLoading }] = useMutation(
    UPDATE_CONTACT_PREFERENCES,
    {
      onCompleted: () => {
        toast.success('Donor updated successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );

  const updateContactPreference = data => {
    editDonar({
      variables: { ...data, id }
    });
  };

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(updateContactPreference)}
    >
      <Card className="mb-3">
        <Card.Header className="pb-0">
          <Card.Title className="fw-medium">Contact Preference</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={12}>
              <Accordion defaultActiveKey="0">
                <Card className="shadow-none">
                  <Card.Header className="ps-0 pe-0 pe-md-3 py-2">
                    <ContextAwareToggle
                      eventKey="email"
                      setValue={setValue}
                      donorData={donorData}
                    >
                      Email
                    </ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="email">
                    <Card.Body className="p-0 pe-md-3">
                      <ListGroup>
                        {emailOptions?.map(option => (
                          <ItemList
                            key={option.id}
                            title={option.title}
                            options={option.access}
                            setValue={setValue}
                            eventKey={option.eventKey}
                            donorData={donorData}
                          />
                        ))}
                      </ListGroup>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <hr />
                <Card className="shadow-none">
                  <Card.Header className="ps-0 pe-0 pe-md-3 py-2">
                    <ContextAwareToggle
                      eventKey="post"
                      setValue={setValue}
                      donorData={donorData}
                    >
                      Post
                    </ContextAwareToggle>
                  </Card.Header>
                  {/* <Accordion.Collapse eventKey="1">
                  <Card.Body>Content will be here</Card.Body>
                </Accordion.Collapse> */}
                </Card>
                <hr />
                <Card className="shadow-none">
                  <Card.Header className="ps-0 pe-0 pe-md-3 py-2">
                    <ContextAwareToggle
                      eventKey="sms"
                      setValue={setValue}
                      donorData={donorData}
                    >
                      SMS
                    </ContextAwareToggle>
                  </Card.Header>
                  {/* <Accordion.Collapse eventKey="2">
                  <Card.Body>Content will be here</Card.Body>
                </Accordion.Collapse> */}
                </Card>
                <hr />
                <Card className="shadow-none">
                  <Card.Header className="ps-0 pe-0 pe-md-3 py-2">
                    <ContextAwareToggle
                      donorData={donorData}
                      eventKey="telephone"
                      setValue={setValue}
                    >
                      Telephone
                    </ContextAwareToggle>
                  </Card.Header>
                  {/* <Accordion.Collapse eventKey="3">
                  <Card.Body>Content will be here</Card.Body>
                </Accordion.Collapse> */}
                </Card>
                <hr />
              </Accordion>
            </Col>

            <Col md={6}>
              <RHFDatePicker name="consented_date" label="Consented Date" />
            </Col>

            <Col md={6}>
              <RHFControl name="consented_via" label="Consented Via" />
            </Col>

            <Col md={12} className="mt-3">
              <RHFControl name="remark" label="Remark" as="textarea" />
            </Col>
          </Row>
        </Card.Body>
        <Flex className="p-3 pt-0" justifyContent={'between'}>
          {/* <Button
          variant="light"
          className="bg-transparent border-0 fs--1 fs-md-0 shadow-none text-primary p-1"
        >
          + Add More Field(s)
        </Button> */}

          {/* <Button
          as={Link}
          to="/"
          target="_blank"
          variant="link"
          size="sm"
          className="ps-0"
        >
          <FontAwesomeIcon icon="exclamation-circle" className="me-2 fs--2" />
          <span className="text-900">Read Privacy Statement</span>
        </Button> */}

          <LoadingButton loading={editLoading} type="submit">
            Update Contact Preference
          </LoadingButton>
        </Flex>
      </Card>
    </FormProvider>
  );
};

ContextAwareToggle.propTypes = {
  children: PropTypes.node.isRequired,
  callback: PropTypes.func,
  eventKey: PropTypes.string,
  setValue: PropTypes.func,
  donorData: PropTypes.object
};

ItemList.propTypes = {
  options: PropTypes.array,
  title: PropTypes.string,
  setValue: PropTypes.func,
  eventKey: PropTypes.string,
  donorData: PropTypes.object
};

ContactPreference.propTypes = {
  donorData: PropTypes.object
};

export default ContactPreference;
