import { gql } from '@apollo/client';

export const GET_SPONSORSHIP_CONDITIONS = gql`
  query GetSponsorshipConditions($organizationId: uuid = "") {
    sponsorship_condition(
      where: { organisation_id: { _eq: $organizationId } }
    ) {
      condition
      id
    }
  }
`;

export const GET_SPONSORSHIP_PRIORITY = gql`
  query GetSponsorshipPriority {
    sponsorship_priority {
      priority_type
      id
    }
  }
`;

export const CREATE_SPONSORSHIP_PRIORITY = gql`
  mutation CreateSponsorshipPriority(
    $priority_type: String!
    $organizationId: uuid!
  ) {
    insert_sponsorship_priority_one(
      object: {
        priority_type: $priority_type
        organisation_id: $organizationId
      }
    ) {
      id
      priority_type
    }
  }
`;

export const UPDATE_SPONSORSHIP_PRIORITY = gql`
  mutation UpdateSponsorshipPriority($id: uuid!, $priority_type: String!) {
    update_sponsorship_priority_by_pk(
      pk_columns: { id: $id }
      _set: { priority_type: $priority_type }
    ) {
      id
      priority_type
    }
  }
`;

export const DELETE_SPONSORSHIP_PRIORITY = gql`
  mutation DeleteSponsorshipPriority($id: uuid!) {
    delete_sponsorship_priority_by_pk(id: $id) {
      id
      priority_type
    }
  }
`;

export const GET_SPONSORSHIP_STATUS = gql`
  query GetSponsorshipStatus($organizationId: uuid = "") {
    sponsorship_status(where: { organisation_id: { _eq: $organizationId } }) {
      status
      id
    }
  }
`;

export const CREATE_SPONSORSHIP_STATUS = gql`
  mutation CreateSponsorshipStatus($status: String!, $organizationId: uuid!) {
    insert_sponsorship_status_one(
      object: { status: $status, organisation_id: $organizationId }
    ) {
      id
      status
    }
  }
`;

export const UPDATE_SPONSORSHIP_STATUS = gql`
  mutation UpdateSponsorshipStatus($id: uuid!, $status: String!) {
    update_sponsorship_status_by_pk(
      pk_columns: { id: $id }
      _set: { status: $status }
    ) {
      id
      status
    }
  }
`;

export const DELETE_SPONSORSHIP_STATUS = gql`
  mutation DeleteSponsorshipStatus($id: uuid!) {
    delete_sponsorship_status_by_pk(id: $id) {
      id
      status
    }
  }
`;

export const GET_SPONSORSHIP_COST = gql`
  query GetSponsorshipCost($organizationId: uuid = "", $branchId: uuid = "") {
    sponsorship_cost(where: { organisation_id: { _eq: $organizationId } }) {
      amount
      id
    }
  }
`;

export const GET_SPONSORSHIP_TYPE = gql`
  query GetSponsorshipType($organizationId: uuid = "", $branchId: uuid = "") {
    sponsorship_type(where: { organisation_id: { _eq: $organizationId } }) {
      type
      id
    }
  }
`;
