import { RHFSelect } from 'components/common/form';
import { Card, Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { GET_SPONSORSHIP_TYPE } from './beneficiary-options-queries';
import { useQuery } from '@apollo/client';
import { useContext, useMemo } from 'react';
import AuthContext from 'context/authContext';

export const SponsorshipTypeCard = ({ sectionRef, sideNavLink }) => {
  const { organization } = useContext(AuthContext);

  const { data } = useQuery(GET_SPONSORSHIP_TYPE, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });

  const typesOptions = useMemo(
    () =>
      data?.sponsorship_type?.map(({ type, id }) => {
        return {
          label: type,
          value: id
        };
      }),
    [data]
  );

  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Sponsorship Type</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <Container className="p-0 d-flex flex-column g-3">
          <Row className="gx-3">
            <Col xs={12} sm={6}>
              <RHFSelect
                name="type"
                label="Type"
                options={typesOptions}
                required
              />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

SponsorshipTypeCard.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
