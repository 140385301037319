import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { FormProvider, RHFControl } from 'components/common/form';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';

const schema = Yup.object().shape({
  email: Yup.string().email().required('Required Field')
});

const ForgetPasswordForm = ({ onSubmit, loading }) => {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      email: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, watch } = methods;
  const { email } = watch();

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
    >
      <Flex gap={3} direction="column" className="mt-3">
        <RHFControl name="email" type="email" placeholder="Email address" />
        <LoadingButton
          loading={loading}
          className="w-100"
          type="submit"
          disabled={!email}
        >
          Send reset link
        </LoadingButton>
      </Flex>
    </FormProvider>
  );
};

ForgetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default ForgetPasswordForm;
