import { Card, Col, Container, Row } from 'react-bootstrap';
import { RHFControl, RHFSelect } from 'components/common/form';
import PropTypes from 'prop-types';
import useCurrenciesOptions from 'hooks/useCurrenciesOptions';

export const PackagesDetails = ({ sectionRef, sideNavLink }) => {
  const { currenciesOptions, loading } = useCurrenciesOptions();
  return (
    <>
      <Card
        className="border shadow-none mb-3"
        ref={sectionRef}
        id={sideNavLink}
      >
        <Card.Header>
          <Card.Title>Packages Details</Card.Title>
        </Card.Header>
        <Card.Body>
          <Container className="px-0 d-flex flex-column gap-3">
            <RHFControl
              name="title"
              placeholder="Title"
              label="Title"
              required
            />
            <Row xs={1} sm={1} md={2}>
              <Col>
                <RHFSelect
                  name="currency"
                  label="Donation Currency"
                  options={currenciesOptions}
                  loading={loading}
                  required
                />
              </Col>
              <Col>
                <RHFControl
                  name="targetAmount"
                  placeholder="Targeted Donations Amount"
                  label="Targeted Donations Amount"
                  required
                />
              </Col>
            </Row>

            <RHFControl
              name="description"
              placeholder="Description"
              label="Description"
              type="text"
              as="textarea"
              rows={5}
            />
            {/* <RHFCheck name="selectAll" label="Select All" type="checkbox" /> */}
          </Container>
        </Card.Body>
      </Card>
    </>
  );
};

PackagesDetails.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
