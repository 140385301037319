import { useState } from 'react';
import { Card, Col, Form, Image, Row, Spinner } from 'react-bootstrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { master, vise } from 'data/weraise-data/image-data';
import LoadingButton from 'components/common/LoadingButton';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { ATTACH_PAYMENT_METHOD } from '../DonationPayment';

const PNPaymentOption = ({
  loading,
  data,
  onSubmitMakePayment,
  paymentMethods,
  loadingPaymentMethods,
  onSubmitMakeDefaultMethod,
  makePaymentMethodDefaultLoading
}) => {
  const elements = useElements();
  const stripe = useStripe();

  // const [paymentMethod, setPaymentMethod] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [addNewCard, setAddNewCard] = useState(false);
  const [val, setVal] = useState('');
  const [date, setDate] = useState('');

  const onChange = e => {
    setVal(e.target.value);
  };

  // const paymentMethodOptions = [
  //   { value: '1', label: 'Credit Card' },
  //   { value: '2', label: 'Visa Card' },
  //   { value: '3', label: 'Master Card' },
  //   { value: '4', label: 'MX Card' },
  //   { value: '5', label: 'Nexus Pay' }
  // ];

  const [attachPaymentMethod, { loading: attachLoading }] = useMutation(
    ATTACH_PAYMENT_METHOD,
    {
      onCompleted: () => {
        onSubmitMakePayment();
      },
      onError: err => {
        console.log({ err });
      }
    }
  );

  function cc_format(value) {
    const v = value
      .replace(/\s+/g, '')
      .replace(/[^0-9]/gi, '')
      .substr(0, 16);
    const parts = [];

    for (let i = 0; i < v.length; i += 4) {
      parts.push(v.substr(i, 4));
    }

    return parts.length > 1 ? parts.join(' ') : value;
  }

  function formatExpirationDate(e) {
    // Remove any non-digit characters
    const sanitizedDate = e.target.value?.replace(/\D/g, '');

    // Extract the month and year
    const month = sanitizedDate.slice(0, 2);
    const year = sanitizedDate.slice(2, 4);

    // Return the formatted date
    setDate(`${month}/${year}`);
  }
  const [loadingAddCArd, setLoadingAddCArd] = useState(false);
  const onSubmitAddCard = async () => {
    setLoadingAddCArd(true);
    if (!stripe || !elements) {
      console.error('Stripe.js has not loaded yet.');
      return;
    }
    const cardNumber = elements.getElement(CardNumberElement);
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumber
    });
    if (result.error) {
      toast.error(result.error);
      setLoadingAddCArd(false);
    } else {
      setLoadingAddCArd(false);
      attachPaymentMethod({
        variables: {
          donor_id: data.donorId,
          paymentMethodId: result.paymentMethod.id
        }
      });
    }
  };

  return (
    <Card>
      <Card.Header className="pb-0">
        <Card.Title className="fw-medium">Payment</Card.Title>
      </Card.Header>
      <Card.Body>
        {/* <Form.Group className="mb-3">
          <Form.Label>Select Payment Method</Form.Label>
          <ReactSelect
            closeMenuOnSelect={true}
            options={paymentMethodOptions}
            placeholder="Select Payment Method"
            classNamePrefix="react-select"
            value={paymentMethod}
            onChange={value => setPaymentMethod(value)}
          />
          <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
        </Form.Group> */}
        {loadingPaymentMethods ? (
          <Row>
            <Spinner />
          </Row>
        ) : (
          !addNewCard && (
            <Row
              className="g-3 mb-3"
              style={{
                opacity: makePaymentMethodDefaultLoading ? '25%' : '100%'
              }}
            >
              {paymentMethods?.map(card => (
                <Col key={card.id} md={6}>
                  <Flex
                    justifyContent="center"
                    alignItems="start"
                    direction="column"
                    className={classNames(
                      'border rounded-2 p-3 cursor-pointer',
                      {
                        'border-primary': card.id === selectedCard
                      }
                    )}
                    style={{ height: '100px' }}
                    onClick={() => {
                      if (card.id !== selectedCard)
                        if (!makePaymentMethodDefaultLoading) {
                          setSelectedCard(card.id);
                          onSubmitMakeDefaultMethod({
                            paymentMethodId: card.id
                          });
                        }
                    }}
                  >
                    <Image
                      src={card.card.brand === 'vise' ? vise : master}
                      className="mb-2"
                    />
                    <p className="mb-0 fs-0 fw-semi-bold">
                      **** **** **** {card.card.last4}
                    </p>
                  </Flex>
                </Col>
              ))}
            </Row>
          )
        )}

        <IconButton
          icon="plus"
          variant="link"
          className="p-0"
          onClick={() => {
            setAddNewCard(!addNewCard);
          }}
        >
          Add new card
        </IconButton>
        {addNewCard ? (
          <Card className="shadow-none border mt-3">
            <Card.Body className="pb-1">
              <Row className="g-3">
                <Form.Group as={Col} md={12} controlId="CARD_NUMBER">
                  <Form.Label>CARD NUMBER</Form.Label>

                  <CardNumberElement
                    className="form-control test"
                    placeholder="0000 0000 0000 0000"
                    onChange={onChange}
                    value={cc_format(val)}
                  />

                  <Form.Control.Feedback type="invalid">
                    CARD NUMBER required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="EXP_DATE">
                  <Form.Label>EXP DATE</Form.Label>

                  <CardExpiryElement
                    className="form-control"
                    type="text"
                    placeholder="MM/YY"
                    value={date}
                    onChange={formatExpirationDate}
                  />

                  <Form.Control.Feedback type="invalid">
                    EXP DATE required
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md={6} controlId="CVV">
                  <Form.Label>CVV</Form.Label>
                  <CardCvcElement
                    className="form-control"
                    type="text"
                    placeholder="123"
                  />

                  <Form.Control.Feedback type="invalid">
                    CVV required
                  </Form.Control.Feedback>
                </Form.Group>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id="Save_This_Card"
                    label="Save This Card"
                    defaultChecked
                  />
                </Col>
              </Row>
            </Card.Body>
            <LoadingButton
              loading={loading || loadingAddCArd || attachLoading}
              onClick={onSubmitAddCard}
              className="w-100 py-3 mt-3"
            >
              Add card and make payment
            </LoadingButton>
          </Card>
        ) : (
          <LoadingButton
            loading={loading}
            onClick={() => {
              if (selectedCard === null) {
                toast.error('Please select or add a card');
              } else onSubmitMakePayment();
            }}
            className="w-100 py-3 mt-3"
          >
            PAY NOW
          </LoadingButton>
        )}
      </Card.Body>
    </Card>
  );
};

PNPaymentOption.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  addNewCard: PropTypes.bool,
  stripe: PropTypes.any,
  elements: PropTypes.any,
  data: PropTypes.object,
  onSubmitMakePayment: PropTypes.func,
  paymentMethods: PropTypes.array,
  loadingPaymentMethods: PropTypes.bool,
  onSubmitMakeDefaultMethod: PropTypes.bool,
  makePaymentMethodDefaultLoading: PropTypes.bool
};

export default PNPaymentOption;
