import { useQuery } from '@apollo/client';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import MainProjectDropdown from 'components/navbar/top/MainProjectDropdown';
import NavbarDropdown from 'components/navbar/top/NavbarDropdown';
import { useAuthContext } from 'context/authContext';
import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import { kanbanProjectRoutes } from 'routes/routes';
import { GET_BRANCH_USERS } from './AddProjectMember';
import InviteToBoard from './InviteToBoard';
import Members from './Members';

const KanbanHeader = () => {
  const navigate = useNavigate();
  const { selectedBranchId } = useAuthContext();
  const { data: branchUsersData } = useQuery(GET_BRANCH_USERS, {
    variables: {
      branchId: selectedBranchId
    }
  });
  const branchUsers = useMemo(() => {
    if (!branchUsersData) return [];
    return branchUsersData.users_roles.map(({ user }) => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      img: user.profile_pic
    }));
  }, [branchUsersData]);
  return (
    <Row className="gx-0 kanban-header rounded-2 px-x1 py-2 mt-2 mb-3">
      <Col className="d-flex align-items-center">
        <NavbarDropdown title="Main Projects">
          <MainProjectDropdown items={kanbanProjectRoutes.children} />
        </NavbarDropdown>

        <IconButton
          variant="default"
          size="sm"
          className="ms-3 shadow-none p-0 fs-0"
          icon={['far', 'star']}
        />
        <div className="vertical-line vertical-line-400 position-relative h-100 mx-3"></div>
        <Members
          avatarSize="xl"
          users={branchUsers}
          showMember={4}
          className="d-none d-md-block"
        />
        <div className="vertical-line vertical-line-400 position-relative h-100 mx-3 d-none d-md-flex"></div>
        <InviteToBoard />
      </Col>
      <Col xs="auto" as={Flex} alignItems="center">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="plus"
          iconClassName="me-2"
          className="me-2 d-none d-md-block"
          onClick={() => navigate(paths.createProject)}
        >
          Create Project
        </IconButton>
        {/* <Dropdown align="end" className="font-sans-serif">
          <Dropdown.Toggle
            size="sm"
            variant="default"
            className="dropdown-caret-none shadow-none"
          >
            <FontAwesomeIcon icon="ellipsis-h" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="border py-2">
            <Dropdown.Item
              className="d-block d-md-none"
              as={Link}
              to={paths.createProject}
            >
              Create Project
            </Dropdown.Item>
            <Dropdown.Divider className="d-block d-md-none" />
            <Dropdown.Item href="#!">Copy link</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#!">Settings</Dropdown.Item>
            <Dropdown.Item href="#!">Themes</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item href="#!" className="text-danger">
              Remove
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}
      </Col>
    </Row>
  );
};

export default KanbanHeader;
