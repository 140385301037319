import { toast } from 'react-toastify';
import PasswordResetForm from './forms/PasswordResetForm';
import { gql, useMutation } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import paths from 'routes/paths';

const RESET_PASSWORD = gql`
  mutation ResetPassword(
    $email: String!
    $password: String!
    $password_confirmation: String!
    $token: String!
  ) {
    ResetPassword(
      email: $email
      password: $password
      password_confirmation: $password_confirmation
      token: $token
    ) {
      message
    }
  }
`;

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: data => {
      toast.success(data.ResetPassword.message);
      navigate(paths.login);
    },
    onError: error => {
      if (error?.graphQLErrors?.[0]?.message === 'validation.exists') {
        toast.error('Email does not exist');
      } else {
        toast.error('Something went wrong! Please try again');
      }
      console.log(error);
    }
  });
  const email = searchParams.get('email');
  const token = searchParams.get('token');
  if (!email || !token) {
    return (
      <div className="text-center">
        <h5>Invalid reset link</h5>
      </div>
    );
  }
  const onSubmit = ({ password, confirmPassword }) => {
    resetPassword({
      variables: {
        email,
        password,
        password_confirmation: confirmPassword,
        token
      }
    });
  };
  return (
    <div className="text-center">
      <h5>Reset new password</h5>
      <PasswordResetForm onSubmit={onSubmit} loading={loading} />
    </div>
  );
};

export default PasswordReset;
