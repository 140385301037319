import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import ReactPhoneInput from 'react-phone-input-2';

export const RHFPhoneInput = ({ name, label, required, ...rest }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Form.Group>
          {!!label && (
            <Form.Label>
              {label} {required && <span className="text-danger">*</span>}
              {!required && <span className="text-500">(optional)</span>}
            </Form.Label>
          )}
          <ReactPhoneInput
            country="gb"
            value={value}
            inputClass="w-100 py-2"
            onChange={phone => onChange(phone)}
            {...rest}
          />
          {error && (
            <Form.Control.Feedback className="d-block" type="invalid">
              {error.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      )}
    />
  );
};

RHFPhoneInput.propTypes = {
  name: propTypes.string,
  label: propTypes.string,
  required: propTypes.bool
};
