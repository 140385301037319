import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useImperativeHandle, forwardRef } from 'react';

import { FormProvider, RHFControl, RHFSelect } from 'components/common/form';
import LoadingButton from 'components/common/LoadingButton';

const CrudCardForm = forwardRef(
  ({ inputs, controlId, title, isEdit, onSubmit, loading, onCancel }, ref) => {
    const methods = useForm({
      resolver: yupResolver(
        yup.object().shape(
          inputs.reduce((acc, input) => {
            acc[input.name] = input.validation;
            return acc;
          }, {})
        )
      ),

      defaultValues: inputs.reduce((acc, input) => {
        acc[input.name] = input.value;
        return acc;
      }, {})
    });
    const { handleSubmit, reset } = methods;

    useImperativeHandle(ref, () => ({
      resetForm() {
        reset();
      }
    }));

    const submitButtonContainer = (
      <>
        <LoadingButton
          // id={card.id}
          variant="light"
          className="border bg-transparent shadow-none fw-semi-bold"
          loading={loading}
          type="submit"
          // onClick={
          //   !isEdit
          //     ? () => {
          //         if (textInput !== '') {
          //           addData(card?.id, textInput);
          //           setTextInput('');
          //         }
          //       }
          //     : () => {
          //         if (textInput !== '') {
          //           editData(card?.id, textInput, editObject);
          //           setTextInput('');
          //           setIsEdit(false);
          //           setEditObject(null);
          //         }
          //       }
          // }
        >
          {isEdit ? 'Update' : 'Add'}{' '}
          {!isEdit && <FontAwesomeIcon icon="plus" />}
        </LoadingButton>
        {isEdit && (
          <Button
            type="button"
            variant="light"
            className="bg-transparent shadow-none text-danger"
            onClick={onCancel}
          >
            <FontAwesomeIcon icon="times" />
          </Button>
        )}
      </>
    );
    return (
      <FormProvider
        controlId={controlId}
        onSubmit={handleSubmit(onSubmit)}
        methods={methods}
      >
        {!isEdit && title && <Form.Label>{title}</Form.Label>}
        <Row className="p-0 gap-0 g-0">
          <Col xs>
            <Row>
              {inputs.map((input, index) => {
                switch (input.type) {
                  case 'text':
                    return (
                      <Col key={index} xs>
                        <RHFControl
                          name={input.name}
                          placeholder={input.placeholder}
                          aria-label={input.placeholder}
                          label={input.label}
                          required={input.required}
                        />
                      </Col>
                    );
                  case 'select':
                    return (
                      <Col key={index} xs>
                        <RHFSelect
                          name={input.name}
                          placeholder={input.placeholder}
                          aria-label={input.placeholder}
                          options={input.options}
                          label={input.label}
                          required={input.required}
                        />
                      </Col>
                    );
                  default:
                    return null;
                }
              })}
            </Row>
          </Col>
          <Col
            xs
            className="flex-grow-0 d-flex align-items-end"
            style={{ minWidth: isEdit ? 150 : 100 }}
          >
            {submitButtonContainer}
          </Col>
        </Row>
      </FormProvider>
    );
  }
);

CrudCardForm.propTypes = {
  inputs: PropTypes.arrayOf({
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    options: PropTypes.arrayOf({
      value: PropTypes.string,
      label: PropTypes.string
    }),
    validation: PropTypes.object,
    value: PropTypes.string
  }),
  controlId: PropTypes.string,
  title: PropTypes.string,
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  onCancel: PropTypes.func
};

export default CrudCardForm;
