import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import createMarkup from 'helpers/createMarkup';
import classNames from 'classnames';
import { Card, Col, Row } from 'react-bootstrap';
import Notification from 'components/we-raise-common/Notification';

const CounterItem = ({ stat, lastIndex, index }) => {
  return (
    <Flex
      className={classNames('mb-3', {
        'border-lg-end me-xxl-5': lastIndex !== index
      })}
    >
      <FontAwesomeIcon
        icon="circle"
        style={{ marginTop: '2px' }}
        className={classNames('me-2 fs--2', {
          'text-success': stat.type === 'up',
          'text-danger': stat.type === 'down',
          'text-primary': stat.type === 'standard',
          'text-warning': stat.type === 'warning',
          'text-info': stat.type === 'pending'
        })}
      />
      <div className="flex-grow-1">
        <h6
          className="pb-1 mb-0 text-700 me-3 mb-md-3 mb-2"
          dangerouslySetInnerHTML={createMarkup(stat.title)}
        />

        <p
          className="font-sans-serif lh-1 mb-2 fs-2"
          dangerouslySetInnerHTML={createMarkup(stat.amount)}
        />
        {/* {stat?.showContent !== false && (
          <>
            {stat?.content ? (
              <ProgressBar
                variant="info"
                style={{ height: '7px', width: '95%' }}
                now={40}
                className="mb-3"
              />
            ) : (
              <div className="d-flex align-items-center">
                <h6 className="fs--1 text-500 mb-0">{stat.subAmount}</h6>
                <h6
                  className={classNames('fs--2 ps-3 mb-0', {
                    'text-success': stat.type === 'up',
                    'text-danger': stat.type === 'down',
                    'text-primary': stat.type === 'standard',
                    'text-warning': stat.type === 'warning',
                    'text-info': stat.type === 'pending'
                  })}
                >
                  <FontAwesomeIcon icon="caret-up" className="me-1" />
                  {stat.percent}%
                </h6>
              </div>
            )}
          </>
        )} */}
      </div>
    </Flex>
  );
};

const DonorCounter = ({ counterData, notifications }) => {
  return (
    <Card className="mb-3 g-3 overflow-hidden">
      <Card.Body className="p-0">
        <Row className="pt-4 px-3 pb-0 w-md-100">
          {counterData?.map((stat, index) => (
            <Col className="col-6" lg key={stat.title}>
              <CounterItem
                stat={stat}
                index={index}
                lastIndex={counterData?.length - 1}
              />
            </Col>
          ))}
        </Row>

        {notifications && (
          <ul className="mb-0 list-unstyled">
            {notifications?.map((notification, index) => (
              <Notification
                key={notification.id}
                notification={notification}
                isLast={notifications.length - 1 === index}
              />
            ))}
          </ul>
        )}
      </Card.Body>
    </Card>
  );
};

DonorCounter.propTypes = {
  counterData: PropTypes.array,
  notifications: PropTypes.array
};
CounterItem.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    subAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    content: PropTypes.string,
    showContent: PropTypes.bool
  }),
  index: PropTypes.number,
  lastIndex: PropTypes.number,
  totalBeneficiaries: PropTypes.number
};

export default DonorCounter;
