import { gql, useMutation } from '@apollo/client';
import SponsorshipForm from './sponsorship-form/SponsorshipForm';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GET_BENEFICIARY } from 'context/beneficiaryContext';

const CREATE_SPONSORSHIP = gql`
  mutation CreateSponsorship(
    $duration: Int
    $donar_id: uuid
    $orphan_id: uuid
    $start_date: timestamptz
    $theme: String
    $stipulation: String
  ) {
    insert_sponsorship(
      objects: {
        duration: $duration
        donar_id: $donar_id
        orphan_id: $orphan_id
        start_date: $start_date
        theme: $theme
        stipulation: $stipulation
      }
    ) {
      affected_rows
      returning {
        donar {
          id
          address
          consented_date
          first_name
          email
          gift_aid_elgibility
          gift_aid_file
          id
          last_name
          lead_source
          user {
            id
            first_name
            last_name
          }
        }
      }
    }
  }
`;

export default function CreateSponsorship() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');

  const [createSponsorship, { loading }] = useMutation(CREATE_SPONSORSHIP, {
    onCompleted: () => {
      toast.success('Sponsorship created successfully');
    },
    onError: error => {
      console.log({ error });
      toast.error('Something went wrong');
    },
    update: (cache, { data }) => {
      const newSponsorship = data?.insert_sponsorship?.returning[0];
      const existingSponsorships = cache.readQuery({
        query: GET_BENEFICIARY,
        variables: { id }
      });
      const sponsorships = [
        newSponsorship,
        ...existingSponsorships.orphans_by_pk.sponsorships
      ];
      const newData = { ...existingSponsorships.orphans_by_pk, sponsorships };
      cache.writeQuery({
        query: GET_BENEFICIARY,
        variables: { id },
        data: {
          orphans_by_pk: newData
        }
      });
    }
  });
  const onSubmit = data => {
    const { duration, theme, stipulation, beneficiaryId, donorId, beginOn } =
      data;
    const start_date = new Date(beginOn).toISOString();

    createSponsorship({
      variables: {
        duration,
        donar_id: donorId,
        orphan_id: beneficiaryId,
        start_date,
        theme,
        stipulation
      }
    });
    navigate(-1);
  };
  return <SponsorshipForm onSubmit={onSubmit} loading={loading} />;
}
