import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import CustomAdvanceTableGiftAid from 'components/common/advance-table/CustomAdvanceTableGiftAid';
import Dropdown from 'react-bootstrap/Dropdown';
// import { weTableClaimedGiftAid } from 'data/weraise-data/sponsored-list-data';
import { gql, useMutation, useQuery } from '@apollo/client';
import LoadingContainer from 'components/common/LoadingContainer';
import { useAuthContext } from 'context/authContext';
import { format } from 'date-fns';
import { formatMoney, parseRouteParams } from 'helpers/utils';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import { useState, useMemo } from 'react';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { toast } from 'react-toastify';
import { Can } from 'routes/permissions/Can';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';

export const GIFT_AID_APPLICATION_AWAITING = 'awaiting';
export const GIFT_AID_APPLICATION_SUBMITTED = 'submitted';
export const GIFT_AID_APPLICATION_COMPLETED = 'completed';

export const giftAidApplicationStatus = {
  [GIFT_AID_APPLICATION_AWAITING]: {
    variant: 'warning',
    label: 'Awaiting'
  },
  [GIFT_AID_APPLICATION_SUBMITTED]: {
    variant: 'primary',
    label: 'Submitted'
  },
  [GIFT_AID_APPLICATION_COMPLETED]: {
    variant: 'success',
    label: 'Completed'
  }
};

const applicationToGiftAidStatusMap = {
  [GIFT_AID_APPLICATION_AWAITING]: 'inprogress',
  [GIFT_AID_APPLICATION_SUBMITTED]: 'inprogress',
  [GIFT_AID_APPLICATION_COMPLETED]: 'accepted'
};

/* -------------------------------------------------------------------------- */
export const GET_GIFT_AID_APPLICATION_QUERY = gql`
  query GetGiftAidApplication($organisation_id: uuid = "") {
    gift_aid_application(
      where: { organisation_id: { _eq: $organisation_id } }
      order_by: { created_at: desc }
    ) {
      status
      submitted_date
      created_at
      updated_at
      id
      gift_aids {
        id
      }
      gift_aids_aggregate {
        aggregate {
          sum {
            amount
          }
          count
        }
        nodes {
          order {
            donation_amount
          }
        }
      }
    }
    completedApplications: gift_aid_application_aggregate(
      where: {
        organisation_id: { _eq: $organisation_id }
        status: { _eq: ${GIFT_AID_APPLICATION_COMPLETED} }
      }
    ) {
      aggregate {
        count
      }
    }
    submittedApplications: gift_aid_application_aggregate(
      where: {
        organisation_id: { _eq: $organisation_id }
        status: { _eq: ${GIFT_AID_APPLICATION_SUBMITTED} }
      }
    ) {
      aggregate {
        count
      }
    }
    awaitingApplications: gift_aid_application_aggregate( 
      where: {
        organisation_id: { _eq: $organisation_id }
        status: { _eq: ${GIFT_AID_APPLICATION_AWAITING} }
      }
    ) {
      aggregate {
        count
      }
    }
    unclaimedTransactions: gift_aid_aggregate(
      where: {
        order: {organisation_id: {_eq: $organisation_id}}
        status: {_eq: ${'unclaimed'}}
      }
    ) {
      aggregate {
        count
      }
    }
    totalDonations: gift_aid_aggregate(
      where: {
        order: {organisation_id: {_eq: $organisation_id}}
        status: {_eq: ${'accepted'}}
      }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }

  }
`;
const UPDATE_APPLICATION_STATUS_MUTATION = gql`
  mutation UpdateGiftAidStatus(
    $id: uuid = ""
    $application_status: String = ""
    $submitted_date: date = ""
    $_in: [uuid!] = ""
    $gift_aid_status: String = ""
  ) {
    update_gift_aid_application_by_pk(
      pk_columns: { id: $id }
      _set: { status: $application_status, submitted_date: $submitted_date }
    ) {
      status
      submitted_date
      created_at
      updated_at
      id
      gift_aids {
        id
        status
      }
      gift_aids_aggregate {
        aggregate {
          sum {
            amount
          }
          count
        }
        nodes {
          order {
            donation_amount
          }
        }
      }
    }
    update_gift_aid_many(
      updates: {
        where: { id: { _in: $_in } }
        _set: { status: $gift_aid_status }
      }
    ) {
      returning {
        sponsored_event
        aggregated_donation
        status
        amount
        created_at
        updated_at
        donar_id
        gift_aid_application_id
        id
        order_id
        order {
          donation_amount
        }
        donar {
          title
          first_name
          last_name
          address
        }
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

const GiftAidApplications = () => {
  const navigate = useNavigate();
  const { organization } = useAuthContext();
  const [statusChangeState, setStatusChangeState] = useState({
    id: null,
    status: null
  });
  const [showChangeStatusConfirmation, setShowChangeStatusConfirmation] =
    useState(false);
  const { data, loading, error } = useQuery(GET_GIFT_AID_APPLICATION_QUERY, {
    variables: {
      organisation_id: organization.id
    }
  });
  const [updateGiftAidStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_APPLICATION_STATUS_MUTATION,
    {
      onCompleted: () => {
        hideChangeStatusConfirmation();
        toast.success('Gift Aid status updated successfully');
      },
      onError: error => {
        toast.error(error.message);
      }
    }
  );
  // const [isEdit, setIsEdit] = useState(false);
  // const [editId, setEditId] = useState(null);

  const columns = [
    {
      accessor: 'ref',
      Header: 'Claim Ref',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'date_generated',
      Header: 'Date Generated',
      cellProps: {
        className: 'py-2 fw-medium'
      }
    },
    {
      accessor: 'date_submitted',
      Header: 'Date Submitted',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'total_applicants',
      Header: 'Total Applicants',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'total_donations',
      Header: 'Total Donations',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        return (
          <span>{formatMoney(rowData.row.original.total_donations, '£')}</span>
        );
      }
    },
    {
      accessor: 'gift_aid',
      Header: 'Gift Aid',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        return <span>{formatMoney(rowData.row.original.gift_aid, '£')}</span>;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge pill bg={giftAidApplicationStatus[status].variant}>
            {giftAidApplicationStatus[status].label}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'oid',
      Header: '',
      cellProps: {
        className: 'text-end fw-medium py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row;
        const { status } = rowData.row.original;

        return (
          <CardDropdown>
            <Can
              I={userActions.update}
              a={permissionsKeyMap.giftAid.children.giftAid}
            >
              <div className="py-2">
                <Dropdown.Item
                // onClick={() => {
                //   setEditId(id);
                //   setIsEdit(true);
                // }}
                >
                  Edit
                </Dropdown.Item>

                {status !== GIFT_AID_APPLICATION_SUBMITTED && (
                  <Dropdown.Item
                    className={`text-${giftAidApplicationStatus[GIFT_AID_APPLICATION_SUBMITTED].variant}`}
                    onClick={() =>
                      handleChangeStatus(id, GIFT_AID_APPLICATION_SUBMITTED)
                    }
                  >
                    {
                      giftAidApplicationStatus[GIFT_AID_APPLICATION_SUBMITTED]
                        .label
                    }
                  </Dropdown.Item>
                )}
                {status !== GIFT_AID_APPLICATION_AWAITING && (
                  <Dropdown.Item
                    className={`text-${giftAidApplicationStatus[GIFT_AID_APPLICATION_AWAITING].variant}`}
                    onClick={() =>
                      handleChangeStatus(id, GIFT_AID_APPLICATION_AWAITING)
                    }
                  >
                    Awaiting
                  </Dropdown.Item>
                )}
                {status !== GIFT_AID_APPLICATION_COMPLETED && (
                  <Dropdown.Item
                    className={`text-${giftAidApplicationStatus[GIFT_AID_APPLICATION_COMPLETED].variant}`}
                    onClick={() =>
                      handleChangeStatus(id, GIFT_AID_APPLICATION_COMPLETED)
                    }
                  >
                    Completed
                  </Dropdown.Item>
                )}
              </div>
            </Can>
          </CardDropdown>
        );
      }
    }
  ];

  const handleChangeStatus = (id, status) => {
    setShowChangeStatusConfirmation(true);
    setStatusChangeState({
      id,
      status
    });
  };

  const hideChangeStatusConfirmation = () => {
    setShowChangeStatusConfirmation(false);
    setStatusChangeState({
      id: null,
      status: null
    });
  };

  const handleChangeStatusConfirm = () => {
    const selectedApplicationData = data.gift_aid_application.find(
      ({ id }) => id === statusChangeState.id
    );
    const giftAidIds = selectedApplicationData.gift_aids.map(({ id }) => id);
    const submittedDate =
      statusChangeState.status === GIFT_AID_APPLICATION_SUBMITTED
        ? new Date()
        : selectedApplicationData.submitted_date;
    updateGiftAidStatus({
      variables: {
        id: statusChangeState.id,
        application_status: statusChangeState.status,
        submitted_date: submittedDate,
        _in: giftAidIds,
        gift_aid_status: applicationToGiftAidStatusMap[statusChangeState.status]
      }
    });
  };

  const listCounter = useMemo(() => {
    if (!data) return [];
    const {
      completedApplications,
      submittedApplications,
      awaitingApplications,
      unclaimedTransactions,
      totalDonations
    } = data;
    return [
      {
        id: 1,
        title: 'Completed',
        value: completedApplications.aggregate.count,
        color: '#00D27B'
      },
      {
        id: 2,
        title: 'Submitted',
        value: submittedApplications.aggregate.count,
        color: '#2B7EED'
      },
      {
        id: 2,
        title: 'Awaiting',
        value: awaitingApplications.aggregate.count,
        color: '#C46632'
      },
      {
        id: 3,
        title: 'Unclaimed',
        value: unclaimedTransactions.aggregate.count,
        color: '#1E1E1E'
      },
      {
        id: 4,
        title: 'Total Donations',
        value: formatMoney(totalDonations.aggregate.sum.amount, '£'),
        color: '#5D6E82'
      }
    ];
  }, [data]);

  const tableData = useMemo(() => {
    if (!data) return [];
    return data.gift_aid_application.map(
      ({ id, status, created_at, submitted_date, gift_aids_aggregate }) => {
        const formattedGeneratedDate = format(
          new Date(created_at),
          'dd/MM/yyyy'
        );
        const formattedSubmittedDate = submitted_date
          ? format(new Date(submitted_date), 'dd/MM/yyyy')
          : null;
        return {
          id,
          ref: id,
          date_generated: formattedGeneratedDate,
          date_submitted: formattedSubmittedDate,
          total_applicants: gift_aids_aggregate.aggregate.count,
          total_donations: gift_aids_aggregate.nodes.reduce(
            (acc, { order }) => acc + order.donation_amount,
            0
          ),
          gift_aid: gift_aids_aggregate.aggregate.sum.amount,
          status
        };
      }
    );
  }, [data]);
  if (loading) return <LoadingContainer />;
  if (error) return <p>Error! {error.message}</p>;
  return (
    <>
      <ConfirmationModal
        show={showChangeStatusConfirmation}
        onHide={hideChangeStatusConfirmation}
        title="Change Status"
        loading={updateStatusLoading}
        handleConfirm={handleChangeStatusConfirm}
      >
        <p>
          Are you sure you want to change the status of this application to{' '}
          <b>{giftAidApplicationStatus[statusChangeState.status]?.label}</b>?
        </p>
      </ConfirmationModal>

      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="mt-1">
              <Row className="g-3">
                {listCounter?.map((item, index) => (
                  <Col className="col-6" xl={2} key={item.id}>
                    <Flex
                      className={classNames({
                        'border-xl-end': index !== listCounter.length - 1
                      })}
                    >
                      <FontAwesomeIcon
                        icon={'circle'}
                        className={'fs--2 mt-1'}
                        color={item.color}
                      />
                      <div className="ms-2">
                        <p className="mb-2 fw-medium">{item.title}</p>
                        <p className="mb-0 fs-2 fw-medium">{item.value}</p>
                      </div>
                    </Flex>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          <CustomAdvanceTableGiftAid
            data={tableData}
            columns={columns}
            perPage={10}
            showTablePagination={false}
            title="Claimed Gift Aid Applications"
            showSearchInput
            searchInputPlaceHolder="Search"
            addButtonIcon="plus"
            addButtonLabel="Generate A New Claim"
            hideBulkActions={true}
            selection={false}
            showFilterButton={false}
            showExportButton={false}
            // isEditable={isEdit}
            // editId={editId}
            // handleSave={handleSave}
            // handleRowChange={handleRowChange}
            onClickAddNew={() => navigate(paths.giftAidApplicationCreate)}
            rowClickEvent={(e, rowData) => {
              if (
                e.target.classList.contains('btn-reveal', 'dropdown-toggle') ||
                e.target.parentElement.classList.contains(
                  'btn-reveal',
                  'dropdown-toggle'
                ) ||
                e.target.classList.contains('dropdown-menu') ||
                e.target.classList.contains('dropdown-item') ||
                e.target.tagName === 'INPUT' ||
                e.target.tagName === 'BUTTON'
              ) {
                e.stopPropagation();
                return;
              }
              navigate(
                parseRouteParams(paths.giftAidApplicationDetails, {
                  id: rowData.id
                })
              );
            }}
            subject={permissionsKeyMap.giftAid.children.giftAid}
          />
        </Col>
      </Row>
    </>
  );
};

export default GiftAidApplications;
