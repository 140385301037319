import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  InputGroup,
  ListGroup,
  Dropdown
} from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import {
  GET_DONOR_CATEGORY,
  DELETE_DONOR_CATEGORY,
  INSERT_DONOR_CATEGORY,
  UPDATE_DONOR_CATEGORY
} from '../queries';
import CardDropdown from 'components/common/CardDropdown';
import PropTypes from 'prop-types';

function DonorGroupModal({ show, handleClose }) {
  const { data, refetch } = useQuery(GET_DONOR_CATEGORY);
  const [deleteDonorCategory] = useMutation(DELETE_DONOR_CATEGORY);
  const [insertDonorCategory] = useMutation(INSERT_DONOR_CATEGORY);
  const [updateDonorCategory] = useMutation(UPDATE_DONOR_CATEGORY);

  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState({
    title: '',
    isDefault: false
  });
  const [editIndex, setEditIndex] = useState(null);
  const [editCategory, setEditCategory] = useState({
    title: '',
    isDefault: false
  });

  useEffect(() => {
    if (data && data.donor_category) {
      setCategories(data.donor_category);
    }
  }, [data]);

  const handleAddCategory = async () => {
    if (newCategory.title.trim()) {
      await insertDonorCategory({
        variables: {
          title: newCategory.title,
          isDefault: newCategory.isDefault
        }
      });
      refetch();
      setNewCategory({ title: '', isDefault: false });
    }
  };

  const handleDeleteCategory = async id => {
    await deleteDonorCategory({ variables: { id } });
    refetch();
  };

  const handleUpdateCategory = async id => {
    if (editCategory.title.trim()) {
      await updateDonorCategory({
        variables: {
          title: editCategory.title,
          isDefault: editCategory.isDefault,
          id
        }
      });
      setEditIndex(null);
      setEditCategory({ title: '', isDefault: false });
      refetch();
    }
  };

  const startEditCategory = index => {
    setEditIndex(index);
    setEditCategory({
      title: categories[index].title,
      isDefault: categories[index].is_default
    });
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setEditCategory({ title: '', isDefault: false });
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Donor Group</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4 gap-2 align-items-center">
          <Form.Control
            type="text"
            placeholder="Add Category"
            value={newCategory.title}
            onChange={e =>
              setNewCategory(prevState => ({
                ...prevState,
                title: e.target.value
              }))
            }
          />
          <Form.Check className="mb-0">
            <Form.Check.Input
              id="mark_new_group_as_default"
              type="checkbox"
              checked={newCategory.isDefault}
              onChange={e =>
                setNewCategory(prevState => ({
                  ...prevState,
                  isDefault: e.target.checked
                }))
              }
            />
            <Form.Check.Label
              className="mb-0"
              htmlFor="mark_new_group_as_default"
            >
              is default
            </Form.Check.Label>
          </Form.Check>
          <Button variant="primary" size="sm" onClick={handleAddCategory}>
            + Add
          </Button>
        </InputGroup>
        <ListGroup>
          {categories.map((category, index) => (
            <ListGroup.Item
              key={category.id}
              className="d-flex justify-content-between align-items-center py-2 px-3"
              style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '20px'
              }}
            >
              {editIndex === index ? (
                <div className="flex-grow-1 flex align-items-center">
                  <div className="mb-2 d-flex gap-2 align-items-center">
                    <Form.Control
                      type="text"
                      value={editCategory.title}
                      onChange={e =>
                        setEditCategory(prevState => ({
                          ...prevState,
                          title: e.target.value
                        }))
                      }
                    />
                    <Form.Check className="flex-shrink-0 mb-0">
                      <Form.Check.Input
                        id={`is_default_${index}`}
                        type="checkbox"
                        checked={editCategory.isDefault}
                        onChange={e =>
                          setEditCategory(prevState => ({
                            ...prevState,
                            isDefault: e.target.checked
                          }))
                        }
                      />
                      <Form.Check.Label
                        className="mb-0"
                        htmlFor={`is_default_${index}`}
                      >
                        Mark as default
                      </Form.Check.Label>
                    </Form.Check>
                  </div>
                  <div className="d-flex justify-content-end gap-1">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => handleUpdateCategory(category.id)}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={cancelEdit}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <span>{category.title}</span>

                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item onClick={() => startEditCategory(index)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleDeleteCategory(category.id)}
                      >
                        Delete
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} className="w-100">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DonorGroupModal;

DonorGroupModal.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool
};
