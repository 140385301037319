import React from 'react';
import paths from 'routes/paths';
import { useBeneficiaryContext } from 'context/beneficiaryContext';
import NavBar from 'components/common/profile/sideNavBar';

const beneficiaryNavLinks = [
  { id: 1, title: 'Overview', link: paths.beneficiaryProfile },
  { id: 2, title: 'School', link: paths.beneficiaryShool },
  { id: 3, title: 'Mosque', link: paths.beneficiaryMosque },
  { id: 4, title: 'Orphanage', link: paths.beneficiaryOrphanage },
  { id: 5, title: 'Hospital', link: paths.beneficiaryHospital },
  { id: 6, title: 'Medical', link: paths.beneficiaryMedical },
  {
    id: 7,
    title: 'Donors',
    link: paths.beneficiaryDonors
  },
  {
    id: 8,
    title: 'Gallery',
    link: paths.beneficiaryGallery
  }
];

/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */

const BeneficiaryNav = () => {
  const { beneficiary } = useBeneficiaryContext();
  return (
    <NavBar
      navLinks={beneficiaryNavLinks}
      user={beneficiary}
      score={false}
      type={'Beneficiary'}
    />
  );
};

export default BeneficiaryNav;
