import { gql, useQuery } from '@apollo/client';
import LoadingButton from 'components/common/LoadingButton';
import {
  FormProvider,
  RHFControl,
  RHFFileUploadRegistration,
  RHFSelect
} from 'components/common/form';
import {
  getCitiesOptions,
  getCountriesOptions
} from 'data/options/countris-and-cities';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Can } from 'routes/permissions/Can';
import { userActions } from 'routes/permissions/permissionKeys';
import { organizationRegistrationResolver } from './organizationRegistrationScheme';

const GET_CURRENCIES = gql`
  query GetCurrenciesAndCountries {
    currency {
      abbreviation
      symbol
      id
      currency_name
    }
  }
`;

export default function OrganizationForm({
  onSubmit,
  loading,
  isEdit,
  initialValues,
  subject
}) {
  const { data, loading: loadingCurrencyData } = useQuery(GET_CURRENCIES);
  const currenciesOptions = useMemo(() => {
    if (data) {
      return data.currency.map(({ abbreviation, id, symbol }) => ({
        label: abbreviation + (symbol ? ` (${symbol})` : ''),
        value: id
      }));
    }
  }, [data]);

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initialValues ?? {
      logo: '',
      name: '',
      // type: '',
      country: '',
      currency: '',
      address1: '',
      address2: '',
      city: '',
      postCode: '',
      email: '',
      charityNumber: ''
    },
    resolver: organizationRegistrationResolver
  });
  const { handleSubmit, watch } = methods;
  const country = watch('country');
  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const citiesOptions = useMemo(() => {
    if (country) {
      return getCitiesOptions(country);
    }
    return [];
  }, [country]);
  const submitButtonLabel = isEdit ? 'Save' : 'Create Business';
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column gap-3">
        <h4 className="my-4">Organization Details</h4>
        <Row>
          <Col>
            <RHFFileUploadRegistration
              label={'Organization Logo'}
              name={'logo'}
            />
          </Col>
        </Row>
        <Row>
          <RHFControl
            label={'Organization Name'}
            name={'name'}
            placeholder={'Organization Name'}
            required
          />
        </Row>
        <Row>
          <RHFControl
            label={'Charity Number/ID'}
            name={'charityNumber'}
            placeholder={'Charity Number/ID'}
          />
        </Row>
        <Row>
          <RHFControl
            label={'First Line Address'}
            name={'address1'}
            placeholder={'First Line Address'}
            required
          />
        </Row>
        <Row>
          <RHFControl
            label={'Second Line Address'}
            name={'address2'}
            placeholder={'Second Line Address'}
          />
        </Row>
        <Row>
          <Col md={6}>
            <RHFSelect
              label={'Country'}
              name={'country'}
              options={countriesOptions}
              required
            />
          </Col>
          <Col md={6}>
            <RHFSelect
              label={'City'}
              name={'city'}
              options={citiesOptions}
              disabled={!country}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RHFControl
              label={'Post Code'}
              name={'postCode'}
              placeholder={'Post Code'}
              required
            />
          </Col>
          <Col md={6}>
            <RHFControl
              label={'Organization Email'}
              name={'email'}
              placeholder={'Organization Email'}
              required
              type={'email'}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <RHFSelect
              label={'Currency'}
              name={'currency'}
              loading={loadingCurrencyData}
              options={currenciesOptions}
              required
            />
          </Col>
        </Row>

        <Can
          I={isEdit ? userActions.update : userActions.create}
          a={subject}
          passThrough={!subject}
        >
          <LoadingButton
            size="lg"
            className="mb-2 rounded-3"
            iconClassName="me-1"
            type="submit"
            loading={loading}
          >
            {submitButtonLabel}
          </LoadingButton>
        </Can>
      </div>
    </FormProvider>
  );
}

OrganizationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  subject: PropTypes.string
};
