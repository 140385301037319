import { gql } from '@apollo/client';

export const GET_LOCATIONS = gql`
  query GetLocations($type: String = "", $organizationId: uuid = "") {
    location(
      where: { type: { _eq: $type }, organisation_id: { _eq: $organizationId } }
    ) {
      location_name
      id
    }
  }
`;

export const GET_PACKAGE_CATEGORIES = gql`
  query GetPackageCategories($organizationId: uuid = "") {
    package_category(where: { organisation_id: { _eq: $organizationId } }) {
      id
      category_name
    }
  }
`;

export const GET_DONATION_TYPES = gql`
  query GetDonationTypes($organizationId: uuid = "") {
    donation_type(where: { organisation_id: { _eq: $organizationId } }) {
      id
      donation_type_name
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($organizationId: uuid = "") {
    campaign(where: { organisation_id: { _eq: $organizationId } }) {
      id
      name
    }
  }
`;

export const GET_DONATION_AMOUNTS = gql`
  query GetDonationAmounts($organizationId: uuid = "") {
    donation_amount(where: { organisation_id: { _eq: $organizationId } }) {
      id
      amount
    }
  }
`;

export const GET_TRANSACTION_FEES = gql`
  query GetTransactionFees($organizationId: uuid = "", $branchId: uuid = "") {
    transaction_fee(
      where: {
        _or: [
          { branch_id: { _is_null: true } }
          { branch_id: { _eq: $branchId } }
        ]
        organisation_id: { _eq: $organizationId }
      }
    ) {
      id
      fee_type
      amount
    }
  }
`;
