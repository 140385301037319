import { PropTypes } from 'prop-types';
import React from 'react';
import paths from 'routes/paths';
import NavBar from 'components/common/profile/sideNavBar';

const DonorNav = ({ notesCount }) => {
  const donorNavLinks = [
    { id: 1, title: 'Profile', link: paths.donorProfile },
    { id: 2, title: 'Insights', link: paths.donorInsights },
    { id: 3, title: 'Giving', link: paths.donorGiving },
    { id: 4, title: 'Sponsorships', link: paths.donorSponsorships },
    // {
    //   id: 5,
    //   title: 'Communications',
    //   link: paths.donorCommunications
    // },
    // {
    //   id: 6,
    //   title: 'Relation Management',
    //   link: paths.donorRelationManagement
    // },
    { id: 7, title: 'Gift Aid', link: paths.donorGiftAid },
    { id: 8, title: 'Notes', link: paths.donorFeedback, count: notesCount }
  ];

  return <NavBar navLinks={donorNavLinks} />;
};

DonorNav.propTypes = {
  notesCount: PropTypes.number
};

export default DonorNav;
