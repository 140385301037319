import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import TableRowAction from 'components/common/TableRowAction';
import { weUserImage } from 'data/weraise-data/donor-data';
import paths from 'routes/paths';
import { parseRouteParams } from 'helpers/utils';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { useBeneficiaryContext } from 'context/beneficiaryContext';
import { toast } from 'react-toastify';
import SelectDonorModal from '../sponsorships/sponsorship-form/SelectDonorModal';
import { DELETE_SPONSORSHIP } from '../mutations';
import { GET_SPONSORSHIP } from '../queries';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
const Donors = () => {
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { beneficiary } = useBeneficiaryContext();
  const { id } = useParams();
  const [donorModalShow, setDonorModalShow] = useState(false);

  const { data: sponsershipData } = useQuery(GET_SPONSORSHIP, {
    fetchPolicy: 'network-only',
    variables: {
      id
    }
  });

  const [deleteDonor, { loading: deleteLoading }] = useMutation(
    DELETE_SPONSORSHIP,
    {
      onCompleted: () => {
        handleDeleteModalHide();
        toast.success('Donor deleted successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      },
      update: cache => {
        const existingSponsorship = cache.readQuery({
          query: GET_SPONSORSHIP,
          variables: {
            id
          }
        });
        if (!existingSponsorship) return;

        cache.writeQuery({
          query: GET_SPONSORSHIP,
          variables: {
            id
          },
          data: {
            sponsorship: [
              ...existingSponsorship.sponsorship.filter(
                item => item.id !== selectedId
              )
            ]
          }
        });
      }
    }
  );

  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { donar } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <Avatar src={weUserImage} rounded="circle" size="xl" />
            <Link
              to={`/donors/${id}`}
              className="fw-medium ms-2 text-1100 hover-primary"
            >
              {donar.first_name}
            </Link>
          </Flex>
        );
      }
    },
    {
      accessor: 'payment_till',
      Header: 'Payment Till',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'status',
      Header: 'Status'
    },
    {
      accessor: 'note',
      Header: 'Note'
    },
    {
      accessor: 'user',
      Header: 'User',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { donar } = rowData.row.original;
        const name = donar.first_name + ' ' + donar.last_name;
        return <Flex alignItems="center">{name}</Flex>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            isNotEdit
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.sponsorship.children.beneficiaries}
          />
        );
      }
    }
  ];

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };
  const handleDeleteModalHide = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    deleteDonor({
      variables: {
        id: selectedId
      }
    });
  };
  const hideDonorModal = () => {
    setDonorModalShow(false);
  };

  const handleSelectDonorSubmit = selectedDonorId => {
    navigate(
      parseRouteParams(
        paths.beneficiaryCreateNewSponsorships,
        { id: beneficiary.id, donorId: selectedDonorId },
        {
          id: beneficiary.id,
          donorId: selectedDonorId
        }
      )
    );
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleDeleteModalHide}
        loading={deleteLoading}
        title="Delete Donor"
        handleConfirm={handleConfirmDelete}
        body="Are you sure you want to delete selected donor?"
      />

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {sponsershipData?.sponsorship && (
            <CustomAdvanceTable
              data={sponsershipData?.sponsorship}
              columns={columns}
              title="Donors list"
              addButtonLabel="Add New Donor"
              showSearchInput
              searchInputPlaceHolder="Search Accounts"
              onClickAddNew={() => {
                setDonorModalShow(true);
              }}
              // loading={loading}
              addNew
              subject={permissionsKeyMap.donor.children.donors}
            />
          )}
        </Col>
      </Row>
      <SelectDonorModal
        onHide={hideDonorModal}
        show={donorModalShow}
        onSubmit={handleSelectDonorSubmit}
      />
    </>
  );
};

export default Donors;
