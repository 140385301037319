import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, ListGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Logo from 'components/common/Logo';
import paths from 'routes/paths';
import { getRelativePath } from 'helpers/utils';

const CardNav = ({ notesCount }) => {
  const location = useLocation();

  const navLinks = [
    { id: 1, title: 'Profile', link: paths.donorProfile },
    { id: 2, title: 'Insights', link: paths.donorInsights },
    { id: 3, title: 'Giving', link: paths.donorGiving },
    { id: 4, title: 'Sponsorships', link: paths.donorSponsorships },
    // { id: 5, title: 'Communications', link: paths.donorCommunications },
    // {
    //   id: 6,
    //   title: 'Relation Management',
    //   link: paths.donorRelationManagement
    // },
    { id: 7, title: 'Gift Aid', link: paths.donorGiftAid },
    { id: 8, title: 'Notes', link: paths.donorFeedback, count: notesCount },
    {
      id: 9,
      title: 'Add Donation',
      link: paths.addNewDonation
      // isNotRelative: true
    }
  ];

  return (
    <Card className="mt-3">
      <Card.Body className="p-3">
        <Logo width={20} textClass="h5" />

        <ListGroup activeKey={location.pathname}>
          {navLinks.map(({ id, link, isNotRelative, title, count }) => (
            <ListGroup.Item
              className="border-0"
              key={id}
              action
              as={Link}
              to={
                isNotRelative ? link : getRelativePath(link, paths.donorDetails)
              }
              active={
                location.pathname ===
                (isNotRelative
                  ? link
                  : getRelativePath(link, paths.donorDetails))
              }
            >
              {title}
              {count > 0 && (
                <Badge pill className="ms-1">
                  {count}
                </Badge>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

CardNav.propTypes = {
  notesCount: PropTypes.number
};

export default CardNav;
