import { Dropdown, Form, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useContext, useMemo, useState } from 'react';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useAuthContext } from 'context/authContext';
import { KanbanContext } from 'context/Context';
import { toast } from 'react-toastify';
import { GET_PROJECT_BY_ID } from './KanbanModal';

/* -------------------------------------------------------------------------- */
export const GET_BRANCH_USERS = gql`
  query GetBranchUsers($branchId: uuid!) {
    users_roles(where: { branch_id: { _eq: $branchId } }) {
      user {
        id
        first_name
        last_name
        email
      }
      branch_id
      id
      is_active
    }
  }
`;

const ADD_PROJECT_MEMBER = gql`
  mutation AddProjectMember($projectId: uuid!, $userId: uuid!) {
    insert_project_members_one(
      object: { project_id: $projectId, member_id: $userId }
    ) {
      id
      user {
        id
        first_name
        last_name
        # profile_image
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

function AddProjectMember({ avatarSize = 'l', currentMembers }) {
  const {
    kanbanState: { kanbanModal }
  } = useContext(KanbanContext);
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedBranchId } = useAuthContext();
  const { data } = useQuery(GET_BRANCH_USERS, {
    variables: {
      branchId: selectedBranchId
    }
  });
  const [addProjectMember] = useMutation(ADD_PROJECT_MEMBER, {
    onCompleted: () => {
      toast.success('Member added successfully');
    },
    error: error => {
      console.log(error);
      toast.error('Something went wrong! Please try again later');
    },
    update: (cache, { data: { insert_project_members_one } }) => {
      const { projects_by_pk } = cache.readQuery({
        query: GET_PROJECT_BY_ID,
        variables: {
          projectId: kanbanModal.modalContent.id
        }
      });
      cache.writeQuery({
        query: GET_PROJECT_BY_ID,
        variables: {
          projectId: kanbanModal.modalContent.id
        },
        data: {
          projects_by_pk: {
            ...projects_by_pk,
            project_members: [
              ...projects_by_pk.project_members,
              insert_project_members_one
            ]
          }
        }
      });
    }
  });
  const usersOptions = useMemo(() => {
    if (!data) return [];
    const currentMemberIds = currentMembers.map(({ id }) => id);
    // filter out current members
    const filteredUsers = data.users_roles.filter(
      ({ user }) => !currentMemberIds.includes(user.id)
    );
    return filteredUsers.map(({ user }) => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      img: user.profile_pic
    }));
  }, [currentMembers, data]);
  const handleAddProjectMember = userId => {
    addProjectMember({
      variables: {
        projectId: kanbanModal.modalContent.id,
        userId
      }
    });
  };

  return (
    <Dropdown>
      <Dropdown.Toggle as={Nav.Link} className="dropdown-caret-none p-0 ms-n1">
        <Avatar
          icon="plus"
          size={avatarSize}
          mediaClass="avatar-button flex-center"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu align={'start'} className="dropdown-md">
        <h6 className="dropdown-header py-0 px-3 mb-0">Select Member</h6>
        <Dropdown.Divider />

        <>
          <Form className="px-3 mb-2">
            <Form.Control
              size="sm"
              type="text"
              placeholder="Search team member"
              onChange={e => setSearchTerm(e.target.value)}
            />
          </Form>
          {/* <ul className="list-unstyled mb-0"> */}
          {usersOptions
            .filter(user => {
              if (!searchTerm) {
                return user;
              } else if (
                user.name
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase().trim())
              ) {
                return user;
              }
            })
            .map(user => (
              <Dropdown.Item
                key={user.id}
                className="px-3"
                onClick={() => handleAddProjectMember(user.id)}
              >
                <Flex alignItems="center" className="py-1 text-decoration-none">
                  <Avatar
                    src={user.img}
                    className="me-2"
                    size={avatarSize}
                    name={user.name}
                  />
                  <div className="flex-1">
                    <h6 className="mb-0">{user.name}</h6>
                  </div>
                </Flex>
              </Dropdown.Item>
            ))}
          {/* </ul> */}
        </>
      </Dropdown.Menu>
    </Dropdown>
  );
}

AddProjectMember.propTypes = {
  avatarSize: PropTypes.string,
  currentMembers: PropTypes.array
};

export default AddProjectMember;
