import React from 'react';
import { Dropdown } from 'react-bootstrap';
import CardDropdown from './CardDropdown';
import PropTypes from 'prop-types';
import { Can } from 'routes/permissions/Can';
import { userActions } from 'routes/permissions/permissionKeys';

const TableRowAction = ({
  onCLickEdit,
  onCLickDelete,
  isNotEdit,
  isNotDelete,
  subject
}) => {
  return (
    <CardDropdown>
      <div className="py-2">
        {isNotEdit ? null : (
          <Can I={userActions.update} a={subject}>
            <Dropdown.Item onClick={onCLickEdit}>Edit</Dropdown.Item>
          </Can>
        )}
        {isNotDelete ? null : (
          <Can I={userActions.delete} a={subject}>
            <Dropdown.Item className="text-danger" onClick={onCLickDelete}>
              Delete
            </Dropdown.Item>
          </Can>
        )}
      </div>
    </CardDropdown>
  );
};

TableRowAction.propTypes = {
  onCLickEdit: PropTypes.func,
  onCLickDelete: PropTypes.func,
  isNotEdit: PropTypes.bool,
  isNotDelete: PropTypes.bool,
  subject: PropTypes.string
};

export default TableRowAction;
