import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import TableRowAction from 'components/common/TableRowAction';
import { weDonorInvoiceData } from 'data/weraise-data/donor-data';
import { parseRouteParams } from 'helpers/utils';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import paths from 'routes/paths';

const DonorGivingDetails = () => {
  const navigate = useNavigate();
  const { id, giving_id } = useParams();

  const columns = [
    {
      accessor: 'ref',
      Header: 'Ref',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { ref } = rowData.row.original;
        return <Link to={`/donors/${id}/giving/${ref}`}>{ref}</Link>;
      }
    },
    {
      accessor: 'date',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'pay_sys',
      Header: 'Pay-sys',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'donation',
      Header: 'Donation',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'id',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row;
        return (
          <TableRowAction
            onCLickEdit={() => console.log(id)}
            onCLickDelete={() => console.log(id)}
          />
        );
      }
    }
  ];

  const weSponsorshipData = [
    { id: 'sp1', label: 'Ref.', value: giving_id },
    { id: 'sp2', label: 'Created on', value: '12/10/2022 21:43' },
    { id: 'sp3', label: 'First Paid ', value: '12/10/2022 21:43' },
    {
      id: 'sp4',
      label: 'Next rebill',
      value: '12/11/2022',
      type: 'stop_Recurring'
    },
    {
      id: 'sp5',
      label: 'Billing Cycle',
      value: 'Recurring payment',
      valueClass: 'text-primary'
    },
    { id: 'sp6', label: 'Method', value: 'Credit Card - XXX42' },
    {
      id: 'sp7',
      label: 'Status',
      value: 'Success',
      valueClass: 'text-success'
    },
    { id: 'sp8', label: 'Amount Paid', value: '$1,500.00' },
    {
      id: 'sp9',
      label: 'Remarks',
      value: 'No Comments',
      type: 'remarks'
    }
  ];

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title={
            <IconButton
              onClick={() => navigate(-1)}
              icon={'arrow-left'}
              iconClassName="me-2"
              variant="link"
              className="px-0 fw-bold fs--1 fs-md-0"
            >
              Giving / {giving_id}
            </IconButton>
          }
          light={false}
          titleTag="div"
          menuClassName="align-items-center"
          endEl={<></>}
        />
      </Card>
      <Card className="mb-3">
        <Card.Header className="pb-0">
          <Card.Title className="fs-0 text-700 mb-0">
            {giving_id}
            <span className="fw-normal ms-1">- Recurring Sponsorship</span>
          </Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <hr />
          {weSponsorshipData?.map(data => (
            <Flex key={data.id}>
              <p className="donor-info-title fs--1 fs-md-0 text-1000">
                {data.label}
              </p>
              <p className={classNames(data.valueClass, 'fs--1 fs-md-0')}>
                {data.type === 'remarks' && (
                  <FontAwesomeIcon icon={'pencil-alt'} className="me-2" />
                )}{' '}
                {data.value}
              </p>
              {data.type === 'stop_Recurring' && (
                <>
                  <span className="mx-2 fs--1 fs-md-0">|</span>
                  <IconButton
                    size="sm"
                    icon={'stop-circle'}
                    className="bg-soft-danger text-danger fs--1 fs-md-0 rounded-4 border-0"
                    style={{ height: '26px' }}
                  >
                    Stop Recurring
                  </IconButton>
                </>
              )}
            </Flex>
          ))}
          <hr className="mt-0" />
          <Flex justifyContent={'end'}>
            <IconButton
              icon="trash-alt"
              iconClassName={'me-2'}
              variant="danger"
              className="me-2"
              onClick={() =>
                navigate(parseRouteParams(paths.donorGiving, { id }))
              }
            >
              Delete
            </IconButton>
            <Button
              variant="primary"
              onClick={() =>
                navigate(parseRouteParams(paths.donorGiving, { id }))
              }
            >
              Issue Refund
            </Button>
          </Flex>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Row>
          <Col md={12} xxl={12} className="h-md-100">
            {weDonorInvoiceData && (
              <CustomAdvanceTable
                data={weDonorInvoiceData}
                columns={columns}
                title="Related Payments"
                addNew={false}
              />
            )}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default DonorGivingDetails;
