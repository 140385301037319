import { getRelativePath } from 'helpers/utils';
import { Navigate, Route, Routes } from 'react-router-dom';
import paths from 'routes/paths';
import OwnerRegistration from './OwnerRegistration';
import RegistrationLayout from './RegistrationLayout';

function RegistrationRoutes() {
  // const [loading, setLoading] = useState(false);
  // const onBusinessInfoSubmit = () => {};

  return (
    <Routes>
      <Route element={<RegistrationLayout />}>
        <Route
          path={getRelativePath(paths.registerOwnerInfo, paths.register)}
          element={<OwnerRegistration />}
        />
        {/* <Route
          path={getRelativePath(paths.registerBusinessInfo, paths.register)}
          element={
            <OrganizationRegistration
              // loading={loading}
              onSubmit={onBusinessInfoSubmit}
            />
          }
        /> */}
        {/* if no match direct to register */}
        <Route
          path="*"
          element={<Navigate to={paths.registerOwnerInfo} replace />}
        />
      </Route>
    </Routes>
  );
}

export default RegistrationRoutes;
