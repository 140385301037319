import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';
// import useBranchPermission from './useBranchPermission';

const GET_CURRENCIES = gql`
  query GetProjectCurrencies($where: donation_currency_bool_exp = {}) {
    donation_currency(where: $where) {
      currency_id
      created_at
      id
      organisation_id
      updated_at
      donation_currency_organisation {
        currency {
          abbreviation
          currency_name
          symbol
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

const useCurrenciesOptions = () => {
  // const { where } = useBranchPermission();

  const { data, loading } = useQuery(GET_CURRENCIES);
  const currenciesOptions = useMemo(() => {
    if (data) {
      return data.donation_currency.map(
        ({ currency_id, donation_currency_organisation }) => ({
          label:
            donation_currency_organisation.currency.currency_name +
            (donation_currency_organisation.currency.symbol
              ? ` (${donation_currency_organisation.currency.symbol})`
              : ''),
          value: currency_id
        })
      );
    }
  }, [data]);
  return { currenciesOptions, loading };
};

export default useCurrenciesOptions;
