import { Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMemo, useContext } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import OrganizationForm from 'components/authenitcation/registration/OrganizationForm';
import AuthContext from 'context/authContext';
import useUpload from 'hooks/useUpload';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $address_1: String = ""
    $address_2: String = ""
    $charity_number: String = ""
    $city: String = ""
    $country: String = ""
    $currency_id: uuid = ""
    $organisation_email: String = ""
    $logo: String = ""
    $organisation_name: String = ""
    $post_code: String = ""
    $id: uuid = ""
  ) {
    update_organisation_by_pk(
      pk_columns: { id: $id }
      _set: {
        address_2: $address_2
        address_1: $address_1
        charity_number: $charity_number
        city: $city
        country: $country
        currency_id: $currency_id
        organisation_name: $organisation_name
        organisation_email: $organisation_email
        post_code: $post_code
        logo: $logo
      }
    ) {
      address_1
      address_2
      charity_number
      city
      country
      created_at
      currency_id
      id
      logo
      organisation_email
      organisation_name
      post_code
      parent_id
      updated_at
    }
  }
`;

const GET_ORGANIZATION = gql`
  query GetOrganization($id: uuid!) {
    organisation_by_pk(id: $id) {
      address_1
      address_2
      charity_number
      city
      country
      created_at
      currency_id
      id
      logo
      organisation_email
      organisation_name
      post_code
      parent_id
      updated_at
    }
  }
`;

export default function OrganizationSettings() {
  const { organization } = useContext(AuthContext);
  const { data, loading: dataLoading } = useQuery(GET_ORGANIZATION, {
    variables: { id: organization.id }
  });
  const [updateOrganization, { loading: updateLoading }] = useMutation(
    UPDATE_ORGANIZATION,
    {
      onCompleted: () => {
        toast.success('Organization updated successfully');
      },
      onError: ({ graphQLErrors }) => {
        toast.error(graphQLErrors[0].message);
        console.log(graphQLErrors);
      }
    }
  );
  const { upload, loading: uploadLoading } = useUpload();

  const onSubmit = async ({
    logo,
    name,
    type,
    country,
    currency,
    address1,
    address2,
    city,
    postCode,
    email,
    charityNumber
  }) => {
    let image = logo;
    try {
      if (image && typeof logo !== 'string') {
        const profileUrl = await upload(image);
        image = profileUrl;
      }
    } catch (e) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }

    updateOrganization({
      variables: {
        logo: image,
        organisation_name: name,
        type: type,
        country: country,
        currency_id: currency,
        address_1: address1,
        address_2: address2,
        city: city,
        post_code: postCode,
        organisation_email: email,
        charity_number: charityNumber,
        id: organization.id
      }
    });
  };
  const initialValues = useMemo(() => {
    if (!data) return {};
    const { organisation_by_pk } = data;
    return {
      logo: organisation_by_pk.logo ?? '',
      name: organisation_by_pk.organisation_name ?? '',
      type: organisation_by_pk.type ?? '',
      country: organisation_by_pk.country ?? '',
      currency: organisation_by_pk.currency_id ?? '',
      address1: organisation_by_pk.address_1 ?? '',
      address2: organisation_by_pk.address_2 ?? '',
      city: organisation_by_pk.city ?? '',
      postCode: organisation_by_pk.post_code ?? '',
      email: organisation_by_pk.organisation_email ?? '',
      charityNumber: organisation_by_pk.charity_number ?? ''
    };
  }, [data]);
  return dataLoading ? (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <Container fluid className="px-0">
      <Row>
        <Col sm={12} md={7}>
          <Card>
            <Card.Body>
              <OrganizationForm
                loading={updateLoading || uploadLoading}
                onSubmit={onSubmit}
                initialValues={initialValues}
                isEdit
                subject={
                  permissionsKeyMap.settings.children.organizationDetails
                }
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
