import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import useUpload from 'hooks/useUpload';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import AddImageModal from './AddImageModal';
import { gql, useMutation } from '@apollo/client';
import { useBeneficiaryContext } from 'context/beneficiaryContext';

export const UPDATE_IMAGE = gql`
  mutation EditOrphans($id: uuid!, $image: String = "") {
    update_orphans_by_pk(pk_columns: { id: $id }, _set: { image: $image }) {
      first_name
      last_name
      id
      image
    }
  }
`;

const ProfileCard = () => {
  const { beneficiary } = useBeneficiaryContext();

  const { first_name, last_name, image, id, simple_id } = beneficiary || {};
  const fullName = `${first_name} ${last_name}`;

  const { upload, loading: uploadLoading } = useUpload();
  const [addImageShow, setAddImageShow] = useState(false);

  const [EditOrphans] = useMutation(UPDATE_IMAGE, {
    onCompleted: () => {
      toast.success('Image Added Successfully');
      setAddImageShow(false);
    },
    onError: error => {
      toast.error('Something went wrong! Please Try again later!');
      console.log(error);
    }
  });

  const onSubmit = async values => {
    try {
      if (values.image && typeof values.image !== 'string') {
        const profileUrl = await upload(values.image);
        values.image = profileUrl;
        EditOrphans({
          variables: {
            image: values.image,
            id
          }
        });
      }
    } catch (error) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }
  };

  return (
    <Card className="border rounded-3 bg-white dark__bg-1000 shadow-none">
      <Card.Body className="row g-0 flex-column flex-sm-row flex-xl-column z-index-1 align-items-center">
        <Flex justifyContent="between" alignItems="center" className="mb-3">
          <h6 className="mb-0">Beneficiary Account</h6>
        </Flex>
        <div className="text-center">
          <Flex justifyContent="center" alignItems="end">
            <Avatar
              src={image}
              name={fullName}
              rounded="circle"
              size="4xl"
              className="border border-5 border-white rounded-circle shadow-sm mb-2"
            />
            <IconButton
              icon="pen"
              variant="falcon-default"
              className="bg-transparent shadow-none"
              onClick={() => {
                setAddImageShow(true);
              }}
              style={{ padding: 0 }}
            />
          </Flex>

          {addImageShow && (
            <AddImageModal
              show={addImageShow}
              onSubmit={onSubmit}
              onHide={() => {
                setAddImageShow(false);
              }}
              loading={uploadLoading}
              title={'Add Photo'}
            />
          )}

          <p className="mb-1 fs--1 text-primary fw-medium">{fullName}</p>
          <p className="mb-0 fs--2 text-700 font-base">ID: B{simple_id}</p>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ProfileCard;
