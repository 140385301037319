import { gql, useMutation, useQuery } from '@apollo/client';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import TableRowAction from 'components/common/TableRowAction';
import { parseRouteParams } from 'helpers/utils';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams, Link } from 'react-router-dom';
import paths from 'routes/paths';
import { projectStatusMap } from './ProjectsList';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';
import { Can } from 'routes/permissions/Can';

export const GET_PROJECT_MILESTONES = gql`
  query GetProjectMilestones($parentId: uuid!) {
    projects(
      where: { parent_id: { _eq: $parentId } }
      order_by: { created_at: desc }
    ) {
      case_study
      feasibility_study
      project_labels {
        id
        label {
          id
          title
        }
      }
      project_charter
      project_name
      status
      end_date
      start_date
      average_beneficiaries_cost
      donation_amount
      target_amount
      num_of_beneficiaries
      project_description
      created_at
      updated_at
      id
      country
      userByManagerId {
        id
        first_name
        last_name
      }
    }
  }
`;

const DELETE_PROJECT = gql`
  mutation DeleteProject($id: uuid!) {
    delete_projects_by_pk(id: $id) {
      id
    }
  }
`;

const ProjectMilestones = () => {
  const { id: parentId } = useParams();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedMilestoneId, setSelectedMilestoneId] = useState(null);
  const { data, loading } = useQuery(GET_PROJECT_MILESTONES, {
    variables: { parentId }
  });
  const [deleteProject, { loading: deleteLoading }] = useMutation(
    DELETE_PROJECT,
    {
      onCompleted: () => {
        toast.success('Project deleted successfully');
      },
      onError: error => {
        console.log(error);
        toast.error('Something went wrong! Please try again.');
      },
      update: (cache, { data }) => {
        const deletedProjectId = data.delete_projects_by_pk.id;
        const { projects } = cache.readQuery({
          query: GET_PROJECT_MILESTONES,
          variables: { parentId }
        });
        cache.writeQuery({
          query: GET_PROJECT_MILESTONES,
          variables: { parentId },
          data: {
            projects: projects.filter(
              project => project.id !== deletedProjectId
            )
          }
        });
      }
    }
  );

  const handleDeleteConfirm = () => {
    setShowDeleteModal(false);
    deleteProject({ variables: { id: selectedMilestoneId } });
  };

  const handleDeleteClick = id => {
    setShowDeleteModal(true);
    setSelectedMilestoneId(id);
  };
  const columns = [
    {
      accessor: 'ref',
      Header: 'Ref',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'project_name',
      Header: 'Milestone',
      cellProps: {
        className: 'py-2 fw-medium'
      },
      Cell: rowData => {
        const { id, project_name } = rowData.row.original;
        return (
          <Link
            to={parseRouteParams(paths.projectReports, {
              id,
              milestoneId: id
            })}
          >
            {project_name}
          </Link>
        );
      }
    },
    {
      accessor: 'manager',
      Header: 'Manager',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { first_name, last_name } = rowData.row.original.manager ?? {
          first_name: '',
          last_name: ''
        };
        return (
          <span className="fw-medium">
            {first_name} {last_name}
          </span>
        );
      }
    },
    {
      accessor: 'start_date',
      Header: 'Started Date',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'end_date',
      Header: 'Due Date',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'target_amount',
      Header: 'Funding',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        return (
          <SoftBadge
            pill
            bg={projectStatusMap[rowData.row.original.status]?.variant}
            className="me-2"
          >
            {rowData.row.original.status}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'oid',
      Header: '',
      cellProps: {
        className: 'text-end fw-medium py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row;

        return (
          <TableRowAction
            onCLickEdit={() =>
              navigate(parseRouteParams(paths.editProject, { id }))
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.project.children.projects}
          />
        );
      }
    }
  ];

  const content = (
    <Can I={userActions.create} a={permissionsKeyMap.project.children.projects}>
      <IconButton
        variant="falcon-default"
        icon="project-diagram"
        iconClassName="text-primary"
        className="me-2"
        size="sm"
        transform="shrink-3"
        onClick={() =>
          navigate(parseRouteParams(paths.createProject, {}, { parentId }))
        }
      >
        <span className="text-primary d-none d-lg-inline-block ms-2">
          Create Milestone
        </span>
      </IconButton>
    </Can>
  );

  return (
    <Row>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        handleConfirm={handleDeleteConfirm}
        title="Delete Milestone"
        body="Are you sure you want to delete this Milestone?"
        loading={deleteLoading}
      />
      <Col md={12} xxl={12} className="h-md-100">
        {data?.projects && (
          <CustomAdvanceTable
            data={data?.projects}
            loading={loading}
            columns={columns}
            perPage={10}
            showTablePagination
            title={'Milestones Table'}
            showSearchInput
            searchInputPlaceHolder="Search"
            headerContent={content}
          />
        )}
      </Col>
    </Row>
  );
};

export default ProjectMilestones;
