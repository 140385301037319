import { Route, Routes } from 'react-router-dom';

import { DonationContextProvider } from 'context/DonationContextProvider';
import Home from '.';

const DonationLayout = () => {
  return (
    <DonationContextProvider>
      <Routes>
        <Route path="/:organizationId" element={<Home />} />
      </Routes>
    </DonationContextProvider>
  );
};

export default DonationLayout;
