import React, { useContext } from 'react';
import { stages } from './homeData';
import PropTypes from 'prop-types';
import DonorForm from './DonorForm';
import DonationContext from 'context/DonationContextProvider';

const Page4 = ({ setStage }) => {
  const { setDonor } = useContext(DonationContext);

  const handleBack = () => {
    setStage(stages.Donation3);
  };
  const handleCreate = data => {
    setDonor(data);
    setStage(stages.Details2);
  };
  return (
    <>
      <DonorForm onSubmit={handleCreate} handleBack={handleBack} />
    </>
  );
};

Page4.propTypes = {
  setStage: PropTypes.func.isRequired
};

export default Page4;
