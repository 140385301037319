import { gql } from '@apollo/client';

export const GET_LOCATION = gql`
  query GetLocation($organizationId: uuid = "") {
    location(where: { organisation_id: { _eq: $organizationId } }) {
      id
      location_name
    }
  }
`;

export const GET_PACKAGES = gql`
  query GetPackages(
    $location: jsonb = ""
    $paymentOptions: jsonb = ""
    $donationType: jsonb = ""
  ) {
    packages(
      where: {
        location: { _contains: $location }
        payment_options: { _contains: $paymentOptions }
        donation_type: { _contains: $donationType }
      }
    ) {
      donation_amount
      package_title
      id
    }
  }
`;

export const GET_DONATION_TYPE = gql`
  query GetDonationTypes($organizationId: uuid = "") {
    donation_type(where: { organisation_id: { _eq: $organizationId } }) {
      id
      donation_type_name
    }
  }
`;

export const GET_DONATION_AMOUNTS = gql`
  query GetDonationAmount($organizationId: uuid = "") {
    donation_amount(where: { organisation_id: { _eq: $organizationId } }) {
      id
      amount
    }
  }
`;

export const GET_URGENT_APPEALS = gql`
  query GetUrgentAppeals($organizationId: uuid = "") {
    urgent_appeals(where: { organisation_id: { _eq: $organizationId } }) {
      images
      title
      id
    }
  }
`;
