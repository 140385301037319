import * as Yup from 'yup';

export const urgentAppealScheme = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  packageId: Yup.string().required('Required Field'),
  targetAmount: Yup.number()
    .typeError('Enter a valid number')
    .required('Required Field'),
  description: Yup.string()
  // redirectUrl: Yup.string().matches(
  //   /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
  //   'Enter correct url'
  // ),
});
