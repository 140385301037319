import { gql, useMutation } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Section from 'components/common/Section';
import paths from 'routes/paths';
import { GET_BRANCH_PROJECTS, PROJECT_STATUS_PROPOSED } from './ProjectsList';
import { useAuthContext } from 'context/authContext';
import { toast } from 'react-toastify';
import ProjectProposalForm from './projectProposal/ProjectProposalForm';
import { parseRouteParams } from 'helpers/utils';
import { GET_PROJECT_MILESTONES } from './ProjectMilestones';

/* -------------------------------------------------------------------------- */
const CREATE_PROJECT = gql`
  mutation CreateProject(
    $status: String!
    $start_date: date!
    $project_name: String!
    $project_description: String!
    $type: String = ""
    $manager_id: uuid = ""
    $target_amount: float8!
    $package_category_id: uuid!
    $num_of_beneficiaries: Int!
    $currency_id: uuid!
    $average_beneficiaries_cost: float8!
    $case_study: String!
    $feasibility_study: String!
    $project_charter: String!
    $end_date: date!
    $organisation_id: uuid!
    $country: String!
    $cities: jsonb!
    $branch_id: uuid!
    $created_by: uuid!
    $parent_id: uuid = null
  ) {
    insert_projects_one(
      object: {
        manager_id: $manager_id
        type: $type
        created_by: $created_by
        project_description: $project_description
        status: $status
        start_date: $start_date
        project_name: $project_name
        target_amount: $target_amount
        package_category_id: $package_category_id
        num_of_beneficiaries: $num_of_beneficiaries
        currency_id: $currency_id
        average_beneficiaries_cost: $average_beneficiaries_cost
        case_study: $case_study
        feasibility_study: $feasibility_study
        project_charter: $project_charter
        end_date: $end_date
        organisation_id: $organisation_id
        country: $country
        cities: $cities
        branch_id: $branch_id
        parent_id: $parent_id
      }
    ) {
      project_name
      project_description
      status
      project_labels {
        id
        label {
          id
          title
        }
      }
      start_date
      target_amount
      id
      created_at
      updated_at
      location_id
      currency_id
      case_study
      feasibility_study
      project_charter
      num_of_beneficiaries
      average_beneficiaries_cost
      manager_id
      package_category_id
      organisation_id
      end_date
      donation_amount
      cities
      country
      branch_id
      parent_id
      type
      created_by
      userByManagerId {
        id
        first_name
        last_name
      }
    }
  }
`;

/* -------------------------------------------------------------------------- */
const CreateProject = () => {
  const navigate = useNavigate();
  const { organization, selectedBranchId, user } = useAuthContext();

  const [searchParams] = useSearchParams();
  const parentId = searchParams.get('parentId');
  const [createProject, { loading }] = useMutation(CREATE_PROJECT, {
    onCompleted: () => {
      if (parentId) {
        navigate(parseRouteParams(paths.projectMilestones, { id: parentId }));
        return;
      }
      navigate(paths.projectsList);
    },
    onError: error => {
      toast.error('Something went wrong! Please try again.');
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingProjects = cache.readQuery({
        query: GET_BRANCH_PROJECTS,
        variables: {
          branchId: selectedBranchId
        }
      });
      const existingMilestones = cache.readQuery({
        query: GET_PROJECT_MILESTONES,
        variables: {
          parentId
        }
      });
      const newProject = data.insert_projects_one;
      if (!existingProjects && !existingMilestones) return;
      if (parentId) {
        cache.writeQuery({
          query: GET_PROJECT_MILESTONES,
          variables: {
            parentId
          },
          data: {
            projects: [newProject, ...existingMilestones.projects]
          }
        });
        return;
      }

      cache.writeQuery({
        query: GET_BRANCH_PROJECTS,
        variables: {
          branchId: selectedBranchId
        },
        data: {
          projects: [newProject, ...existingProjects.projects]
        }
      });
    }
  });

  const onSubmit = data => {
    const formattedStartDate = new Date(data.startDate).toISOString();
    const formattedEndDate = new Date(data.endDate).toISOString();
    createProject({
      variables: {
        status: PROJECT_STATUS_PROPOSED,
        start_date: formattedStartDate,
        project_name: data.title,
        project_description: data.description,
        target_amount: data.estimatedCost,
        manager_id: data.manager,
        type: data.type,
        package_category_id: data.category,
        num_of_beneficiaries: data.numOfBeneficiaries,
        currency_id: data.currency,
        average_beneficiaries_cost: data.averageBeneficiariesCost,
        case_study: data.caseStudy,
        feasibility_study: data.feasibilityStudy,
        project_charter: data.projectCharter,
        end_date: formattedEndDate,
        organisation_id: organization.id,
        country: data.country,
        cities: data.cities,
        branch_id: selectedBranchId,
        created_by: user.id,
        parent_id: parentId
      }
    });
  };
  return (
    <Section
      className={'py-0 mx-auto font-sans-serif '}
      style={{ maxWidth: '820px' }}
    >
      <ProjectProposalForm onSubmit={onSubmit} loading={loading} />
      {/* <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <PrimaryInfo
          countriesOptions={countriesOptions}
          citiesOptions={citiesOptions}
        />
        <ProjectDocumentation methods={methods} />
        <Card>
          <Card.Body>
            <Flex
              justifyContent={'between'}
              className="flex-column flex-md-row align-items-center"
            >
              <p className="mb-3 mb-md-0 fs-2 fw-medium">Proposed Project</p>
              <LoadingButton loading={loading} type="submit">
                Submit
              </LoadingButton>
            </Flex>
          </Card.Body>
        </Card>
      </FormProvider> */}
    </Section>
  );
};

export default CreateProject;
