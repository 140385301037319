import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import LoadingContainer from 'components/common/LoadingContainer';
import { Route, Routes, useParams } from 'react-router-dom';
import ProjectCompletionReport from './ProjectCompletionReport';
import CreateProjectCompletion from './CreateProjectCompletion';
import paths from 'routes/paths';
import { getRelativePath } from 'helpers/utils';
import EditProjectCompletion from './EditProjectCompletion';
import PermissionWrapper from 'routes/PermissionWrapper';
import { projectRoutes } from 'routes/routes';

export const GET_PROJECT_COMPLETION_QUERY = gql`
  query GetProjectCompletion($projectId: uuid = "") {
    project_completion(
      where: { project_id: { _eq: $projectId } }
      order_by: { created_at: desc }
      limit: 1
    ) {
      id
      completion_date
      no_of_beneficiary
      cities
      currency_id
      project_cost
      administration_cost
      created_at
      updated_at
      project_id
      video_url
      files
      feedback
      country
      status
      admin_comment
      project {
        id
        project_name
        start_date
      }
      user {
        first_name
        last_name
      }
    }
  }
`;

export default function ProjectCompleteRoutes() {
  const { id } = useParams();

  return (
    <Query query={GET_PROJECT_COMPLETION_QUERY} variables={{ projectId: id }}>
      {({ loading, error, data }) => {
        if (loading) return <LoadingContainer />;
        if (error) return `Error! ${error.message}`;
        if (!data.project_completion.length) {
          return (
            <Routes>
              <Route
                index
                // path={getRelativePath(
                //   paths.createCompleteReport,
                //   paths.completeProject
                // )}
                element={<CreateProjectCompletion />}
              />
              <Route
                path={getRelativePath(
                  paths.editCompleteReport,
                  paths.completeProject
                )}
                element={
                  <PermissionWrapper
                    permissionKey={projectRoutes.key}
                    type={'edit'}
                  >
                    <EditProjectCompletion data={data.project_completion[0]} />
                  </PermissionWrapper>
                }
              />
            </Routes>
          );
        }
        return (
          <Routes>
            <Route
              index
              element={
                <ProjectCompletionReport data={data.project_completion[0]} />
              }
            />
            <Route
              path={getRelativePath(
                paths.editCompleteReport,
                paths.completeProject
              )}
              element={
                <EditProjectCompletion data={data.project_completion[0]} />
              }
            />
          </Routes>
        );
      }}
    </Query>
  );
}
