import React, { useState } from 'react';
import Avatar from 'components/common/Avatar';
import SoftBadge from 'components/common/SoftBadge';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import DonorSponsorshipModal from 'components/donor-details/modals/DonorSponsorshipModal';

const SponsoredCardLayout = ({
  data,
  isSelectedItem,
  toggleSelectedItem,
  showCheckbox = false
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [orphanId, setOrphanId] = useState(null);
  return (
    <>
      <Row className="g-3">
        {data?.map(item => (
          <Col key={item.id} lg={3} md={4} sm={6}>
            <Card
              onClick={() => {
                setOrphanId(item.id);
                setModalShow(true);
              }}
              className="cursor-pointer"
            >
              <Card.Body style={{ padding: 12 }}>
                {showCheckbox && (
                  <Form.Check
                    type="checkbox"
                    id="inboxBulkSelect"
                    className="fs-0 form-check me-2 me-xxl-3 mb-0"
                  >
                    <Form.Check.Input
                      type="checkbox"
                      checked={isSelectedItem('ticket.id')}
                      onChange={() => toggleSelectedItem('ticket.id')}
                    />
                  </Form.Check>
                )}
                <Flex
                  justifyContent="between"
                  alignItems="center"
                  className={'mb-2'}
                >
                  <h6 className="mb-0">ID: {item.id}</h6>
                  <SoftBadge
                    pill
                    bg={item.typeColor}
                    className="align-self-center fs--2"
                  >
                    <FontAwesomeIcon
                      icon={item.typeIcon || ''}
                      className="me-1"
                    />{' '}
                    {item.type}
                  </SoftBadge>
                </Flex>
                <div className="text-center">
                  <Avatar
                    src={item.image}
                    name={item.name}
                    rounded="circle"
                    size="4xl"
                    className="border border-5 border-white rounded-circle shadow-sm mb-2"
                  />
                  <Button
                    variant="link"
                    className="d-block fs--1 p-0 fw-medium mx-auto"
                    onClick={() => setModalShow(true)}
                  >
                    {item.name}
                  </Button>
                  <p className="mb-0 fs--2 text-500 font-base">
                    {item.gender}, Age {item.age}, {item.location}
                  </p>
                </div>
                <hr className="text-200 my-2" />
                {item?.priority ? (
                  <Flex justifyContent={'between'} alignItems="center">
                    <p className="mb-0 fs--2 fw-medium">Priority:</p>
                    <p className={`${item.priorityColor} mb-0 fs--2 fw-medium`}>
                      {item?.priority}
                    </p>
                  </Flex>
                ) : (
                  <Flex justifyContent={'between'} alignItems="center">
                    <p className="mb-0 fs--2 fw-medium">Sponsor:</p>
                    <p className="mb-0 fs--2 fw-medium">{item.sponsor}</p>
                  </Flex>
                )}
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      {modalShow && (
        <DonorSponsorshipModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          orphanId={orphanId}
        />
      )}
    </>
  );
};

SponsoredCardLayout.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isSelectedItem: PropTypes.func,
  toggleSelectedItem: PropTypes.func,
  showCheckbox: PropTypes.bool
};

export default SponsoredCardLayout;
