import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GET_CONTACT, GET_SCHOOL_BY_ID } from '../queries';
import { useMutation, useQuery } from '@apollo/client';
import SchoolForm from './SchoolForm';
import {
  INSERT_CONTACT,
  UPDATE_CONTACT,
  UPDATE_SCHOOL_BY_ID
} from '../mutations';
import { toast } from 'react-toastify';
import ContactForm from './ContactForm';
import useUpload from 'hooks/useUpload';

function EditSchool() {
  const { upload, loading: uploadLoading } = useUpload();
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: schoolData } = useQuery(GET_SCHOOL_BY_ID, {
    variables: {
      id
    }
  });

  const { data: contactData } = useQuery(GET_CONTACT, {
    variables: {
      where: {
        school_id: { _eq: id }
      }
    }
  });

  const [updateSchool, { loading }] = useMutation(UPDATE_SCHOOL_BY_ID, {
    onCompleted: () => {
      toast.success('School Updated Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const [updateContact, { loading: updateContactLoading }] = useMutation(
    UPDATE_CONTACT,
    {
      onCompleted: () => {
        toast.success('Contact Updated Successfully');
        navigate(`/school/${id}/details`);
      },
      onError: error => {
        toast.error('Something went wrong');
        console.error(error);
      }
    }
  );

  const [insertContact, { loading: insertContactLoading }] = useMutation(
    INSERT_CONTACT,
    {
      onCompleted: () => {
        toast.success('Contact Added Successfully');
        navigate(`/school/${id}/details`);
      },
      onError: error => {
        toast.error('Something went wrong');
        console.error(error);
      }
    }
  );

  const onSubmit = async values => {
    if (values.school_document && typeof values.school_document !== 'string') {
      const school_document = await upload(values.school_document);
      values.school_document = school_document;
    }

    updateSchool({
      variables: {
        ...values,
        id,
        school_address: values.address,
        school_city: values.city,
        school_email: values.email,
        school_name: values.schoolName,
        serial_number: values.serialNumber,
        school_number: values.contact,
        school_headteacher: values.schoolHeadTeacher
      }
    });
  };

  const submitContact = values => {
    if (contactData?.contact?.length !== 0) {
      updateContact({
        variables: {
          ...values,
          where: { school_id: { _eq: id } }
        }
      });
    } else {
      insertContact({
        variables: {
          object: {
            ...values,
            school_id: id
          }
        }
      });
    }
  };

  return (
    <>
      <SchoolForm
        initValues={schoolData?.schools_by_pk}
        onSubmit={onSubmit}
        loading={loading || uploadLoading}
      />

      <ContactForm
        initValues={contactData?.contact}
        onSubmit={submitContact}
        loading={updateContactLoading || insertContactLoading}
      />
    </>
  );
}

export default EditSchool;
