import { FormProvider, RHFControl, RHFSelect } from 'components/common/form';
import React from 'react';
import { Card } from 'react-bootstrap/esm';
import { useForm } from 'react-hook-form';
import { smtpResolver } from './smtpScheme';
import { Button, Col, Row } from 'react-bootstrap';

function SMTPSettings() {
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      outGoingEmail: '',
      emailSenderName: '',
      emailSendingMethod: '',
      smtpHost: '',
      smtpPort: '',
      smtpSecurity: '',
      authType: '',
      smtpUsername: '',
      smtpPassword: '',
      testEmail: ''
    },
    resolver: smtpResolver
  });
  const { handleSubmit } = methods;
  const onSubmit = () => {};
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col sm={12} md={8}>
          <div className="d-flex flex-column gap-3">
            <Card>
              <Card.Body>
                <div className="d-flex flex-column gap-3">
                  <h5>E-Mail</h5>
                  <RHFControl
                    label={'Out Going Email Address '}
                    name={'outGoingEmail'}
                    placeholder={'Out Going Email Address'}
                    infoText={
                      'This is the email address that will be used to send emails to your customers.'
                    }
                  />
                  <RHFControl
                    label={'E-Mail Sender Name'}
                    name={'emailSenderName'}
                    placeholder={'E-Mail Sender Name'}
                    infoText={
                      'This is the name that will be used to send emails to your customers.'
                    }
                  />
                </div>
              </Card.Body>
            </Card>
            <Card>
              <Card.Body>
                <div className="d-flex flex-column gap-3">
                  <h5>E-Mail System Configuration</h5>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={8} sm={12}>
                      <h6 className="mb-0">Email Sending Method</h6>
                    </Col>
                    <Col md={4} sm={12}>
                      <RHFSelect name={'emailSendingMethod'} />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={8} sm={12}>
                      <h6 className="mb-0">SMTP Hostname</h6>
                    </Col>
                    <Col md={4} sm={12}>
                      <RHFControl
                        name={'smtpHost'}
                        placeholder={'SMTP Hostname'}
                      />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={8} sm={12}>
                      <h6 className="mb-0">SMTP Port</h6>
                    </Col>
                    <Col md={4} sm={12}>
                      <RHFControl name={'smtpHost'} placeholder={'SMTP Port'} />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={8} sm={12}>
                      <h6 className="mb-0">SMTP Security</h6>
                    </Col>
                    <Col md={4} sm={12}>
                      <RHFSelect name={'smtpSecurity'} />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={8} sm={12}>
                      <h6 className="mb-0">Authentication Type</h6>
                    </Col>
                    <Col md={4} sm={12}>
                      <RHFSelect name={'authType'} />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={8} sm={12}>
                      <h6 className="mb-0">SMTP Username</h6>
                    </Col>
                    <Col md={4} sm={12}>
                      <RHFSelect name={'smtpUsername'} />
                    </Col>
                  </Row>
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={8} sm={12}>
                      <h6 className="mb-0">SMTP Password</h6>
                    </Col>
                    <Col
                      md={4}
                      sm={12}
                      className="d-flex gap-2 justify-content-between align-items-center"
                    >
                      <h6 className="mb-0">D**************</h6>
                      <Button
                        variant="link"
                        size={'sm'}
                        className="text-secondary"
                      >
                        Change
                      </Button>
                      {/* <RHFControl name={'smtpHost'} /> */}
                    </Col>
                  </Row>
                  <hr />
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={7} sm={12}>
                      <h6 className="mb-0">SMTP Password</h6>
                    </Col>
                    <Col
                      md={5}
                      sm={12}
                      className="d-flex gap-2 justify-content-between align-items-center"
                    >
                      <RHFControl
                        name={'testEmail'}
                        placeholder="Test Email Address"
                      />
                      <Button variant="secondary" className="h-100">
                        Send
                      </Button>
                      {/* <RHFControl name={'smtpHost'} /> */}
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </FormProvider>
  );
}

export default SMTPSettings;
