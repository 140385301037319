import { gql, useMutation, useQuery } from '@apollo/client';
import OwnerRegistrationForm from 'components/authenitcation/registration/OwnerRegistrationForm';
import AuthContext from 'context/authContext';
import { useMemo, useContext } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ChangePassword from './ChangePassword';

const UPDATE_USER = gql`
  mutation UpdateUser(
    $id: uuid = ""
    $email: String = ""
    $first_name: String = ""
    $last_name: String = ""
    $phone: String = ""
    $title: String = ""
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: {
        email: $email
        first_name: $first_name
        last_name: $last_name
        phone: $phone
        title: $title
      }
    ) {
      created_at
      email
      email_verified_at
      first_name
      id
      last_name
      organisation_id
      password
      phone
      remember_token
      title
      updated_at
    }
  }
`;

const GET_USER = gql`
  query GetUser($id: uuid!) {
    users_by_pk(id: $id) {
      created_at
      email
      email_verified_at
      first_name
      id
      last_name
      organisation_id
      password
      phone
      remember_token
      title
      updated_at
    }
  }
`;

export default function PersonalSettings() {
  const { user } = useContext(AuthContext);
  const { data, loading: dataLoading } = useQuery(GET_USER, {
    variables: { id: user.id }
  });
  const [updateUser, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => {
      toast.success('Profile details updated successfully');
    },
    onError: ({ graphQLErrors }) => {
      toast.error(graphQLErrors[0].message);
      console.log(graphQLErrors);
    }
  });
  const onSubmit = ({
    title,
    firstName,
    lastName,
    phoneNumber,
    email
    // companyEmail
  }) => {
    updateUser({
      variables: {
        id: user.id,
        title,
        first_name: firstName,
        last_name: lastName,
        phone: phoneNumber,
        email
      }
    });
  };
  const initialValues = useMemo(() => {
    if (data) {
      const {
        title,
        first_name: firstName,
        last_name: lastName,
        phone,
        email
      } = data.users_by_pk;
      return {
        title,
        firstName,
        lastName,
        phoneNumber: phone,
        confirmPhoneNumber: phone,
        email
      };
    }
    return {};
  }, [data]);
  return dataLoading ? (
    <div className="d-flex justify-content-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <Container fluid className="px-0">
      <Row>
        <Col sm={12} md={7} className="d-flex flex-column gap-3">
          <Card>
            <Card.Header>
              <h4>Personal Settings</h4>
            </Card.Header>
            <Card.Body>
              <OwnerRegistrationForm
                loading={updateLoading}
                onSubmit={onSubmit}
                initialValues={initialValues}
                isEdit
              />
            </Card.Body>
          </Card>
          <Card>
            <Card.Header>
              <h4>Change Password</h4>
            </Card.Header>
            <Card.Body>
              <ChangePassword />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
