import PropTypes from 'prop-types';
import { Button, Card, Col, Image, Row } from 'react-bootstrap';
import image0 from 'assets/img/we-raise-images/front-end/cart_image_0.png';

import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { stages } from './homeData';
import { useContext, useEffect, useState } from 'react';
import DonationContext from 'context/DonationContextProvider';
import { useParams } from 'react-router-dom';
import { GET_URGENT_APPEALS } from './queries';
import { useQuery } from '@apollo/client';

const Page2 = ({ setStage }) => {
  const { setUrgentAppeal } = useContext(DonationContext);
  const { organizationId } = useParams();

  const { data: urgentAppealData } = useQuery(GET_URGENT_APPEALS, {
    variables: {
      organizationId
    }
  });

  const [urgentAppealDataOptions, setUrgentAppealDataOptions] = useState([]);
  useEffect(() => {
    setUrgentAppealDataOptions(urgentAppealData?.urgent_appeals);
  }, [urgentAppealData]);

  const addUrgentAppeal = project => {
    setUrgentAppealDataOptions(
      urgentAppealDataOptions.map(item => {
        return item.id === project.id ? { ...item, selected: true } : item;
      })
    );
  };

  const removergentAppeal = project => {
    setUrgentAppealDataOptions(
      urgentAppealDataOptions.map(item => {
        return item.id === project.id ? { ...item, selected: false } : item;
      })
    );
  };

  return (
    <Card className="shadow-none border mb-6">
      <Card.Header className="text-center">
        <p className="fs-0 fw-bold text-uppercase">Urgent appeals </p>
        <p className="mb-0">
          Would you like to add any of our current appeals to the basket
        </p>
      </Card.Header>
      <Card.Body>
        <Row className="g-3 overflow-auto" style={{ maxHeight: '550px' }}>
          {urgentAppealDataOptions?.map(project => (
            <Col
              key={project.id}
              className="d-flex col-xxs"
              xs={6}
              sm={4}
              md={3}
            >
              <div className="border p-2 rounded-2 d-flex flex-column justify-content-between">
                <Image fluid className="w-100" src={project.image || image0} />
                <Flex
                  justifyContent="between"
                  alignItems="center"
                  className="mt-2"
                >
                  <p className="mb-0 fw-medium fs--2">{project.title}</p>
                  <p className="mb-0 fw-medium fs--2">{'10$'}</p>
                </Flex>
                <IconButton
                  icon="user"
                  className="fs--2 w-100 bg-soft-primary text-primary border-0 mt-2 hover-bg-primary hover-100"
                  iconClassName="me-1"
                  onClick={() =>
                    project.selected
                      ? removergentAppeal(project)
                      : addUrgentAppeal(project)
                  }
                >
                  {project.selected ? 'Remove from cart' : 'Add To Cart'}
                </IconButton>
              </div>
            </Col>
          ))}
        </Row>
        <Flex
          justifyContent="between"
          alignItems="center"
          className={'mt-4 flex-column flex-md-row'}
        >
          <Button
            variant="default"
            className="bg-soft-secondary h-52 hover-bg-primary hover-100 w-215px"
            onClick={() => {
              setStage(stages.Donation1);
            }}
          >
            Back
          </Button>
          <Button
            className="h-52 w-215px mt-3 mt-md-0"
            onClick={() => {
              setStage(stages.Donation3);
              setUrgentAppeal(
                urgentAppealDataOptions.filter(item => item.selected === true)
              );
            }}
          >
            Skip
          </Button>
        </Flex>
      </Card.Body>
    </Card>
  );
};

Page2.propTypes = {
  setStage: PropTypes.func.isRequired
};

export default Page2;
