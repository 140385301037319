import React, { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadingButton from 'components/common/LoadingButton';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FormProvider,
  RHFControl,
  RHFDatePicker
} from 'components/common/form';
import Flex from 'components/common/Flex';

const schema = Yup.object().shape({
  comment: Yup.string().required('Required Field'),
  hosptial: Yup.string().required('Required Field'),
  inspectionDate: Yup.date().required('Required Field'),
  reviewDate: Yup.date().required('Required Field')
});
function AddMedicalReportModal({
  show,
  onSubmit,
  onHide,
  title,
  loading,
  initValues
}) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      hosptial: '',
      comment: '',
      inspectionDate: '',
      reviewDate: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  useEffect(() => {
    if (initValues) {
      const reviewDate = new Date(initValues.review_date);
      const inspectionDate = new Date(initValues.inspection_date);
      reset({
        hosptial: initValues.hosptial,
        inspectionDate,
        comment: initValues.medical_note,
        reviewDate
      });
    }
  }, [initValues]);

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-header-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3 ps-3 pe-3">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
          >
            <Flex gap={3} direction="column" className="mt-3">
              <Row>
                <Col md={12}>
                  <RHFControl
                    label={'Hospital'}
                    name="hosptial"
                    placeholder="hosptial"
                    required={true}
                  />
                </Col>
                <Col md={12}>
                  <RHFControl
                    label={'Comment'}
                    name="comment"
                    placeholder="comment"
                    type="text"
                    as="textarea"
                    rows={5}
                    required={true}
                  />
                </Col>
                <Col md={6}>
                  <RHFDatePicker
                    label={'Inspection Date'}
                    name="inspectionDate"
                    type="date"
                    required={true}
                  />
                </Col>
                <Col md={6}>
                  <RHFDatePicker
                    label={'Review Date'}
                    name="reviewDate"
                    type="date"
                    required={true}
                  />
                </Col>
              </Row>
              <LoadingButton
                style={{ width: '100%', color: 'white' }}
                variant="primary"
                loading={loading}
                disabled={loading}
                type={'submit'}
              >
                {'Save'}
              </LoadingButton>
            </Flex>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}
AddMedicalReportModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool,
  initValues: PropTypes.obj
};
export default AddMedicalReportModal;
