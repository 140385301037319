import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Table } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  rowClickEvent,
  isEditable,
  isAdd,
  editId,
  handleRowChange,
  handleSave
}) => {
  return (
    <SimpleBarReact>
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={bodyClassName}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr key={i} className={rowClassName} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      onClick={
                        rowClickEvent
                          ? e => rowClickEvent(e, row?.original)
                          : null
                      }
                      style={{
                        cursor: rowClickEvent ? 'pointer' : 'default'
                      }}
                      {...cell.getCellProps(cell.column.cellProps)}
                    >
                      {isEditable &&
                      parseInt(cell.row.index) === parseInt(editId) ? (
                        cell.column.id === 'status' ? (
                          <Button size="sm" onClick={handleSave}>
                            {isAdd ? 'Add' : 'Save'}
                          </Button>
                        ) : cell.value !== undefined ? (
                          <input
                            style={{
                              border: 'none',
                              width: '100px'
                            }}
                            value={cell.value}
                            type="text"
                            onFocus={e => {
                              if (cell.column.id === 'donation_date') {
                                e.target.type = 'date';
                                e.target.value = new Date(cell.value);
                                e.target.click();
                              }
                            }}
                            onBlur={e => {
                              if (cell.column.id === 'donation_date') {
                                e.target.type = 'text';
                                e.target.value = cell.value;
                              }
                            }}
                            onChange={e =>
                              handleRowChange(
                                parseInt(editId),
                                cell.column.id,
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          cell.render('Cell')
                        )
                      ) : (
                        cell.render('Cell')
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  rowClickEvent: PropTypes.func,
  isEditable: PropTypes.bool,
  isAdd: PropTypes.bool,
  editId: PropTypes.bool,
  handleRowChange: PropTypes.func,
  handleSave: PropTypes.func
};

export default AdvanceTable;
