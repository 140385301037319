import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import AppContext from 'context/Context';
import React, { useContext } from 'react';
import { Button, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AuthContext from 'context/authContext';
// import { useNavigate } from 'react-router-dom';
// import paths from 'routes/paths';
import {
  // faCodeBranch,

  faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

const TopNavRightSideNavItem = () => {
  const {
    config: { isDark, isRTL },
    setConfig
  } = useContext(AppContext);
  const {
    logout
    // removeBranchSelection
  } = useContext(AuthContext);
  // const navigate = useNavigate();

  return (
    <Nav
      navbar
      className="navbar-nav-icons flex-row align-items-center"
      as="ul"
    >
      <Nav.Item as={'li'}>
        <Nav.Link
          className="px-2 theme-control-toggle"
          onClick={() => setConfig('isDark', !isDark)}
        >
          <OverlayTrigger
            key="left"
            placement={isRTL ? 'bottom' : 'left'}
            overlay={
              <Tooltip id="ThemeColor">
                {isDark ? 'Switch to light theme' : 'Switch to dark theme'}
              </Tooltip>
            }
          >
            <div className="theme-control-toggle-label">
              <FontAwesomeIcon
                icon={isDark ? 'sun' : 'moon'}
                className="fs-0"
              />
            </div>
          </OverlayTrigger>
        </Nav.Link>
      </Nav.Item>

      {/* <SettingMenu /> */}
      {/* <NotificationDropdown /> */}
      <ProfileDropdown />
      {/* <Button
        onClick={() => {
          navigate(paths.branchSelection);
          removeBranchSelection();
        }}
        variant="link"
        className="nav-link px-2"
      >
        <FontAwesomeIcon icon={faCodeBranch} className="fs-1" />
      </Button> */}
      <Button onClick={logout} variant="link" className="nav-link px-2">
        <FontAwesomeIcon icon={faSignOutAlt} className="fs-1 text-danger" />
      </Button>
    </Nav>
  );
};

export default TopNavRightSideNavItem;
