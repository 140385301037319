import { Spinner } from 'react-bootstrap';
import Flex from './Flex';
import Logo from './Logo';

function LoadingScreen() {
  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh', width: '100vw' }}
    >
      <Logo width={50} />
      <Spinner size="xl" className="mt-3" variant="primary" />
    </Flex>
  );
}

export default LoadingScreen;
