import React from 'react';
import { Card } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import ProductShareChart from './ProductShareChart';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';

const ProductShare = props => {
  const {
    bgColor,
    title = 'Target Donations',
    target = '55%',
    changes = 3.5,
    current = '34.6%',
    chartData,
    labels
  } = props;
  return (
    <Card className="h-md-100">
      <FalconCardHeader
        title={title}
        light={false}
        titleTag="h6"
        className="pb-0"
        // endEl={<CardDropdown />}
      />

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <h2 className="mb-1 text-700 fw-normal lh-1 fs-2">{current}</h2>
          <SoftBadge
            pill
            bg={changes > 5 ? 'success' : 'warning'}
            className="me-2 fs--2"
          >
            <FontAwesomeIcon
              icon={`caret-${changes > 5 ? 'up' : 'down'}`}
              className="me-1"
            />
            {changes}%
          </SoftBadge>
        </div>
        <div>
          <div className="my-n5">
            <ProductShareChart
              bgColor={bgColor}
              chartData={chartData}
              labels={labels}
            />
          </div>
          <p className="mb-0 mt-4 text-center fs--2 text-500">
            Target: <span className="text-800">{target}</span>
          </p>
        </div>
      </Card.Body>
    </Card>
  );
};

ProductShare.propTypes = {
  bgColor: PropTypes.string,
  title: PropTypes.string,
  target: PropTypes.string,
  changes: PropTypes.number,
  current: PropTypes.string,
  chartData: PropTypes.array,
  labels: PropTypes.array
};

export default ProductShare;
