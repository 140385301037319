import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import Flex from 'components/common/Flex';
import { FormProvider, RHFControl } from 'components/common/form';
import LoadingButton from 'components/common/LoadingButton';

const schema = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  link: Yup.string().required('Required Field'),
  description: Yup.string().required('Required Field')
});

function AddLinkFormModal({ show, onSubmit, onHide }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;
  const onSubmitHandle = data => {
    onSubmit(data);
    reset();
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add ShortCut</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitHandle)}>
          <Flex gap={3} direction="column" className="mt-1">
            <RHFControl
              name="title"
              placeholder="Title"
              label={'Title'}
              required={true}
            />
            <RHFControl
              name="description"
              placeholder="Description"
              label={'Description'}
              required={true}
            />
            <RHFControl
              name="link"
              placeholder="Link"
              label={'Link'}
              required={true}
            />
            <LoadingButton type="submit" color="primary" className="w-100">
              Add
            </LoadingButton>
          </Flex>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
}

AddLinkFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AddLinkFormModal;
