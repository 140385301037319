import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, CloseButton, Col, Image, Modal, Row } from 'react-bootstrap';
import MonthlyDonations from './MonthlyDonations';
import MarketShare from './MarketShare';
import { gql, useLazyQuery } from '@apollo/client';
import userConfig from 'assets/img/we-raise-images/user-cog.png';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import AuthContext from 'context/authContext';
import {
  weAddWedgitData,
  weGreetingItems,
  weOrganizerOptions,
  weShoppingCartItems,
  weWeeklySalesData
} from 'data/weraise-data/dashboard-data';
import useBranchPermission from 'hooks/useBranchPermission';
import Select from 'react-select';
import Greetings from '../we-raise-common/Greetings';
import ProductShare from '../we-raise-common/ProductShare/ProductShare';
import ShoppingCart from '../we-raise-common/ShoppingCart';
import TotalStat from './TotalStat';
import NewDonors from './NewDonors';

const GET_ORGANIZATION_ORDERS = gql`
  query GetOrders($where: orders_bool_exp = {}) {
    orders(where: $where, order_by: { created_at: desc }) {
      payment_status
      donation_amount
      created_at
      branch_id
      id
      organisation_id
      payment_method_id
      donar {
        first_name
        last_name
        email
        id
      }
      order_details {
        package {
          package_title
        }
      }
    }
    orders_aggregate(where: $where) {
      aggregate {
        count
        sum {
          donation_amount
        }
      }
    }
  }
`;

export const columns = [
  {
    accessor: 'name',
    Header: 'Donar',
    cellProps: {
      className: 'py-2'
    }
  },
  {
    accessor: 'email',
    Header: 'Email',
    cellProps: {
      className: 'py-2'
    },
    Cell: rowData => {
      const { email } = rowData.row.original;
      return <a href={'mailto:' + email}>{email}</a>;
    }
  },
  {
    accessor: 'description',
    Header: 'Donation Description',
    cellProps: {
      className: 'fw-medium py-2'
    }
  },
  {
    accessor: 'score',
    Header: 'Donor Score',
    cellProps: {
      className: 'fw-medium py-2'
    },
    Cell: rowData => {
      return (
        <SoftBadge
          pill
          // bg={rowData.row.original.variant}
          className="me-2"
        >
          {rowData.row.original.score}
        </SoftBadge>
      );
    }
  },
  {
    accessor: 'amount',
    Header: 'Amount',
    cellProps: {
      className: 'py-2'
    }
  }
  // {
  //   accessor: 'id',
  //   Header: '',
  //   cellProps: {
  //     className: 'py-2 text-end'
  //   },
  //   disableSortBy: true,

  //   Cell: rowData => {
  //     const { id } = rowData.row;
  //     return (
  //       <TableRowAction
  //         onCLickEdit={() => console.log(id)}
  //         onCLickDelete={() => console.log(id)}
  //       />
  //     );
  //   }
  // }
];

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const { where } = useBranchPermission();
  const [getOrder, { data: orderData, loading: ordersLoading }] = useLazyQuery(
    GET_ORGANIZATION_ORDERS
  );
  useEffect(() => {
    getOrder({ variables: { where } });
  }, [getOrder, where]);

  const [modalShow, setModalShow] = useState(false);
  const [value, setValue] = useState(null);
  const tableData = useMemo(() => {
    return orderData?.orders.map(order => {
      // setTotalDonation(totalDonation + order.donation_amount ?? 0);
      let primaryEmail = '';
      if (order.donar.email.length) {
        primaryEmail =
          order.donar.email?.find(email => email.isPrimary)?.email ?? '';
      }
      return {
        name: `${order.donar.first_name} ${order.donar.last_name}`,
        email: primaryEmail,
        description:
          order.order_details.length !== 0
            ? order.order_details[0].package.package_title
            : '',
        score: 0,
        variant: 'success',
        amount: order.donation_amount,
        id: order.id
      };
    });
  }, [orderData]);

  return (
    <>
      {/* <TestScheduler /> */}
      <Row className="g-3 mb-3">
        <Col xs={12}>
          <Greetings
            data={weGreetingItems}
            showBg={false}
            name={user.first_name}
          />
        </Col>
        {/* <Col xs={12}>
          <GreetingCard notifications={weNotificationsDashBoard} />
        </Col> */}
      </Row>

      <Row className="g-3">
        <Col>
          <TotalStat />
        </Col>
      </Row>

      <Row className="g-3 mb-3">
        <Col lg={9}>
          <Row className="g-3">
            <Col sm={6} xl={4}>
              <MonthlyDonations
                data={weWeeklySalesData}
                title="Monthly Donations"
                tooltipText="Calculated according to last week's sales"
              />
            </Col>

            <Col sm={6} xl={4}>
              <ProductShare fontSize="fs-2" />
            </Col>

            <Col sm={6} xl={4}>
              <ProductShare
                fontSize="fs-2"
                bgColor="success"
                labels={['Active', 'Inactive']}
                chartData={[80, 20]}
                target="60%"
                title="Active Donors"
                current="46%"
                changes={5.5}
              />
            </Col>

            <Col sm={6} xl={4}>
              <MarketShare
                title="Most Leads"
                radius={['100%', '80%']}
                showPercentage={false}
              />
            </Col>

            <Col sm={6} xl={4}>
              <NewDonors font="fs-2" />
            </Col>
          </Row>
        </Col>

        <Col lg={3} className="h-md-100">
          <ShoppingCart data={weShoppingCartItems} title="Projects Progress" />
        </Col>
      </Row>

      <Row>
        <Col className="h-md-100">
          {tableData && (
            <CustomAdvanceTable
              data={tableData ?? []}
              columns={columns}
              loading={ordersLoading}
              title="Recent Donations"
            />
          )}
        </Col>
      </Row>

      {modalShow && (
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header className="border-0">
            <Modal.Title id="contained-modal-title-vcenter">
              Add Wedgit
            </Modal.Title>

            <CloseButton
              className="btn btn-circle btn-sm transition-base p-0"
              onClick={() => setModalShow(false)}
            />
          </Modal.Header>
          <Modal.Body className="pt-0">
            <div className="w-md-33">
              <label
                className="fs--1 fw-medium"
                id="aria-label"
                htmlFor="aria-example-input"
              >
                Category
              </label>
              <Select
                options={weOrganizerOptions}
                placeholder="Select..."
                classNamePrefix="react-select"
                value={value}
                onChange={value => setValue(value)}
              />
            </div>

            <hr />

            <Row style={{ maxHeight: 400 }} className="overflow-auto g-3 ">
              {weAddWedgitData?.map(data => (
                <Col md={6} key={data.id}>
                  <Button
                    className="cursor-pointer bg-transparent border p-3 rounded-3 text-start"
                    onClick={() => console.log(data.id)}
                  >
                    <Flex alignItems="center">
                      <Image
                        src={userConfig}
                        className="me-3"
                        height={77}
                        width={77}
                      />
                      <div>
                        <p className="mb-1 text-1100 fw-medium">{data.title}</p>
                        <p className="fs--1 mb-0 text-800">{data.details}</p>
                      </div>
                    </Flex>
                  </Button>
                </Col>
              ))}
            </Row>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Dashboard;
