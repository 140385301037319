import { useContext, useMemo, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RHFSelect } from 'components/common/form';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';
import StatusModal from './StatusModal';
import {
  GET_SPONSORSHIP_PRIORITY,
  GET_SPONSORSHIP_STATUS
} from './beneficiary-options-queries';
import PriorityModal from './PriorityModal';

export const SponsorshipStatus = ({ sectionRef, sideNavLink }) => {
  const { organization } = useContext(AuthContext);

  const { data: statusData, loading: statusLoadingLoading } = useQuery(
    GET_SPONSORSHIP_STATUS,
    {
      fetchPolicy: 'network-only',
      variables: {
        organizationId: organization.id
      }
    }
  );

  const { data: priorityData, loading: priorityLoading } = useQuery(
    GET_SPONSORSHIP_PRIORITY,
    {
      fetchPolicy: 'network-only',
      variables: {
        organizationId: organization.id
      }
    }
  );

  const statusOptions = useMemo(
    () =>
      statusData?.sponsorship_status?.map(({ id, status }) => ({
        value: id,
        label: status
      })),
    [statusData]
  );

  const priorityOptions = useMemo(
    () =>
      priorityData?.sponsorship_priority?.map(priority => ({
        value: priority.id,
        label: priority.priority_type
      })),
    [priorityData]
  );

  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showPriorityModal, setShowPriorityModal] = useState(false);

  const openStatusModal = () => {
    setShowStatusModal(true);
  };
  const closeStatusModal = () => {
    setShowStatusModal(false);
  };
  const openPriorityModal = () => {
    setShowPriorityModal(true);
  };
  const closePriorityModal = () => {
    setShowPriorityModal(false);
  };

  return (
    <>
      {showStatusModal && (
        <StatusModal show={showStatusModal} handleClose={closeStatusModal} />
      )}

      {showPriorityModal && (
        <PriorityModal
          show={showPriorityModal}
          handleClose={closePriorityModal}
        />
      )}

      <Card
        className="border shadow-none mb-3"
        ref={sectionRef}
        id={sideNavLink}
      >
        <Card.Header className="pb-0">
          <div className="dFlexRowStartSpace pt-3">
            <Card.Title as="h5">Sponsorship Status</Card.Title>
            <Button
              variant="light"
              className="shadow-none bg-transparent border-0"
              style={{
                fontSize: 13
              }}
              as={Link}
              to={paths.sponsorshipValues}
            >
              <FontAwesomeIcon icon="pen" style={{ marginRight: '5px' }} />
              Edit
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Row className="gx-3">
            <Col xs={12} sm={6} className="pt-3">
              <RHFSelect
                name="status"
                label="Status"
                options={statusOptions}
                required
                hasAddOption
                onClickAdd={openStatusModal}
                loading={statusLoadingLoading}
              />
            </Col>
            <Col xs={12} sm={6} className="pt-3">
              <RHFSelect
                name="priority"
                label="Priority"
                options={priorityOptions}
                required
                hasAddOption
                onClickAdd={openPriorityModal}
                loading={priorityLoading}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

SponsorshipStatus.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
