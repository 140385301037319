import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFControl, RHFSelect } from 'components/common/form';
import { projectStatusOptions } from '../ProjectsList';
import { toast } from 'react-toastify';

/* -------------------------------------------------------------------------- */
const feedbackScheme = Yup.object().shape({
  firstAuthentication: Yup.string().required('Required'),
  secondAuthentication: Yup.string().required('Required'),
  status: Yup.string().required('Required'),
  comments: Yup.string()
});
/* -------------------------------------------------------------------------- */
const CREATE_PROJECT_FEEDBACK_MUTATION = gql`
  mutation CreateProjectFeedback(
    $feedback: String = ""
    $first_auth: String = ""
    $second_auth: String = ""
    $status: String = ""
    $project_id: uuid = ""
  ) {
    insert_project_feedback_one(
      object: {
        feedback: $feedback
        first_auth: $first_auth
        second_auth: $second_auth
        status: $status
        project_id: $project_id
      }
    ) {
      created_at
      feedback
      first_auth
      id
      second_auth
      status
      updated_at
      project_id
    }
    update_projects_by_pk(
      pk_columns: { id: $project_id }
      _set: { status: $status }
    ) {
      case_study
      feasibility_study
      project_labels {
        id
        label {
          id
          title
        }
      }
      project_charter
      project_name
      status
      end_date
      start_date
      average_beneficiaries_cost
      donation_amount
      target_amount
      num_of_beneficiaries
      project_description
      created_at
      updated_at
      id
      country
      userByManagerId {
        id
        first_name
        last_name
      }
      package_category {
        category_name
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

export default function ProposalFeedback() {
  const { id } = useParams();
  const [createProjectFeedback, { loading }] = useMutation(
    CREATE_PROJECT_FEEDBACK_MUTATION,
    {
      onCompleted: () => {
        toast.success('Project feedback created successfully');
        reset();
      },
      onError: error => {
        toast.error(error.message);
      }
    }
  );
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      firstAuthentication: '',
      secondAuthentication: '',
      status: '',
      comments: ''
    },
    resolver: yupResolver(feedbackScheme)
  });
  const { handleSubmit, reset } = methods;
  const onSubmit = data => {
    createProjectFeedback({
      variables: {
        feedback: data.comments,
        first_auth: data.firstAuthentication,
        second_auth: data.secondAuthentication,
        status: data.status,
        project_id: id
      }
    });
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card className="mb-3">
        <Card.Header>
          <Card.Title className="fw-medium mb-0">Project Status</Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Row className="g-3">
            <Col sm={12} md={6}>
              <RHFControl
                name="firstAuthentication"
                label="First Authentication"
                placeholder="First Authentication"
                required
              />
            </Col>
            <Col sm={12} md={6}>
              <RHFControl
                name="secondAuthentication"
                label="Second Authentication"
                placeholder="Second Authentication"
                required
              />
            </Col>
            <Col sm={12}>
              <RHFSelect
                name="status"
                label="Status"
                placeholder="Status"
                options={projectStatusOptions}
                required
              />
            </Col>
            <Col sm={12}>
              <RHFControl
                name="comments"
                label="Comments"
                placeholder="Comments"
                as="textarea"
                rows={5}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Flex
            justifyContent={'between'}
            className="flex-column flex-md-row align-items-center"
          >
            <p className="mb-0 fs-2 fw-medium">Update Project</p>
            <LoadingButton
              loading={loading}
              className="w-215px mt-2 mt-md-0"
              type="submit"
            >
              Submit
            </LoadingButton>
          </Flex>
        </Card.Body>
      </Card>
    </FormProvider>
  );
}
