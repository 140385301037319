import { Col, Row } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import TableRowAction from 'components/common/TableRowAction';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { useParams } from 'react-router-dom';
import AddSchoolModal from './AddSchoolModal';
import EditSchoolModal from './EditSchoolModal';
import AddReportModal from './AddReportModal';
import { GET_SCHOOL_REPORT, GET_SCHOOL_STUDENT } from '../queries';
import { useAuthContext } from 'context/authContext';
import {
  ADD_SCHOOL_REPORT,
  ADD_SCHOOL_STUDENT,
  Edit_SCHOOL_STUDENT,
  DELETE_SCHOOL_REPORT_BY_ID,
  DELETE_SCHOOL_STUDENT,
  UPDATE_SCHOOL_REPORT
} from '../mutations';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import { format } from 'date-fns';

const School = () => {
  const schoolColumns = [
    {
      accessor: 'school_name',
      Header: 'School Name',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { school } = rowData.row.original;
        return <>{school.school_name}</>;
      }
    },
    {
      accessor: 'school_address',
      Header: 'Location',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const {
          school: { school_address }
        } = rowData.row.original;
        return <>{school_address}</>;
      }
    },
    {
      accessor: 'start_date',
      Header: 'Start Date',
      Cell: rowData => {
        const { start_date } = rowData.row.original;
        return format(new Date(start_date), 'dd/MM/yyyy');
      }
    },
    {
      accessor: 'end_date',
      Header: 'End Date',
      Cell: rowData => {
        const { end_date } = rowData.row.original;
        return end_date ? format(new Date(end_date), 'dd/MM/yyyy') : 'Present';
      }
    },
    {
      accessor: 'user',
      Header: 'User',
      Cell: rowData => {
        const { user } = rowData.row.original;
        return <>{user.first_name + ' ' + user.last_name}</>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickEdit={() =>
              setEditSchoolModal({ isOpen: true, schoolStudentId: id })
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.sponsorship.children.school}
          />
        );
      }
    }
  ];

  const reportColumns = [
    {
      accessor: 'reporter',
      Header: 'Reporter',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { user } = rowData.row.original;
        return <>{user.first_name + ' ' + user.last_name}</>;
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { report_date } = rowData.row.original;
        return dayjs(report_date).format('DD/MM/YYYY');
      }
    },
    {
      accessor: 'comment',
      Header: 'Comment'
    },
    {
      accessor: 'evaluation',
      Header: 'Evaluation'
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            onCLickEdit={() => handleEditReportClick(rowData.row.original)}
            onCLickDelete={() => handleDeleteReportClick(id)}
            subject={permissionsKeyMap.sponsorship.children.beneficiaries}
          />
        );
      }
    }
  ];

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteReportModal, setShowDeleteReportModal] = useState(false);
  const [showEditReportModal, setShowEditReportModal] = useState(false);

  const [selectedId, setSelectedId] = useState(null);
  const [editObj, setEditObj] = useState(null);

  const [addSchoolModalShow, setAddSchoolModalShow] = useState(false);
  const [editSchoolModal, setEditSchoolModal] = useState({
    isOpen: false,
    schoolStudentId: null
  });
  const [addReportModalShow, setAddReportModalShow] = useState(false);

  const { organization, selectedBranchId, user } = useAuthContext();
  const { id } = useParams();

  const [addSchoolStudent, { loading: isAddSchoolStudentLoading }] =
    useMutation(ADD_SCHOOL_STUDENT, {
      onCompleted: () => {
        toast.success('School Added Successfully');
        setAddSchoolModalShow(false);
      },
      onError: error => {
        toast.error('Something went wrong');
        setAddSchoolModalShow(false);
        console.log(error);
      },
      update: (cache, { data: insert_school_students_one }) => {
        const existingStudentSchool = cache.readQuery({
          query: GET_SCHOOL_STUDENT,
          variables: { orphanId: id }
        });

        if (!existingStudentSchool) return;
        cache.writeQuery({
          query: GET_SCHOOL_STUDENT,
          variables: { orphanId: id },
          data: {
            school_students: [
              ...existingStudentSchool.school_students,
              insert_school_students_one?.insert_school_students_one
            ]
          }
        });
      }
    });

  const [editSchoolStudent, { loading: isEditSchoolStudentLoading }] =
    useMutation(Edit_SCHOOL_STUDENT, {
      onCompleted: () => {
        toast.success('School Edited Successfully');
        setEditSchoolModal({ isOpen: false, schoolStudentId: null });
        refetchSchoolStudents();
      },
      onError: error => {
        toast.error('Something went wrong');
        setEditSchoolModal({ isOpen: false, schoolStudentId: null });
        console.log(error);
      }
    });

  const [deleteSchoolStudent] = useMutation(DELETE_SCHOOL_STUDENT, {
    onCompleted: () => {
      toast.success('School Removed Successfully');
      setAddSchoolModalShow(false);
    },
    onError: error => {
      toast.error('Something went wrong');
      setAddSchoolModalShow(false);
      console.log(error);
    },
    update: cache => {
      const existingStudentSchool = cache.readQuery({
        query: GET_SCHOOL_STUDENT,
        variables: { orphanId: id }
      });

      if (!existingStudentSchool) return;
      cache.writeQuery({
        query: GET_SCHOOL_STUDENT,
        variables: { orphanId: id },
        data: {
          school_students: existingStudentSchool.school_students.filter(
            item => item.id !== selectedId
          )
        }
      });
    }
  });

  const [deleteSchoolReport] = useMutation(DELETE_SCHOOL_REPORT_BY_ID, {
    onCompleted: () => {
      toast.success('School Report Deleted Successfully');
      setAddSchoolModalShow(false);
    },
    onError: error => {
      toast.error('Something went wrong');
      setAddSchoolModalShow(false);
      console.log(error);
    },
    update: cache => {
      const existingStudentReport = cache.readQuery({
        query: GET_SCHOOL_REPORT,
        variables: { id }
      });

      if (!existingStudentReport) return;
      cache.writeQuery({
        query: GET_SCHOOL_REPORT,
        variables: { id },
        data: {
          school_reports: existingStudentReport.school_reports.filter(
            item => item.id !== selectedId
          )
        }
      });
    }
  });

  const {
    data: schoolList,
    loading,
    refetch: refetchSchoolStudents
  } = useQuery(GET_SCHOOL_STUDENT, {
    fetchPolicy: 'network-only',
    variables: {
      orphanId: id
    }
  });

  const { data: schoolReportList, loading: reportsLoading } = useQuery(
    GET_SCHOOL_REPORT,
    {
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    }
  );

  const [addSchoolReport] = useMutation(ADD_SCHOOL_REPORT, {
    onCompleted: () => {
      toast.success('Report Added Successfully');
      setAddReportModalShow(false);
    },
    onError: error => {
      toast.error('Something went wrong');
      setAddReportModalShow(false);
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingStudentReport = cache.readQuery({
        query: GET_SCHOOL_REPORT,
        variables: { id }
      });

      if (!existingStudentReport) return;
      cache.writeQuery({
        query: GET_SCHOOL_REPORT,
        variables: { id },
        data: {
          school_reports: [
            ...existingStudentReport.school_reports,
            data.insert_school_reports_one
          ]
        }
      });
    }
  });

  const [updateSchoolReport] = useMutation(UPDATE_SCHOOL_REPORT, {
    onCompleted: () => {
      toast.success('Report Updated Successfully');
      setAddReportModalShow(false);
    },
    onError: error => {
      toast.error('Something went wrong');
      setAddReportModalShow(false);
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingStudentReport = cache.readQuery({
        query: GET_SCHOOL_REPORT,
        variables: { id }
      });

      if (!existingStudentReport) return;
      cache.writeQuery({
        query: GET_SCHOOL_REPORT,
        variables: { id },
        data: {
          school_reports: [
            ...existingStudentReport.school_reports.filter(
              item => item.id !== editObj.id
            ),
            data.update_school_reports_by_pk
          ]
        }
      });
      setEditObj(null);
      setShowEditReportModal(false);
    }
  });

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const handleDeleteReportClick = id => {
    setSelectedId(id);
    setShowDeleteReportModal(true);
  };

  const handleEditReportClick = obj => {
    setEditObj(obj);
    setShowEditReportModal(true);
  };

  const handleDeleteModalHide = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };

  const handleDeleteRreportModalHide = () => {
    setSelectedId(null);
    setShowDeleteReportModal(false);
  };

  const handleConfirmDelete = () => {
    setShowDeleteModal(false);
    deleteSchoolStudent({ variables: { id: selectedId } });
  };

  const handleConfirmDeleteReport = () => {
    setShowDeleteReportModal(false);
    deleteSchoolReport({ variables: { id: selectedId } });
  };

  const submitAddSchool = values => {
    addSchoolStudent({
      variables: {
        school_id: values.school,
        orphan_id: id,
        branch_id: selectedBranchId,
        organisation_id: organization.id,
        user_id: user.id,
        start_date: values.startDate === '' ? null : values.startDate,
        end_date: values.endDate === '' ? null : values.endDate
      }
    });
  };

  const submitEditSchool = values => {
    editSchoolStudent({
      variables: {
        id: editSchoolModal.schoolStudentId,
        school: values.school,
        startDate: values.startDate === '' ? null : values.startDate,
        endDate: values.endDate === '' ? null : values.endDate
      }
    });
  };

  const submitAddReport = values => {
    {
      editObj
        ? updateSchoolReport({
            variables: {
              id: editObj.id,
              school_id: values.school,
              evaluation: values.evaluation,
              comment: values.comment
            }
          })
        : addSchoolReport({
            variables: {
              branch_id: selectedBranchId,
              organisation_id: organization.id,
              orphan_id: id,
              school_id: values.school,
              user_id: user.id,
              evaluation: values.evaluation,
              reporter: values.reporter,
              comment: values.comment,
              report_date: values.date
            }
          });
    }
    // console.log(values.reportDocument[0]?.name);
  };

  const schoolOptions = useMemo(() => {
    return schoolList?.school_students.map(item => {
      return { label: item.school.school_name, value: item.school.id };
    });
  }, [schoolList]);

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal || showDeleteReportModal}
        onHide={
          showDeleteModal ? handleDeleteModalHide : handleDeleteRreportModalHide
        }
        title={showDeleteModal ? 'Remove School' : 'Delete Report'}
        handleConfirm={
          showDeleteModal ? handleConfirmDelete : handleConfirmDeleteReport
        }
        body={
          showDeleteModal
            ? 'Are you sure you want to remove selected School?'
            : 'Are you sure you want to remove selected Report?'
        }
      />

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {schoolList?.school_students && (
            <CustomAdvanceTable
              data={schoolList?.school_students}
              columns={schoolColumns}
              title="Schools"
              addButtonLabel="Add"
              onClickAddNew={() => {
                setAddSchoolModalShow(true);
              }}
              loading={loading}
              addNew
              subject={permissionsKeyMap.sponsorship.children.school}
            />
          )}
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={12} xxl={12} className="h-md-100">
          {schoolReportList?.school_reports && (
            <CustomAdvanceTable
              data={schoolReportList?.school_reports}
              columns={reportColumns}
              title="Reports"
              addButtonLabel="Add"
              onClickAddNew={() => {
                setAddReportModalShow(true);
              }}
              loading={reportsLoading}
              addNew
              // selection={true}
              subject={permissionsKeyMap.sponsorship.children.school}
            />
          )}
        </Col>
      </Row>
      {addSchoolModalShow && (
        <AddSchoolModal
          show={addSchoolModalShow}
          onSubmit={submitAddSchool}
          isSubmitLoading={isAddSchoolStudentLoading}
          onHide={() => {
            setAddSchoolModalShow(false);
          }}
          title={'Add School'}
        />
      )}
      {editSchoolModal.isOpen && (
        <EditSchoolModal
          show={editSchoolModal.isOpen}
          id={editSchoolModal.schoolStudentId}
          onSubmit={submitEditSchool}
          isSubmitLoading={isEditSchoolStudentLoading}
          onHide={() =>
            setEditSchoolModal({ isOpen: false, schoolStudentId: null })
          }
          title="Edit School"
        />
      )}
      {(addReportModalShow || showEditReportModal) && (
        <AddReportModal
          show={addReportModalShow || showEditReportModal}
          onHide={() => {
            setAddReportModalShow(false);
            setShowEditReportModal(false);
          }}
          title={addReportModalShow ? 'Add Report' : 'Edit Report'}
          loading={isAddSchoolStudentLoading}
          onSubmit={submitAddReport}
          schoolOptions={schoolOptions}
          initValues={editObj}
        />
      )}
    </>
  );
};

export default School;
