import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import CrudCard from '../../common/CrudCard/CrudCard';

/* -------------------------------------------------------------------------- */
const CREATE_PACKAGE_CATEGORY = gql`
  mutation CreatePackageCategory(
    $category_name: String!
    $organisation_id: uuid!
  ) {
    insert_package_category_one(
      object: {
        category_name: $category_name
        organisation_id: $organisation_id
      }
    ) {
      category_id
      category_name
      id
      created_at
      organisation_id
      updated_at
    }
  }
`;

const UPDATE_PACKAGE_CATEGORY = gql`
  mutation UpdatePackageCategory($id: uuid!, $category_name: String!) {
    update_package_category_by_pk(
      pk_columns: { id: $id }
      _set: { category_name: $category_name }
    ) {
      category_id
      category_name
      created_at
      id
      organisation_id
      updated_at
    }
  }
`;

const DELETE_PACKAGE_CATEGORY = gql`
  mutation DeletePackageCategory($id: uuid!) {
    delete_package_category_by_pk(id: $id) {
      category_id
      category_name
      created_at
      id
      organisation_id
      updated_at
    }
  }
`;

const GET_PACKAGE_CATEGORIES = gql`
  query GetPackageCategories($where: package_category_bool_exp!) {
    package_category(where: $where) {
      category_id
      category_name
      created_at
      id
      organisation_id
      updated_at
    }
  }
`;
/* -------------------------------------------------------------------------- */
function ProjectCategories({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();
  const { organization } = useContext(AuthContext);

  const [where, setWhere] = useState({});

  useEffect(() => {
    setWhere({
      where: { organisation_id: { _eq: organization.id } }
    });
  }, [organization]);

  const [createCategory, { loading: createLoading }] = useMutation(
    CREATE_PACKAGE_CATEGORY,
    {
      refetchQueries: ['GetPackageCategories'],
      onCompleted: () => {
        toast.success('Category created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateCategory, { loading: updateLoading }] = useMutation(
    UPDATE_PACKAGE_CATEGORY,
    {
      refetchQueries: ['GetPackageCategories'],
      onCompleted: () => {
        toast.success('Category updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteCategory, { loading: deleteLoading }] = useMutation(
    DELETE_PACKAGE_CATEGORY,
    {
      refetchQueries: ['GetPackageCategories'],
      onCompleted: () => {
        toast.success('Category deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [getPackageCategories, { data }] = useLazyQuery(GET_PACKAGE_CATEGORIES);
  useEffect(() => {
    if (Object.keys(where).length)
      getPackageCategories({ variables: { ...where } });
  }, [where]);

  const handleCreate = ({ category_name }) => {
    createCategory({
      variables: {
        category_name,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateCategory({
      variables: {
        id,
        category_name: data.category_name
      }
    });
  };
  const handleDelete = id => {
    deleteCategory({
      variables: {
        id
      }
    });
  };
  const list = data?.package_category.map(category => ({
    id: category.id,
    values: [
      {
        value: category.category_name,
        name: 'category_name'
      }
    ]
  }));
  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      formTitle={'Add Category'}
      listTitle={'Current Categories'}
      title={'Project Categories'}
      inputs={[
        {
          name: 'category_name',
          placeholder: 'Add Project Category',
          type: 'text',
          required: true,
          value: '',
          validation: yup.string().required('Project Category is required')
        }
      ]}
      list={list}
      subject={subject}
    />
  );
}

ProjectCategories.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default ProjectCategories;
