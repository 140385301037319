import { gql, useMutation, useQuery } from '@apollo/client';
import { rolesMap } from 'context/authContext';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import UserForm from './UserForm';

const EDIT_USER_ROLE = gql`
  mutation EditUserRole(
    $id: uuid = ""
    $permissions: json = ""
    $userId: uuid = ""
    $email: String = ""
    $first_name: String = ""
    $last_name: String = ""
    $branch_id: uuid = ""
    $role_id: uuid = ""
  ) {
    update_users(
      where: { id: { _eq: $userId } }
      _set: { email: $email, first_name: $first_name, last_name: $last_name }
    ) {
      returning {
        id
        first_name
        last_name
        email
      }
    }
    update_users_roles(
      where: { id: { _eq: $id } }
      _set: {
        permissions: $permissions
        branch_id: $branch_id
        role_id: $role_id
      }
    ) {
      returning {
        user {
          first_name
          last_name
          email
        }
        branch_id
        permissions
        role_id
        role {
          name
        }
        branch {
          name
        }
        id
        is_active
      }
    }
  }
`;

const GET_USER_ROLE = gql`
  query GetUserRole($id: uuid = "") {
    users_roles(where: { id: { _eq: $id } }) {
      permissions
      user_id
      role_id
      user {
        email
        first_name
        last_name
        id
      }
      branch_id
    }
  }
`;

export default function EditUser() {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: dataUserRole } = useQuery(GET_USER_ROLE, {
    variables: {
      id
    },
    fetchPolicy: 'network-only'
  });

  const [editUserRole, { loading }] = useMutation(EDIT_USER_ROLE, {
    onCompleted: () => {
      toast.success('Update Successfully.');
      navigate(paths.userRolesSetting);
    },
    onError: error => {
      toast.error('Something went wrong! Please try again');
      console.log(error);
    }
    // update: (
    //   cache,
    //   {
    //     data: {
    //       update_users_roles: { returning: update_users_roles_returning },
    //       update_users: { returning: update_users_returning }
    //     }
    //   }
    // ) => {
    //   const existingUserRole = cache.readQuery({
    //     query: GET_USER_ROLE,
    //     variables: { organisation_id: organization.id }
    //   });

    //   if (!existingUserRole) return;

    //   const tmpUserRoles = existingUserRole.users_roles.filter(
    //     item => item.id !== id
    //   );
    //   cache.writeQuery({
    //     query: GET_USER_ROLE,
    //     variables: { organisation_id: organization.id },
    //     data: {
    //       users_roles: [
    //         {
    //           id,
    //           is_active: true,
    //           branch_id: update_users_roles_returning[0].branch_id,
    //           permissions: update_users_roles_returning[0].permissions,
    //           user: {
    //             email: update_users_returning[0].email,
    //             first_name: update_users_returning[0].first_name,
    //             id: update_users_returning[0].id
    //           }
    //         },
    //         ...tmpUserRoles
    //       ]
    //     }
    //   });
    // }
  });

  const onSubmit = values => {
    editUserRole({
      variables: {
        password: values.password,
        email: values.email,
        branch_id: values.branch,
        first_name: values.firstName,
        last_name: values.lastName,
        id,
        userId: dataUserRole.users_roles[0].user_id,
        permissions: values.superAdmin
          ? null
          : JSON.stringify(values.permissions),
        role_id: values.superAdmin ? rolesMap.admin.id : rolesMap.staff.id
      }
    });
  };

  return (
    <UserForm
      isEdit
      loading={loading}
      onSubmit={onSubmit}
      initValues={dataUserRole?.users_roles[0]}
    />
  );
}
