import PropTypes from 'prop-types';
import { Card, Container } from 'react-bootstrap';
import { Can } from './permissions/Can';

export default function PermissionWrapper({ subject, action, children }) {
  const renderAccessDenied = () => (
    <Container>
      <Card className="d-flex align-items-center justify-content-center vh-75 ">
        <h3 className="text-600 mb-3">Access Denied</h3>
        <p className="text-500 mb-1">
          You don't have permission to access this page.
        </p>
        <p className="text-500">
          Please contact your administrator for more information.
        </p>
      </Card>
    </Container>
  );

  return (
    <>
      <Can I={action} a={subject}>
        {allowed => (allowed ? children : renderAccessDenied())}
      </Can>
      <Can not I={action} a={subject}>
        {renderAccessDenied()}
      </Can>
    </>
  );
}

PermissionWrapper.propTypes = {
  subject: PropTypes.string,
  children: PropTypes.string,
  action: PropTypes.string
};
