import { gql, useMutation, useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import React, { useContext, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { TestScheduler } from './Scheduler';
import AddEventModal from './addEventModal';

const INSERT_CALENDER_EVENT = gql`
  mutation InsertCalenderEvent(
    $branch_id: uuid = ""
    $end_date: timestamptz = ""
    $organisation_id: uuid = ""
    $start_date: timestamptz = ""
    $text: String = ""
  ) {
    insert_calendar_events(
      objects: {
        branch_id: $branch_id
        end_date: $end_date
        organisation_id: $organisation_id
        start_date: $start_date
        text: $text
      }
    ) {
      affected_rows
    }
  }
`;
const UPDATE_EVENT = gql`
  mutation UpdateEvent(
    $id: uuid = ""
    $end_date: timestamptz = ""
    $start_date: timestamptz = ""
    $text: String = ""
  ) {
    update_calendar_events_by_pk(
      pk_columns: { id: $id }
      _set: { end_date: $end_date, start_date: $start_date, text: $text }
    ) {
      id
    }
  }
`;
const GET_EVENTS = gql`
  query GetEvent($branchId: uuid = "", $organizationId: uuid = "") {
    calendar_events(
      where: {
        branch_id: { _eq: $branchId }
        organisation_id: { _eq: $organizationId }
      }
    ) {
      end_date
      id
      start_date
      text
    }
  }
`;
export const DELETE_EVENT = gql`
  mutation DeleteEvent($id: uuid = "") {
    delete_calendar_events_by_pk(id: $id) {
      id
    }
  }
`;
function EventScheduler() {
  const [show, setShow] = useState(false);
  const { organization, selectedBranchId } = useContext(AuthContext);

  const [addEvent, { loading: insertLoading }] = useMutation(
    INSERT_CALENDER_EVENT,
    {
      refetchQueries: ['GetEvent'],
      onCompleted: () => {
        toast.success('Event Added successfully');
      },
      onError: error => {
        console.log({ error });
        toast.error('Something went wrong');
      }
    }
  );

  const [updateEvent, { loading: updateLoading }] = useMutation(UPDATE_EVENT, {
    refetchQueries: ['GetEvent'],
    onCompleted: () => {
      toast.success('Event Updated successfully');
    },
    onError: error => {
      console.log({ error });
      toast.error('Something went wrong');
    }
  });

  const { data: eventData, loading: getEventLoading } = useQuery(GET_EVENTS, {
    variables: {
      organizationId: organization.id,
      branchId: selectedBranchId
    }
  });

  const onSubmit = data => {
    const startDateTime = new Date(data.startDate);
    startDateTime.setHours(startDateTime.getHours() + 5);
    startDateTime.setHours(Number(data.startTime.split(':')[0]) + 5);
    startDateTime.setMinutes(data.startTime.split(':')[1]);
    const endDateTime = new Date(data.endDate);
    endDateTime.setHours(endDateTime.getHours() + 5);
    endDateTime.setHours(Number(data.endTime.split(':')[0]) + 5);
    endDateTime.setMinutes(data.endTime.split(':')[1]);
    addEvent({
      variables: {
        branch_id: selectedBranchId,
        organisation_id: organization.id,
        text: data.text,
        start_date: startDateTime.toISOString(),
        end_date: endDateTime.toISOString()
      }
    });
    setShow(false);
  };
  const events = useMemo(() => {
    return eventData?.calendar_events.map(item => {
      return {
        ...item,
        end_date:
          item.end_date.split('T')[0] +
          ' ' +
          item.end_date.split('T')[1].split('+')[0],
        start_date:
          item.start_date.split('T')[0] +
          ' ' +
          item.start_date.split('T')[1].split('+')[0]
      };
    });
  }, [eventData]);
  return (
    <div className="bg-white">
      <div className="d-flex justify-content-end pt-3 pb-3 pe-2">
        <Button onClick={() => setShow(true)}>Add a new Event</Button>
      </div>
      {insertLoading || getEventLoading || updateLoading ? (
        <Spinner />
      ) : (
        <>
          {events !== undefined && (
            <>
              <TestScheduler events={events} onUpdate={updateEvent} />
            </>
          )}
        </>
      )}

      {show && (
        <AddEventModal
          show={show}
          onSubmit={onSubmit}
          onHide={() => setShow(false)}
        />
      )}
    </div>
  );
}

export default EventScheduler;
