import { gql, useMutation } from '@apollo/client';
import { useAuthContext } from 'context/authContext';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CompletionForm from './CompletionForm';
import { GET_PROJECT_COMPLETION_QUERY } from './ProjectCompleteRoutes';

/* -------------------------------------------------------------------------- */
const CREATE_PROJECT_COMPLETION_MUTATION = gql`
  mutation CreateProjectCompletion(
    $administration_cost: Int = 10
    $cities: jsonb = ""
    $completion_date: date = ""
    $currency_id: uuid = ""
    $no_of_beneficiary: Int = 10
    $project_cost: Int = 10
    $project_id: uuid = ""
    # $status: String = ""
    $video_url: String = ""
    $feedback: String = ""
    $country: String = ""
    $created_by: uuid = ""
  ) {
    insert_project_completion_one(
      object: {
        administration_cost: $administration_cost
        cities: $cities
        completion_date: $completion_date
        currency_id: $currency_id
        no_of_beneficiary: $no_of_beneficiary
        project_cost: $project_cost
        project_id: $project_id
        # status: $status
        video_url: $video_url
        feedback: $feedback
        created_by: $created_by
        country: $country
      }
    ) {
      administration_cost
      cities
      completion_date
      created_at
      currency_id
      id
      no_of_beneficiary
      project_cost
      project_id
      updated_at
      # status
      video_url
      feedback
      country
      created_by
    }
  }
`;
/* -------------------------------------------------------------------------- */

const CreateProjectCompletion = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const [createProjectCompletion, { loading }] = useMutation(
    CREATE_PROJECT_COMPLETION_MUTATION,
    {
      onCompleted: () => {
        // navigate(
        //   getRelativePath(paths.createCompleteReport, paths.completeProject)
        // );
        toast.success('Project completion created successfully');
      },
      onError: error => {
        toast.error(error.message);
      },
      update: (cache, { data }) => {
        const newProjectCompletion = data?.insert_project_completion_one;
        cache.writeQuery({
          query: GET_PROJECT_COMPLETION_QUERY,
          variables: { projectId: id },
          data: {
            project_completion: [newProjectCompletion]
          }
        });
      }
    }
  );

  const onSubmit = data => {
    const formattedCompletionDate = new Date(data.completionDate).toISOString();
    createProjectCompletion({
      variables: {
        administration_cost: data.administrationCost,
        cities: data.cities,
        completion_date: formattedCompletionDate,
        currency_id: data.currency,
        no_of_beneficiary: data.numOfBeneficiaries,
        project_cost: data.projectCost,
        project_id: id,
        status: data.status,
        video_url: data.videoUrl,
        feedback: data.feedback,
        country: data.country,
        created_by: user?.id
      }
    });
  };

  return <CompletionForm onSubmit={onSubmit} loading={loading} />;
};

export default CreateProjectCompletion;
