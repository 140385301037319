import { Card, Col, Row } from 'react-bootstrap';
import { RHFSelect, RHFControl } from 'components/common/form';
import { HEAR_ABOUT_US_OPTIONS } from 'data/weraise-data/hear-about-us-options';

const LeadSource = () => {
  return (
    <Card className="mb-3">
      <Card.Header>
        <Card.Title className="fw-medium">Comment & Lead Source</Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={6} className="mb-3">
                <RHFControl
                  name="comment"
                  as="textarea"
                  type="text"
                  label="Comment"
                  placeholder="Comment"
                  rows={3}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <RHFSelect
                  name="lead_source"
                  label="how did you hear about us"
                  options={HEAR_ABOUT_US_OPTIONS}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default LeadSource;
