import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import CardDropdown from 'components/common/CardDropdown';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import Notification from 'components/we-raise-common/Notification';
import { useAuthContext } from 'context/authContext';
import { weNotificationsProject } from 'data/weraise-data/sponsored-list-data';
import { parseRouteParams } from 'helpers/utils';

import React, { useMemo } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

export const PROJECT_STATUS_PROPOSED = 'proposed';
export const PROJECT_STATUS_COMPLETED = 'completed';
export const PROJECT_STATUS_INPROGRESS = 'inprogress';
export const PROJECT_STATUS_REJECTED = 'rejected';

export const projectStatusMap = {
  [PROJECT_STATUS_PROPOSED]: {
    label: 'Proposed',
    variant: 'info'
  },
  [PROJECT_STATUS_COMPLETED]: {
    label: 'Completed',
    variant: 'success'
  },
  [PROJECT_STATUS_INPROGRESS]: {
    label: 'In progress',
    variant: 'warning'
  },
  [PROJECT_STATUS_REJECTED]: {
    label: 'Rejected',
    variant: 'danger'
  }
};

export const projectStatusOptions = [
  {
    label: projectStatusMap[PROJECT_STATUS_PROPOSED].label,
    value: PROJECT_STATUS_PROPOSED
  },
  {
    label: projectStatusMap[PROJECT_STATUS_COMPLETED].label,
    value: PROJECT_STATUS_COMPLETED
  },
  {
    label: projectStatusMap[PROJECT_STATUS_INPROGRESS].label,
    value: PROJECT_STATUS_INPROGRESS
  },
  {
    label: projectStatusMap[PROJECT_STATUS_REJECTED].label,
    value: PROJECT_STATUS_REJECTED
  }
];

/* -------------------------------------------------------------------------- */
export const GET_BRANCH_PROJECTS = gql`
  query GetBranchProjects($branchId: uuid!) {
    projects(
      where: { branch_id: { _eq: $branchId }, parent_id: { _is_null: true } }
      order_by: { created_at: desc }
    ) {
      case_study
      feasibility_study
      project_labels {
        id
        label {
          id
          title
        }
      }
      project_charter
      project_name
      status
      end_date
      start_date
      average_beneficiaries_cost
      donation_amount
      target_amount
      num_of_beneficiaries
      project_description
      created_at
      updated_at
      id
      country
      userByManagerId {
        id
        first_name
        last_name
      }
      package_category {
        category_name
      }
    }
  }
`;

const GET_STATUS_COUNT = gql`
  query GetStatusCount($branchId: uuid!) {
    proposedCount:projects_aggregate(
      where: {
        branch_id: { _eq: $branchId }
        status: { _eq: ${PROJECT_STATUS_PROPOSED} }
      }
    ) {
      aggregate {
        count
      }
    }
    completedCount:projects_aggregate(
      where: {
        branch_id: { _eq: $branchId }
        status: { _eq: ${PROJECT_STATUS_COMPLETED} }
      } 
    ) {
      aggregate {
        count
      }
    }
    ongoingCount:projects_aggregate(
      where: {
        branch_id: { _eq: $branchId }
        status: { _eq: ${PROJECT_STATUS_INPROGRESS} }
      }
    ) {
      aggregate {
        count
      }
    }
    rejectedCount:projects_aggregate(
      where: {
        branch_id: { _eq: $branchId }
        status: { _eq: ${PROJECT_STATUS_REJECTED} }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

const ProjectsList = () => {
  const navigate = useNavigate();
  const { selectedBranchId } = useAuthContext();
  const { data: projectsData, loading: projectsLoading } = useQuery(
    GET_BRANCH_PROJECTS,
    {
      variables: {
        branchId: selectedBranchId
      }
    }
  );
  const { data: statusCountData } = useQuery(GET_STATUS_COUNT, {
    variables: {
      branchId: selectedBranchId
    }
  });
  const statusCountItems = useMemo(() => {
    if (!statusCountData) return [];
    return [
      {
        title: 'Proposed',
        value: statusCountData.proposedCount.aggregate.count,
        color: 'info'
      },
      {
        title: 'Completed',
        value: statusCountData.completedCount.aggregate.count,
        color: 'success'
      },
      {
        title: 'Ongoing',
        value: statusCountData.ongoingCount.aggregate.count,
        color: 'warning'
      },
      {
        title: 'Rejected',
        value: statusCountData.rejectedCount.aggregate.count,
        color: 'danger'
      }
    ];
  }, [statusCountData]);
  const columns = [
    {
      accessor: 'id',
      Header: 'Ref',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'project_name',
      Header: 'Project',
      cellProps: {
        className: 'py-2 fw-medium'
      },
      Cell: rowData => {
        const { id, project_name } = rowData.row.original;
        return (
          <Link to={parseRouteParams(paths.projectMilestones, { id })}>
            {project_name}
          </Link>
        );
      }
    },
    {
      accessor: 'country',
      Header: 'Country',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'manager',
      Header: 'Manager',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { first_name, last_name } = rowData.row.original
          .userByManagerId ?? {
          first_name: '',
          last_name: ''
        };
        return (
          <span className="fw-medium">
            {first_name} {last_name}
          </span>
        );
      }
    },
    {
      accessor: 'start_date',
      Header: 'Started Date',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'target_amount',
      Header: 'Total Funding',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        return (
          <span className="fw-medium">
            ${rowData.row.original.target_amount}
          </span>
        );
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        return (
          <SoftBadge
            pill
            bg={projectStatusMap[rowData.row.original.status]?.variant}
          >
            {rowData.row.original.status}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'oid',
      Header: '',
      cellProps: {
        className: 'text-end fw-medium py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row;

        return (
          <CardDropdown>
            <div className="py-2">
              <Dropdown.Item
                as={Link}
                to={parseRouteParams(paths.projectProposal, { id })}
              >
                Project Proposal
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to={parseRouteParams(paths.projectMilestones, { id })}
              >
                Project Progress
              </Dropdown.Item>
              <Dropdown.Item
                className="text-success"
                as={Link}
                to={parseRouteParams(paths.completeProject, { id })}
              >
                Complete Project
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  return (
    <>
      <Row className="g-3 mb-3">
        <Col md={6}>
          <Card>
            <Card.Body className="mt-1">
              <Row className="g-3">
                {statusCountItems?.map((item, index) => (
                  <Col className="col-6" xl={3} key={item.id}>
                    <Flex
                      className={classNames({
                        'border-xl-end': index !== statusCountItems.length - 1
                      })}
                    >
                      <FontAwesomeIcon
                        icon={'circle'}
                        className={`text-${item.color} fs--2 mt-1`}
                      />
                      <div className="ms-2">
                        <p className="mb-2 fw-medium">{item.title}</p>
                        <p className="mb-0 fs-2 fw-medium">{item.value}</p>
                      </div>
                    </Flex>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="overflow-hidden">
            <Card.Body className="p-0">
              <ul className="mb-0 list-unstyled">
                {weNotificationsProject.map((notification, index) => (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    isLast={weNotificationsProject.length - 1 === index}
                  />
                ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {projectsData?.projects && (
            <CustomAdvanceTable
              data={projectsData?.projects}
              columns={columns}
              loading={projectsLoading}
              perPage={10}
              showTablePagination
              title="Projects"
              showSearchInput
              addNew
              searchInputPlaceHolder="Search"
              addButtonIcon="project-diagram"
              addButtonLabel="Create Project"
              onClickAddNew={() => navigate(paths.createProject)}
              subject={permissionsKeyMap.project.children.projects}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default ProjectsList;
