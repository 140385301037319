import OwnerRegistrationForm from './OwnerRegistrationForm';
import { useContext, useState } from 'react';

import AuthContext from 'context/authContext';

export default function OwnerRegistration() {
  const [loading, setLoading] = useState(false);
  const { registerOwner } = useContext(AuthContext);
  const onSubmit = async ({
    title,
    firstName,
    lastName,
    phoneNumber,
    confirmPhoneNumber,
    email,
    companyEmail,
    password,
    confirmPassword
  }) => {
    setLoading(true);
    await registerOwner({
      title,
      firstName,
      lastName,
      phoneNumber,
      confirmPhoneNumber,
      email,
      companyEmail,
      password,
      confirmPassword
    });
    setLoading(false);
  };
  return (
    <div>
      <h4 className="my-4">Organization Admin</h4>
      <OwnerRegistrationForm onSubmit={onSubmit} loading={loading} />
    </div>
  );
}
