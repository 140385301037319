import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  InputGroup,
  ListGroup,
  Dropdown
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { gql, useMutation, useQuery } from '@apollo/client';
import CardDropdown from '../CardDropdown';

function generateQueries(entityName) {
  const capitalizedEntityName = entityName
    .toLowerCase()
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  const GET_ENTITY = gql`
      query Get${capitalizedEntityName} {
        ${entityName} {
          id
          name
        }
      }
    `;

  const INSERT_ENTITY = gql`
      mutation Insert${capitalizedEntityName}($name: String!) {
        insert_${entityName}_one(object: { name: $name }) {
          id
          name
        }
      }
    `;

  const UPDATE_ENTITY = gql`
      mutation Update${capitalizedEntityName}($id: uuid!, $name: String!) {
        update_${entityName}_by_pk(pk_columns: { id: $id }, _set: { name: $name }) {
          id
          name
        }
      }
    `;

  const DELETE_ENTITY = gql`
  mutation Delete${capitalizedEntityName}($id: uuid!) {
    delete_${entityName}_by_pk( id: $id ) {
      id
      name
    }
  }
`;

  return {
    GET_ENTITY,
    INSERT_ENTITY,
    UPDATE_ENTITY,
    DELETE_ENTITY
  };
}

function AddOptionModal({ show, handleClose, title, entityName }) {
  const {
    GET_ENTITY: fetchQuery,
    INSERT_ENTITY: insertMutation,
    UPDATE_ENTITY: updateMutation,
    DELETE_ENTITY: deleteMutation
  } = generateQueries(entityName);

  const { data, refetch } = useQuery(fetchQuery, {
    fetchPolicy: 'network-only'
  });

  const [insertOption] = useMutation(insertMutation);
  const [updateOption] = useMutation(updateMutation);
  const [deleteOption] = useMutation(deleteMutation);

  const [options, setOptions] = useState([]);
  const [newOption, setNewOption] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editOption, setEditOption] = useState('');

  useEffect(() => {
    if (data && data[entityName]) {
      setOptions(data[entityName]);
    }
  }, [data, entityName]);

  const handleAddOption = async () => {
    if (newOption.trim()) {
      await insertOption({
        variables: { name: newOption }
      });
      refetch();
      setNewOption('');
    }
  };

  const handleUpdateOption = async id => {
    if (editOption.trim()) {
      await updateOption({ variables: { name: editOption, id } });
      setEditIndex(null);
      setEditOption('');
      refetch();
    }
  };

  const startEditOption = index => {
    setEditIndex(index);
    setEditOption(options[index].name);
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setEditOption('');
  };

  const handleDeleteOption = async id => {
    await deleteOption({ variables: { id } });
    refetch();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            placeholder={`Add ${title}`}
            value={newOption}
            onChange={e => setNewOption(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddOption}>
            + Add
          </Button>
        </InputGroup>
        <ListGroup>
          {options.map((option, index) => (
            <ListGroup.Item
              key={option.id}
              className="d-flex justify-content-between align-items-center py-2 px-3"
              style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '20px'
              }}
            >
              {editIndex === index ? (
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={editOption}
                    onChange={e => setEditOption(e.target.value)}
                  />
                  <Button
                    variant="outline-primary"
                    onClick={() => handleUpdateOption(option.id)}
                  >
                    Save
                  </Button>
                  <Button variant="outline-secondary" onClick={cancelEdit}>
                    Cancel
                  </Button>
                </InputGroup>
              ) : (
                <>
                  <span>{option.name}</span>

                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item onClick={() => startEditOption(index)}>
                        Edit
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleDeleteOption(option.id)}
                      >
                        Delete
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} className="w-100">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddOptionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  entityName: PropTypes.string.isRequired
};

export default AddOptionModal;
