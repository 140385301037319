import product2 from 'assets/img/products/2.jpg';
export const imageData = [
  {
    name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
    id: 'TN000001',
    owner: 'Hamza',
    date: '20/01/2023',
    files: [
      {
        id: 1,
        src: product2
      }
    ]
  },
  {
    name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
    id: 'TN000001',
    owner: 'Hamza',
    date: '20/01/2023',
    files: [
      {
        id: 1,
        src: product2
      }
    ]
  },
  {
    name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
    id: 'TN000001',
    owner: 'Hamza',
    date: '20/01/2023',
    files: [
      {
        id: 1,
        src: product2
      }
    ]
  },
  {
    name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
    id: 'TN000001',
    owner: 'Hamza',
    date: '20/01/2023',
    files: [
      {
        id: 1,
        src: product2
      }
    ]
  },
  {
    name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
    id: 'TN000001',
    owner: 'Hamza',
    date: '20/01/2023',
    files: [
      {
        id: 1,
        src: product2
      }
    ]
  },
  {
    name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
    id: 'TN000001',
    owner: 'Hamza',
    date: '20/01/2023',
    files: [
      {
        id: 1,
        src: product2
      }
    ]
  },
  {
    name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
    id: 'TN000001',
    owner: 'Hamza',
    date: '20/01/2023',
    files: [
      {
        id: 1,
        src: product2
      }
    ]
  },
  {
    name: 'Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)',
    id: 'TN000001',
    owner: 'Hamza',
    date: '20/01/2023',
    files: [
      {
        id: 1,
        src: product2
      }
    ]
  }
];
