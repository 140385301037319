import { gql, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import DonarFormContainer from './donor-form/DonorFormContainer';
import LoadingContainer from 'components/common/LoadingContainer';

/* -------------------------------------------------------------------------- */
const EDIT_DONOR = gql`
  mutation EditDonor(
    $id: uuid!
    $address: jsonb = ""
    $consented_date: timestamp = ""
    $consented_via: String = ""
    $contact_preferences: jsonb = ""
    $declaration_source: String = ""
    $email: jsonb = ""
    $first_name: String = ""
    $gift_aid_file: String = ""
    $last_name: String = ""
    $comment: String = ""
    $lead_source: String = ""
    $phone: jsonb = ""
    $remark: String = ""
    $title: String = ""
    $gift_aid_elgibility: Boolean = false
    $tax_vat_number: String = ""
    $username: String = ""
    $website: String = ""
    $job_title: String = ""
    $charity_registration_number: String = ""
    $partner_name: String = ""
    $type: String = ""
    $donor_category_id: uuid!
  ) {
    update_donars_by_pk(
      pk_columns: { id: $id }
      _set: {
        address: $address
        # consented_date: $consented_date
        # consented_via: $consented_via
        # contact_preferences: $contact_preferences
        # declaration_source: $declaration_source
        email: $email
        first_name: $first_name
        # gift_aid_elgibility: $gift_aid_elgibility
        gift_aid_file: $gift_aid_file
        last_name: $last_name
        comment: $comment
        lead_source: $lead_source
        phone: $phone
        # remark: $remark
        title: $title
        tax_vat_number: $tax_vat_number
        username: $username
        website: $website
        job_title: $job_title
        charity_registration_number: $charity_registration_number
        partner_name: $partner_name
        type: $type
        donor_category_id: $donor_category_id
      }
    ) {
      address
      consented_date
      consented_via
      contact_preferences
      created_at
      declaration_source
      email
      first_name
      gift_aid_elgibility
      gift_aid_file
      id
      last_name
      lead_source
      organisation_id
      phone
      remark
      title
      user_id
      type
      donor_category_id
    }
  }
`;
const GET_DONOR_BY_ID = gql`
  query GetDonorById($id: uuid!) {
    donars_by_pk(id: $id) {
      address
      consented_date
      consented_via
      contact_preferences
      created_at
      declaration_source
      email
      first_name
      gift_aid_elgibility
      gift_aid_file
      id
      last_name
      comment
      lead_source
      organisation_id
      phone
      remark
      title
      user_id
      tax_vat_number
      username
      website
      is_donar
      is_partner
      is_sponsor
      job_title
      charity_registration_number
      partner_name
      donor_category_id
      type
    }
  }
`;
/* -------------------------------------------------------------------------- */
const EditDonor = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { loading: dataLoading, data } = useQuery(GET_DONOR_BY_ID, {
    fetchPolicy: 'network-only',
    variables: { id }
  });
  const [editDonar, { loading: editLoading }] = useMutation(EDIT_DONOR, {
    refetchQueries: ['GetDonors', 'GetDonorById'],
    onCompleted: data => {
      toast.success('Donor created successfully');
      navigate(`/donors/${data?.update_donars_by_pk?.id}/profile`);
    },
    onError: error => {
      toast.error('Something went wrong! Please try again');
      console.log(error);
    }
  });

  const handleCreate = data => {
    editDonar({
      variables: { ...data, id }
    });
  };

  return dataLoading ? (
    <LoadingContainer />
  ) : (
    <DonarFormContainer
      loading={editLoading}
      onSubmit={handleCreate}
      data={data?.donars_by_pk}
      isEdit
    />
  );
};

export default EditDonor;
