import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { useDonorContext } from 'context/donorContext';

/* -------------------------------------------------------------------------- */

const GET_TOP_DONATIONS = gql`
  query GetTopDonations($donorId: uuid = "") {
    order_details(
      order_by: { amount: desc_nulls_last }
      limit: 3
      offset: 0
      where: { order: { donar_id: { _eq: $donorId } } }
    ) {
      package {
        package_title
      }
      amount
      id
    }
  }
`;
/* -------------------------------------------------------------------------- */

// const topDonationData = [
//   {
//     id: 1,
//     title: 'Bread 4 Yemen',
//     value: '$850.00',
//     color: 'text-primary'
//   },
//   {
//     id: 2,
//     title: 'Build Orphanage Centre',
//     value: '$550.00',
//     color: 'text-secondary'
//   },
//   {
//     id: 3,
//     title: 'Water Relief',
//     value: '$150.00',
//     color: 'text-info'
//   }
// ];

export default function AssignedStaffCard({ handleSelectStaff }) {
  const {
    donor: { user, id: donorId }
  } = useDonorContext();
  const { data: topDonationData } = useQuery(GET_TOP_DONATIONS, {
    variables: {
      donorId
    }
  });

  const topDonationsItems = useMemo(() => {
    if (topDonationData) {
      return topDonationData.order_details.map(item => ({
        id: item.id,
        title: item.package.package_title,
        value: `$${item.amount}`,
        color: 'text-primary'
      }));
    }
    return [];
  }, [topDonationData]);

  const name = useMemo(() => {
    if (user) {
      return `${user.first_name} ${user.last_name}`;
    }
    return '';
  }, [user]);

  return (
    <Card>
      <FalconCardHeader
        title={'Assigned Staff'}
        light={false}
        titleTag="div"
        className="pb-0"
        menuClassName="align-items-start"
        endEl={
          <IconButton
            icon="pen"
            variant="falcon-default"
            className="fs--1 bg-transparent shadow-none p-0"
            onClick={handleSelectStaff}
          />
        }
      />
      <Card.Body>
        <Flex alignItems={'center'}>
          {user ? (
            <>
              <Avatar src={user.image} name={name} size="2xl" />

              <div className="ms-2">
                <p className="mb-0 fw-medium">{name}</p>
                {/* <p className="mb-0 fw-medium fs--2 text-500">CRM Specialist</p> */}
              </div>
            </>
          ) : (
            <h5 className="text-500">No Staff Assigned</h5>
          )}
        </Flex>
        <hr />
        <div className="opacity-25 d-none">
          <Flex justifyContent={'between'}>
            <p className="mb-2 fw-medium">
              Targeted Donation <FontAwesomeIcon icon={'question-circle'} />
            </p>
            <IconButton
              icon="pen"
              variant="falcon-default"
              className="fs--1 bg-transparent shadow-none p-0"
            />
          </Flex>
          <p className="text-success fs-2 fw-medium">
            $1500.00 <span className="fs--1 text-700">/ $ 2,500.00</span>
          </p>

          <ProgressBar
            now={60}
            style={{ height: '5px', marginBottom: '1.5rem' }}
          />
        </div>
        <hr />
        <div>
          <Flex justifyContent={'between'}>
            <p className="mb-2 fa--2 fw-medium">Top Donations to date</p>
            <p className="mb-2 fs--2 fw-medium">Amount</p>
          </Flex>
          {topDonationsItems.map((item, index) => (
            <div
              key={item.id}
              className={classNames('p-3 border rounded-3 bg-soft-primary', {
                'mb-3': index !== topDonationsItems.length - 1
              })}
            >
              <Flex justifyContent={'between'} alignItems={'center'}>
                <p className="mb-0 text-900 fw-medium">
                  <FontAwesomeIcon
                    icon={'circle'}
                    className={classNames('me-2', item.color)}
                  />
                  {item.title}
                </p>
                <p className="mb-0 fs--2 text-600 fw-medium">{item.value}</p>
              </Flex>
            </div>
          ))}
        </div>
      </Card.Body>
    </Card>
  );
}

AssignedStaffCard.propTypes = {
  handleSelectStaff: PropTypes.func.isRequired
};
