import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  Button,
  Form,
  InputGroup,
  ListGroup,
  Dropdown
} from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import CardDropdown from 'components/common/CardDropdown';
import PropTypes from 'prop-types';
import {
  CREATE_SPONSORSHIP_PRIORITY,
  DELETE_SPONSORSHIP_PRIORITY,
  GET_SPONSORSHIP_PRIORITY,
  UPDATE_SPONSORSHIP_PRIORITY
} from './beneficiary-options-queries';
import AuthContext from 'context/authContext';

function PriorityModal({ show, handleClose }) {
  const { organization } = useContext(AuthContext);

  const { data, refetch } = useQuery(GET_SPONSORSHIP_PRIORITY, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });

  const [insertPriority] = useMutation(CREATE_SPONSORSHIP_PRIORITY, {
    variables: {
      organizationId: organization.id
    }
  });
  const [updatePriority] = useMutation(UPDATE_SPONSORSHIP_PRIORITY, {
    variables: {
      organizationId: organization.id
    }
  });

  const [deletePriority] = useMutation(DELETE_SPONSORSHIP_PRIORITY);

  const [priority, setPriority] = useState([]);
  const [newPriority, setNewPriority] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editPriority, setEditPriority] = useState('');

  useEffect(() => {
    if (data && data.sponsorship_priority) {
      setPriority(data.sponsorship_priority);
    }
  }, [data]);

  const handleAddPriority = async () => {
    if (newPriority.trim()) {
      await insertPriority({ variables: { priority_type: newPriority } });
      refetch();
      setNewPriority('');
    }
  };

  const handleUpdatePriority = async id => {
    if (editPriority.trim()) {
      await updatePriority({ variables: { priority_type: editPriority, id } });
      setEditIndex(null);
      setEditPriority('');
      refetch();
    }
  };

  const startEditPriority = index => {
    setEditIndex(index);
    setEditPriority(priority[index].priority_type);
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setEditPriority('');
  };

  const handleDeletePriority = async id => {
    await deletePriority({ variables: { id } });
    refetch();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Sponsorship Priority</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            placeholder="Add Priority"
            value={newPriority}
            onChange={e => setNewPriority(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddPriority}>
            + Add
          </Button>
        </InputGroup>
        <ListGroup>
          {priority.map((priority, index) => (
            <ListGroup.Item
              key={priority.id}
              className="d-flex justify-content-between align-items-center py-2 px-3"
              style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '20px'
              }}
            >
              {editIndex === index ? (
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={editPriority}
                    onChange={e => setEditPriority(e.target.value)}
                  />
                  <Button
                    variant="outline-primary"
                    onClick={() => handleUpdatePriority(priority.id)}
                  >
                    Save
                  </Button>
                  <Button variant="outline-secondary" onClick={cancelEdit}>
                    Cancel
                  </Button>
                </InputGroup>
              ) : (
                <>
                  <span>{priority.priority_type}</span>

                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item onClick={() => startEditPriority(index)}>
                        Edit
                      </Dropdown.Item>{' '}
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleDeletePriority(priority.id)}
                      >
                        Delete
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} className="w-100">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PriorityModal;

PriorityModal.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool
};
