import { gql, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import Avatar from 'components/common/Avatar';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFDatePicker, RHFSelect } from 'components/common/form';
import { calculateAge } from 'helpers/utils';
import CreateSponsorNav from './CreateSponsorNav';
import { sponsorshipScheme } from './sponsorshipScheme';
import LoadingContainer from 'components/common/LoadingContainer';
import SelectBeneficiaryModal from './SelectBeneficiaryModal';
import SelectDonorModal from './SelectDonorModal';

// const commitmentOptions = [
//   { label: 'Fixed Term , open ended', value: 1 },
//   { label: 'open ended', value: 2 },
//   { label: 'Fixed Term', value: 3 }
// ];

// const durationOptions = [
//   { label: '6 Months', value: '6 Months' },
//   { label: '9 Months', value: '9 Months' },
//   { label: '12 Months', value: '12 Months' },
//   { label: '18 Months', value: '18 Months' },
//   { label: '24 Months', value: '24 Months' },
//   { label: '36 Months', value: '36 Months' }
// ];

/* -------------------------------------------------------------------------- */
const GET_BENEFICIARY = gql`
  query GetBeneficiary($id: uuid!) {
    orphans_by_pk(id: $id) {
      country
      first_name
      gender
      last_name
      middle_name
      image
      DOB
      id
    }
  }
`;
/* -------------------------------------------------------------------------- */

const SponsorshipForm = ({ onSubmit, isEdit, loading }) => {
  const navigate = useNavigate();
  const [showSponsorshipModal, setShowSponsorshipModal] = useState(false);
  const [donorModalShow, setDonorModalShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const donorId = searchParams.get('donorId');
  const beneficiaryId =
    searchParams.get('beneficiaryId') || searchParams.get('id');

  const { data: beneficiaryData, loading: beneficiaryLoading } = useQuery(
    GET_BENEFICIARY,
    {
      variables: { id: beneficiaryId }
    }
  );

  const { first_name, last_name, image, country, gender, DOB } =
    beneficiaryData?.orphans_by_pk ?? {};
  const name = first_name + ' ' + last_name;
  const age = calculateAge(DOB);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      beginOn: '',
      theme: '',
      stipulation: ''
    },
    resolver: yupResolver(sponsorshipScheme)
  });
  const { handleSubmit: RHFHandleSubmit } = methods;
  const handleSubmit = data => {
    onSubmit({ ...data, beneficiaryId, donorId });
  };
  const hideBeneficiaryModal = () => {
    setShowSponsorshipModal(false);
  };
  const showBeneficiaryModal = () => {
    setShowSponsorshipModal(true);
  };
  const handleSelectBeneficiarySubmit = selectedBeneficiaryId => {
    setSearchParams(
      {
        beneficiaryId: selectedBeneficiaryId,
        donorId
      },
      { replace: true }
    );
  };
  const hideDonorModal = () => {
    setDonorModalShow(false);
  };
  const showDonorModal = () => {
    setDonorModalShow(true);
  };
  const handleSelectDonorSubmit = selectedDonorId => {
    setSearchParams(
      {
        beneficiaryId,
        donorId: selectedDonorId
      },
      { replace: true }
    );
  };
  return (
    <>
      <SelectBeneficiaryModal
        onHide={hideBeneficiaryModal}
        show={showSponsorshipModal}
        onSubmit={handleSelectBeneficiarySubmit}
        beneficiaryId={beneficiaryId}
      />
      <SelectDonorModal
        onHide={hideDonorModal}
        show={donorModalShow}
        onSubmit={handleSelectDonorSubmit}
        donorId={donorId}
      />
      <div className="py-0">
        <Row className="g-3">
          <Col md={4} lg={3}>
            <CreateSponsorNav
              onChangeDonor={showDonorModal}
              donorId={donorId}
            />
          </Col>
          <Col md={8} lg={9}>
            <FormProvider
              methods={methods}
              onSubmit={RHFHandleSubmit(handleSubmit)}
            >
              {beneficiaryLoading ? (
                <LoadingContainer />
              ) : (
                <Card className="mb-3">
                  <FalconCardHeader
                    title={
                      <Flex>
                        <IconButton
                          variant="default"
                          className="shadow-none text-primary p-0"
                          iconClassName={'me-3'}
                          icon={'arrow-left'}
                          onClick={() => navigate(-1)}
                        />
                        <h4 className="mt-2 fw-bold fs-0  fs-md-2">
                          {isEdit ? 'Update' : 'Create'} Sponsorship
                        </h4>
                      </Flex>
                    }
                    light={false}
                    titleTag="div"
                    menuClassName="align-items-center"
                    endEl={
                      <Flex alignItems={'center'}>
                        <LoadingButton
                          transform="shrink-3"
                          className="me-2"
                          type="submit"
                          loading={loading}
                        >
                          {isEdit ? 'Update' : 'Save'}
                        </LoadingButton>
                        {/* <CardDropdown /> */}
                      </Flex>
                    }
                  />
                </Card>
              )}

              <Card>
                <Card.Body>
                  <Row>
                    <Col as={Col} md={6} className="mb-3">
                      <RHFDatePicker name="beginOn" label="Begin on" required />
                    </Col>
                    <Col md={8} className="d-none d-md-block" />
                    {/* <Form.Group as={Col} md={6} className="mb-3">
                      <Form.Label>
                        Commitment Type <span className="text-danger">*</span>
                      </Form.Label>
                      <ReactSelect
                        required
                        closeMenuOnSelect={true}
                        options={commitmentOptions}
                        placeholder="Select"
                        classNamePrefix="react-select"
                        value={commitment}
                        onChange={value => setCommitment(value)}
                        menuPosition="fixed"
                      />
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Col md={12} className="d-none d-md-block" />
                    <Form.Group as={Col} md={6}>
                      <Form.Label>
                        Sponsorship Duration
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <ReactSelect
                        closeMenuOnSelect={true}
                        options={durationOptions}
                        placeholder="Select"
                        classNamePrefix="react-select"
                        value={duration}
                        onChange={value => setDuration(value)}
                        menuPosition="fixed"
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Required
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Col
                      md={6}
                      className="d-flex justify-content-start justify-content-md-end align-items-end"
                    >
                      <p className="mb-0 mt-3 mt-md-0">
                        Due for renewal on : 12/2023
                      </p>
                    </Col> */}
                    <Col md={12} className="mt-3">
                      <div className="border rounded-3 p-3">
                        <Flex justifyContent={'between'} alignItems="center">
                          <Flex alignItems={'center'}>
                            <div className="me-3">
                              <Avatar src={image} name={name} size="2xl" />
                            </div>
                            <p className="mb-0 fw-medium">
                              {name} - {age} Years old - {gender} - {country}
                            </p>
                          </Flex>
                          <Button
                            variant="default"
                            className="text-primary shadow-none p-0"
                            onClick={showBeneficiaryModal}
                          >
                            Change
                          </Button>
                        </Flex>
                      </div>
                      <hr />
                    </Col>
                    <Col as={Col} md={6} className="mb-3">
                      <RHFSelect hasDummyOptions name="theme" label="Theme" />
                    </Col>
                    <Col as={Col} md={6} className="mb-3">
                      <RHFSelect
                        hasDummyOptions
                        name="stipulation"
                        label="Stipulation"
                      />
                    </Col>
                    <Col md={12} className="d-none d-md-block" />
                  </Row>
                </Card.Body>
              </Card>
            </FormProvider>
          </Col>
        </Row>
      </div>
      {/* <SelectBeneficiaryModal
        setModalShow={setModalShow}
        modalShow={modalShow}
        path="#"
      /> */}
    </>
  );
};

SponsorshipForm.propTypes = {
  isEdit: PropTypes.bool,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default SponsorshipForm;
