import {
  FormProvider,
  RHFControl,
  RHFFileUploadRegistration,
  RHFPhoneInput,
  RHFSelect
} from 'components/common/form';
import { useMemo, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { branchResolver } from './branchScheme';
import { useAuthContext } from 'context/authContext';

/* -------------------------------------------------------------------------- */
const GET_CURRENCIES_AND_COUNTRIES = gql`
  query GetCurrenciesAndCountries($organisation_id: uuid!) {
    currency {
      abbreviation
      symbol
      id
      currency_name
    }
    location(where: { organisation_id: { _eq: $organisation_id } }) {
      id
      location_name
    }
  }
`;
/* -------------------------------------------------------------------------- */

export default function BranchForm({ onSubmit, loading, isEdit, initValues }) {
  const { organization } = useAuthContext();
  const { data, loading: loadingData } = useQuery(
    GET_CURRENCIES_AND_COUNTRIES,
    {
      variables: { organisation_id: organization.id }
    }
  );
  const currenciesOptions = useMemo(() => {
    if (data) {
      return data.currency.map(({ abbreviation, id, symbol }) => ({
        label: abbreviation + (symbol ? ` (${symbol})` : ''),
        value: id
      }));
    }
  }, [data]);

  const locationsOptions = useMemo(() => {
    if (data) {
      return data.location.map(location => ({
        label: location.location_name,
        value: location.id
      }));
    }
  }, [data]);

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      logo: initValues?.logo ?? '',
      name: initValues?.name ?? '',
      city: initValues?.city ?? '',
      currency_id: initValues?.currency_id ?? '',
      address: initValues?.address ?? '',
      location_id: initValues?.location_id ?? '',
      charityNumber: ''
    },
    resolver: branchResolver
  });

  const { handleSubmit, reset } = methods;
  useEffect(() => {
    if (initValues) {
      reset({ ...initValues });
    }
  }, [initValues]);

  const submitButtonLabel = isEdit ? 'Save' : 'Create Branch';
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column gap-3">
        <h4 className="my-4">Branch Details</h4>
        <Row>
          <Col>
            <RHFFileUploadRegistration label={'Branch Logo'} name={'logo'} />
          </Col>
        </Row>
        <Row>
          <RHFControl
            label={'Branch Name'}
            name={'name'}
            placeholder={'Branch Name'}
            required
          />
        </Row>
        <Row>
          <RHFControl
            label={'Branch Address'}
            name={'address'}
            placeholder={'Branch Address'}
            required
          />
        </Row>
        <Row>
          <Col md={6}>
            <RHFControl label={'City'} name={'city'} required />
          </Col>
          <Col md={6}>
            <RHFSelect
              label={'Currency'}
              name={'currency_id'}
              loading={loadingData}
              options={currenciesOptions}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RHFSelect
              label={'Location'}
              name={'location_id'}
              loading={loadingData}
              options={locationsOptions}
              required
              infoMessage="Select the location where the branch is located, you can add locations in sponsorships settings. "
            />
          </Col>
          <Col md={6}>
            <RHFPhoneInput
              label={'Phone Number'}
              name={'phoneNumber'}
              placeholder={'Phone Number'}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RHFControl
              label={'Charity Number/ID'}
              name={'charityNumber'}
              placeholder={'Charity Number/ID'}
              required
            />
          </Col>
        </Row>
        <Button
          size="lg"
          className="mb-2 rounded-3"
          iconClassName="me-1"
          type="submit"
        >
          {loading ? 'Loading...' : submitButtonLabel}
        </Button>
      </div>
    </FormProvider>
  );
}

BranchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  initValues: PropTypes.object
};
