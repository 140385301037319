import { Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';

export const RHFCheck = ({
  name,
  label,
  type = 'switch',
  endAddon,
  value,
  onChange,
  ...rest
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Form.Group>
          <Form.Check type={type} id={value ?? name} className="mb-0">
            {type === 'radio' ? (
              <Form.Check.Input
                name={name}
                value={value}
                onChange={onChange}
                type={type}
                checked={field.value === value}
                {...rest}
              />
            ) : (
              <Form.Check.Input
                {...field}
                // type={type}
                checked={field.value}
                {...rest}
              />
            )}
            {label && (
              <Form.Check.Label
                htmlFor={value ?? name}
                className="mb-0 text-700"
              >
                {label}
              </Form.Check.Label>
            )}
            {endAddon ? endAddon : null}
          </Form.Check>

          <Form.Control.Feedback type="invalid">
            {error && error.message}
          </Form.Control.Feedback>
        </Form.Group>
      )}
    />
  );
};

RHFCheck.propTypes = {
  name: propTypes.string,
  type: propTypes.string,
  label: propTypes.string,
  endAddon: propTypes.node,
  value: propTypes.string,
  onChange: propTypes.func
};
