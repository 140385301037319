import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import CrudCard from '../../common/CrudCard/CrudCard';

/* -------------------------------------------------------------------------- */
const CREATE_SPONSORSHIP_COST = gql`
  mutation CreateSponsorshipCost(
    $amount: float8!
    $organisation_id: uuid!
    $description: String = ""
    $type: String = ""
    $currency_id: uuid!
  ) {
    insert_sponsorship_cost_one(
      object: {
        amount: $amount
        organisation_id: $organisation_id
        description: $description
        type: $type
        currency_id: $currency_id
      }
    ) {
      id
      organisation_id
      amount
      created_at
      description
      type
      currency_id
    }
  }
`;

const UPDATE_SPONSORSHIP_COST = gql`
  mutation UpdateSponsorshipCost(
    $id: uuid!
    $amount: float8!
    $description: String = ""
    $type: String = ""
    $currency_id: uuid!
  ) {
    update_sponsorship_cost_by_pk(
      pk_columns: { id: $id }
      _set: {
        amount: $amount
        description: $description
        type: $type
        currency_id: $currency_id
      }
    ) {
      id
      organisation_id
      amount
      created_at
      description
      type
      currency_id
    }
  }
`;

const DELETE_SPONSORSHIP_COST = gql`
  mutation DeleteSponsorshipCost($id: uuid!) {
    delete_sponsorship_cost_by_pk(id: $id) {
      id
    }
  }
`;

const GET_SPONSORSHIP_COSTS = gql`
  query GetSponsorshipCosts($where: sponsorship_cost_bool_exp!) {
    sponsorship_cost(where: $where) {
      id
      organisation_id
      amount
      created_at
      description
      type
      currency_id
    }
  }
`;

const GET_CURRENCY = gql`
  query GetCurrency {
    currency {
      id
      abbreviation
    }
  }
`;

/* -------------------------------------------------------------------------- */
function SponsorshipCosts({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();
  const { organization } = useContext(AuthContext);

  const [createCost, { loading: createLoading }] = useMutation(
    CREATE_SPONSORSHIP_COST,
    {
      refetchQueries: ['GetSponsorshipCosts'],
      onCompleted: () => {
        toast.success('Sponsorship Cost created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateCost, { loading: updateLoading }] = useMutation(
    UPDATE_SPONSORSHIP_COST,
    {
      refetchQueries: ['GetSponsorshipCosts'],

      onCompleted: () => {
        toast.success('Sponsorship Cost updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteCost, { loading: deleteLoading }] = useMutation(
    DELETE_SPONSORSHIP_COST,
    {
      refetchQueries: ['GetSponsorshipCosts'],
      onCompleted: () => {
        toast.success('Sponsorship Cost deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [getSponsorshipCosts, { data }] = useLazyQuery(GET_SPONSORSHIP_COSTS);
  useEffect(() => {
    getSponsorshipCosts({
      variables: { where: { organisation_id: { _eq: organization.id } } }
    });
  }, [getSponsorshipCosts, organization.id]);

  const handleCreate = ({ amount, description, type }) => {
    createCost({
      variables: {
        amount,
        organisation_id: organization.id,
        description,
        type,
        currency_id: data.currency_id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateCost({
      variables: {
        id,
        amount: data.amount,
        description: data.description,
        type: data.type,
        currency_id: data.currency_id
      }
    });
  };
  const handleDelete = id => {
    deleteCost({
      variables: {
        id
      }
    });
  };
  const list = data?.sponsorship_cost.map(item => ({
    id: item.id,
    values: [
      {
        value: item.amount,
        name: 'amount'
      },
      {
        value: item.description,
        name: 'description'
      },
      {
        value: item.type,
        name: 'type'
      },
      {
        value: item.currency_id,
        name: 'currency_id'
      }
    ]
  }));

  const { data: currencyData } = useQuery(GET_CURRENCY, {
    fetchPolicy: 'network-only'
  });

  const currencyOptions = currencyData?.currency?.map(
    ({ id, abbreviation }) => ({ label: abbreviation, value: id })
  );

  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      formTitle={'Add Amount'}
      listTitle={'Current Amounts'}
      title={'Sponsorship Costs'}
      inputs={[
        {
          name: 'amount',
          placeholder: 'Add Amount',
          type: 'text',
          required: true,
          value: '',
          validation: yup.string().required('Sponsorship Cost is required')
        },
        {
          name: 'currency_id',
          placeholder: 'Add Currency',
          type: 'select',
          options: currencyOptions,
          required: true,
          value: '',
          validation: yup.string().required('Sponsorship Currency is required')
        },
        {
          name: 'description',
          placeholder: 'Description',
          type: 'text',
          required: true,
          value: '',
          validation: yup
            .string()
            .required('Sponsorship Description is required')
        },
        {
          name: 'type',
          placeholder: 'Add Project Type',
          type: 'select',
          required: true,
          value: '',
          validation: yup.string().required('Project Type is required'),
          options: [
            { value: 'monthly', label: 'monthly' },
            { value: 'yearly', label: 'yearly' }
          ]
        }
      ]}
      list={list}
      subject={subject}
    />
  );
}

SponsorshipCosts.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default SponsorshipCosts;
