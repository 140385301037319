import { Col, Form, FormControl, InputGroup, Row } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import ActionButton from '../ActionButton';

export const RHFControl = ({
  name,
  label,
  type = 'text',
  endAddon,
  startAddon,
  placeholder,
  noErrorMessage,
  isHorizontal,
  required,
  autoComplete = 'off',
  infoText,
  noOptional,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(type);
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Form.Group as={isHorizontal ? Row : Form.Group}>
          {!!label && (
            <Form.Label column={isHorizontal} sm={isHorizontal ? 5 : 12}>
              {label} {required && <span className="text-danger">*</span>}
              {!required && (
                <span className="text-500">{!noOptional && '(optional)'}</span>
              )}
              {infoText && (
                <ActionButton
                  variant="falcon-default"
                  title={infoText}
                  icon={'info-circle'}
                  className="fs--1 bg-transparent shadow-none p-0 text-400 ms-1"
                />
              )}
            </Form.Label>
          )}
          <Col sm={isHorizontal ? 7 : 12}>
            <InputGroup className="position-relative">
              {startAddon ? startAddon : null}
              <FormControl
                placeholder={placeholder}
                type={inputType}
                isInvalid={!!error}
                autoComplete={
                  type === 'password' ? 'new-password' : autoComplete
                }
                {...field}
                {...rest}
              />
              {endAddon ? endAddon : null}
              {type === 'password' && (
                <InputGroup.Text
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword(!showPassword);
                    setInputType(showPassword ? 'password' : 'text');
                  }}
                >
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </InputGroup.Text>
              )}
            </InputGroup>
            {error && (
              <Form.Control.Feedback className="d-block" type="invalid">
                {!noErrorMessage && error && error.message}
              </Form.Control.Feedback>
            )}
          </Col>
        </Form.Group>
      )}
    />
  );
};

RHFControl.propTypes = {
  name: propTypes.string,
  type: propTypes.string,
  label: propTypes.string,
  placeholder: propTypes.string,
  endAddon: propTypes.node,
  startAddon: propTypes.node,
  noErrorMessage: propTypes.bool,
  isHorizontal: propTypes.bool,
  required: propTypes.bool,
  autoComplete: propTypes.string,
  infoText: propTypes.string,
  noOptional: propTypes.bool
};
