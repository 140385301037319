import { useAuthContext } from 'context/authContext';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

export default function useParentPermissions() {
  const { ability } = useAuthContext();
  const shouldShowParent = parentKey => {
    const parentKeysObject = Object.values(permissionsKeyMap).find(
      key => key.parent === parentKey
    );
    const childrenKeys = Object.values(parentKeysObject.children);
    const shouldShow = childrenKeys.some(key => ability.can('read', key));
    return shouldShow;
  };
  return { shouldShowParent };
}
