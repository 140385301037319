import classNames from 'classnames';
import Flex from 'components/common/Flex';
import React from 'react';
import { Button } from 'react-bootstrap';
import { firstPageContents, stages } from './homeData';
import PropTypes from 'prop-types';

const WRFrontEndTopNav = ({ stage, setStage, setShowContent }) => {
  return (
    <>
      {stage !== 7 && (
        <div className="pt-md-4 pt-lg-5 pb-md-1 pb-lg-3 py-3">
          <Flex className="justify-content-center">
            <Button
              className={classNames('wr-top-nav-btn', {
                active: stage >= 1
              })}
              variant="falcon-default"
              onClick={() => {
                setStage(stages.Donation1);
                setShowContent(firstPageContents.donationType);
              }}
            >
              <span
                className={classNames('wr-top-nav-item after-line', {
                  'active-after-line': stage >= 2
                })}
              >
                1
              </span>
              <span className="d-none d-md-block mt-1 fs--1 wr-top-nav-label">
                Donation
              </span>
            </Button>
            <Button
              className={classNames('wr-top-nav-btn', {
                active: stage >= 4
              })}
              variant="falcon-default"
              onClick={() => setStage(stages.Details1)}
            >
              <span
                className={classNames(
                  'wr-top-nav-item after-line before-line',
                  {
                    'active-after-line': stage >= 5,
                    'active-before-line': stage >= 4
                  }
                )}
              >
                2
              </span>
              <span className="d-none d-md-block mt-1 fs--1 wr-top-nav-label">
                Details
              </span>
            </Button>
            <Button
              className={classNames('wr-top-nav-btn', {
                active: stage >= 6
              })}
              variant="falcon-default"
              onClick={() => setStage(stages.Payment)}
            >
              <span
                className={classNames('wr-top-nav-item before-line', {
                  'active-before-line': stage >= 6
                })}
              >
                3
              </span>
              <span className="d-none d-md-block mt-1 fs--1 wr-top-nav-label">
                Payment
              </span>
            </Button>
          </Flex>
        </div>
      )}
    </>
  );
};

WRFrontEndTopNav.propTypes = {
  stage: PropTypes.number.isRequired,
  setStage: PropTypes.func.isRequired,
  setShowContent: PropTypes.func.isRequired
};

export default WRFrontEndTopNav;
