import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import CrudCard from '../../common/CrudCard/CrudCard';

/* -------------------------------------------------------------------------- */
const CREATE_CAMPAIGN = gql`
  mutation CreateCampaign($name: String = "", $organisation_id: uuid = "") {
    insert_campaign_one(
      object: { name: $name, organisation_id: $organisation_id }
    ) {
      id
      name
      organisation_id
    }
  }
`;

const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign($id: uuid = "", $name: String = "") {
    update_campaign(where: { id: { _eq: $id } }, _set: { name: $name }) {
      returning {
        id
        name
        organisation_id
      }
    }
  }
`;

const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($id: uuid = "") {
    delete_campaign_by_pk(id: $id) {
      id
    }
  }
`;

const GET_CAMPAIGN = gql`
  query GetCampaigns($where: campaign_bool_exp!) {
    campaign(order_by: { created_at: desc }, where: $where) {
      id
      name
      organisation_id
    }
  }
`;

/* -------------------------------------------------------------------------- */
function Campaigns({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();
  const { organization } = useContext(AuthContext);

  const [where, setWhere] = useState({});

  useEffect(() => {
    setWhere({
      where: { organisation_id: { _eq: organization.id } }
    });
  }, [organization]);
  const [createCampaign, { loading: createLoading }] = useMutation(
    CREATE_CAMPAIGN,
    {
      refetchQueries: ['GetCampaigns'],
      onCompleted: () => {
        toast.success('Campaign created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateCampaign, { loading: updateLoading }] = useMutation(
    UPDATE_CAMPAIGN,
    {
      refetchQueries: ['GetCampaigns'],
      onCompleted: () => {
        toast.success('Campaign updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteCampaign, { loading: deleteLoading }] = useMutation(
    DELETE_CAMPAIGN,
    {
      refetchQueries: ['GetCampaigns'],
      onCompleted: () => {
        toast.success('Campaign deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [getCampaign, { data }] = useLazyQuery(GET_CAMPAIGN);

  useEffect(() => {
    if (Object.keys(where).length)
      getCampaign({
        variables: { ...where }
      });
  }, [where]);

  const handleCreate = ({ name }) => {
    createCampaign({
      variables: {
        name,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateCampaign({
      variables: {
        id,
        name: data.name
      }
    });
  };
  const handleDelete = id => {
    deleteCampaign({
      variables: {
        id
      }
    });
  };
  const list = data?.campaign.map(item => ({
    id: item.id,
    values: [
      {
        value: item.name,
        name: 'name'
      }
    ]
  }));
  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      formTitle={'Add Campaigns'}
      listTitle={'Current Campaigns'}
      title={'Campaigns'}
      inputs={[
        {
          name: 'name',
          placeholder: 'Add Campaign',
          type: 'text',
          required: true,
          value: '',
          validation: yup.string().required('Name is required')
        }
      ]}
      list={list}
      subject={subject}
    />
  );
}

Campaigns.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default Campaigns;
