import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadingButton from '../LoadingButton';

function ConfirmationModalDonation({
  show,
  handleConfirm,
  onHide,
  title,
  subTitle,
  body,
  loading,
  children,
  confirmText = 'Confirm',
  onMoveToNextPage
}) {
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header className="modal-header">
          <Modal.Title
            className="modal-header-title center text-primary"
            style={{ fontFamily: 'Patrick Hand SC' }}
          >
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          {body ? body : null}
          {children ? children : null}
          {subTitle ? subTitle : null}
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton
            className={'w-100'}
            loading={loading}
            disabled={loading}
            onClick={handleConfirm}
          >
            {confirmText}
          </LoadingButton>
          <Button variant="falcon-danger w-100" onClick={onMoveToNextPage}>
            No Skip
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ConfirmationModalDonation.propTypes = {
  show: PropTypes.bool,
  handleConfirm: PropTypes.func,
  onHide: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  body: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  confirmText: PropTypes.string,
  onMoveToNextPage: PropTypes.func
};

export default ConfirmationModalDonation;
