import { useForm } from 'react-hook-form';
import { userResolver } from './userScheme';
import { Button, Col, Row, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
  FormProvider,
  RHFCheck,
  RHFControl,
  RHFSelect
} from 'components/common/form';
import { Card } from 'react-bootstrap/esm';
import PermissionItem from './PermissionItem';
import { gql, useQuery } from '@apollo/client';
import { useContext, useEffect, useMemo } from 'react';
import AuthContext, { rolesMap } from 'context/authContext';
import { permissionsMap } from 'routes/permissions/permissionsMap';

export const GET_BRANCHES = gql`
  query GetBranches($organisation_id: uuid = "") {
    branches(where: { organisation_id: { _eq: $organisation_id } }) {
      id
      name
    }
  }
`;

export default function UserForm({ onSubmit, loading, isEdit, initValues }) {
  const { organization } = useContext(AuthContext);
  const { data } = useQuery(GET_BRANCHES, {
    variables: {
      organisation_id: organization.id
    }
  });

  const branchOptions = useMemo(() => {
    return data?.branches.map(item => {
      return { label: item.name, value: item.id };
    });
  }, [data]);

  const permissionsInitialValues = useMemo(
    () =>
      permissionsMap.reduce((acc, currPermission) => {
        const { children } = currPermission;
        const tmp = children.reduce((acc, curr) => {
          const { key, actions } = curr;
          const tmp = actions.reduce((acc, curr) => {
            return {
              ...acc,
              [curr]: false
            };
          }, {});
          return {
            ...acc,
            [key]: tmp
          };
        }, {});
        return {
          ...acc,
          ...tmp
        };
      }, {}),
    []
  );

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: initValues?.user?.first_name ?? '',
      lastName: initValues?.user?.last_name ?? '',
      branch: '',
      email: '',
      password: '',
      confirmPassword: '',
      superAdmin: false,
      permissions: permissionsInitialValues,
      isEdit
    },
    resolver: userResolver
  });
  const { handleSubmit, setValue, watch } = methods;
  const superAdmin = watch('superAdmin');

  useEffect(() => {
    if (isEdit && initValues) {
      setValue('firstName', initValues?.user?.first_name);
      setValue('lastName', initValues?.user?.last_name);
      setValue('email', initValues?.user?.email);
      setValue('branch', initValues?.branch_id);
      const superAdmin = initValues?.role_id === rolesMap.admin.id;
      setValue('superAdmin', superAdmin);
      if (!superAdmin) {
        setValue('permissions', JSON.parse(initValues?.permissions));
      }
    }
  }, [initValues, isEdit, setValue]);

  const submitButtonLabel = isEdit ? 'Save' : 'Add Now';
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Container fluid className="px-0">
        <Row>
          <Col sm={12} md={8}>
            <Card>
              <Card.Body>
                <div className="d-flex flex-column gap-3">
                  <Row className="g-3">
                    <Col md={6}>
                      <RHFControl
                        label={'First Name'}
                        name={'firstName'}
                        placeholder={'First Name'}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <RHFControl
                        label={'Last Name'}
                        name={'lastName'}
                        placeholder={'Last Name'}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <RHFSelect
                        label={'Branch'}
                        name={'branch'}
                        options={branchOptions}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      <RHFControl
                        label={'Email Address'}
                        name={'email'}
                        placeholder={'Email Address'}
                        required
                        type={'email'}
                        disabled={isEdit}
                      />
                    </Col>
                  </Row>
                  {!isEdit && (
                    <Row>
                      <Col md={6}>
                        <RHFControl
                          label={'Password'}
                          name={'password'}
                          placeholder={'Password'}
                          type="password"
                          required
                        />
                      </Col>
                      <Col md={6}>
                        <RHFControl
                          label={'Confirm Password'}
                          name={'confirmPassword'}
                          placeholder={'Confirm Password'}
                          type="password"
                          required
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <div className="d-flex justify-content-between mb-3">
                      <h5 className="mb-0">Super Admin</h5>
                      <RHFCheck name={'superAdmin'} />
                    </div>
                    {!superAdmin && (
                      <>
                        <hr />
                        <h5 className="mb-3">Permissions</h5>
                        <hr />
                        {permissionsMap.map((item, index) => (
                          <div key={item.key}>
                            <PermissionItem data={item} watch={watch} />
                            {index < permissionsMap.length - 1 && <hr />}
                          </div>
                        ))}
                      </>
                    )}
                  </Row>
                </div>
              </Card.Body>
            </Card>
            <Card className="mt-3">
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-0">User Roles</h5>
                  <Button
                    variant="primary"
                    className="px-4"
                    size="sm"
                    type="submit"
                  >
                    {loading ? 'Loading...' : submitButtonLabel}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </FormProvider>
  );
}

// props validation
UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
  initValues: PropTypes.object
};
