import React from 'react';
import ForgetPasswordForm from './forms/ForgetPasswordForm';
import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

const FORGET_PASSWORD = gql`
  mutation ForgetPassword($email: String!) {
    ForgotPassword(email: $email) {
      message
    }
  }
`;

const ForgetPassword = () => {
  const [forgetPassword, { loading }] = useMutation(FORGET_PASSWORD, {
    onCompleted: data => {
      toast.success(data.ForgotPassword.message);
    },
    onError: error => {
      if (error?.graphQLErrors?.[0]?.message === 'validation.exists') {
        toast.error('Email does not exist');
      } else {
        toast.error('Something went wrong! Please try again');
      }
      console.log(error);
    }
  });
  const onSubmit = ({ email }) => {
    forgetPassword({ variables: { email } });
  };
  return (
    <div className="text-center">
      <h5 className="mb-0"> Forgot your password?</h5>
      <small>Enter your email and we'll send you a reset link.</small>
      <ForgetPasswordForm onSubmit={onSubmit} loading={loading} />
    </div>
  );
};

export default ForgetPassword;
