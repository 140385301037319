import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';

import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { RHFCheck, RHFDatePicker, RHFSelect } from 'components/common/form';

const generateData = (length = 12) => {
  const value = [];
  for (let i = 1; i <= length; i++) {
    const data = { value: i, label: i };
    value.push(data);
  }
  return value;
};

const yearOptions = [];
for (let i = 1990; i <= new Date().getFullYear(); i++) {
  const data = { value: i, label: i };
  yearOptions.unshift(data);
}

const GiftAidEligibility = () => {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <Card className="mb-3">
      <Card.Header>
        <Card.Title as={Flex} className="fw-medium justify-content-between">
          <p className="mb-0">Gift Aid Eligibility</p>
          <RHFCheck name={'giftAid.eligibility'} />
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col md={12}>
            <Row>
              <Col md={4}>
                <RHFDatePicker
                  name={'giftAid.date'}
                  className="form-control border-0 shadow-none"
                  placeholderText="DD-MM-YYYY"
                  timeIntervals={5}
                  dateFormat="dd-MM-yyyy"
                  fixedHeight
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col md={12}>
                <Form.Label>
                  Valid Form<span className="text-danger">*</span>
                </Form.Label>

                <Row className="g-3 mb-3">
                  <Col md={4}>
                    <RHFSelect
                      name={'giftAid.validFrom.day'}
                      options={generateData(31)}
                    />
                  </Col>
                  <Col md={2}>
                    <RHFSelect
                      name={'giftAid.validFrom.month'}
                      options={generateData(12)}
                    />
                  </Col>
                  <Col md={4}>
                    <RHFSelect
                      name={'giftAid.validFrom.year'}
                      options={yearOptions}
                    />
                  </Col>
                </Row>

                <Col md={4}>
                  <RHFSelect
                    closeMenuOnSelect={true}
                    options={generateData(100)}
                    name={'giftAid.declarationSource'}
                    label="Deceleration Source"
                  />
                </Col>
              </Col>
            </Row>
            <hr />
            <Flex justifyContent={'between'}>
              <Button
                variant="light"
                className="bg-transparent border-0 fs--1 fs-md-0 shadow-none text-primary p-1"
              >
                + Add Note
              </Button>

              <Button
                as={Link}
                to="/"
                target="_blank"
                variant="link"
                size="sm"
                className="ps-0"
              >
                <FontAwesomeIcon
                  icon="exclamation-circle"
                  className="me-2 fs--2"
                />
                <span className="text-900">Read Conditions</span>
              </Button>
            </Flex>

            <div className="mt-2">
              <div
                {...getRootProps({
                  className: 'dropzone-area h-auto py-6'
                })}
              >
                <input {...getInputProps({ multiple: false })} />
                <Flex
                  justifyContent="center"
                  alignItems={'center'}
                  direction={'column'}
                >
                  <img src={cloudUpload} alt="" width={60} className="me-2" />
                  <p className="fs-0 mb-0 text-700">Upload File</p>
                </Flex>
              </div>
              <div className="mt-3">
                {acceptedFiles.length > 0 && (
                  <>
                    <h6>File</h6>
                    <ul>{files}</ul>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default GiftAidEligibility;
