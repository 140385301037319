import { gql, useLazyQuery, useMutation } from '@apollo/client';
import SelectBeneficiaryModal from 'components/beneficiaries/sponsorships/sponsorship-form/SelectBeneficiaryModal';
import Avatar from 'components/common/Avatar';
import ExportModal from 'components/common/ExportModal';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import SoftBadge from 'components/common/SoftBadge';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import DonorSponsorshipModal from 'components/donor-details/modals/DonorSponsorshipModal';
import DonorCounter from 'components/donor/DonorCounter';
import { weBeneficiariesItems } from 'data/weraise-data/donor-data';
import { calculateAge, parseRouteParams } from 'helpers/utils';
import useBranchPermission from 'hooks/useBranchPermission';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import { Can } from 'routes/permissions/Can';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';
import SelectDonorModal from './sponsorships/sponsorship-form/SelectDonorModal';

export const GET_BENEFACTORS = gql`
  query GetBeneficiary($where: orphans_bool_exp = {}) {
    orphans(where: $where, order_by: { created_at: asc }) {
      simple_id
      first_name
      middle_name
      gender
      country
      DOB
      gender
      sponsorship_condition {
        condition
      }
      sponsorship_priority {
        priority_type
      }
      sponsorship_type {
        type
      }
      id
      last_name
      image
    }
  }
`;
const DELETE_BENEFICIARY = gql`
  mutation DeleteBeneficiary($id: uuid = "") {
    update_orphans_by_pk(pk_columns: { id: $id }, _set: { is_hidden: true }) {
      id
    }
  }
`;

const AllBeneficiaries = () => {
  const navigate = useNavigate();

  const { where } = useBranchPermission();

  const [modalShow, setModalShow] = useState(false);
  const [beneficiaryModalShow, setBeneficiaryModalShow] = useState(false);
  const [donorModalShow, setDonorModalShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedBeneficiaryId, setSelectedBeneficiaryId] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [getBeneficiaries, { data, loading }] = useLazyQuery(GET_BENEFACTORS);
  useEffect(() => {
    getBeneficiaries({
      variables: { where }
    });
  }, [where, getBeneficiaries]);

  const [deleteBeneficiary, { loading: deleteLoading }] = useMutation(
    DELETE_BENEFICIARY,
    {
      refetchQueries: [
        'GetBeneficiaries',
        'GetBeneficiariesSponsored',
        'GetBeneficiariesUnSponsored'
      ],
      onCompleted: () => {
        handleDeleteModalHide();
        toast.success('Beneficiary deleted successfully');
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const tableData = useMemo(
    () =>
      data?.orphans?.map(
        ({
          id,
          simple_id: simpleId,
          first_name,
          middle_name,
          last_name,
          DOB,
          country,
          sponsorship_priority,
          sponsorship_type,
          gender
        }) => ({
          id,
          simpleId,
          name: `${first_name} ${middle_name ?? ''} ${last_name}`,
          age: calculateAge(DOB),
          country,
          sponsor: '-',
          sp_validity: ' -',
          priority: sponsorship_priority?.priority_type,
          type: sponsorship_type?.type,
          gender
        })
      ),
    [data]
  );
  const columns = [
    {
      accessor: 'id',
      Header: 'ID',
      cellProps: {
        className: 'py-2 fw-medium '
      },
      Cell: rowData => {
        const { id, simpleId } = rowData.row.original;
        return (
          <Link
            to={`/beneficiaries/${id}/profile`}
            className="fw-medium text-1100 hover-primary"
          >
            {`B${simpleId}`}
          </Link>
        );
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      cellProps: {
        className: 'py-2 fw-medium '
      },
      Cell: rowData => {
        const { name, image, id } = rowData.row.original;
        return (
          <Flex alignItems="center">
            <Avatar src={image} rounded="circle" size="xl" name={name} />
            <Button
              variant="link"
              onClick={() =>
                navigate(
                  parseRouteParams(paths.beneficiaryProfile, {
                    id
                  })
                )
              }
              className="fw-medium ms-2 fs--1 p-0 text-1100 hover-primary"
            >
              {name}
            </Button>
          </Flex>
        );
      }
    },
    {
      accessor: 'age',
      Header: 'Age',
      cellProps: {
        className: 'py-2 fw-medium '
      }
    },
    {
      accessor: 'country',
      Header: 'Location',
      cellProps: {
        className: 'py-2 fw-medium '
      }
    },
    {
      accessor: 'gender',
      Header: 'Gender',
      cellProps: {
        className: 'py-2 fw-medium '
      }
    },
    // {
    //   accessor: 'sponsor',
    //   Header: 'Sponsor',
    //   cellProps: {
    //     className: 'fw-medium text-800 py-2'
    //   }
    // },
    {
      accessor: 'sp_validity',
      Header: 'Sp. Until',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { sp_validity } = rowData.row.original;
        return sp_validity ? sp_validity : <>&mdash;</>;
      }
    },

    {
      accessor: 'priority',
      Header: 'Priority',
      cellProps: {
        className: 'fw-medium  py-2'
      },
      Cell: rowData => {
        const { priority } = rowData.row.original;
        return priority ? (
          <SoftBadge pill className="me-2" bg="primary">
            {priority}
          </SoftBadge>
        ) : (
          <>&mdash;</>
        );
      }
    },
    {
      accessor: 'type',
      Header: 'Type',
      cellProps: {
        className: 'fw-medium text-800 py-2'
      }
    },
    {
      accessor: 'id_d',
      Header: '',
      headerProps: {
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end fw-medium py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row;
        return (
          <TableRowAction
            onCLickEdit={() =>
              navigate(parseRouteParams(paths.editBeneficiary, { id }))
            }
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.sponsorship.children.beneficiaries}
          />
        );
      }
    }
  ];
  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };
  const handleDeleteModalHide = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };
  const handleConfirmDelete = () => {
    deleteBeneficiary({
      variables: {
        id: selectedId
      }
    });
  };
  const content = (
    <>
      <Can
        I={userActions.create}
        a={permissionsKeyMap.sponsorship.children.sponsorships}
      >
        <IconButton
          variant="primary"
          size="sm"
          icon="link"
          transform="shrink-3"
          className="me-2"
          onClick={() => setBeneficiaryModalShow(true)}
          // disabled
        >
          <span className="ms-1 d-none d-lg-inline-block">Sponsor</span>
        </IconButton>
      </Can>
      <Can
        I={userActions.create}
        a={permissionsKeyMap.sponsorship.children.beneficiaries}
      >
        <IconButton
          variant="falcon-default"
          size="sm"
          icon="child"
          transform="shrink-3"
          className="me-2"
          onClick={() => navigate(paths.addBeneficiary)}
        >
          <span className="ms-1 d-none d-lg-inline-block">Add Beneficiary</span>
        </IconButton>
      </Can>
    </>
  );
  const hideSponsorshipModal = () => {
    setBeneficiaryModalShow(false);
  };
  const hideDonorModal = () => {
    setDonorModalShow(false);
  };
  const handleSelectBeneficiarySubmit = selectedBeneficiaryId => {
    setSelectedBeneficiaryId(selectedBeneficiaryId);
    // setBeneficiaryModalShow(false);
    setDonorModalShow(true);
  };
  const handleSelectDonorSubmit = selectedDonorId => {
    navigate(
      parseRouteParams(
        paths.createNewSponsorship,
        {},
        {
          beneficiaryId: selectedBeneficiaryId,
          donorId: selectedDonorId
        }
      )
    );
  };
  const handleExport = () => {
    setShowExportModal(true);
  };
  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleDeleteModalHide}
        loading={deleteLoading}
        title="Delete Package"
        handleConfirm={handleConfirmDelete}
        body="Are you sure you want to delete selected beneficiary?"
      />
      <SelectBeneficiaryModal
        onHide={hideSponsorshipModal}
        show={beneficiaryModalShow}
        onSubmit={handleSelectBeneficiarySubmit}
      />
      <SelectDonorModal
        onHide={hideDonorModal}
        show={donorModalShow}
        onSubmit={handleSelectDonorSubmit}
      />

      <Row className="opacity-25 d-none">
        <Col>
          <DonorCounter
            counterData={weBeneficiariesItems({
              totalBeneficiaries: tableData?.length ?? 0
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12} xxl={12} className="h-md-100 ">
          {tableData && (
            <CustomAdvanceTable
              data={tableData}
              columns={columns}
              title={`Beneficiaries (${tableData?.length ?? 0})`}
              headerContent={content}
              showSearchInput
              searchInputPlaceHolder="Search Beneficiaries"
              perPage={10}
              loading={loading}
              showTablePagination
              hasExport
              handleExport={handleExport}
              subject={permissionsKeyMap.sponsorship.children.beneficiaries}
            />
          )}
        </Col>
      </Row>
      {modalShow && (
        <DonorSponsorshipModal
          setModalShow={setModalShow}
          modalShow={modalShow}
        />
      )}
      <ExportModal
        data={tableData ?? []}
        columns={columns.slice(0, columns.length - 1)}
        show={showExportModal}
        onHide={() => setShowExportModal(false)}
        fileName={'User List'}
      />
    </>
  );
};

export default AllBeneficiaries;
