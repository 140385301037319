import React from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Spinner, Table } from 'react-bootstrap';
import classNames from 'classnames';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  bodyClassName,
  rowClassName,
  tableProps,
  loading,
  selectableRow,
  selectedRowId,
  onSelectRow = () => {}
}) => {
  return (
    <SimpleBarReact>
      <Table {...getTableProps(tableProps)}>
        <thead className={headerClassName}>
          <tr>
            {headers.map(column => (
              <th
                key={column.accessor}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        {!loading && (
          <tbody className={classNames(bodyClassName, 'borderless')}>
            {page.length === 0 && (
              <tr>
                <td colSpan={headers.length} className="text-center py-3 fs-1">
                  No data found
                </td>
              </tr>
            )}
            {page.map(row => {
              prepareRow(row);
              return (
                <>
                  <tr
                    key={row.id}
                    {...row.getRowProps()}
                    className={rowClassName}
                    // className={classNames(rowClassName, {
                    //   'border border-1 border-primary border-top-0 border-bottom-0':
                    //     selectableRow && selectedRowId === row.id
                    // })}
                    style={{
                      cursor: selectableRow ? 'pointer' : 'default'
                      // borderTop: '1px solid #2c7be5 !important'
                    }}
                    onClick={() => onSelectRow(row.original.id)}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={`${row.id}-${index}`}
                          {...cell.getCellProps(cell.column.cellProps)}
                          className={classNames({
                            'text-primary border border-1 border-primary border-start-0 border-end-0':
                              selectableRow && selectedRowId === row.id
                          })}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                </>
              );
            })}
          </tbody>
        )}
      </Table>
      {loading && (
        <div className="text-center w-100 py-3">
          <Spinner />
        </div>
      )}
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  loading: PropTypes.bool,
  selectableRow: PropTypes.bool,
  selectedRowId: PropTypes.string,
  onSelectRow: PropTypes.func
};

export default AdvanceTable;
