import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const smtpScheme = Yup.object().shape({
  username: Yup.string().required('Required Field'),
  branch: Yup.string().required('Required Field'),
  email: Yup.string().required('Required Field'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number'
    )
    .min(8, 'Must be at least 8 characters')
    .required('Required Field'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
});

const smtpResolver = yupResolver(smtpScheme);

export { smtpResolver };
