import KanbanContainer from 'components/projects/kanban/KanbanContainer';
import KanbanHeader from 'components/projects/kanban/KanbanHeader';
import KanbanProvider from 'components/projects/kanban/KanbanProvider';
import AppContext from 'context/Context';
import React, { useContext, useEffect, useState } from 'react';

const ProjectsKanban = () => {
  const {
    config: { isFluid, isNavbarVerticalCollapsed },
    setConfig
  } = useContext(AppContext);
  const [kanbanIsFluid] = useState(isFluid);
  const [kanbanIsNavbarVerticalCollapsed] = useState(isNavbarVerticalCollapsed);

  useEffect(() => {
    setConfig('isFluid', true);
    setConfig('isNavbarVerticalCollapsed', true);

    return () => {
      setConfig('isFluid', kanbanIsFluid);
      setConfig('isNavbarVerticalCollapsed', kanbanIsNavbarVerticalCollapsed);
    };
  }, []);

  return (
    <>
      <KanbanProvider>
        <KanbanHeader />
        <KanbanContainer />
      </KanbanProvider>
    </>
  );
};

export default ProjectsKanban;
