import { gql, useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import CardDropdown from 'components/common/CardDropdown';
import IconButton from 'components/common/IconButton';
import LoadingContainer from 'components/common/LoadingContainer';
import CustomAdvanceTableGiftAid from 'components/common/advance-table/CustomAdvanceTableGiftAid';
import { useAuthContext } from 'context/authContext';
import { format } from 'date-fns';
import { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate, useSearchParams } from 'react-router-dom';
import UserContext from './../../../context/UserContext';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import {
  GET_GIFT_AID_APPLICATION_QUERY,
  GIFT_AID_APPLICATION_AWAITING
} from '../gift-aid-applications';
import { Can } from 'routes/permissions/Can';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';

export const GIFT_AID_STATUS_UNCLAIMED = 'unclaimed';
export const GIFT_AID_STATUS_INPROGRESS = 'inprogress';
export const GIFT_AID_STATUS_REJECTED = 'rejected';
export const GIFT_AID_STATUS_ACCEPTED = 'accepted';

export const giftAidStatus = {
  [GIFT_AID_STATUS_UNCLAIMED]: {
    label: 'Unclaimed',
    variant: 'warning'
  },
  [GIFT_AID_STATUS_INPROGRESS]: {
    label: 'In Progress',
    variant: 'info'
  },
  [GIFT_AID_STATUS_REJECTED]: {
    label: 'Rejected',
    variant: 'danger'
  },
  [GIFT_AID_STATUS_ACCEPTED]: {
    label: 'Accepted',
    variant: 'success'
  }
};
/* -------------------------------------------------------------------------- */
const GET_GIFT_AID_QUERY = gql`
  query GetGiftAid($organisation_id: uuid = "") {
    gift_aid(
      where: {
        order: {
          organisation_id: { _eq: $organisation_id }
        }
        status: { _eq: ${GIFT_AID_STATUS_UNCLAIMED} }
      }
      order_by: { created_at: desc }
    ) {
      sponsored_event
      aggregated_donation
      status
      amount
      date_generated
      date_submitted
      created_at
      updated_at
      donar_id
      gift_aid_application_id
      id
      order_id
      order {
        donation_amount
      }
      donar {
        first_name
        last_name
        address
        title
      }
    }
    gift_aid_aggregate(
      where: { order: { organisation_id: { _eq: $organisation_id } } status: { _eq: ${GIFT_AID_STATUS_UNCLAIMED} }}
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;
const CREATE_GIFT_AID_APPLICATION_MUTATION = gql`
  mutation CreateGiftAidApplication(
    $branch_id: uuid!
    $gift_aid_data: [gift_aid_insert_input!] = { id: "", status: "" }
    $organisation_id: uuid!
    $status: String!
  ) {
    insert_gift_aid_application_one(
      object: {
        branch_id: $branch_id
        organisation_id: $organisation_id
        gift_aids: {
          data: $gift_aid_data
          on_conflict: {
            constraint: gift_aid_id_key
            update_columns: [gift_aid_application_id, status]
          }
        }
        status: $status
      }
    ) {
      status
      submitted_date
      created_at
      updated_at
      id
      gift_aids {
        id
      }
      gift_aids_aggregate {
        aggregate {
          sum {
            amount
          }
          count
        }
        nodes {
          order {
            donation_amount
          }
        }
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */

const UnclaimedTransactions = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { giftAidData, setGiftAidData } = useContext(UserContext);
  const { organization, selectedBranchId } = useAuthContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [createGiftAidApplication, { loading: createLoading }] = useMutation(
    CREATE_GIFT_AID_APPLICATION_MUTATION,
    {
      onCompleted: () => {
        setShowConfirmationModal(false);
        setSelectedRows([]);
        toast.success('Gift Aid Application Created Successfully');
        navigate(paths.giftAidApplicationsList);
      },
      onError: error => {
        toast.error(error.message);
        console.log(error);
      },
      update: (cache, { data }) => {
        const { insert_gift_aid_application_one } = data;
        const giftAidApplicationsData = cache.readQuery({
          query: GET_GIFT_AID_APPLICATION_QUERY,
          variables: {
            organisation_id: organization.id
          }
        });
        if (!giftAidApplicationsData) return;
        cache.writeQuery({
          query: GET_GIFT_AID_APPLICATION_QUERY,
          variables: {
            organisation_id: organization.id
          },
          data: {
            gift_aid_application: [
              ...giftAidApplicationsData.gift_aid_application,
              insert_gift_aid_application_one
            ]
          }
        });
      }
    }
  );
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);

  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'firstName',
      Header: 'First Name',
      cellProps: {
        className: 'py-2 fw-medium'
      }
    },
    {
      accessor: 'lastName',
      Header: 'Second Name',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'houseNumber',
      Header: 'House Number',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'postcode',
      Header: 'PostCode',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'aggregatedDonations',
      Header: 'Aggregated Donations',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'sponsoredEvent',
      Header: 'Sponsored Event',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'donationDate',
      Header: 'Donation Date',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { amount } = rowData.row.original;
        return <div>£{amount.toFixed(2).toLocaleString()}</div>;
      }
    },
    {
      accessor: 'oid',
      Header: '',
      cellProps: {
        className: 'text-end fw-medium py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row;

        return (
          <CardDropdown>
            <Can
              I={userActions.update}
              a={permissionsKeyMap.giftAid.children.giftAid}
            >
              <div className="py-2">
                {isEdit ? (
                  <Dropdown.Item onClick={handleSave}>Save</Dropdown.Item>
                ) : (
                  <Dropdown.Item
                    onClick={() => {
                      setEditId(id);
                      setIsEdit(true);
                    }}
                  >
                    Edit
                  </Dropdown.Item>
                )}
              </div>
            </Can>
          </CardDropdown>
        );
      }
    }
  ];

  const handleTransactions = (type, data) => {
    const keys = Object.keys(data);
    const unclaimed = [...giftAidData.unclaimed];
    const newTransactions = [];
    keys.forEach(key => {
      newTransactions.push(unclaimed[parseInt(key)]);
    });
    const claimed = [...giftAidData.claimed];
    if (type === 'addTransaction') {
      const ref = searchParams.get('ref');
      const updatedListings = claimed?.map(singleClaim => {
        if (singleClaim?.ref === ref) {
          const tempListings = [...singleClaim.listings];
          const concatenatedListings = tempListings.concat(newTransactions);
          singleClaim.listings = concatenatedListings;
          return singleClaim;
        }
        return singleClaim;
      });
      giftAidData.claimed = updatedListings;
      setGiftAidData(giftAidData);
    } else {
      const lastRef = claimed[claimed.length - 1].ref;
      let lastDigits = parseInt(
        lastRef.slice(lastRef.length - 3, lastRef.length)
      );
      lastDigits = String(lastDigits + 1).padStart(3, '0');
      const newRef = lastRef.substring(0, lastRef.length - 3) + lastDigits;

      const lastClaimed = claimed[claimed.length - 1];
      const newClaimed = {
        ...lastClaimed,
        ref: newRef,
        listings: newTransactions,
        status: 'Awaiting',
        variant: 'warning'
      };
      claimed.push(newClaimed);
      giftAidData.claimed = claimed;
      setGiftAidData(giftAidData);
    }
    navigate('/aid/');
  };

  const createApplicationSubmit = () => {
    const giftAidData = selectedRows.map(key => ({
      id: key,
      status: GIFT_AID_STATUS_INPROGRESS
    }));
    createGiftAidApplication({
      variables: {
        branch_id: selectedBranchId,
        gift_aid_data: giftAidData,
        organisation_id: organization.id,
        status: GIFT_AID_APPLICATION_AWAITING
      }
    });
  };

  const handleCreateClick = data => {
    setShowConfirmationModal(true);
    const keys = Object.keys(data);
    setSelectedRows(keys);
  };

  const content = data => (
    <>
      {searchParams.get('newClaim') === 'false' ? (
        <IconButton
          variant="primary"
          size="sm"
          transform="shrink-3"
          className="me-2"
          onClick={() => handleTransactions('addTransaction', data)}
        >
          <span className="ms-1 d-none d-lg-inline-block">Add Transaction</span>
        </IconButton>
      ) : (
        <IconButton
          variant="primary"
          size="sm"
          transform="shrink-3"
          className="me-2"
          onClick={() => handleCreateClick(data)}
        >
          <span className="ms-1 d-none d-lg-inline-block">Create Claim</span>
        </IconButton>
      )}
    </>
  );

  const footerContent = total => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        textAlign: 'right',
        flexWrap: 'wrap'
      }}
      className="amount-content-2"
    >
      <div>Total Amount :</div>
      <div> £ {total.toFixed(2).toLocaleString()}</div>
    </div>
  );

  const handleRowChange = (index, key, value) => {
    const temp = [...giftAidData.unclaimed];
    const tempObj = { ...temp[index] };
    if (key === 'donation_date') {
      const date = new Date(value);
      const formattedDate = date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      });
      tempObj[key] = formattedDate;
    } else {
      tempObj[key] = value;
    }
    temp[index] = tempObj;
    giftAidData.unclaimed = temp;
    // setTableData(temp);
    setGiftAidData(giftAidData);
  };

  const handleSave = () => {
    setIsEdit(false);
    setEditId(null);
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        onHide={() => setShowConfirmationModal(false)}
        title="Create Claim Confirmation"
        body="Are you sure you want to create claim?"
        handleConfirm={createApplicationSubmit}
        loading={createLoading}
      />
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          <Query
            query={GET_GIFT_AID_QUERY}
            variables={{
              organisation_id: organization.id
            }}
          >
            {({ loading, error, data }) => {
              if (loading) return <LoadingContainer />;
              if (error) return <p>Error! {error.message}</p>;
              const tableData = data.gift_aid.map(
                ({
                  donar,
                  created_at,
                  aggregated_donation,
                  sponsored_event,
                  amount,
                  id
                }) => {
                  const primaryAddress = donar.address.find(
                    singleAddress => singleAddress.isPrimary
                  );
                  const formattedDate = format(
                    new Date(created_at),
                    'dd/MM/yyyy'
                  );
                  return {
                    id,
                    title: donar.title,
                    firstName: donar.first_name,
                    lastName: donar.last_name,
                    houseNumber: primaryAddress.addressLine1,
                    postcode: primaryAddress.postcode,
                    aggregatedDonations: aggregated_donation,
                    sponsoredEvent: sponsored_event,
                    donationDate: formattedDate,
                    amount: amount
                  };
                }
              );

              return (
                <CustomAdvanceTableGiftAid
                  data={tableData}
                  columns={columns}
                  perPage={10}
                  showTablePagination={false}
                  title="Unclaimed Transactions"
                  showSearchInput
                  searchInputPlaceHolder="Search"
                  content={content}
                  addNew={false}
                  showFooterContent={true}
                  footerContent={footerContent(
                    data.gift_aid_aggregate.aggregate.sum.amount ?? 0
                  )}
                  showExportButton={false}
                  hideBulkActions={true}
                  isEditable={isEdit}
                  editId={editId}
                  handleRowChange={handleRowChange}
                  subject={permissionsKeyMap.giftAid.children.giftAid}
                />
              );
            }}
          </Query>
        </Col>
      </Row>
    </>
  );
};

export default UnclaimedTransactions;
