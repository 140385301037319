import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import Logo from 'components/common/Logo';
import paths from 'routes/paths';
import { getRelativePath } from 'helpers/utils';

const CardNav = () => {
  const location = useLocation();

  const navLinks = [
    { id: 1, title: 'Overview', link: paths.beneficiaryProfile },
    { id: 2, title: 'School', link: paths.beneficiaryShool },
    { id: 3, title: 'Mosque', link: paths.beneficiaryMosque },
    { id: 4, title: 'Orphanage', link: paths.beneficiaryOrphanage },
    { id: 5, title: 'Hospital', link: paths.beneficiaryHospital },
    { id: 6, title: 'Medical', link: paths.beneficiaryMedical },
    {
      id: 7,
      title: 'Donors',
      link: paths.beneficiaryDonors
    },
    {
      id: 8,
      title: 'Gallery',
      link: paths.beneficiaryGallery
    }
  ];

  return (
    <Card className="mt-3">
      <Card.Body className="p-3">
        <Logo width={20} textClass="h5" />

        <ListGroup activeKey={location.pathname}>
          {navLinks.map(({ id, link, isNotRelative, title }) => (
            <ListGroup.Item
              className="border-0"
              key={id}
              action
              as={Link}
              to={
                isNotRelative ? link : getRelativePath(link, paths.donorDetails)
              }
              active={
                location.pathname ===
                (isNotRelative
                  ? link
                  : getRelativePath(link, paths.donorDetails))
              }
            >
              {title}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};

export default CardNav;
