import { gql, useMutation } from '@apollo/client';
import AuthContext, { rolesMap } from 'context/authContext';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import UserForm from './UserForm';

const CREATE_BRANCH_USER = gql`
  mutation CreateBranchUser(
    $branch_id: String!
    $organisation_id: String!
    $permissions: String
    $email: String!
    $first_name: String!
    $last_name: String!
    $password: String!
    $role_id: String!
  ) {
    CreateBranchUser(
      branch_id: $branch_id
      email: $email
      first_name: $first_name
      last_name: $last_name
      organisation_id: $organisation_id
      password: $password
      role_id: $role_id
      permissions: $permissions
      title: ""
      phone: ""
    ) {
      user {
        id
        email
        first_name
        last_name
      }
    }
  }
`;

export default function CreateUser() {
  const { organization } = useContext(AuthContext);
  const navigate = useNavigate();
  const [insertUserRole, { loading }] = useMutation(CREATE_BRANCH_USER, {
    onCompleted: data => {
      console.log(data);
      navigate(paths.userRolesSetting);
    },
    onError: error => {
      toast.error('Something went wrong! Please try again');
      console.log(error);
    }
    // update: (cache, { data: { insert_users_roles_one } }) => {
    //   const existingUserRole = cache.readQuery({
    //     query: GET_USER_ROLE,
    //     variables: { organisation_id: organization.id }
    //   });
    //   if (existingUserRole) {
    //     cache.writeQuery({
    //       query: GET_USER_ROLE,
    //       variables: { organisation_id: organization.id },
    //       data: {
    //         users_roles: [
    //           insert_users_roles_one,
    //           ...existingUserRole.users_roles
    //         ]
    //       }
    //     });
    //   }
    // }
  });

  const onSubmit = values => {
    insertUserRole({
      variables: {
        password: values.password,
        email: values.email,
        permissions: values.superAdmin
          ? null
          : JSON.stringify(values.permissions),
        branch_id: values.branch,
        first_name: values.firstName,
        organisation_id: organization.id,
        role_id: values.superAdmin ? rolesMap.admin.id : rolesMap.staff.id,
        last_name: values.lastName
      }
    });
  };

  return <UserForm loading={loading} onSubmit={onSubmit} />;
}
