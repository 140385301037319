import { chartJsDefaultTooltip } from 'helpers/chartjs-utils';
import { getColor } from 'helpers/utils';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const ProductShareChart = props => {
  const {
    bgColor = 'primary',
    chartData = [60, 40],
    labels = ['Falcon', 'Sparrow']
  } = props;
  const options = {
    tooltip: chartJsDefaultTooltip(),
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: chartJsDefaultTooltip()
    }
  };
  const data = {
    labels: labels,
    datasets: [
      {
        data: chartData,
        backgroundColor: [getColor(bgColor), getColor('gray-300')],
        borderColor: [getColor(bgColor), getColor('gray-300')]
      }
    ]
  };
  return <Doughnut data={data} options={options} width="112" />;
};

ProductShareChart.propTypes = {
  bgColor: PropTypes.string,
  chartData: PropTypes.array,
  labels: PropTypes.array
};

export default ProductShareChart;
