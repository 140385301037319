import classNames from 'classnames';
import { Card } from 'react-bootstrap';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RHFCheck } from 'components/common/form';

const ADAdvancedSettings = ({ transactionFee }) => {
  const advanceSettingsData = useMemo(
    () => [
      {
        id: 1,
        label: `Cover $${transactionFee.toFixed(2)} Transaction Fees`,
        name: 'settings.coverTransactionFee'
      },
      {
        id: 2,
        label: 'Gift Aid Eligible ',
        name: 'settings.giftAid'
      }
    ],
    [transactionFee]
  );
  return (
    <Card className="mb-3">
      <Card.Header className="pb-0">
        <Card.Title className="fw-medium">Advanced Settings</Card.Title>
      </Card.Header>
      <Card.Body>
        {advanceSettingsData?.map(({ label, name }, index) => (
          <div
            key={`check-${index}`}
            className={classNames('p-3 pb-2 border rounded-3', {
              'mb-3': index !== advanceSettingsData?.length - 1
            })}
          >
            <RHFCheck name={name} label={label} type="checkbox" />
          </div>
        ))}
      </Card.Body>
    </Card>
  );
};

ADAdvancedSettings.propTypes = {
  transactionFee: PropTypes.number
};

ADAdvancedSettings.defaultProps = {
  transactionFee: 0
};

export default ADAdvancedSettings;
