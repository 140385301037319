import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import LoadingContainer from 'components/common/LoadingContainer';
import { useAuthContext } from 'context/authContext';
import { weUserImage2 } from 'data/weraise-data/donor-data';
import { parseRouteParams } from 'helpers/utils';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'routes/paths';
import {
  AttachmentsItems,
  ListItemData,
  SoftBadeItem
} from '../ProjectCommonComponents';
import ProposalFeedback from './ProposalFeedback';

/* -------------------------------------------------------------------------- */
const GET_PROJECT_BY_ID = gql`
  query GetProjectById($id: uuid!) {
    projects_by_pk(id: $id) {
      case_study
      feasibility_study
      project_labels {
        id
        label {
          id
          title
        }
      }
      project_charter
      project_name
      status
      end_date
      start_date
      average_beneficiaries_cost
      donation_amount
      target_amount
      num_of_beneficiaries
      project_description
      created_at
      updated_at
      id
      country
      userByManagerId {
        id
        first_name
        last_name
      }
      package_category {
        category_name
      }
    }
  }
`;

const ProjectProposal = () => {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_PROJECT_BY_ID, {
    variables: { id }
  });
  const { isAdmin } = useAuthContext();

  const navigate = useNavigate();

  if (loading) return <LoadingContainer />;
  if (!data) return <div className="text-center">Error fetching data</div>;

  const {
    id: ref,
    project_name,
    project_description,
    start_date,
    end_date,
    num_of_beneficiaries,
    average_beneficiaries_cost,
    target_amount,
    package_category,
    case_study,
    feasibility_study,
    project_charter,
    cities
  } = data.projects_by_pk;

  const attachments = [
    {
      id: '1',
      title: 'winter.jpg',
      type: 'image/jpeg',
      size: 121212
    },
    { id: '2', title: 'winter.png', type: 'image/jpeg', size: 1212 },
    {
      id: '3',
      title: 'title.zip',
      type: 'application/zip',
      size: 21212
    },
    {
      id: '4',
      title: 'doc.pdf',
      type: 'application/pdf',
      size: 121432121
    },
    { id: '5', title: 'data.cvs', type: 'text/csv', size: 121212 },
    {
      id: '6',
      title: 'winter 1.png',
      type: 'image/jpeg',
      size: 1212
    },
    {
      id: '7',
      title: 'title.zip',
      type: 'application/zip',
      size: 21212
    },
    {
      id: '8',
      title: 'tutorial.mp4',
      type: 'video/mp4',
      size: 12122243121
    }
  ];

  return (
    <>
      <Card className="mb-3">
        <Card.Header className="bg-100">
          <Card.Title className="fw-medium mb-0">
            <FontAwesomeIcon icon={'paperclip'} className="me-2" />
            {ref} | {project_name}
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Flex justifyContent={'between'} alignItems="center">
            <Flex alignItems={'center'}>
              <Avatar src={weUserImage2} size="2xl" />
              <div className="ms-2">
                <p className="mb-0">Ali Mohamed</p>
                <p className="mb-0 text-500">Project Manager</p>
              </div>
            </Flex>
            <Button
              className="px-md-5"
              onClick={() =>
                navigate(
                  parseRouteParams(paths.editProject, {
                    id: ref
                  })
                )
              }
            >
              Edit Form
            </Button>
          </Flex>
          <hr />
          <div className="font-base">
            <ListItemData title="Project Title" value={project_name} />
            <ListItemData
              title="Project Category"
              value={package_category?.category_name}
            />
            <ListItemData title="Start Date" value={start_date} />
            <ListItemData title="End Date" value={end_date} />
            <ListItemData
              title="Number of Beneficiaries"
              value={num_of_beneficiaries}
            />
            <ListItemData
              title="City(ies)"
              content={
                <div className="w-75">
                  <SoftBadeItem data={cities} showTotal />
                </div>
              }
            />
            <ListItemData
              title="Total Cost"
              value={`$${target_amount.toFixed(2).toLocaleString()}`}
            />
            <ListItemData
              title="Average Cost Per Beneficiaries"
              value={`$${average_beneficiaries_cost
                .toFixed(2)
                .toLocaleString()}`}
            />
            <ListItemData
              title="Project Description"
              content={project_description}
              direction="column"
            />
            <ListItemData
              title="Case Study"
              innerHtml={case_study}
              direction="column"
            />
            <ListItemData
              title="Feasibility"
              innerHtml={feasibility_study}
              direction="column"
            />
            <ListItemData
              title="Project Charter"
              innerHtml={project_charter}
              hr={false}
              direction="column"
            />
          </div>
          <AttachmentsItems
            attachments={attachments}
            deleteItem={id => console.log(id)}
            downloadAllClick={() => console.log('Download all')}
          />
        </Card.Body>
      </Card>
      {isAdmin && <ProposalFeedback />}
    </>
  );
};

export default ProjectProposal;
