import { gql, useMutation } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { Button, Card } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import LoadingContainer from 'components/common/LoadingContainer';
import TableRowAction from 'components/common/TableRowAction';
import {
  getDurationAgo,
  getRelativePath,
  getSize,
  parseRouteParams
} from 'helpers/utils';
import paths from 'routes/paths';
import { useState } from 'react';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { GET_PROJECT_REPORTS_QUERY } from '../ProjectReports';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

/* -------------------------------------------------------------------------- */
const GET_PROJECT_REPORT_BY_ID_QUERY = gql`
  query GetProjectReports($id: uuid = "") {
    project_reports_by_pk(id: $id) {
      description
      title
      url
      video_url
      created_at
      updated_at
      id
      project_id
      user {
        id
        first_name
        last_name
      }
    }
  }
`;
const DELETE_PROJECT_REPORT_MUTATION = gql`
  mutation DeleteProjectReport($id: uuid = "") {
    delete_project_reports_by_pk(id: $id) {
      id
    }
  }
`;
/* -------------------------------------------------------------------------- */

const ProjectReportDetails = () => {
  const navigate = useNavigate();
  const { reportId, id: projectId } = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteProjectReport, { loading: deleteLoading }] = useMutation(
    DELETE_PROJECT_REPORT_MUTATION,
    {
      onCompleted: () => {
        navigate(-1);
      },
      onError: error => {
        console.log(error);
      },
      update: cache => {
        const existingProjectReports = cache.readQuery({
          query: GET_PROJECT_REPORTS_QUERY,
          variables: {
            projectId
          }
        });
        if (!existingProjectReports) return;
        cache.writeQuery({
          query: GET_PROJECT_REPORTS_QUERY,
          variables: {
            projectId
          },
          data: {
            project_reports: existingProjectReports.project_reports.filter(
              report => report.id !== reportId
            )
          }
        });
      }
    }
  );
  const attachments = [
    {
      id: '1',
      title: 'winter.jpg',
      type: 'image/jpeg',
      size: 121212
    },
    { id: '2', title: 'winter.png', type: 'image/jpeg', size: 1212 },
    {
      id: '3',
      title: 'title.zip',
      type: 'application/zip',
      size: 21212
    },
    {
      id: '4',
      title: 'doc.pdf',
      type: 'application/pdf',
      size: 121432121
    },
    { id: '5', title: 'data.cvs', type: 'text/csv', size: 121212 },
    {
      id: '6',
      title: 'winter 1.png',
      type: 'image/jpeg',
      size: 1212
    },
    {
      id: '7',
      title: 'title.zip',
      type: 'application/zip',
      size: 21212
    },
    {
      id: '8',
      title: 'tutorial.mp4',
      type: 'video/mp4',
      size: 12122243121
    }
  ];

  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };

  const handleDeleteConfirm = () => {
    deleteProjectReport({
      variables: {
        id: reportId
      }
    });
  };
  return (
    <Card>
      <ConfirmationModal
        show={showDeleteConfirmation}
        onHide={() => setShowDeleteConfirmation(false)}
        title={'Delete Project Report'}
        handleConfirm={handleDeleteConfirm}
        body={'Are you sure you want to delete this project report?'}
        loading={deleteLoading}
      />
      <Query
        query={GET_PROJECT_REPORT_BY_ID_QUERY}
        variables={{ id: reportId }}
      >
        {({ loading, error, data }) => {
          if (loading) return <LoadingContainer />;
          if (error)
            return <p className="text-center fs-0">Error! {error.message}</p>;
          const { title, created_at, user, description, video_url } =
            data.project_reports_by_pk;
          const userFullName = `${user?.first_name} ${user?.last_name}`;
          const formattedDate = dayjs(created_at).format(
            'DD MMM, YYYY | hh:mm A'
          );
          const durationAgo = getDurationAgo(created_at);
          return (
            <>
              <Card.Header className="bg-100 d-flex justify-content-between align-items-center">
                <Flex alignItems={'center'}>
                  <IconButton
                    icon={'chevron-left'}
                    variant="falcon-default"
                    onClick={() => navigate(-1)}
                  />
                  <IconButton
                    icon={'envelope'}
                    className="p-0 ms-3 me-2 text-900"
                    variant="link"
                  />
                  <p className="fs-0 mb-0">{title}</p>
                </Flex>
                <TableRowAction
                  onCLickEdit={() => {
                    navigate(
                      parseRouteParams(
                        getRelativePath(
                          paths.editProjectReport,
                          paths.projectReportDetails
                        )
                      )
                    );
                  }}
                  onCLickDelete={handleDeleteClick}
                  subject={permissionsKeyMap.project.children.projects}
                />
              </Card.Header>
              <Card.Body>
                <Flex
                  justifyContent={'between'}
                  className="flex-column flex-md-row align-items-start align-items-md-center"
                >
                  <Flex alignItems={'center'}>
                    <Avatar name={userFullName} size="2xl" />
                    <div className="ms-2">
                      <p className="mb-0">{userFullName}</p>
                      {/* <p className="mb-0 text-500">Project Manager</p> */}
                    </div>
                  </Flex>
                  <p className="mb-0 text-800 mt-3 mt-md-0 w-100 w-md-auto text-end">
                    {formattedDate} <i>({durationAgo})</i>
                  </p>
                </Flex>
                <div style={{ maxWidth: '525px', marginTop: '20px' }}>
                  {/* <p className="fs-0 fw-semi-bold text-1100">{title}</p> */}
                  <div
                    className="text-700"
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                </div>
                <hr />
                <Flex className="my-3" alignItems={'center'}>
                  <FontAwesomeIcon
                    icon={faPaperclip}
                    className="text-primary"
                  />
                  <a
                    href={video_url}
                    target="_blank"
                    className="fs-0 mb-0 ms-2 text-dark"
                    rel="noreferrer"
                  >
                    {video_url}
                  </a>
                </Flex>
                {/* <hr /> */}
                <div className="bg-100 p-4 pb-3">
                  <div style={{ maxWidth: '240px' }}>
                    <ul className="ps-0">
                      {attachments?.map((file, index) => (
                        <li
                          className={classNames(
                            'list-group bg-white text-600 fs--1 border p-2 px-3',
                            {
                              'mb-2': index !== attachments.length - 1
                            }
                          )}
                          key={file.id}
                        >
                          <Flex alignItems={'center'}>
                            <FontAwesomeIcon
                              className="me-3"
                              icon={
                                (file.type === 'image/png' && 'image') ||
                                (file.type === 'image/jpeg' && 'image') ||
                                (file.type === 'text/csv' && 'file') ||
                                (file.type === 'application/pdf' &&
                                  'file-pdf') ||
                                (file.type === 'application/zip' &&
                                  'file-archive') ||
                                (file.type === 'video/mp4' && 'video') ||
                                'paperclip'
                              }
                            />
                            {file.title} ({getSize(file.size)})
                            <Button
                              variant="link"
                              className="ms-auto p-0 text-400"
                              onClick={() => {}}
                            >
                              <FontAwesomeIcon icon={'times'} />
                            </Button>
                          </Flex>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <hr />
                  <Flex
                    justifyContent={'between'}
                    alignItems="center"
                    className="flex-column flex-md-row"
                  >
                    <p className="fw-medium mb-3 mb-md-0">
                      <FontAwesomeIcon icon={'link'} className="me-3" />
                      {attachments?.length} file
                      {attachments?.length > 1 ? 's' : ''} attached
                    </p>
                    <IconButton variant="falcon-default" icon={'file-download'}>
                      Download All
                    </IconButton>
                  </Flex>
                </div>
              </Card.Body>
            </>
          );
        }}
      </Query>
    </Card>
  );
};

export default ProjectReportDetails;
