import { gql, useLazyQuery } from '@apollo/client';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { FormProvider, RHFControl } from 'components/common/form';
import useBranchPermission from 'hooks/useBranchPermission';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

/* -------------------------------------------------------------------------- */
const FILTER_DONORS = gql`
  query FilterDonors($where: donars_bool_exp!) {
    donars(where: $where, order_by: { created_at: desc }) {
      first_name
      id
      last_name
      # image
      # score
    }
  }
`;
/* -------------------------------------------------------------------------- */

const SelectDonorModal = ({ show, onHide, onSubmit, donorId }) => {
  const [selectedDonor, setSelectedDonor] = useState(null);
  const [filterDonors, { data, loading }] = useLazyQuery(FILTER_DONORS);
  const { where } = useBranchPermission();

  useEffect(() => {
    if (donorId) {
      setSelectedDonor(donorId);
    }
  }, [show, donorId]);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      name: ''
    }
  });
  const { handleSubmit } = methods;

  const onSearchSubmit = ({ name }) => {
    if (name) {
      where.name = { _ilike: `%${name}%` };
    }
    filterDonors({
      variables: { where }
    });
  };
  useEffect(() => {
    if (Object.keys(where) !== 0)
      filterDonors({
        variables: { where }
      });
  }, [where]);

  const tableData = useMemo(
    () =>
      data?.donars?.map(({ id, first_name, last_name, score }) => ({
        id,
        name: `${first_name} ${last_name}`,
        score: score ?? 0
      })),
    [data]
  );

  const columns = [
    {
      accessor: 'ref',
      Header: 'Ref.',
      cellProps: {
        className: 'fw-semi-bold py-2'
      }
      // Cell: rowData => {
      //   const { ref } = rowData.row.original;
      //   return (
      //     <span onClick={onHide} to={path}>
      //       {ref}
      //     </Link>
      //   );
      // }
    },
    {
      accessor: 'name',
      Header: 'Name',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { name, image } = rowData.row.original;
        return (
          <Flex alignItems={'center'}>
            <Avatar src={image} name={name} />
            <p className="mb-0 ms-2">{name}</p>
          </Flex>
        );
      }
    },

    {
      accessor: 'score',
      Header: 'Score',
      cellProps: {
        className: 'py-2'
      }
    }
  ];
  const handleSelectDonor = donorId => {
    if (selectedDonor === donorId) {
      setSelectedDonor(null);
      return;
    }
    setSelectedDonor(donorId);
  };

  const onNext = () => {
    onHide();
    onSubmit(selectedDonor);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter" as="h5">
          Select Donor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="g-3">
          <Col lg={4}>
            <div className="border rounded-3 p-3">
              <p className="fw-medium">Search Donor</p>
              <FormProvider
                methods={methods}
                onSubmit={handleSubmit(onSearchSubmit)}
              >
                <RHFControl name="name" label="Search" noOptional />
                <hr />
                <Button type="submit" className="w-100">
                  Search
                </Button>
              </FormProvider>
            </div>
          </Col>
          <Col lg={8} className="d-flex flex-column">
            <div className="border rounded-3 overflow-hidden">
              <AdvanceTableWrapper
                columns={columns}
                data={tableData ?? []}
                sortable
                pagination
                perPage={8}
              >
                <AdvanceTable
                  table
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    striped: true,
                    className: 'fs--1 mb-0 overflow-hidden'
                  }}
                  loading={loading}
                  selectableRow
                  selectedRowId={selectedDonor}
                  onSelectRow={handleSelectDonor}
                />
                <div className="p-3">
                  <AdvanceTableFooter
                    rowCount={tableData?.length}
                    table
                    rowInfo
                    navButtons
                  />
                </div>
              </AdvanceTableWrapper>
            </div>
            <div className="d-flex justify-content-end mt-auto">
              <Button
                size="lg"
                className="px-7"
                disabled={!selectedDonor}
                onClick={onNext}
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

SelectDonorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  donorId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

export default SelectDonorModal;
