import { gql } from '@apollo/client';

export const GET_DONOR_NOTES_COUNT = gql`
  query GetDonorNotesCount($donor_id: uuid = "") {
    donars_feedback_aggregate(where: { donar_id: { _eq: $donor_id } }) {
      aggregate {
        count
      }
    }
  }
`;
