import React, { useContext } from 'react';
// import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import AuthContext from 'context/authContext';

const ProfileDropdown = () => {
  const {
    // logout,
    user
  } = useContext(AuthContext);
  const { profile_pic, first_name, last_name } = user;
  const name = `${first_name} ${last_name}`;

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        variant="link"
        // to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={profile_pic} name={name} />
      </Dropdown.Toggle>

      {/* <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to="/donors">
            Donor
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/project">
            Projects
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={logout} className="text-danger">
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu> */}
    </Dropdown>
  );
};

export default ProfileDropdown;
