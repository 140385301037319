import { Card, Col, Nav, Row } from 'react-bootstrap';
import { useRef, useState, useEffect } from 'react';
import useScrollSpy from 'react-use-scrollspy';
import DonorForm from './DonorForm';

function DonarFormContainer(props) {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, []);

  const sideNavLinks = [
    { id: 1, href: '#name', title: 'Name' },
    { id: 2, href: '#address', title: 'Address' },
    { id: 3, href: '#phone_number', title: 'Phone No' },
    { id: 4, href: '#email_address', title: 'Email Address' },
    // {
    //   id: 5,
    //   href: '#contact_preference',
    //   title: 'Contact Preference'
    // },
    // { id: 6, href: '#gift_aid', title: 'Gift Aid' },
    { id: 7, href: '#source', title: 'Source' }
  ];
  return (
    <Row>
      <Col md={8} className="order-1 order-md-0">
        <DonorForm sectionRefs={sectionRefs} {...props} />
      </Col>
      <Col md={4} className="order-0 order-md-1 d-none d-md-block">
        <Card
          className={'mb-3 sticky-top '}
          style={{ top: stickyStatus ? '5rem' : '0' }}
        >
          <Card.Body>
            <div>
              <Nav className="flex-column" variant="pills">
                {sideNavLinks?.map(item => (
                  <Nav.Link
                    key={item.id}
                    href={item.href}
                    className={`${
                      activeSection === item.id
                        ? 'current custom-nav-link'
                        : 'custom-nav-link'
                    }`}
                    active={activeSection >= item.id}
                  >
                    {item.title}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default DonarFormContainer;
