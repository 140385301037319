import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Image } from 'react-bootstrap';
import SoftBadge from 'components/common/SoftBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom/dist';
import paths from 'routes/paths';
import { parseRouteParams } from 'helpers/utils';
import classNames from 'classnames';
import { Can } from 'routes/permissions/Can';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';
// import activeStore from 'assets/img/icons/store-active.svg';
// import store from 'assets/img/icons/store.svg';
// import storeDeactive from 'assets/img/icons/store-deactive.svg';

const BranchCard = ({
  name,
  isActive,
  image,
  id,
  organization,
  handleClick,
  isButton
}) => {
  const statusText = isActive ? 'Active' : 'Inactive';
  const statusColor = isActive ? 'success' : 'warning';
  const statusIcon = isActive ? 'check' : 'lock';
  const navigate = useNavigate();
  const handleEditBranch = () => {
    if (organization) navigate(parseRouteParams(paths.branchEdit, { id }));
    else navigate(parseRouteParams(paths.editBranch, { id }));
  };

  return (
    <Card>
      <Card.Body
        className={classNames('d-flex flex-column align-items-center pb-0 ', {
          'pb-5 cursor-pointer': isButton
        })}
        onClick={handleClick}
      >
        <SoftBadge pill bg={statusColor} className="float-center">
          <FontAwesomeIcon icon={statusIcon} className="me-1" />
          {statusText}
        </SoftBadge>
        <Image width={150} className="px-3 py-3 mx-5" src={image} />
        <h5 className="text-dark">{name}</h5>
      </Card.Body>
      {!isButton && (
        <Card.Footer className="py-0">
          <hr className="text-300 border-2 border-top text-300 w-100 mb-0" />
          <Can
            I={userActions.update}
            a={permissionsKeyMap.settings.children.branches}
          >
            <Button
              variant="outline-secondary"
              className="w-100 border-0 fw-normal my-1"
              onClick={handleEditBranch}
            >
              Edit Branch
            </Button>
          </Can>
        </Card.Footer>
      )}
    </Card>
  );
};

BranchCard.propTypes = {
  name: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  image: PropTypes.string.isRequired,
  id: PropTypes.number,
  organization: PropTypes.bool,
  handleClick: PropTypes.func,
  isButton: PropTypes.bool
};

export default BranchCard;
