import React, { useEffect, useState } from 'react';
import { Card, Image, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logo from 'assets/img/illustrations/weraise.png';
import paths from 'routes/paths';
import { getRelativePath } from 'helpers/utils';

const donorNavLinks = [
  {
    id: 1,
    title: 'Project Proposal',
    link: getRelativePath(paths.projectProposal, paths.projectDetails)
  },
  {
    id: 2,
    title: 'Project Progress',
    link: getRelativePath(paths.projectReports, paths.projectDetails)
  },
  {
    id: 3,
    title: 'Complete Project',
    link: getRelativePath(paths.completeProject, paths.projectDetails)
  }
];

const ProjectNav = () => {
  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 10) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, [window.scrollY]);

  return (
    <div
      className="font-sans-serif sticky-top"
      style={{ top: stickyStatus ? '5rem' : '0' }}
    >
      <Card>
        <Card.Body>
          <div className="mb-4">
            <Image src={logo} height="24px" width="24px" />
            <span className="ms-2 fs--1 fw-medium text-900">
              Project Status
            </span>
          </div>
          <Nav variant="pills" className="flex-column">
            {donorNavLinks.map(navItem => (
              <Nav.Item key={navItem.id}>
                <Nav.Link
                  className="fs--1 fw-medium donor-profile-nav"
                  as={NavLink}
                  to={navItem.link}
                >
                  {navItem.title}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ProjectNav;
