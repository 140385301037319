import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import paths from 'routes/paths';

const ModalSidebar = () => {
  // const id = 'AL' + (Math.floor(Math.random() * 100) + 1);

  const [addToCardMenu] = useState([
    { icon: 'user', title: 'Members', link: '#!' },
    { icon: 'tag', title: 'Label', link: '#!' },
    { icon: 'paperclip', title: 'Attachments', link: '#!' },
    { icon: 'check', title: 'Checklists', link: '#!' }
  ]);

  const [actionMenu] = useState([
    {
      icon: 'file-pdf',
      title: 'Project Proposal',
      link: paths.createProject,
      color: 'info'
    },
    {
      icon: 'chevron-circle-right',
      title: 'Project Progress',
      link: paths.projectMilestones,
      color: 'primary'
    },
    {
      icon: 'check-circle',
      title: 'Complete Project',
      link: paths.completeProject,
      color: 'success'
    },
    {
      icon: 'stop-circle',
      title: 'Stop',
      link: '#!',
      color: 'default'
    }
  ]);
  return (
    <>
      <h6 className="mt-5 mt-lg-0">Add To Card</h6>
      {addToCardMenu.map(menu => (
        <Nav key={menu.title} className="flex-lg-column fs--1">
          <Nav.Item className="me-2 me-lg-0">
            <Nav.Link as={Link} to="#!" className="nav-link-card-details">
              <FontAwesomeIcon icon={menu.icon} className="me-2" />
              {menu.title}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      ))}

      <h6 className="mt-3">Actions</h6>
      {actionMenu.map(menu => (
        <Nav key={menu.title} className="flex-lg-column fs--1">
          <Nav.Item className="me-2 me-lg-0">
            <Nav.Link
              as={Link}
              to={menu.link}
              className={`bg-soft-${menu.color} text-${menu.color} nav-link-card-details`}
            >
              <FontAwesomeIcon icon={menu.icon} className="me-2" />
              {menu.title}
            </Nav.Link>
          </Nav.Item>
        </Nav>
      ))}
    </>
  );
};

export default ModalSidebar;
