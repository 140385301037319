import React from 'react';
import sessionImage from 'assets/img/illustrations/session.png';
import { Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';
export default function SessionOut() {
  const navigate = useNavigate();
  return (
    <div className="text-center">
      <Image src={sessionImage} />
      <p className="h4">Your Session has expired</p>
      <p>Please Login Again.</p>
      <Button
        style={{ borderRadius: '30px' }}
        onClick={() => {
          navigate(paths.login);
        }}
      >
        Login
      </Button>
    </div>
  );
}
