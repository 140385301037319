import { gql, useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_URGENT_APPEALS_BY_ORGANIZATION } from '.';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from 'context/authContext';
import paths from 'routes/paths';
import UrgentAppealForm from './urgent-appeal-form/UrgentAppealForm';
import useUpload from 'hooks/useUpload';

const CREATE_URGENT_APPEAL = gql`
  mutation CreateUrgentAppeal(
    $branch_id: uuid = ""
    $organisation_id: uuid = ""
    $description: String = ""
    $title: String = ""
    $targeted_amount: Int = ""
    $images: jsonb = ""
    $package_id: uuid = ""
  ) {
    insert_urgent_appeals_one(
      object: {
        branch_id: $branch_id
        organisation_id: $organisation_id
        description: $description
        title: $title
        targeted_amount: $targeted_amount
        images: $images
        package_id: $package_id
        is_display: false
      }
    ) {
      title
      id
      organisation_id
      branch_id
      description
      targeted_amount
      package_id
      is_display
      images
    }
  }
`;

function CreateUrgentAppeal() {
  const navigate = useNavigate();
  const { organization, selectedBranchId } = useContext(AuthContext);
  const [createUrgentAppeal, { loading }] = useMutation(CREATE_URGENT_APPEAL, {
    onCompleted: () => {
      toast.success('Urgent appeal created successfully');
      navigate(paths.urgentAppeals);
    },
    onError: error => {
      toast.error(error.message);
      console.log(error);
    },
    update: (cache, data) => {
      const newUrgentAppeal = data.data.insert_urgent_appeals_one;
      const existingUrgentAppeals = cache.readQuery({
        query: GET_URGENT_APPEALS_BY_ORGANIZATION,
        variables: { organizationId: organization.id }
      });
      if (existingUrgentAppeals) {
        cache.writeQuery({
          query: GET_URGENT_APPEALS_BY_ORGANIZATION,
          variables: { organizationId: organization.id },
          data: {
            urgent_appeals: [
              newUrgentAppeal,
              ...existingUrgentAppeals.urgent_appeals
            ]
          }
        });
      }
    }
  });
  const { upload, loading: uploadLoading } = useUpload();

  const onSubmit = async data => {
    let image = data.images;
    try {
      if (image && typeof image !== 'string') {
        const profileUrl = await upload(image);
        image = profileUrl;
      }
    } catch (e) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }
    createUrgentAppeal({
      variables: {
        ...data,
        branch_id: selectedBranchId,
        organisation_id: organization.id,
        images: { primary: image }
      }
    });
  };
  return (
    <UrgentAppealForm onSubmit={onSubmit} loading={loading || uploadLoading} />
  );
}

export default CreateUrgentAppeal;
