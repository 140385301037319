import { Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
import SoftBadge from 'components/common/SoftBadge';
import React, { useEffect, useState } from 'react';
import IconButton from 'components/common/IconButton';
import SelectBeneficiaryModal from './SelectBeneficiaryModal';
import paths from 'routes/paths';
import LoadingContainer from 'components/common/LoadingContainer';
import { parseRouteParams } from 'helpers/utils';

/* -------------------------------------------------------------------------- */
const GET_DONOR = gql`
  query GetDonor($id: uuid!) {
    donars_by_pk(id: $id) {
      first_name
      id
      last_name
      title
    }
  }
`;
/* -------------------------------------------------------------------------- */

const SponsorInfoItem = ({
  icon,
  iconColor = 'text-800',
  label,
  value,
  className = 'mb-3 fw-medium text-600'
}) => (
  <p className={className}>
    {icon && <FontAwesomeIcon icon={icon} className={`${iconColor} me-2`} />}
    {label} {value && `: ${value}`}
  </p>
);

SponsorInfoItem.propTypes = {
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string
};

const CreateSponsorNav = ({ donorId, onChangeDonor }) => {
  const navigate = useNavigate();
  const { data: donorData, loading: donorLoading } = useQuery(GET_DONOR, {
    variables: { id: donorId }
  });
  const [stickyStatus, setStickyStatus] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 10) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, []);

  const { first_name, last_name, title, image } = donorData?.donars_by_pk || {};
  const fullName = `${first_name} ${last_name}`;

  return donorLoading ? (
    <LoadingContainer />
  ) : (
    <>
      <div
        className="font-sans-serif sticky-top"
        style={{ top: stickyStatus ? '5rem' : '0' }}
      >
        <Card className="mb-3">
          <Card.Body>
            <Flex
              justifyContent="between"
              alignItems="center"
              className={'mb-3'}
            >
              <h6 className="mb-0">Donor Account</h6>
              <SoftBadge pill bg="primary" className="align-self-center fs--2">
                <FontAwesomeIcon icon="user" className="me-1" /> Individual
              </SoftBadge>
            </Flex>
            <div className="text-center">
              <Avatar
                src={image}
                name={fullName}
                rounded="circle"
                size="4xl"
                className="border border-5 border-white rounded-circle shadow-sm mb-2"
              />

              <p className="mb-1 fs--1 text-primary fw-medium">
                {title} {fullName}
              </p>
              <p className="mb-0 fs--2 text-700 font-base">
                ID AC {donorId}
                <IconButton
                  onClick={() =>
                    navigate(parseRouteParams(paths.editDonor, { id: donorId }))
                  }
                  variant="default"
                  className="p-0 shadow-none fs--1"
                  iconClassName={'ms-2'}
                  icon={'external-link-alt'}
                />
              </p>
              <Button
                onClick={onChangeDonor}
                variant="default"
                className="p-0 shadow-none fs--1 mt-2 "
              >
                (Change Account)
              </Button>
            </div>
            <hr className="text-200" />
            <div className="opacity-25 d-none">
              <Flex
                justifyContent={'between'}
                alignItems="center"
                className="mb-4"
              >
                <p className="mb-0 fw-medium">Gift Aid</p>
                <IconButton
                  variant="default"
                  className="p-0 shadow-none fs--1"
                  icon={'pencil-alt'}
                  onClick={() => navigate(paths.addNewDonar)}
                />
              </Flex>
              <SponsorInfoItem
                icon="exclamation-triangle"
                iconColor="text-warning"
                label="Ineligible"
                value="Unspecified"
              />
              <SponsorInfoItem
                icon="calendar-alt"
                label="Declared on"
                value="12/05/2022"
              />
              <SponsorInfoItem icon="globe" label="Via Web Form" />
            </div>
            <hr />
            <div className="opacity-25 d-none">
              <Flex
                justifyContent={'between'}
                alignItems="center"
                className="mb-4"
              >
                <p className="mb-0 fw-medium">Contact Preferences</p>
                <IconButton
                  variant="default"
                  className="p-0 shadow-none fs--1"
                  icon={'pencil-alt'}
                  onClick={() => navigate(paths.addNewDonar)}
                />
              </Flex>
              <SponsorInfoItem icon="envelope" label="Email (3/5)" />
              <SponsorInfoItem icon="comment-dots" label="SMS (1/2)" />
              <SponsorInfoItem icon="phone-alt" label="Telephone (0/5)" />
              <SponsorInfoItem
                icon="file-alt"
                label="Post (0/5)"
                className="mb-0 fw-medium text-600"
              />
            </div>
          </Card.Body>
        </Card>
      </div>
      <SelectBeneficiaryModal
        setModalShow={setModalShow}
        modalShow={modalShow}
        path="#"
      />
    </>
  );
};

CreateSponsorNav.propTypes = {
  donorId: PropTypes.string,
  onChangeDonor: PropTypes.func
};

export default CreateSponsorNav;
