import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';

import { useLazyQuery } from '@apollo/client';
import { GET_SCHOOLS } from '../queries';

import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import useBranchPermission from '../../../hooks/useBranchPermission';

import { Col, Row } from 'react-bootstrap';

import { FormProvider, RHFDatePicker, RHFSelect } from '../../common/form';
import Flex from '../../common/Flex';
import LoadingButton from '../../common/LoadingButton';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = Yup.object().shape({
  school: Yup.string().required('Required Field'),
  startDate: Yup.string().required('Required Field'),
  endDate: Yup.string().nullable()
});

function SchoolModalForm({
  onSubmit,
  isSubmitLoading,
  actionType,
  initValues
}) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      school: '',
      startDate: '',
      endDate: null
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;

  const { where } = useBranchPermission();
  const [getSchool, { data: schoolData }] = useLazyQuery(GET_SCHOOLS);

  const schoolOptions = useMemo(() => {
    return schoolData?.schools.map(item => {
      return { value: item.id, label: item.school_name };
    });
  }, [schoolData]);

  useEffect(() => {
    getSchool({ variables: { where } });
  }, [where, getSchool]);

  useEffect(() => {
    if (actionType === 'EDIT' && initValues) {
      reset(initValues);
    }
  }, [actionType, initValues, reset]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitLoading}
    >
      <Flex gap={3} direction="column" className="mt-3">
        <Row>
          <Col md={12}>
            <RHFSelect
              name={'school'}
              label={'School'}
              options={schoolOptions}
              required={true}
            />
          </Col>
          <Col md={6}>
            <RHFDatePicker
              name={'startDate'}
              label={'Start Date'}
              required={true}
              max={false}
            />
          </Col>
          <Col md={6}>
            <RHFDatePicker name={'endDate'} label={'End Date'} max={false} />
          </Col>
        </Row>
        <LoadingButton
          style={{ width: '100%', color: 'white' }}
          variant="primary"
          loading={isSubmitLoading}
          disabled={isSubmitLoading}
          className="w-100"
          type="submit"
        >
          {'Save'}
        </LoadingButton>
      </Flex>
    </FormProvider>
  );
}

SchoolModalForm.propTypes = {
  actionType: PropTypes.oneOf(['ADD', 'EDIT']),
  initValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  isSubmitLoading: PropTypes.bool.isRequired
};

export default SchoolModalForm;
