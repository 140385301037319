import bg from 'assets/img/backgrounds/registration-bg.png';
import Logo from 'components/common/Logo';
import { useBreakpoints } from 'hooks/useBreakpoints';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

export default function RegistrationLayout() {
  const { breakpoints } = useBreakpoints();
  const isSmallScreen = breakpoints.down('md');

  return (
    <div
      className="w-100 bg-white max-h-100"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        overflow: 'auto',
        backgroundPositionY: 'center'
      }}
    >
      <div className="h-100">
        <Row className="h-100 w-100 m-0">
          <Col
            lg={6}
            sm={12}
            className="bg-white h-auto d-flex align-items-center"
            style={{ placeContent: 'center' }}
          >
            <Container className="my-3 mx-sm-auto" style={{ overflow: 'auto' }}>
              <Logo at="navbar-top" width={30} />
              <Outlet />
            </Container>
          </Col>
          {!isSmallScreen && <Col md={6} sm={0}></Col>}
        </Row>
      </div>
    </div>
  );
}
