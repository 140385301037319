import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFControl } from 'components/common/form';
import { Col, Row } from 'react-bootstrap';
import LoadingButton from 'components/common/LoadingButton';

const schema = Yup.object().shape({
  oldPassword: Yup.string().required('Required Field'),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number'
    )
    .min(8, 'Must be at least 8 characters')
    .required('Required Field'),
  confirmPassword: Yup.string()
    .required()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});

const changePasswordResolver = yupResolver(schema);

export default function ChangePassword() {
  const loading = false;
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    },
    resolver: changePasswordResolver
  });
  const { handleSubmit } = methods;
  const onSubmit = data => {
    console.log(data);
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column gap-3">
        <Row>
          <Col md={6}>
            <RHFControl
              label={'Old Password'}
              name={'oldPassword'}
              placeholder={'Old Password'}
              type="password"
              required
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <RHFControl
              label={'New Password'}
              name={'newPassword'}
              placeholder={'New Password'}
              type="password"
              required
            />
          </Col>
          <Col md={6}>
            <RHFControl
              label={'Confirm Password'}
              name={'confirmPassword'}
              placeholder={'Confirm Password'}
              type="password"
              required
            />
          </Col>
        </Row>

        <LoadingButton loading={loading} type="submit" className="mb-2">
          Change
        </LoadingButton>
      </div>
    </FormProvider>
  );
}
