import ShowMoreText from 'react-show-more-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import { getSize } from 'helpers/utils';
import React from 'react';

export const ListItemData = ({
  title,
  value,
  content,
  hr = true,
  direction = 'row',
  innerHtml
}) => (
  <>
    <Flex
      className={classNames({ 'mb-3': !hr })}
      justifyContent={'between'}
      direction={direction}
    >
      <p
        className={classNames('mb-0 fs-0 text-900', {
          'mb-2': direction === 'column'
        })}
      >
        {title}
      </p>
      {value ? <p className="mb-0 fs-0">{value}</p> : content}

      {innerHtml && (
        <ShowMoreText
          /* Default options */
          lines={2}
          more="Show more"
          less="Show less"
          className="content-css"
          anchorClass="show-more-less-clickable fw-semi-bold text-primary"
          // onClick={this.executeOnClick}
          expanded={false}
          // width={280}
          truncatedEndingComponent={'... '}
        >
          <div
            className="mb-0 fs-0"
            dangerouslySetInnerHTML={{ __html: innerHtml }}
          />
        </ShowMoreText>
      )}
    </Flex>
    {hr && <hr />}
  </>
);

ListItemData.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  content: PropTypes.node,
  hr: PropTypes.bool,
  direction: PropTypes.string,
  innerHtml: PropTypes.string
};

export const SoftBadeItem = ({ data = [], showTotal = false }) => (
  <Flex className="flex-wrap justify-content-end">
    {showTotal && <p className="mb-0 fs-0 me-2">({data?.length})</p>}
    {data.map((item, index) => (
      <p
        key={index}
        className={classNames('mb-0 rounded-2 fw-semi-bold', {
          'me-2': index !== data?.length - 1
        })}
        style={{
          backgroundColor: '#E5F7FF',
          color: '#1978A2',
          padding: '2px 8px',
          fontSize: '12px'
        }}
      >
        {item}
      </p>
    ))}
  </Flex>
);

SoftBadeItem.propTypes = {
  data: PropTypes.array,
  showTotal: PropTypes.bool
};

export const AttachmentsItems = ({
  attachments,
  deleteItem,
  downloadAllClick
}) => {
  return (
    <div className="bg-100 p-4 pb-3">
      <Flex className={'flex-wrap'}>
        {attachments?.map((file, index) => (
          <div
            style={{ width: '240px' }}
            className={classNames(
              'list-group bg-white text-600 fs--1 border p-2 px-3 mb-3',
              {
                'me-3': index !== attachments.length - 1
              }
            )}
            key={file.id}
          >
            <Flex alignItems={'center'}>
              <FontAwesomeIcon
                className="me-3"
                icon={
                  (file.type === 'image/png' && 'image') ||
                  (file.type === 'image/jpeg' && 'image') ||
                  (file.type === 'text/csv' && 'file') ||
                  (file.type === 'application/pdf' && 'file-pdf') ||
                  (file.type === 'application/zip' && 'file-archive') ||
                  (file.type === 'video/mp4' && 'video') ||
                  'paperclip'
                }
              />
              {file.title} ({getSize(file.size)})
              <Button
                variant="link"
                className="ms-auto p-0 text-400"
                onClick={() => deleteItem(file.id)}
              >
                <FontAwesomeIcon icon={'times'} />
              </Button>
            </Flex>
          </div>
        ))}
      </Flex>

      <hr className="mt-3" />
      <Flex
        justifyContent="between"
        alignItems="center"
        className="flex-column flex-md-row"
      >
        <p className="fw-medium mb-3 mb-md-0">
          <FontAwesomeIcon icon={'link'} className="me-3" />
          {attachments?.length} file
          {attachments?.length > 1 ? 's' : ''} attached
        </p>
        <IconButton
          onClick={downloadAllClick}
          variant="falcon-default"
          icon={'file-download'}
        >
          Download All
        </IconButton>
      </Flex>
    </div>
  );
};

AttachmentsItems.propTypes = {
  attachments: PropTypes.array,
  deleteItem: PropTypes.func,
  downloadAllClick: PropTypes.func
};
