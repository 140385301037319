import { gql, useLazyQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { useContext, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import AuthContext from 'context/authContext';
import CrudCard from '../../common/CrudCard/CrudCard';

/* -------------------------------------------------------------------------- */
const CREATE_SPONSORSHIP_TYPE = gql`
  mutation CreateSponsorshipType($type: String!, $organisation_id: uuid!) {
    insert_sponsorship_type_one(
      object: { type: $type, organisation_id: $organisation_id }
    ) {
      id
      organisation_id
      type
      created_at
    }
  }
`;

const UPDATE_SPONSORSHIP_TYPE = gql`
  mutation UpdateSponsorshipType($id: uuid!, $type: String!) {
    update_sponsorship_type_by_pk(
      pk_columns: { id: $id }
      _set: { type: $type }
    ) {
      id
      organisation_id
      type
      created_at
    }
  }
`;

const DELETE_SPONSORSHIP_TYPE = gql`
  mutation DeleteSponsorshipType($id: uuid!) {
    delete_sponsorship_type_by_pk(id: $id) {
      id
    }
  }
`;

const GET_SPONSORSHIP_TYPES = gql`
  query GetSponsorshipType($where: sponsorship_type_bool_exp!) {
    sponsorship_type(where: $where) {
      id
      organisation_id
      type
      created_at
    }
  }
`;
/* -------------------------------------------------------------------------- */
function SponsorshipTypes({ sectionRef, sideNavLink, subject }) {
  const cardRef = useRef();

  const { organization } = useContext(AuthContext);

  const [createType, { loading: createLoading }] = useMutation(
    CREATE_SPONSORSHIP_TYPE,
    {
      refetchQueries: ['GetSponsorshipType'],
      onCompleted: () => {
        toast.success('Sponsorship Type created successfully');
        cardRef.current.onCreationSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [updateType, { loading: updateLoading }] = useMutation(
    UPDATE_SPONSORSHIP_TYPE,
    {
      refetchQueries: ['GetSponsorshipType'],
      onCompleted: () => {
        toast.success('Sponsorship Type updated successfully');
        cardRef.current.onEditSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );
  const [deleteType, { loading: deleteLoading }] = useMutation(
    DELETE_SPONSORSHIP_TYPE,
    {
      refetchQueries: ['GetSponsorshipType'],
      onCompleted: () => {
        toast.success('Sponsorship Type deleted successfully');
        cardRef.current.onDeleteSuccess();
      },
      onError: error => {
        toast.error('Something went wrong! Please try again');
        console.log(error);
      }
    }
  );

  const [getSponsorshipType, { data }] = useLazyQuery(GET_SPONSORSHIP_TYPES);

  useEffect(() => {
    getSponsorshipType({
      variables: { where: { organisation_id: { _eq: organization.id } } }
    });
  }, [organization]);

  const handleCreate = ({ type }) => {
    createType({
      variables: {
        type,
        organisation_id: organization.id
      }
    });
  };
  const handleEdit = ({ id, data }) => {
    updateType({
      variables: {
        id,
        type: data.type
      }
    });
  };
  const handleDelete = id => {
    deleteType({
      variables: {
        id
      }
    });
  };
  const list = data?.sponsorship_type.map(type => ({
    id: type.id,
    values: [
      {
        value: type.type,
        name: 'type'
      }
    ]
  }));
  return (
    <CrudCard
      ref={cardRef}
      sectionRef={sectionRef}
      sideNavLink={sideNavLink}
      createData={handleCreate}
      editData={handleEdit}
      deleteData={handleDelete}
      createLoading={createLoading}
      updateLoading={updateLoading}
      deleteLoading={deleteLoading}
      formTitle={'Add Sponsorship Type'}
      listTitle={'Current Sponsorship Type'}
      title={'Sponsorship Types'}
      inputs={[
        {
          name: 'type',
          placeholder: 'Add Sponsorship Type',
          type: 'text',
          required: true,
          value: '',
          validation: yup.string().required('Sponsorship Type is required')
        }
      ]}
      list={list}
      subject={subject}
    />
  );
}

SponsorshipTypes.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  subject: PropTypes.string
};

export default SponsorshipTypes;
