import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import Flex from 'components/common/Flex';
import { FormProvider, RHFControl } from 'components/common/form';
import LoadingButton from 'components/common/LoadingButton';
import { useEffect } from 'react';

const schema = Yup.object().shape({
  comment: Yup.string().required('Required Field')
});

function CommentFormModal({
  show,
  onHide,
  isEdit,
  initialValues,
  onSubmit,
  loading
}) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: initialValues ?? {
      comment: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, watch, reset } = methods;
  const { comment } = watch();
  useEffect(() => {
    reset();
  }, [show, reset]);
  useEffect(() => {
    if (initialValues) {
      reset(initialValues);
    }
  }, [initialValues, reset]);

  const headerText = isEdit ? 'Edit Comment' : 'Create Comment';
  const submitText = isEdit ? 'Save' : 'Create';
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{headerText}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Flex gap={3} direction="column" className="mt-3">
            <RHFControl name="comment" placeholder="Comment" autoFocus />
            <LoadingButton
              type="submit"
              color="primary"
              className="w-100"
              disabled={!comment}
              loading={loading}
            >
              {submitText}
            </LoadingButton>
          </Flex>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
}

CommentFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default CommentFormModal;
