import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RHFControl } from 'components/common/form';

export const InspirationCard = ({ sectionRef, sideNavLink }) => {
  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <div className="dFlexRowStartSpace pt-3">
          <Card.Title as="h5">Inspiration</Card.Title>
        </div>
      </Card.Header>
      <Card.Body>
        <RHFControl name="inspiration" label="Inspiration" />
      </Card.Body>
    </Card>
  );
};

InspirationCard.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
