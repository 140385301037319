import { useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import TinymceEditor from 'components/common/TinymceEditor';
import {
  FormProvider,
  RHFControl,
  RHFDatePicker,
  RHFSelect
} from 'components/common/form';
import { GET_PACKAGE_CATEGORIES } from 'components/donations/donation-packages/package-form/package-options-queries';
import { useAuthContext } from 'context/authContext';
import {
  getCitiesOptions,
  getCountriesOptions
} from 'data/options/countris-and-cities';
import { getSize } from 'helpers/utils';
import useCurrenciesOptions from 'hooks/useCurrenciesOptions';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { PROJECT_STATUS_PROPOSED } from '../ProjectsList';
import { GET_BRANCH_USERS } from '../kanban/AddProjectMember';
import { projectScheme } from '../projectScheme';
import { toast } from 'react-toastify';

const PROJECT_TYPE_FIXED = 'fixed';
const PROJECT_TYPE_ONGOING = 'ongoing';

const projectTypesLabels = {
  [PROJECT_TYPE_FIXED]: 'Fixed',
  [PROJECT_TYPE_ONGOING]: 'On going'
};

const projectTyeOptions = [
  { label: projectTypesLabels[PROJECT_TYPE_FIXED], value: PROJECT_TYPE_FIXED },
  {
    label: projectTypesLabels[PROJECT_TYPE_ONGOING],
    value: PROJECT_TYPE_ONGOING
  }
];

/* -------------------------------------------------------------------------- */

export default function ProjectProposalForm({ onSubmit, initValues, loading }) {
  const { organization, selectedBranchId } = useAuthContext();
  const { currenciesOptions, loading: currenciesLoading } =
    useCurrenciesOptions();
  const { data: projectCategoriesData, loading: projectCategoriesLoading } =
    useQuery(GET_PACKAGE_CATEGORIES, {
      variables: {
        organizationId: organization.id,
        branchId: selectedBranchId
      }
    });
  const { data: branchUsersData, loading: branchUsersLoading } = useQuery(
    GET_BRANCH_USERS,
    {
      variables: {
        branchId: selectedBranchId
      }
    }
  );

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      title: '',
      category: '',
      description: '',
      cities: '',
      startDate: '',
      endDate: '',
      numOfBeneficiaries: '',
      currency: '',
      estimatedCost: '',
      averageBeneficiariesCost: '',
      type: '',
      manager: '',
      caseStudy: '',
      feasibilityStudy: '',
      projectCharter: ''
    },
    resolver: yupResolver(projectScheme)
  });

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors }
  } = methods;

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      toast.error(errors[Object.keys(errors)[0]].message);
    }
  }, [errors]);
  const country = watch('country');
  const caseStudy = watch('caseStudy');
  const feasibility = watch('feasibilityStudy');
  const projectCharter = watch('projectCharter');

  const branchUsersOptions = useMemo(() => {
    if (!branchUsersData) return [];
    return branchUsersData.users_roles.map(({ user }) => ({
      value: user.id,
      label: `${user.first_name} ${user.last_name}`
    }));
  }, [branchUsersData]);

  const projectCategoryOptions = useMemo(
    () =>
      projectCategoriesData?.package_category?.map(({ id, category_name }) => ({
        label: category_name,
        value: id
      })),
    [projectCategoriesData]
  );
  useEffect(() => {
    if (initValues) {
      const startDate = new Date(initValues.start_date);
      const endDate = new Date(initValues.end_date);
      reset({
        status: PROJECT_STATUS_PROPOSED,
        startDate,
        endDate,
        title: initValues.project_name,
        description: initValues.project_description,
        estimatedCost: initValues.target_amount,
        manager: initValues.manager_id,
        type: initValues.type,
        category: initValues.package_category_id,
        numOfBeneficiaries: initValues.num_of_beneficiaries,
        currency: initValues.currency_id,
        averageBeneficiariesCost: initValues.average_beneficiaries_cost,
        isOnGoing: initValues.is_on_going,
        caseStudy: initValues.case_study,
        feasibilityStudy: initValues.feasibility_study,
        projectCharter: initValues.project_charter,
        organization_id: organization.id,
        country: initValues.country,
        cities: initValues.cities,
        branch_id: selectedBranchId
      });
    }
  }, [initValues]);

  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const citiesOptions = useMemo(() => {
    if (country) {
      return getCitiesOptions(country);
    }
    return [];
  }, [country]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(acceptedFiles);
  }, [acceptedFiles]);

  const allFiles = files.map((file, index) => (
    <li
      className={classNames('list-group border p-2 px-3', {
        'mb-2': index !== files.length - 1
      })}
      key={file.path}
    >
      <Flex alignItems={'center text-word-break'}>
        <FontAwesomeIcon
          className="me-3"
          icon={
            (file.type === 'image/png' && 'image') ||
            (file.type === 'image/jpeg' && 'image') ||
            (file.type === 'text/csv' && 'file') ||
            (file.type === 'application/pdf' && 'file-pdf') ||
            (file.type === 'application/zip' && 'file-archive') ||
            (file.type === 'video/mp4' && 'video') ||
            'paperclip'
          }
        />
        {file.path} - {getSize(file.size)}
        <Button
          variant="link"
          className="ms-auto p-0"
          onClick={() => handleRemove(file.path)}
        >
          <FontAwesomeIcon icon={'times'} />
        </Button>
      </Flex>
    </li>
  ));

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Card className="mb-3">
          <Card.Header>
            <Card.Title className="fw-medium">Primary Information</Card.Title>
          </Card.Header>
          <Card.Body className="pt-0">
            <Row className="mb-3 g-3">
              <Col md={6}>
                <RHFControl
                  name="title"
                  placeholder="Project Title"
                  label="Project Title"
                  required
                />
              </Col>
              <Col md="6">
                <RHFSelect
                  name="category"
                  label="Project Category"
                  options={projectCategoryOptions}
                  isLoading={projectCategoriesLoading}
                  required
                />
              </Col>
              <Col md="12">
                <RHFControl
                  name="description"
                  placeholder="Project Description"
                  label="Project Description"
                  as="textarea"
                  rows={3}
                  required
                />
              </Col>
              <Col md="12">
                <RHFSelect
                  name="country"
                  label="Country"
                  required
                  options={countriesOptions}
                />
              </Col>
              <Col md="12">
                <RHFSelect
                  name="cities"
                  label="Cities"
                  options={citiesOptions}
                  isDisabled={citiesOptions.length === 0}
                  required
                  isMulti
                />
              </Col>
              <Col md="6">
                <RHFControl
                  name="numOfBeneficiaries"
                  placeholder="Number of Beneficiaries"
                  label="Number of Beneficiaries"
                  required
                />
              </Col>
              <Col md="6">
                <RHFSelect
                  name="currency"
                  placeholder="Currency"
                  label="Currency"
                  options={currenciesOptions}
                  isLoading={currenciesLoading}
                  required
                />
              </Col>
              <Col md="6">
                <RHFDatePicker name="startDate" label="Start Date" required />
              </Col>
              <Col md="6">
                <RHFDatePicker name="endDate" label="End Date" required />
              </Col>
              <Col md="6">
                <RHFControl
                  name="estimatedCost"
                  placeholder="Estimated Cost"
                  label="Estimated Cost"
                  required
                />
              </Col>
              <Col md="6">
                <RHFControl
                  name="averageBeneficiariesCost"
                  placeholder="Average Beneficiaries Cost"
                  label="Average Beneficiaries Cost"
                  required
                />
              </Col>
              <Col md="6">
                <RHFSelect
                  name="type"
                  label="Project Type"
                  required
                  options={projectTyeOptions}
                />
              </Col>
              <Col md="6">
                <RHFSelect
                  name="manager"
                  label="Manager"
                  options={branchUsersOptions}
                  isLoading={branchUsersLoading}
                  required
                />
              </Col>
              {/* <Flex justifyContent={'between'}>
                <p className="mb-0 fw-medium fs-1">On going project ?</p>
                <RHFCheck name="isOngoing" />
              </Flex> */}
            </Row>
          </Card.Body>
        </Card>
        {/* </FormProvider> */}

        <Card className="mb-3">
          <Card.Header>
            <Card.Title className="fw-medium">Documentation</Card.Title>
          </Card.Header>
          <Card.Body className="pb-2 pt-0">
            <Row className="g-3">
              <Form.Group as={Col} md="12" controlId="Case Study">
                <Form.Label>
                  Case Study <span className="text-danger">*</span>
                </Form.Label>
                <TinymceEditor
                  value={caseStudy}
                  handleChange={newValue => setValue('caseStudy', newValue)}
                  height={'200'}
                />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="Feasibility">
                <Form.Label>
                  Feasibility <span className="text-danger">*</span>
                </Form.Label>
                <TinymceEditor
                  value={feasibility}
                  handleChange={newValue =>
                    setValue('feasibilityStudy', newValue)
                  }
                  height={'200'}
                />
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="Project Charter">
                <Form.Label>
                  Project Charter <span className="text-danger">*</span>
                </Form.Label>
                <TinymceEditor
                  value={projectCharter}
                  handleChange={newValue =>
                    setValue('projectCharter', newValue)
                  }
                  height={'200'}
                />
              </Form.Group>

              <Col md={12}>
                <div className="mt-2">
                  <div
                    {...getRootProps({
                      className: 'dropzone-area h-auto py-4'
                    })}
                  >
                    <input {...getInputProps({ multiple: true })} />
                    <Flex justifyContent="center" alignItems={'center'}>
                      <img
                        src={cloudUpload}
                        alt=""
                        width={30}
                        className="me-2"
                      />
                      <p className="fs-0 mb-0 text-700">Drop your files here</p>
                    </Flex>
                  </div>
                  <div className="mt-3">
                    {files.length > 0 && (
                      <div
                        style={{ backgroundColor: '#F9FAFD' }}
                        className="rounded-2"
                      >
                        <ul className="p-4">{allFiles}</ul>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Flex justifyContent={'between'} className="align-items-center">
              <p className="mb-0 fs-2 fw-medium">
                {initValues ? 'Update Project' : 'Create Project'}
              </p>
              <LoadingButton
                loading={loading}
                type="submit"
                className="px-5 py-2"
              >
                Submit
              </LoadingButton>
            </Flex>
          </Card.Body>
        </Card>
      </FormProvider>
    </>
  );
}

ProjectProposalForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initValues: PropTypes.func.object,
  loading: PropTypes.bool
};
