import { gql, useMutation, useQuery } from '@apollo/client';

import Section from 'components/common/Section';
import ProjectProposalForm from './projectProposal/ProjectProposalForm';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

/* -------------------------------------------------------------------------- */
const GET_PROJECT_BY_ID = gql`
  query GetProjectById($id: uuid!) {
    projects_by_pk(id: $id) {
      project_name
      project_description
      status
      project_labels {
        id
        label {
          id
          title
        }
      }
      start_date
      target_amount
      id
      created_at
      updated_at
      location_id
      currency_id
      case_study
      feasibility_study
      project_charter
      num_of_beneficiaries
      average_beneficiaries_cost
      manager_id
      package_category_id
      organization_id
      end_date
      donation_amount
      cities
      country
      branch_id
      parent_id
      type
      created_by
    }
  }
`;
const EDIT_PROJECT = gql`
  mutation EditProject(
    $id: uuid!
    $start_date: date!
    $project_name: String!
    $project_description: String!
    $manager_id: uuid!
    $type: String!
    $target_amount: float8!
    $package_category_id: uuid!
    $num_of_beneficiaries: Int!
    $currency_id: uuid!
    $average_beneficiaries_cost: float8!
    $case_study: String!
    $feasibility_study: String!
    $project_charter: String!
    $end_date: date!
    $country: String!
    $cities: jsonb!
  ) {
    update_projects_by_pk(
      pk_columns: { id: $id }
      _set: {
        type: $type
        manager_id: $manager_id
        project_description: $project_description
        start_date: $start_date
        project_name: $project_name
        target_amount: $target_amount
        package_category_id: $package_category_id
        num_of_beneficiaries: $num_of_beneficiaries
        currency_id: $currency_id
        average_beneficiaries_cost: $average_beneficiaries_cost
        case_study: $case_study
        feasibility_study: $feasibility_study
        project_charter: $project_charter
        end_date: $end_date
        country: $country
        cities: $cities
      }
    ) {
      project_name
      project_description
      status
      project_labels {
        id
        label {
          id
          title
        }
      }
      start_date
      target_amount
      id
      created_at
      updated_at
      location_id
      currency_id
      case_study
      feasibility_study
      project_charter
      num_of_beneficiaries
      average_beneficiaries_cost
      manager_id
      package_category_id
      organization_id
      end_date
      donation_amount
      cities
      country
      branch_id
      parent_id
      type
      created_by
    }
  }
`;

/* -------------------------------------------------------------------------- */
const EditProject = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  // const { selectedBranchId } = useAuthContext();
  const { data } = useQuery(GET_PROJECT_BY_ID, {
    variables: { id }
  });
  const [editProject, { loading }] = useMutation(EDIT_PROJECT, {
    onCompleted: () => {
      navigate(-1);
    },
    onError: error => {
      toast.error('Something went wrong! Please try again.');
      console.log(error);
    }
    // update: (cache, { data }) => {
    //   const existingProjects = cache.readQuery({
    //     query: GET_BRANCH_PROJECTS,
    //     variables: {
    //       branchId: selectedBranchId
    //     }
    //   });

    //   const project = data.update_projects_by_pk;
    //   if (!existingProjects) return;

    //   const filteredData = existingProjects?.projects.filter(
    //     item => item.id !== project.id
    //   );

    //   cache.writeQuery({
    //     query: GET_BRANCH_PROJECTS,
    //     variables: {
    //       branchId: selectedBranchId
    //     },
    //     data: {
    //       projects: [project, ...filteredData.projects]
    //     }
    //   });
    // }
  });
  const onSubmit = values => {
    const formattedStartDate = new Date(values.startDate).toISOString();
    const formattedEndDate = new Date(values.endDate).toISOString();
    editProject({
      variables: {
        id,
        start_date: formattedStartDate,
        project_name: values.title,
        project_description: values.description,
        target_amount: values.estimatedCost,
        package_category_id: values.category,
        num_of_beneficiaries: values.numOfBeneficiaries,
        currency_id: values.currency,
        average_beneficiaries_cost: values.averageBeneficiariesCost,
        case_study: values.caseStudy,
        feasibility_study: values.feasibilityStudy,
        project_charter: values.projectCharter,
        end_date: formattedEndDate,
        country: values.country,
        cities: values.cities,
        type: values.type,
        manager_id: values.manager
      }
    });
  };
  return (
    <Section
      className={'py-0 mx-auto font-sans-serif '}
      style={{ maxWidth: '820px' }}
    >
      <ProjectProposalForm
        onSubmit={onSubmit}
        initValues={data?.projects_by_pk}
        loading={loading}
      />
    </Section>
  );
};

export default EditProject;
