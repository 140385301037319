import { permissionsKeyMap, userActions } from './permissionKeys';

// read action is default for all keys, based on this we will show the menu
// if we want to hide the menu then we need to remove the read action from the key

export const backendPermissions = {
  dashboard: {
    read: true
  },
  donors: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  projects: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  sponsorships: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  beneficiaries: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  sponsoredList: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  unsponsoredList: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  sponsorshipValues: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  school: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  donations: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  donationSettings: {
    read: true,
    create: false,
    update: false,
    delete: true
  },
  donationPackages: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  giftAid: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  organizationDetails: {
    read: true,
    update: true
  },
  users: {
    read: true,
    create: true,
    update: true,
    delete: true
  },
  branches: {
    read: true,
    create: false,
    update: false,
    delete: true
  },
  payment: {
    read: true,
    create: true,
    update: true
  },
  smtp: {
    read: true,
    create: true,
    update: true
  }
};

export const permissionsMap = [
  {
    key: permissionsKeyMap.dashboard.parent,
    label: 'Dashboard',
    children: [
      {
        key: permissionsKeyMap.dashboard.children.dashboard,
        label: 'Dashboard',
        actions: [userActions.read]
      }
    ]
  },
  {
    key: permissionsKeyMap.donor.parent,
    label: 'Donors',
    children: [
      {
        key: permissionsKeyMap.donor.children.donors,
        label: 'Donors',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      }
    ]
  },
  {
    key: permissionsKeyMap.project.parent,
    label: 'Projects',
    children: [
      {
        key: permissionsKeyMap.project.children.projects,
        label: 'Projects',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      }
    ]
  },
  {
    key: permissionsKeyMap.sponsorship.parent,
    label: 'Sponsorships',
    children: [
      {
        key: permissionsKeyMap.sponsorship.children.sponsorships,
        label: 'Sponsorships',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.sponsorship.children.beneficiaries,
        label: 'All Beneficiaries',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.sponsorship.children.sponsoredList,
        label: 'Sponsored List',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.sponsorship.children.unsponsoredList,
        label: 'Unsponsored List',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.sponsorship.children.sponsorshipValues,
        label: 'Sponsorship Values',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.sponsorship.children.school,
        label: 'School',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.sponsorship.children.mosque,
        label: 'Mosque',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.sponsorship.children.hospital,
        label: 'Hospital',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.sponsorship.children.orphanage,
        label: 'Orphanage',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      }
    ]
  },
  {
    key: permissionsKeyMap.donation.parent,
    label: 'Donations',
    children: [
      {
        key: permissionsKeyMap.donation.children.donations,
        label: 'Donations',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.donation.children.donationSettings,
        label: 'Donation Settings',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      },
      {
        key: permissionsKeyMap.donation.children.donationPackages,
        label: 'Urgent Appeals',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      }
    ]
  },
  {
    key: permissionsKeyMap.giftAid.parent,
    label: 'Gift Aid',
    children: [
      {
        key: permissionsKeyMap.giftAid.children.giftAid,
        label: 'Gift Aid',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      }
    ]
  },
  {
    key: permissionsKeyMap.eventScheduler.parent,
    label: 'Gift Aid',
    children: [
      {
        key: permissionsKeyMap.eventScheduler.children.eventScheduler,
        label: 'Gift Aid',
        actions: [
          userActions.read,
          userActions.create,
          userActions.update,
          userActions.delete
        ]
      }
    ]
  }
  // {
  //   key: permissionsKeyMap.settings.parent,
  //   label: 'Settings',
  //   children: [
  //     {
  //       key: permissionsKeyMap.settings.children.organizationDetails,
  //       label: 'Organization Details',
  //       actions: [userActions.read, userActions.update]
  //     },
  //     {
  //       key: permissionsKeyMap.settings.children.users,
  //       label: 'Users',
  //       actions: [
  //         userActions.read,
  //         userActions.create,
  //         userActions.update,
  //         userActions.delete
  //       ]
  //     },
  //     {
  //       key: permissionsKeyMap.settings.children.branches,
  //       label: 'Branches',
  //       actions: [
  //         userActions.read,
  //         userActions.create,
  //         userActions.update,
  //         userActions.delete
  //       ]
  //     },
  //     {
  //       key: permissionsKeyMap.settings.children.payment,
  //       label: 'Payment',
  //       actions: [userActions.read, userActions.create, userActions.update]
  //     },
  //     {
  //       key: permissionsKeyMap.settings.children.smtp,
  //       label: 'SMTP',
  //       actions: [userActions.read, userActions.create, userActions.update]
  //     }
  //   ]
  // }
];
