import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const ownerRegistrationScheme = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  firstName: Yup.string().required('Required Field'),
  lastName: Yup.string().required('Required Field'),
  phoneNumber: Yup.string().required('Required Field'),
  // make sure confirm phone number is the same as phone number
  confirmPhoneNumber: Yup.string().oneOf(
    [Yup.ref('phoneNumber'), null],
    'Phone numbers must match'
  ),
  email: Yup.string().required('Required Field'),
  companyEmail: Yup.string(),
  password: Yup.string().when('isEdit', {
    is: false,
    then: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number'
      )
      .min(8, 'Must be at least 8 characters')
      .required('Required Field')
  }),
  confirmPassword: Yup.string().when('isEdit', {
    is: false,
    then: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required Field')
  })
});

const ownerRegistrationResolver = yupResolver(ownerRegistrationScheme);

export { ownerRegistrationResolver };
