export const tickets = [
  {
    id: 'O52111',
    name: 'Emma Watson',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary',
    priority: 'Urgent',
    priorityColor: 'text-danger'
  },
  {
    id: 'O52112',
    name: 'Luke',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O52113',
    name: 'Finley',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O52114',
    name: 'Peter Gill',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary',
    priority: 'Medium',
    priorityColor: 'text-warning'
  },
  {
    id: 'O52115',
    name: 'Freya',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O52116',
    name: 'Morrison',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O52117',
    name: 'Morrison Banneker',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O52118',
    name: 'Aar Kay',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O52119',
    name: 'Fadil Badr',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Family',
    typeIcon: 'users',
    typeColor: 'success'
  },
  {
    id: 'O521110',
    name: 'Emma Watson',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O521111',
    name: 'Luke',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O521112',
    name: 'Finley',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O521113',
    name: 'Peter Gill',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O521114',
    name: 'Freya',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O521115',
    name: 'Morrison',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O521116',
    name: 'Morrison Banneker',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O521117',
    name: 'Aar Kay',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  },
  {
    id: 'O521118',
    name: 'Fadil Badr',
    renewal: '25/12/2022',
    age: 9,
    gender: 'Male',
    location: 'Jordan',
    sponsor: 'Amir Eid',
    duration: '24M',
    type: 'Orphan',
    typeIcon: 'user',
    typeColor: 'primary'
  }
];

export const weNotificationsProject = [
  {
    id: 1,
    title: '<strong>Reports</strong> your monthly projects reports',
    linkFor: ''
  },
  {
    id: 2,
    title: '<strong>Reports</strong> Monthly revenue report',
    linkFor: '',
    type: 'default'
  }
];

export const weTableDataForProject = [
  {
    id: 'C971',
    project: 'Food Relief Yamen 2022',
    variant: 'warning',
    description: 'Monthly Donation - Bread 4 Yemen',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Ongoing',
    milestone: 'HY Orphanage | IT Room',
    due_date: '1/9/2023 01.05',
    amount: '$9502'
  },
  {
    id: 'C972',
    project: 'Winter Campaign',
    variant: 'info',
    description: 'Onetime Donation - Zakat',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Initiated',
    milestone: 'HY Orphanage | Utilities',
    due_date: '1/9/2023 01.05',
    amount: '$9501'
  },
  {
    id: 'C973',
    project: 'Ramadan 2023',
    variant: 'danger',
    description: 'Onetime Donation - Build Orphanage Centre',
    country: 'Yamen,So',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Cancel',
    milestone: 'HY Orphanage | Secound Floor Construction',
    due_date: '1/9/2023 01.05',
    amount: '$9504'
  },
  {
    id: 'C974',
    project: 'Orphanage Center',
    variant: 'primary',
    description: 'Onetime Donation - Water Relief',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Initiated',
    milestone: 'HY Orphanage | Team Hiring',
    due_date: '1/9/2023 01.05',
    amount: '$9509'
  },
  {
    id: 'C975',
    project: 'Food Relief - So',
    variant: 'success',
    description: 'Onetime Donation - Sadaqah jariyah',
    country: 'Somalia',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Complete',
    milestone: 'HY Orphanage | Utilities',
    due_date: '1/9/2023 01.05',
    amount: '$9506'
  },
  {
    id: 'C971',
    project: 'Food Relief Yamen 2022',
    variant: 'warning',
    description: 'Monthly Donation - Bread 4 Yemen',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Ongoing',
    milestone: 'HY Orphanage | Secound Floor Construction',
    due_date: '1/9/2023 01.05',
    amount: '$9502'
  },
  {
    id: 'C972',
    project: 'Winter Campaign',
    variant: 'info',
    description: 'Onetime Donation - Zakat',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Initiated',
    milestone: 'HY Orphanage | Martial Prepration',
    due_date: '1/9/2023 01.05',
    amount: '$9501'
  },
  {
    id: 'C973',
    project: 'Ramadan 2023',
    variant: 'danger',
    description: 'Onetime Donation - Build Orphanage Centre',
    country: 'Yamen,So',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Cancel',
    milestone: 'HY Orphanage | Secound Floor Construction',
    due_date: '1/9/2023 01.05',
    amount: '$9504'
  },
  {
    id: 'C974',
    project: 'Orphanage Center',
    variant: 'primary',
    description: 'Onetime Donation - Water Relief',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Initiated',
    milestone: 'HY Orphanage | Appliance',
    due_date: '1/9/2023 01.05',
    amount: '$9509'
  },
  {
    id: 'C975',
    project: 'Food Relief - So',
    variant: 'success',
    description: 'Onetime Donation - Sadaqah jariyah',
    country: 'Somalia',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Complete',
    milestone: 'HY Orphanage | IT Room',
    due_date: '1/9/2023 01.05',
    amount: '$9506'
  },
  {
    id: 'C971',
    project: 'Food Relief Yamen 2022',
    variant: 'warning',
    description: 'Monthly Donation - Bread 4 Yemen',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Ongoing',
    milestone: 'HY Orphanage | Utilities',
    due_date: '1/9/2023 01.05',
    amount: '$9502'
  },
  {
    id: 'C972',
    project: 'Winter Campaign',
    variant: 'info',
    description: 'Onetime Donation - Zakat',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Initiated',
    milestone: 'HY Orphanage | Secound Floor Construction',
    due_date: '1/9/2023 01.05',
    amount: '$9501'
  },
  {
    id: 'C973',
    project: 'Ramadan 2023',
    variant: 'danger',
    description: 'Onetime Donation - Build Orphanage Centre',
    country: 'Yamen,So',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Cancel',
    milestone: 'HY Orphanage | Secound Floor Construction',
    due_date: '1/9/2023 01.05',
    amount: '$9504'
  },
  {
    id: 'C974',
    project: 'Orphanage Center',
    variant: 'primary',
    description: 'Onetime Donation - Water Relief',
    country: 'Yamen',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Initiated',
    milestone: 'HY Orphanage | IT Room',
    due_date: '1/9/2023 01.05',
    amount: '$9509'
  },
  {
    id: 'C975',
    project: 'Food Relief - So',
    variant: 'success',
    description: 'Onetime Donation - Sadaqah jariyah',
    country: 'Somalia',
    manager: 'HY_YA',
    start_date: '1/1/2023 01.04',
    total_funding: '$50,000',
    status: 'Complete',
    milestone: 'HY Orphanage | Secound Floor Construction',
    due_date: '1/9/2023 01.05',
    amount: '$9506'
  }
];

const weTableSingleClaimListing = [
  {
    title: 'Ms.',
    first_name: 'Alvin ',
    second_name: 'Schneider',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00',
    status: 'Rejected',
    variant: 'danger'
  },
  {
    title: 'Ms.',
    first_name: 'Celia',
    second_name: 'Legros MD',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00',
    status: 'Accepted',
    variant: 'warning'
  },
  {
    title: 'Mr.',
    first_name: 'Eugene ',
    second_name: 'Nicolas',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00',
    status: 'Accepted',
    variant: 'warning'
  }
  // ,
  // {
  //   title: 'Ms.',
  //   first_name: 'Amelia',
  //   second_name: 'Gislason',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Rejected',
  //   variant: 'danger'
  // },
  // {
  //   title: 'Mr.',
  //   first_name: 'Albert',
  //   second_name: 'Collins',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Accepted',
  //   variant: 'warning'
  // },
  // {
  //   title: 'Mr.',
  //   first_name: 'Danielle',
  //   second_name: 'Swaniawski',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Accepted',
  //   variant: 'warning'
  // },
  // {
  //   title: 'Mr.',
  //   first_name: 'Dave',
  //   second_name: 'Zieme',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Rejected',
  //   variant: 'danger'
  // },
  // {
  //   title: 'Mr.',
  //   first_name: 'Kim',
  //   second_name: 'Howell',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Rejected',
  //   variant: 'danger'
  // },
  // {
  //   title: 'Ms.',
  //   first_name: 'Carla ',
  //   second_name: ' Sipes',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Accepted',
  //   variant: 'warning'
  // },
  // {
  //   title: 'Dr.',
  //   first_name: 'Tracey',
  //   second_name: 'Fritsch',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Rejected',
  //   variant: 'danger'
  // },
  // {
  //   title: 'Ms.',
  //   first_name: 'Evan',
  //   second_name: 'Abshire',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Accepted',
  //   variant: 'warning'
  // },
  // {
  //   title: 'Ms.',
  //   first_name: 'Sarah',
  //   second_name: 'Olson',
  //   house_number: '152A',
  //   postcode: 'M99 2QD',
  //   aggregated_donations: 'One off Gift Aid ',
  //   sponsored_event: 'Yes',
  //   donation_date: '03/28/2023',
  //   amount: '£10.00',
  //   status: 'Rejected',
  //   variant: 'danger'
  // }
];

export const weTableClaimedGiftAid = [
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Awaiting',
    variant: 'warning',
    listings: weTableSingleClaimListing
  },
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Completed',
    variant: 'danger',
    listings: weTableSingleClaimListing
  },
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Submitted',
    listings: weTableSingleClaimListing
  },
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Awaiting',
    variant: 'warning',
    listings: weTableSingleClaimListing
  },
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Completed',
    variant: 'danger',
    listings: weTableSingleClaimListing
  },
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Submitted',
    listings: weTableSingleClaimListing
  },
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Awaiting',
    variant: 'warning',
    listings: weTableSingleClaimListing
  },
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Completed',
    variant: 'danger',
    listings: weTableSingleClaimListing
  },
  {
    id: '78f2eda2-6803-429e-8326-1dc334383692',
    date_generated: '03/28/2023 07:50',
    date_submitted: '04/02/2023 03:41',
    total_applicants: '150',
    total_donations: '£10,000',
    gift_aid: '£2,000',
    status: 'Submitted',
    listings: weTableSingleClaimListing
  }
];

export const weTableUnclaimedTransactions = [
  {
    title: 'Ms.',
    first_name: 'Alvin ',
    second_name: 'Schneider',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Ms.',
    first_name: 'Celia',
    second_name: 'Legros MD',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Mr.',
    first_name: 'Eugene ',
    second_name: 'Nicolas',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Ms.',
    first_name: 'Amelia',
    second_name: 'Gislason',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Mr.',
    first_name: 'Albert',
    second_name: 'Collins',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Mr.',
    first_name: 'Danielle',
    second_name: 'Swaniawski',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Mr.',
    first_name: 'Dave',
    second_name: 'Zieme',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Mr.',
    first_name: 'Kim',
    second_name: 'Howell',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Ms.',
    first_name: 'Carla ',
    second_name: ' Sipes',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Dr.',
    first_name: 'Tracey',
    second_name: 'Fritsch',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Ms.',
    first_name: 'Evan',
    second_name: 'Abshire',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  },
  {
    title: 'Ms.',
    first_name: 'Sarah',
    second_name: 'Olson',
    house_number: '152A',
    postcode: 'M99 2QD',
    aggregated_donations: 'One off Gift Aid ',
    sponsored_event: 'Yes',
    donation_date: '03/28/2023',
    amount: '£10.00'
  }
];
