import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadingButton from 'components/common/LoadingButton';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFFileUpload } from 'components/common/form';
import Flex from 'components/common/Flex';
const MAX_FILE_SIZE = 10048576;

const schema = Yup.object().shape({
  image: Yup.mixed().test({
    message: "File too big, can't exceed 10MB",
    test: file => {
      if (typeof file !== 'string' && file) {
        const isValid = file?.size < MAX_FILE_SIZE;
        return isValid;
      }
      return true;
    }
  })
});

function AddImageModal({ show, onSubmit, onHide, title, loading }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      image: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit } = methods;

  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-header-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3 ps-3 pe-3">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(onSubmit)}
            loading={loading}
          >
            <Flex gap={3} direction="column" className="mt-3">
              <Row>
                <Col md={12}>
                  <RHFFileUpload
                    label={'Image'}
                    name="image"
                    rows={5}
                    required={true}
                  />
                </Col>
              </Row>
              <LoadingButton
                style={{ width: '100%', color: 'white' }}
                variant="primary"
                loading={loading}
                disabled={loading}
                type={'submit'}
              >
                {'Save'}
              </LoadingButton>
            </Flex>
          </FormProvider>
        </Modal.Body>
      </Modal>
    </>
  );
}
AddImageModal.propTypes = {
  show: PropTypes.bool,
  onSubmit: PropTypes.func,
  onHide: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool
};
export default AddImageModal;
