import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useContext, useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { stages } from './homeData';
import PropTypes from 'prop-types';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import DonationContext from 'context/DonationContextProvider';

const Page5 = ({ setStage }) => {
  const [activeEdit] = useState(false);
  const [show, setShow] = useState(false);
  const {
    donationPackages,
    transactionFee,
    urgentAppeal,
    transactionFeeAmount
  } = useContext(DonationContext);

  const amountTotal = useMemo(() => {
    let totalAmount = 0;
    donationPackages.forEach(item => {
      if (item.selectedAmount.value === 'custom') {
        totalAmount += Number(item.customAmount);
      } else {
        totalAmount += Number(item.selectedAmount.label);
      }
    });

    // urgentAppeal.forEach(() => {
    //   totalAmount += 10;
    // });

    return totalAmount;
  }, []);
  return (
    <>
      <Card className="we-card-shadow">
        <Card.Header className="p-4">
          <Card.Title className="mb-0 text-uppercase">
            Donation information asd
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-4 pt-0">
          <hr className="mt-0" />

          <div className="mb-4 mt-4">
            {donationPackages?.map(donation => (
              <div
                key={donation.selectedPackage.id}
                className="border rounded-3 p-3 mb-3 position-relative"
              >
                {activeEdit && (
                  <IconButton
                    variant="default"
                    className={`${
                      donation.editAble ? 'text-danger' : 'text-500'
                    } bg-100 donation-info-edit-icon`}
                    icon={'times-circle'}
                    onClick={() => {}}
                  />
                )}

                <Flex justifyContent="between" alignItems="center">
                  <p className="mb-2 fw-semi-bold text-800">
                    {donation.selectedPackage.title}
                  </p>
                  <p className="mb-2 fw-semi-bold text-800">
                    {donation.customAmount === 0
                      ? '$' + donation.selectedAmount.label
                      : '$' + donation.customAmount}
                  </p>
                </Flex>
                <Flex justifyContent="between" alignItems="center">
                  <p className="mb-0 text-600">{'Transaction Fee'}</p>
                  <p className="mb-0 text-600">
                    {transactionFee ? '$' + transactionFeeAmount : ''}
                  </p>
                </Flex>
              </div>
            ))}
            {urgentAppeal?.map(appeal => (
              <div
                key={appeal.id}
                className="border rounded-3 p-3 mb-3 position-relative"
              >
                {activeEdit && (
                  <IconButton
                    variant="default"
                    className={`${
                      appeal.editAble ? 'text-danger' : 'text-500'
                    } bg-100 donation-info-edit-icon`}
                    icon={'times-circle'}
                    onClick={() => {}}
                  />
                )}

                <Flex justifyContent="between" alignItems="center">
                  <p className="mb-2 fw-semi-bold text-800">{appeal.title}</p>
                  <p className="mb-2 fw-semi-bold text-800">{'$10'}</p>
                </Flex>
                <Flex justifyContent="between" alignItems="center">
                  <p className="mb-0 text-600">{'Transaction Fee'}</p>
                  <p className="mb-0 text-600">
                    {transactionFee ? '$' + transactionFeeAmount : ''}
                  </p>
                </Flex>
              </div>
            ))}
          </div>

          {/* <IconButton
            variant="falcon-default"
            className="w-100 mb-4 py-2 text-600 text-uppercase bg-soft-secondary shadow-none border"
            icon="pencil-alt"
            iconClassName="ms-2"
            iconAlign="right"
            onClick={() => setActiveEdit(pre => !pre)}
          >
            Edit your donation
          </IconButton> */}

          <Flex justifyContent="between" alignItems="center" className="mb-4">
            <p className="fs-0 fw-semi-bold mb-0 text-900">Total amount</p>
            <p className="fs-0 fw-semi-bold mb-0 text-900">
              {amountTotal + transactionFeeAmount} USD
            </p>
          </Flex>

          <Button
            size="lg"
            className="w-100 h-52 text-uppercase"
            onClick={() => {
              setStage(stages.Payment);
            }}
            // onClick={() => setShow(true)}
          >
            Pay Now
          </Button>
        </Card.Body>
      </Card>
      <ConfirmationModal
        show={show}
        setShow={setShow}
        title="Divide your contributions !"
        subTitle="would like to split your donation of $1000 to monthly donation of $100/M for 10 Months ?"
        noBtnText="No, Thank You"
        yesBtnText={
          <span>
            <FontAwesomeIcon icon={'hand-holding-heart'} className="me-2" />
            Yes, I would like to be monthly supporter
          </span>
        }
        noBtnAction={() => setStage(stages.Payment)}
        yesBtnAction={() => {
          setStage(stages.Payment);
        }}
      />
    </>
  );
};

Page5.propTypes = {
  setStage: PropTypes.func.isRequired
};

export default Page5;
