import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

export default function ExportModal({ show, onHide, columns, data, fileName }) {
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map(column => column.accessor)
  );
  useEffect(() => {
    // filter data based on selected columns
    const filteredData = data.map(item => {
      const filteredItem = {};
      selectedColumns.forEach(column => {
        filteredItem[column] = item[column];
      });
      return filteredItem;
    });
    setCsvData(filteredData);
    // set csv headers
    const filteredHeaders = columns
      .filter(column => selectedColumns.includes(column.accessor))
      .map(column => ({
        label: column.Header,
        key: column.accessor
      }));
    setCsvHeaders(filteredHeaders);
  }, [selectedColumns, data, columns]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Export Columns</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100">
          {/* toggle for each column */}
          <h6>Select Columns</h6>
          <div className="d-flex gap-3">
            {columns.map(column => (
              <Form.Check key={column.accessor} type="checkbox" id="rememberMe">
                <Form.Check.Input
                  type="checkbox"
                  name={column.accessor}
                  onChange={e => {
                    if (e.target.checked) {
                      setSelectedColumns([...selectedColumns, column.accessor]);
                    } else {
                      setSelectedColumns(
                        selectedColumns.filter(item => item !== column.accessor)
                      );
                    }
                  }}
                  checked={selectedColumns.includes(column.accessor)}
                />
                <Form.Check.Label className="mb-0">
                  {column.Header}
                </Form.Check.Label>
              </Form.Check>
            ))}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          onClick={onHide}
          target="_blank"
          className="btn btn-primary"
          filename={fileName}
        >
          Download
        </CSVLink>
      </Modal.Footer>
    </Modal>
  );
}

ExportModal.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  fileName: PropTypes.string
};
