import { Dropdown, Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';

function BoardMembers({ avatarSize = 'l', users, showMember = 4 }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={Nav.Link} className="dropdown-caret-none p-0 ms-n1">
        <Avatar
          name={`${users.length - showMember}+`}
          size={avatarSize}
          isExact
          mediaClass="avatar-button"
        />
      </Dropdown.Toggle>

      <Dropdown.Menu align={'start'} className="dropdown-md">
        <h6 className="dropdown-header py-0 px-3 mb-0">Board Members</h6>
        <Dropdown.Divider />
        <Flex className="px-3">
          {users.map(user => (
            <Link className="text-900" to="/user/profile" key={user.id}>
              <div className="position-relative">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`member-${user.id}`}>{user.name}</Tooltip>
                  }
                >
                  <div className="">
                    <Avatar
                      src={user.img}
                      name={user.name}
                      className="me-2"
                      size="xl"
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </Link>
          ))}
        </Flex>
      </Dropdown.Menu>
    </Dropdown>
  );
}

BoardMembers.propTypes = {
  avatarSize: PropTypes.string,
  users: PropTypes.array,
  showMember: PropTypes.number
};

export default BoardMembers;
