import React, { Fragment } from 'react';
import { GET_CONTACT, GET_MOSQUE } from '../queries';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Card, Col, Row } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import Logs from '../school/Logs';
import dayjs from 'dayjs';
import OrphanTable from './OrphanTable';

const MosqueDetails = () => {
  const { id } = useParams();

  const { data: mosqueData } = useQuery(GET_MOSQUE, {
    variables: {
      id
    }
  });

  const { data: contactData } = useQuery(GET_CONTACT, {
    variables: {
      where: {
        mosque_id: { _eq: id }
      }
    }
  });

  return (
    <Fragment>
      <Card className="mb-3">
        <FalconCardHeader
          title={<h4 className="fw-bold">Mosque Details</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
        />

        <Card.Body>
          <Row>
            <Col>
              <Flex>
                <div className="donor-info-title">
                  <p className="mb-2">Serial Number</p>
                  <p className="mb-2">Address</p>
                  <p className="mb-2">City</p>
                  <p className="mb-2">Country</p>
                </div>
                <div>
                  <p className="mb-2 text-primary">
                    {mosqueData?.mosques_by_pk?.serial_number}
                  </p>
                  <p className="mb-2 ">{mosqueData?.mosques_by_pk?.address}</p>
                  <p className="mb-2 ">{mosqueData?.mosques_by_pk?.city}</p>
                  <p className="mb-2 ">{mosqueData?.mosques_by_pk?.country}</p>
                </div>
              </Flex>
            </Col>
            <Col>
              <Flex>
                <div className="donor-info-title">
                  <p className="mb-2"> Mosque Name</p>
                  <p className="mb-2">Registered Date</p>
                  <p className="mb-2">Email</p>
                  <p className="mb-2">Documents</p>
                </div>
                <div>
                  <p className="mb-2">{mosqueData?.mosques_by_pk?.name}</p>
                  <p className="mb-2">
                    {dayjs(mosqueData?.mosques_by_pk?.created_at).format(
                      'MMM-DD-YYYY'
                    )}
                  </p>
                  <p className="mb-2">{mosqueData?.mosques_by_pk?.email}</p>
                  {mosqueData?.mosques_by_pk?.document ? (
                    <p className="mb-2">
                      <a
                        href={mosqueData?.mosques_by_pk?.document}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        File URL{' '}
                      </a>
                    </p>
                  ) : (
                    ''
                  )}
                </div>
              </Flex>
            </Col>
          </Row>
          <hr />

          <FalconCardHeader
            title={<h4 className="fw-bold">Person To Contact</h4>}
            light={false}
            titleTag="div"
            className="p-0 pb-3"
            menuClassName="align-items-start"
          />

          <Row>
            <Col>
              <Flex>
                <div className="donor-info-title">
                  <p className="mb-2">Title</p>
                  <p className="mb-2">Name</p>
                </div>
                <div>
                  <p className="mb-2 text-primary">
                    {contactData?.contact[0]?.title}
                  </p>
                  <p className="mb-2 ">{`${contactData?.contact[0]?.first_name} ${contactData?.contact[0]?.last_name}`}</p>
                </div>
              </Flex>
            </Col>
            <Col>
              <Flex>
                <div className="donor-info-title">
                  <p className="mb-2"> Email </p>
                  <p className="mb-2"> Phone Number </p>
                </div>
                <div>
                  <p className="mb-2 ">{contactData?.contact[0]?.email}</p>
                  <p className="mb-2 "> {contactData?.contact[0]?.phone}</p>
                </div>
              </Flex>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Row className="my-3">
        <Col md={12} xxl={12} className="h-md-100">
          <OrphanTable />
        </Col>
      </Row>

      <Logs moduleType="Mosque" />
    </Fragment>
  );
};

export default MosqueDetails;
