import classNames from 'classnames';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Image } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import paths from 'routes/paths';

const ImageGrid = ({
  ImageObj,
  index,
  setSelectedId,
  setShowDeleteImageModal
}) => {
  const { id } = useParams();

  const {
    created_at,
    user,
    gallery_image,
    image_description,
    id: imageId
  } = ImageObj;
  return (
    <Col md={2} key={index} className="center-text">
      <Flex
        direction="column"
        justifyContent="center"
        className="rounded-1 h-100"
      >
        <div className="overflow-hidden" style={{ alignSelf: 'center' }}>
          <Image
            src={gallery_image}
            rounded={true}
            style={{ width: '100px', height: '100px' }}
            className={classNames(' fit-cover')}
          />
          <div className="mt-2">
            <h5 className="fs-0">{image_description}</h5>
            <div className=" mt-0">
              <p className="mb-1 mb-md-1">{created_at.split('T')[0]} By </p>{' '}
              <p className="mb-1 mb-md-1 text-primary">
                <Link to={paths.beneficiaries + '/' + id}>
                  {'  ' + user.first_name + ' ' + user.last_name}
                </Link>
              </p>
            </div>
            <Button
              variant="link"
              className="ps-0"
              style={{ fontSize: '12px' }}
              onClick={() => {
                setSelectedId(imageId);
                setShowDeleteImageModal(true);
              }}
            >
              Remove
            </Button>
            {/* <p className="fs--1 mb-3">
              <Link to="#!" className="text-500">
                {category}
              </Link>
            </p>
            <h5 className="fs-md-2 text-warning mb-0 d-flex align-items-center mb-3">
              {`$${salePrice ? salePrice : price}`}
              {salePrice && <del className="ms-2 fs--1 text-500">${price}</del>}
            </h5>
            <p className="fs--1 mb-1">
              Shipping Cost: <strong>${shippingCost}</strong>
            </p>
            <p className="fs--1 mb-1">
              Stock:{' '}
              <strong
                className={classNames({
                  'text-success': isInStock,
                  'text-danger': !isInStock
                })}
              >
                {isInStock ? 'Available' : 'Sold-Out'}
              </strong>
            </p> */}
          </div>
        </div>
      </Flex>
    </Col>
  );
};

ImageGrid.propTypes = {
  ImageObj: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    created_at: PropTypes.date,
    owner: PropTypes.string,
    gallery_image: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: PropTypes.object,
    image_description: PropTypes.string
  }),
  index: PropTypes.number,
  setSelectedId: PropTypes.func,
  setShowDeleteImageModal: PropTypes.func
};

export default ImageGrid;
