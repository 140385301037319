import * as Yup from 'yup';

export const completionScheme = Yup.object().shape({
  completionDate: Yup.string().required('Required Field'),
  country: Yup.string().required('Required Field'),
  cities: Yup.array(Yup.string()).required('Required Field'),
  numOfBeneficiaries: Yup.string().required('Required Field'),
  currency: Yup.string().required('Required Field'),
  administrationCost: Yup.string().required('Required Field'),
  projectCost: Yup.string().required('Required Field'),
  // status: Yup.string().required('Required Field'),
  videoUrl: Yup.string().required('Required Field'),
  feedback: Yup.string().required('Required Field')
});
