import paths from 'routes/paths';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';

export const weMarketShare = [
  { id: 1, value: 88, name: 'Email', color: 'primary' },
  { id: 2, value: 22, name: 'Call', color: 'info' },
  { id: 3, value: 99, name: 'Others', color: 'warning' }
];

export const weWeeklySalesData = [6000, 9000, 8500, 4000, 4500, 6500, 7000];
export const weTotalOrder = [110, 100, 250, 210, 530, 480, 320, 325];

export const weShoppingCartItems = [
  {
    id: 1,
    title: 'Build Orphanage Centre',
    amount: 83.6,
    barWidth: 50,
    items: 'Session: <span class ="text-600">6817k</span> ',
    variant: 'success',
    iconColor: 'success'
    // icon: 'caret-up'
  },
  {
    id: 2,
    title: '2022 Iftar',
    amount: 10.6,
    barWidth: 88,
    items: '<span class ="text-600">44k</span>  of 61k',
    variant: 'danger',
    iconColor: 'danger'
    // icon: 'caret-up'
  },
  {
    id: 3,
    title: 'Water Relief',
    amount: 12.11,
    barWidth: 60,
    items: '<span class ="text-600">8k</span>  of 61k',
    variant: 'primary',
    iconColor: 'success'
    // icon: 'caret-up'
  }
];

export const weTableData = [
  {
    name: 'Anna',
    email: 'anna@example.com',
    score: 198,
    variant: 'warning',
    description: 'Monthly Donation - Bread 4 Yemen',
    amount: '$9502'
  },
  {
    name: 'Homer',
    email: 'homer@example.com',
    score: 1968,
    variant: 'info',
    description: 'Onetime Donation - Zakat',
    amount: '$9501'
  },
  {
    name: 'Oscar',
    email: 'oscar@example.com',
    score: 98,
    variant: 'danger',
    description: 'Onetime Donation - Build Orphanage Centre',
    amount: '$9504'
  },
  {
    name: 'Emily',
    email: 'emily@example.com',
    score: 998,
    variant: 'primary',
    description: 'Onetime Donation - Water Relief',
    amount: '$9509'
  },
  {
    name: 'Jara',
    email: 'jara@example.com',
    score: 3938,
    variant: 'success',
    description: 'Onetime Donation - Sadaqah jariyah',
    amount: '$9506'
  }
];

export const weGreetingItems = [
  {
    title: 'Add Donation',
    text: 'Create donation from CMS',
    icon: 'donate',
    color: 'primary',
    link: paths.addNewDonation,
    action: userActions.create,
    subject: permissionsKeyMap.donation.children.donations
  },
  {
    title: 'Issue sponsorship',
    text: 'create a sponsorship',
    icon: 'hands-helping',
    color: 'success',
    link: paths.sponsoredList,
    action: userActions.create,
    subject: permissionsKeyMap.sponsorship.children.sponsorships
  },
  {
    title: 'Create A project',
    text: 'Propose projects',
    icon: 'dice-d20',
    color: 'warning',
    link: paths.createProject,
    action: userActions.create,
    subject: permissionsKeyMap.project.children.projects
  },
  {
    title: 'Add beneficiary',
    text: 'Add a needy beneficiary',
    icon: 'hand-holding-heart',
    color: 'danger',
    link: paths.addBeneficiary,
    action: userActions.create,
    subject: permissionsKeyMap.sponsorship.children.beneficiaries
  },
  {
    title: 'Add donor',
    text: 'create donor profile',
    icon: 'user',
    color: 'info',
    link: paths.addNewDonar,
    action: userActions.create,
    subject: permissionsKeyMap.donor.children.donors
  }
];

export const weNotificationsDashBoard = [
  {
    id: 1,
    title: '<strong>5 products</strong> didn’t publish to your Facebook page',
    linkFor: 'products',
    type: 'warning'
  },
  {
    id: 2,
    title: '<strong>7 orders</strong> have payments that need to be captured',
    linkFor: 'payments'
  },
  {
    id: 3,
    title: '<strong>50+ orders</strong> need to be fulfilled',
    linkFor: 'orders'
  }
];

export const weDashboardSaleItems = [
  {
    title: 'Total Donors',
    amount: '<strong>829</strong>',
    subAmount: '775',
    type: 'standard',
    percent: 21.8
    // className: 'border-200 border-bottom border-end pb-4'
  },
  {
    title: 'This Week’s total Donations',
    amount: '<strong>$10,349.29/</strong> <span class="fs-0">$18,000.00</span>',
    subAmount: '$13,675',
    type: 'warning',
    percent: 21.8
    // className: 'border-200 border-md-200 border-bottom border-md-end pb-4 ps-3'
  },
  {
    title: 'Today’s total Donations',
    amount: '<strong>$1,850.36</strong>',
    subAmount: '13,675 ',
    type: 'up',
    percent: 21.8
    // className:
    //   'border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3'
  },
  {
    title: 'New Donors this week',
    amount: '<strong>130/</strong> <span class="fs-0">150</span>',
    subAmount: '$109.65 ',
    type: 'down',
    percent: 21.8
    // className:
    //   'border-200 border-md-200 border-bottom border-md-bottom-0 border-md-end pt-4 pb-md-0 ps-3 ps-md-0'
  }
];

export const weOrganizerOptions = [
  { value: '1', label: 'Accounting' },
  { value: '2', label: 'Account Closed' },
  { value: '3', label: 'Accounts Age' },
  { value: '4', label: 'Accounts Merged' }
];

export const weAddWedgitData = [
  {
    id: 1,
    title: 'Accounts Age',
    details:
      'The Average age in months of an account that has donated during  the period'
  },
  {
    id: 2,
    title: 'Account Closed',
    details:
      'The Average age in months of an account that has donated during  the period'
  },
  {
    id: 3,
    title: 'Accounts Merged',
    details:
      'The Average age in months of an account that has donated during  the period'
  },
  {
    id: 4,
    title: 'Active Accounts',
    details:
      'The Average age in months of an account that has donated during  the period'
  },
  {
    id: 5,
    title: 'Accounts Age',
    details:
      'The Average age in months of an account that has donated during  the period'
  },
  {
    id: 6,
    title: 'Accounts Merged',
    details:
      'The Average age in months of an account that has donated during  the period'
  },
  {
    id: 7,
    title: 'Active Accounts',
    details:
      'The Average age in months of an account that has donated during  the period'
  },
  {
    id: 8,
    title: 'Accounts Merged',
    details:
      'The Average age in months of an account that has donated during  the period'
  },
  {
    id: 9,
    title: 'Accounts Age',
    details:
      'The Average age in months of an account that has donated during  the period'
  }
];
