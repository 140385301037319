import { useQuery } from '@apollo/client';
import { titleOptions } from 'components/authenitcation/registration/OwnerRegistrationForm';
import { RHFControl, RHFSelect } from 'components/common/form';
import { useState, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { GET_DONOR_CATEGORY } from '../queries';
import DonorGroupModal from './DonorGroupModal';
import PropTypes from 'prop-types';

const NameInputs = ({
  selectedDonorCategoryId,
  setDonorCategoryId,
  selectedDonorType,
  setSelectedDonorType
}) => {
  const { data: donorCategoryData } = useQuery(GET_DONOR_CATEGORY);

  const donorTypesOptions = [
    { value: 'individual', label: 'Individual' },
    { value: 'organisation', label: 'Organisation' }
  ];

  const donorGroupOptions = donorCategoryData?.donor_category?.map(
    ({ id, title }) => ({ value: id, label: title })
  );

  const [showCategoryModal, setShowCategoryModal] = useState(false);

  const openModal = () => {
    setShowCategoryModal(true);
  };

  const closeModal = () => {
    setShowCategoryModal(false);
  };

  const handleDonorTypeChange = e => {
    setSelectedDonorType(e.target.value);
  };

  useEffect(() => {
    if (donorCategoryData && !selectedDonorCategoryId) {
      const defaultCategoryId = donorCategoryData.donor_category.find(
        category => category.is_default
      )?.id;
      if (defaultCategoryId) {
        setDonorCategoryId(defaultCategoryId);
      }
    }
  }, [donorCategoryData, selectedDonorCategoryId, setDonorCategoryId]);

  return (
    <>
      {showCategoryModal && (
        <DonorGroupModal show={showCategoryModal} handleClose={closeModal} />
      )}

      <Card className="mb-3">
        <Card.Header className="d-flex">
          <Card.Title className="fw-medium">Donor Type</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row className="g-3">
            <Col md="6">
              <RHFSelect
                name="donor_category_id"
                label="Donor Group"
                options={donorGroupOptions}
                required
                hasAddOption
                onClickAdd={openModal}
              />
            </Col>

            <Form.Group as={Col} md={6} controlId="type">
              <Form.Label> Donor Type </Form.Label>

              <Form.Select
                className="w-100"
                onChange={e => handleDonorTypeChange(e)}
                value={selectedDonorType}
              >
                {donorTypesOptions.map(({ value, label }) => (
                  <option value={value} key={value}>
                    {label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3">
        <Card.Header className="d-flex">
          <Card.Title className="fw-medium">Donor info</Card.Title>
        </Card.Header>

        <Card.Body>
          <Row className="g-3">
            <Col md="6">
              <RHFSelect
                name="title"
                label="Title"
                options={titleOptions}
                required
              />
            </Col>
            <Col md="6">
              {/* <RHFControl
                placeholder={'Username'}
                name="username"
                label="Username"
                required
              /> */}
            </Col>{' '}
            <Col md="6">
              <RHFControl
                name="firstName"
                placeholder="First name"
                label="First name"
                required
              />
            </Col>
            <Col md="6">
              <RHFControl
                name="lastName"
                placeholder="Last name"
                label="Last name"
                required
              />
            </Col>
            {selectedDonorType === 'organisation' && (
              <>
                <Col md="6">
                  <RHFControl
                    name="partner_name"
                    placeholder="Company Name"
                    label="Company Name"
                  />
                </Col>
                <Col md="6">
                  <RHFControl
                    name="job_title"
                    placeholder="Job Title"
                    label="Job Title"
                  />
                </Col>
                <Col md="6">
                  <RHFControl
                    name="charity_registration_number"
                    placeholder="Charity Registration Number"
                    label="Charity Registration No"
                  />
                </Col>
                <Col md="6">
                  <RHFControl
                    name="website"
                    placeholder="Website Link"
                    label="Website Link"
                  />
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

NameInputs.propTypes = {
  selectedDonorCategoryId: PropTypes.string,
  setDonorCategoryId: PropTypes.func,
  selectedDonorType: PropTypes.string,
  setSelectedDonorType: PropTypes.func
};
export default NameInputs;
