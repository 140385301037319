import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export const VerifyEmailAlert = () => {
  return (
    <div className="text-center">
      {/* display message and icon to let the user know that his email is not verified */}
      <FontAwesomeIcon
        icon="fa-solid fa-envelope"
        className="w-25 h-25 text-primary"
      />
      <p className="h4">Email Verification</p>
      <p>
        Your email is not verified. Please verify your email to continue using
        the application.
      </p>
    </div>
  );
};
