import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import React, { useContext, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { stages } from './homeData';
import PropTypes from 'prop-types';
import DonationContext from 'context/DonationContextProvider';
import { INSERT_ORDER_DOANTION } from './mutations';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import {
  FEE_TYPE_FIXED,
  FEE_TYPE_PERCENTAGE,
  GET_TRANSACTION_FEES
} from 'components/donations/donation-settings/TransactionFees';
import { calculatePercentageFee } from 'components/add-donation/DonationConfig';

const Page6 = ({ setStage }) => {
  const [date, setDate] = useState('');
  const { organizationId } = useParams();

  function formatExpirationDate(e) {
    // Remove any non-digit characters
    const sanitizedDate = e.target.value?.replace(/\D/g, '');

    // Extract the month and year
    const month = sanitizedDate.slice(0, 2);
    const year = sanitizedDate.slice(2, 4);

    // Return the formatted date
    setDate(`${month}/${year}`);
  }
  const { donationPackages, transactionFee, donor } =
    useContext(DonationContext);

  const { data: transactionFeesData } = useQuery(GET_TRANSACTION_FEES, {
    variables: {
      organisation_id: organizationId
    }
  });

  const [createDonarOrders, { loading: donationLoading }] = useMutation(
    INSERT_ORDER_DOANTION,
    {
      onCompleted: () => {
        toast.success('Donation created.');
        // setStage(stages.PayNow);
      },
      onError: err => {
        console.log(err);
        toast.error('Something went wrong');
      }
    }
  );

  const saveData = () => {
    let subtotal = 0;
    donationPackages.forEach(item => {
      subtotal +=
        item.customAmount !== 0
          ? Number(item.customAmount)
          : Number(item.selectedAmount.label);
    });

    const orderDetailItems = donationPackages.map(item => {
      return {
        amount:
          item.customAmount !== 0
            ? Number(item.customAmount)
            : Number(item.selectedAmount.label),
        billing_condition: item.typeofDonation,
        donation_type: item.donationType.value,
        package_id: item.selectedPackage.id
      };
    });
    let total = subtotal;

    // if (giftAid) total += 2;

    let totalAmount = 0;
    donationPackages.forEach(item => {
      if (item.selectedAmount.value === 'custom') {
        totalAmount += Number(item.customAmount);
      } else {
        totalAmount += Number(item.selectedAmount.label);
      }
    });
    // total += total
    let accCheck = false;
    if (transactionFee) {
      if (transactionFeesData) {
        const totalTransaction = transactionFeesData.transaction_fee.reduce(
          (acc, curr) => {
            if (curr.fee_type === FEE_TYPE_PERCENTAGE) {
              acc = calculatePercentageFee(totalAmount, curr.amount);
            } else if (curr.fee_type === FEE_TYPE_FIXED) {
              acc += curr.amount;
            }
            accCheck = true;
            total += acc;
          },
          0
        );
        if (!accCheck) {
          total += totalTransaction;
        }
      }
    }

    createDonarOrders({
      variables: {
        address: [
          {
            city: donor.city,
            county: donor.country,
            postcode: donor.postcode,
            isPrimary: true,
            addressLine1: donor.addressLine1,
            addressLine2: donor.addressLine2
          }
        ],
        last_name: donor.lastName,
        phone: [
          {
            type: 'personal',
            isPrimary: true,
            phoneNumber: donor.phoneNumber
          }
        ],
        organisation_id: organizationId,
        email: [{ email: donor.email, isPrimary: true, type: 'personal' }],
        first_name: donor.firstName,
        title: donor.title,
        order_details: orderDetailItems,
        subtotal,
        transaction_fees: transactionFee ? 10 : 0,
        donation_amount: total
      }
    });
  };

  const paymentMethodOptions = [
    { value: '1', label: 'Credit Card' },
    { value: '2', label: 'Visa Card' },
    { value: '3', label: 'Master Card' },
    { value: '4', label: 'MX Card' },
    { value: '5', label: 'Nexus Pay' }
  ];
  const [paymentMethod, setPaymentMethod] = useState({
    value: '1',
    label: 'Credit Card'
  });

  return (
    <>
      <Card className="we-card-shadow">
        <Card.Header className="p-4">
          <Card.Title className="text-uppercase mb-0">
            Payment Method
          </Card.Title>
        </Card.Header>

        <Card.Body className="p-4 pt-0 pb-3">
          <ReactSelect
            closeMenuOnSelect={true}
            options={paymentMethodOptions}
            placeholder="Select Payment Method"
            classNamePrefix="react-select"
            value={paymentMethod}
            onChange={value => setPaymentMethod(value)}
          />
          <Flex className="mb-3 justify-content-between justify-content-md-start mt-3">
            <IconButton
              className="fs--1 fw-semi-bold rounded-3 me-3"
              style={{ width: '144px', padding: '10px' }}
              icon="credit-card"
              iconClassName="me-2"
            >
              Credit Card
            </IconButton>
            <IconButton
              variant="outline-secondary"
              className="fs--1 fw-semi-bold border-400 rounded-3 bg-200 hover-bg-primary"
              style={{ width: '144px', padding: '10px' }}
              icon="fa-brands fa-paypal"
              iconClassName="me-2"
            >
              PayPal
            </IconButton>
          </Flex>

          <div className="bg-100 px-3 py-4 rounded-4">
            <Row className="g-3">
              <p className="mb-0 fs-0 fw-semi-bold text-1100">Add new card</p>
              <Form.Group as={Col} md={12} className="mt-2 position-relative">
                <FontAwesomeIcon
                  icon="credit-card"
                  className="card-input-icon fs-1 text-500"
                />
                <Form.Control
                  type="number"
                  className="h-52 ps-5"
                  placeholder="Name on card"
                />
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Control
                  required
                  type="text"
                  placeholder="MM/YY"
                  className="h-52"
                  value={date}
                  onChange={formatExpirationDate}
                />
                <Form.Control.Feedback type="invalid">
                  EXP DATE required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={6}>
                <Form.Control
                  type="number"
                  className="h-52"
                  placeholder="Cvv"
                />
              </Form.Group>
            </Row>
          </div>
          <p className="mt-3">
            Give with confidence. Our secure payment system ensure your personal
            details are kept safe and secure
          </p>
          <Form.Check type="checkbox" id="stay_anonymous" className="my-0">
            <Form.Check.Input type="checkbox" isValid />
            <Form.Check.Label className="ms-1 text-800">
              Use same personal details for payment
            </Form.Check.Label>
          </Form.Check>
        </Card.Body>
      </Card>
      <Flex
        justifyContent="between"
        alignItems="center"
        className={'mt-4 flex-column flex-md-row'}
      >
        <Button
          variant="outline-secondary"
          className="h-52 bg-300 hover-bg-primary border-0 w-215px"
          onClick={() => setStage(stages.Donation1)}
        >
          Cancel
        </Button>
        <Button
          className="h-52 mt-3 mt-md-0 border-0 w-215px"
          onClick={() => {
            saveData();
          }}
        >
          {donationLoading ? 'Loading' : 'Pay Now'}
        </Button>
      </Flex>
    </>
  );
};
Page6.propTypes = {
  setStage: PropTypes.func.isRequired
};

export default Page6;
