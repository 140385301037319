import { gql, useMutation } from '@apollo/client';
import { rolesMap, useAuthContext } from 'context/authContext';
import { convertBase64 } from 'helpers/utils';
import { toast } from 'react-toastify';
import OrganizationForm from './OrganizationForm';

/* -------------------------------------------------------------------------- */
const REGISTER_ORGANIZATION = gql`
  mutation RegisterOrganization(
    $charity_number: String!
    $country: String!
    $city: String!
    $logo: String!
    $organisation_email: String!
    $organisation_name: String!
    $parent_id: uuid!
    $post_code: String!
    $address_1: String!
    $address_2: String = ""
    $branch_id: uuid = ""
    $user_id: uuid = ""
    $role_id: uuid = ""
    $address: jsonb = ""
    $currency_id: uuid = ""
    $name: String = ""
    $phone: String = ""
  ) {
    insert_organisation_one(
      object: {
        charity_number: $charity_number
        country: $country
        logo: $logo
        organisation_email: $organisation_email
        organisation_name: $organisation_name
        parent_id: $parent_id
        post_code: $post_code
        city: $city
        currency_id: $currency_id
        address_1: $address_1
        address_2: $address_2
        branches: {
          data: {
            address: $address
            city: $city
            currency_id: $currency_id
            logo: $logo
            name: $name
            phone: $phone
            is_main: true
            users_roles: {
              data: {
                is_active: true
                is_approved: true
                user_id: $user_id
                role_id: $role_id
              }
            }
          }
        }
      }
    ) {
      id
      branches {
        address
        city
        created_at
        currency_id
        id
        location_id
        logo
        name
        organisation_id
        phone
        users_roles {
          id
          role {
            name
          }
          branch_id
          role_id
        }
      }
    }
  }
`;

const SET_ORGANIZATION_ID = gql`
  mutation SetOrganizationId(
    $id: uuid!
    $organisation_id: uuid!
    # $branch_id: uuid!
    $user_role_id: uuid!
  ) {
    update_users_by_pk(
      pk_columns: { id: $id }
      _set: { organisation_id: $organisation_id }
    ) {
      id
      email
      first_name
      last_name
      users_roles {
        id
        branch_id
        organisation_id
        role_id
        is_active
        role {
          name
        }
        branch {
          address
          city
          created_at
          currency_id
          id
          location_id
          logo
          name
          organisation_id
          phone
        }
      }
      organisation {
        id
        post_code
        parent_id
        organisation_name
        organisation_email
        logo
        currency_id
        created_at
        country
        city
        address_2
        address_1
        charity_number
      }
      organisation_id
      title
    }
    # update organisation admin role
    update_users_roles_by_pk(
      pk_columns: { id: $user_role_id }
      _set: { organisation_id: $organisation_id }
    ) {
      id
    }
  }
`;
/* -------------------------------------------------------------------------- */

export default function OrganizationRegistration() {
  // const navigate = useNavigate();
  const { user, initializeUser } = useAuthContext();

  const [setOrganizationId] = useMutation(SET_ORGANIZATION_ID, {
    onCompleted: async () => {
      toast.success('Organization created successfully');
      await initializeUser();
      // navigate(paths.branchSelection, { replace: true });
    }
  });

  const [createOrganization, { loading: createLoading }] = useMutation(
    REGISTER_ORGANIZATION,
    {
      onCompleted: data => {
        // const branchAdminRole =
        //   data.insert_organisation_one.branches[0].users_roles.find(
        //     userRole => userRole.role_id === rolesMap.admin.id
        //   );
        const organizatinoAdminRole = user.users_roles.find(
          userRole => userRole.role_id === rolesMap.organisationAdmin.id
        );
        // selectBranch(branchAdminRole);
        // setOrganization(data.insert_organisation_one);
        setOrganizationId({
          variables: {
            id: user.id,
            organisation_id: data.insert_organisation_one.id,
            // branch_id: branchAdminRole.branch.id,
            user_role_id: organizatinoAdminRole.id
          }
        });
      },
      onError: error => {
        toast.error(error.message);
        console.log(error);
      }
    }
  );
  const onSubmit = async ({
    address1,
    address2,
    charityNumber,
    city,
    country,
    currency,
    email,
    logo,
    name,
    postCode
  }) => {
    let base64 = null;
    if (logo) {
      base64 = await convertBase64(logo);
    }

    createOrganization({
      variables: {
        address_1: address1,
        address_2: address2,
        charity_number: charityNumber,
        city,
        country,
        currency_id: currency,
        logo: base64,
        organisation_email: email,
        organisation_name: name,
        post_code: postCode,
        parent_id: user.id,
        address: '',
        name: 'Main Branch',
        phone: user.phone,
        user_id: user.id,
        role_id: rolesMap.admin.id
      }
    });
  };
  return <OrganizationForm loading={createLoading} onSubmit={onSubmit} />;
}
