import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Flex from 'components/common/Flex';
import { FormProvider, RHFCheck, RHFControl } from 'components/common/form';
import paths from 'routes/paths';
import { useForm } from 'react-hook-form';
import AuthContext from 'context/authContext';
import { useContext, useState } from 'react';
import LoadingButton from 'components/common/LoadingButton';

const schema = Yup.object().shape({
  email: Yup.string().email().required('Required Field'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Passwords must match'
  )
});

const LoginForm = () => {
  const { login } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
      email: '',
      remember: false
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit, watch } = methods;
  const { email, password } = watch();
  // Handler
  const onSubmit = async ({ email, password, remember }) => {
    setLoading(true);
    await login({ email, password, remember });
    setLoading(false);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Flex gap={3} direction="column" className="mt-3">
        <RHFControl name="email" type="email" placeholder="Email address" />
        <RHFControl name="password" type="password" placeholder="Password" />

        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <RHFCheck name="remember" label="Remember me" type="checkbox" />
          </Col>

          <Col xs="auto">
            <Link className="fs--1 mb-0" to={paths.forgotPassword}>
              Forgot Password?
            </Link>
          </Col>
        </Row>

        <LoadingButton
          type="submit"
          color="primary"
          className="w-100"
          disabled={!email || !password}
          loading={loading}
        >
          Log in
        </LoadingButton>
      </Flex>

      {/* <Divider className="mt-4">or log in with</Divider> */}

      {/* <SocialAuthButtons /> */}
    </FormProvider>
  );
};

export default LoginForm;
