import { useMutation } from '@apollo/client';
import 'dhtmlx-scheduler';
import 'dhtmlx-scheduler/codebase/dhtmlxscheduler_material.css';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { DELETE_EVENT } from '.';

const scheduler = window.scheduler;

export const TestScheduler = ({ events, onUpdate }) => {
  const [deleteEvent] = useMutation(DELETE_EVENT, {
    refetchQueries: ['GetEvent'],
    onCompleted: () => {
      toast.success('Event Deleted successfully');
    },
    onError: error => {
      console.log({ error });
      toast.error('Something went wrong');
    }
  });

  useEffect(() => {
    scheduler.config.first_hour = 0;
    scheduler.config.last_hour = 24;
    scheduler.init('scheduler_here', new Date(2024, 1, 4), 'month');
    if (!scheduler._onEventChanged)
      scheduler._onEventChanged = scheduler.attachEvent(
        'onEventChanged',
        function (id, ev) {
          const start_date = new Date(ev.start_date);
          start_date.setHours(start_date.getHours() + 5);

          const end_date = new Date(ev.end_date);
          end_date.setHours(end_date.getHours() + 5);

          onUpdate({
            variables: {
              id: id,
              end_date: end_date.toISOString(),
              start_date: start_date.toISOString(),
              text: ev.text
            }
          });
        }
      );
    if (!scheduler._onEventDelete)
      scheduler._onEventDelete = scheduler.attachEvent(
        'onEventDeleted',
        // eslint-disable-next-line no-unused-vars
        function (id, ev) {
          deleteEvent({
            variables: {
              id: id
            }
          });
        }
      );
  }, []);

  useEffect(() => {
    scheduler.parse(events);
  }, [events]);

  return (
    <div
      id="scheduler_here"
      className="dhx_cal_container"
      style={{ width: '100%', height: '100vh' }}
    >
      <div className="dhx_cal_navline">
        <div className="dhx_cal_prev_button">&nbsp;</div>
        <div className="dhx_cal_next_button">&nbsp;</div>
        <div className="dhx_cal_today_button"></div>
        <div className="dhx_cal_date"></div>
        <div className="dhx_cal_tab" data-tab="day"></div>
        <div className="dhx_cal_tab" data-tab="week"></div>
        <div className="dhx_cal_tab" data-tab="month"></div>
      </div>
      <div className="dhx_cal_header"></div>
      <div className="dhx_cal_data"></div>
    </div>
  );
};

TestScheduler.propTypes = {
  events: PropTypes.array,
  onUpdate: PropTypes.func
};
