import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import {
  firstPageContents as contents,
  stages,
  donationCurrencyData
} from './homeData';

import {
  GET_DONATION_AMOUNTS,
  GET_DONATION_TYPE,
  GET_LOCATION,
  GET_PACKAGES
} from './queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import DonationContext from 'context/DonationContextProvider';
import ConfirmationModalDonation from 'components/common/modals/ConfirmationModalDonation';
import { toast } from 'react-toastify';

const Page1 = ({ setStage, showContent, setShowContent }) => {
  const { donationPackages, setDonationPackages } = useContext(DonationContext);
  const { organizationId } = useParams();

  const [selectedPackage, setSelectedPackage] = useState({});
  const [show, setShow] = useState(false);
  const [country, setCountry] = useState();
  const [donationType, setDonationType] = useState();
  const [selectedAmount, setSelectedAmount] = useState({});
  const [customAmount, setCustomAmount] = useState(0);
  const [donationCurrency, setDonationCurrency] = useState({
    value: '1',
    label: 'USD',
    symbol: '$'
  });
  const [selectedDonationCategory, setSelectedDonationCategory] = useState(1);
  const getLocations = () => {};

  // const [optionsRemoved, setOptionsRemoved] = useState(false);
  const donationTypeHandler = item => {
    setSelectedPackage(item);
  };
  const { data: locationsData } = useQuery(GET_LOCATION, {
    variables: {
      organizationId
    }
  });
  const [getPackages, { data: packagesData }] = useLazyQuery(GET_PACKAGES);

  const { data: donationTypesData } = useQuery(GET_DONATION_TYPE, {
    variables: {
      organizationId
    }
  });

  const { data: donationAmountsData } = useQuery(GET_DONATION_AMOUNTS, {
    variables: {
      organizationId
    }
  });

  const donationAmountsOptions = useMemo(() => {
    const options = [];
    if (Object.keys(selectedPackage).length !== 0) {
      const { donation_amount } = selectedPackage;
      donation_amount.forEach(donation_amount_id => {
        const donationAmountData = donationAmountsData?.donation_amount?.find(
          ({ id }) => id === donation_amount_id
        );

        if (donationAmountData) {
          const { amount, description } = donationAmountData;
          options.push({
            label: `${amount}` + (description ? ` - (${description})` : ''),
            value: donation_amount_id
          });
        }
      });
    }

    options.push({
      label: 'Custom',
      value: 'custom'
    });
    return options;
  }, [donationAmountsData, packagesData, selectedPackage]);

  const locationsOptions = useMemo(
    () =>
      locationsData?.location?.map(({ id, location_name }) => ({
        label: location_name,
        value: id
      })),
    [locationsData]
  );

  useEffect(() => {
    if (country && donationType) {
      getPackages({
        variables: {
          location: country.value,
          paymentOptions:
            selectedDonationCategory === 1 ? 'monthly' : 'one-off',
          donationType: donationType.value
        }
      });
    }
  }, [country, donationType]);

  const getPackagesData = useMemo(() => {
    if (contents.donationType === showContent) {
      setCountry({});
      setDonationType({});
      setSelectedPackage({});
    }
    return packagesData?.packages?.map(
      ({ id, package_title, donation_amount }) => ({
        title: package_title,
        id: id,
        donation_amount: donation_amount
      })
    );
  }, [packagesData, showContent]);

  const donationTypesOptions = useMemo(
    () =>
      donationTypesData?.donation_type?.map(({ id, donation_type_name }) => ({
        label: donation_type_name,
        value: id
      })),
    [donationTypesData]
  );

  return (
    <>
      {showContent === contents.donationType && (
        <>
          <Card className="mb-4 we-card-shadow">
            <Card.Header className="p-4">
              <Card.Title className="mb-0">Billing Condition </Card.Title>
            </Card.Header>
            <Card.Body className="p-4 pt-0">
              <Row className="g-3">
                <Col md={6}>
                  <Button
                    iconAlign="right"
                    iconClassName="ms-2"
                    className="w-100  p-3"
                    size="lg"
                    variant={
                      selectedDonationCategory === 1
                        ? 'primary'
                        : 'outline-primary'
                    }
                    onClick={() => {
                      setSelectedDonationCategory(1);
                      // setOptionsRemoved(false);
                    }}
                  >
                    Monthly Donation
                  </Button>
                </Col>
                <Col md={6}>
                  <Button
                    iconAlign="right"
                    iconClassName="ms-2"
                    className="w-100  p-3"
                    size="lg"
                    variant={
                      selectedDonationCategory === 2 ||
                      selectedDonationCategory === 4 ||
                      selectedDonationCategory === 5
                        ? 'primary'
                        : 'outline-primary'
                    }
                    onClick={() => {
                      setSelectedDonationCategory(2);
                      // setOptionsRemoved(true);
                    }}
                  >
                    One-OFF Donation
                  </Button>
                </Col>
              </Row>
              {false && (
                <div className="border rounded-3 border-primary bg-100 p-3 mt-4">
                  <Flex justifyContent="between" alignItems="middle">
                    <p className="mb-0 fs-0 fw-medium">
                      Become a monthly supporter
                    </p>
                    <FalconCloseButton
                      size="sm"
                      onClick={() => {}}
                      //  setOptionsRemoved(false)}
                    />
                  </Flex>
                  <div className="mt-3">
                    <p>
                      Will you consider becoming one of our Valued Monthly
                      supporters by converting your $50 Contribution into a
                      monthly donation?
                    </p>
                    <p>
                      Ongoing monthly donations allow us to better focus on our
                      mission.
                    </p>
                  </div>
                  <Flex className="flex-column flex-md-row">
                    <IconButton
                      className="fs--1 fw-medium rounded-pill me-md-3 py-2"
                      variant={
                        selectedDonationCategory === 4
                          ? 'primary'
                          : 'outline-primary'
                      }
                      icon="hand-holding-heart"
                      transform="shrink-3"
                      onClick={() => {
                        setSelectedDonationCategory(4);
                        // setOneTime(false);
                      }}
                    >
                      Donate $10/Month
                    </IconButton>
                    <Button
                      variant={
                        selectedDonationCategory === 5
                          ? 'primary'
                          : 'outline-primary'
                      }
                      className="fs--1 fw-medium rounded-pill hover-100 py-2"
                      onClick={() => {
                        setSelectedDonationCategory(5);
                      }}
                    >
                      No thanks, Keep it as one time gift
                    </Button>
                  </Flex>
                </div>
              )}
            </Card.Body>
          </Card>
          <Flex justifyContent="end" alignItems="center">
            <Button
              className="h-52 w-215px mt-3 mt-md-0"
              onClick={() => {
                setShowContent(contents.donationCause);
                getLocations();
              }}
            >
              Next
            </Button>
          </Flex>
        </>
      )}
      {showContent === contents.donationCause && (
        <>
          <Card className="mb-4 we-card-shadow">
            <Card.Header className="p-4">
              <Card.Title className="mb-0">Select Your Cause</Card.Title>
            </Card.Header>
            <Card.Body className="p-4 pt-0">
              <Form.Group as={Col} md={12} className="position-relative mb-3">
                <small className="wr-custom-label">Country</small>
                <ReactSelect
                  closeMenuOnSelect={true}
                  options={locationsOptions}
                  placeholder="Select"
                  classNamePrefix="react-select"
                  className="wr-donation-amount-currency"
                  value={country}
                  onChange={value => setCountry(value)}
                  menuPosition="fixed"
                />

                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md={12} className="position-relative mb-3">
                <small className="wr-custom-label">Donation Type</small>
                <ReactSelect
                  closeMenuOnSelect={true}
                  options={donationTypesOptions}
                  placeholder="Select"
                  classNamePrefix="react-select"
                  className="wr-donation-amount-currency"
                  value={donationType}
                  onChange={value => setDonationType(value)}
                  menuPosition="fixed"
                />

                <Form.Control.Feedback type="invalid">
                  Required
                </Form.Control.Feedback>
              </Form.Group>
              <Row className="g-3">
                {getPackagesData?.map(item => (
                  <Col key={item.id} md={6}>
                    <Button
                      variant="outline-secondary"
                      size="lg"
                      className={`${
                        selectedPackage.id === item.id && 'active'
                      } wr-custom-donation-btn w-100 p-2`}
                      onClick={() => {
                        donationTypeHandler(item);
                      }}
                    >
                      {item.title}
                    </Button>
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </Card>
          <Flex
            justifyContent="between"
            alignItems="center"
            className={'mt-3 flex-column flex-md-row'}
          >
            <Button
              variant="default"
              className="bg-soft-secondary h-52 hover-bg-primary hover-100 w-215px"
              onClick={() => {
                setShowContent(contents.donationType);
              }}
            >
              Back
            </Button>
            <Button
              className="h-52 w-215px mt-3 mt-md-0"
              onClick={() => {
                if (Object.keys(selectedPackage).length)
                  setShowContent(contents.donationAmount);
                else {
                  toast.error('please select a package.');
                }
              }}
            >
              Next
            </Button>
          </Flex>
        </>
      )}

      {showContent === contents.donationAmount && (
        <>
          <Card className="mb-4 we-card-shadow">
            <Card.Header className="p-4">
              <Card.Title className="mb-0">Donation Amount</Card.Title>
            </Card.Header>
            <Card.Body className="p-4 pt-0 pb-2">
              <Row className="g-3 d-flex align-items-end mb-3">
                <Form.Group as={Col} md={3}>
                  <Form.Label>Currency</Form.Label>
                  <ReactSelect
                    closeMenuOnSelect={true}
                    options={donationCurrencyData}
                    placeholder="Select"
                    classNamePrefix="react-select"
                    className="wr-donation-amount-currency"
                    value={donationCurrency}
                    onChange={value => {
                      setDonationCurrency(value);
                    }}
                    menuPosition="fixed"
                  />
                  <Form.Control.Feedback type="invalid">
                    Required
                  </Form.Control.Feedback>
                </Form.Group>

                <Col md={9}>
                  <Row className="g-3">
                    {donationAmountsOptions?.map(amount => (
                      <Col key={amount.id} md={3}>
                        <Button
                          variant="falcon-default"
                          className={`${
                            selectedAmount.value === amount.value && 'active'
                          } wr-donation-amount border-0 shadow-sm w-100`}
                          onClick={() => {
                            setSelectedAmount(amount);
                          }}
                        >
                          {amount.id !== 'other'}
                          {amount.label}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Col>
                {selectedAmount.value === 'custom' && (
                  <Form.Group as={Col} md={6} controlId="Custom Amount">
                    <Form.Control
                      className="h-52"
                      required
                      type="number"
                      placeholder={`Custom Amount (${donationCurrency.symbol})`}
                      onChange={item => {
                        setCustomAmount(item.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Custom Amount
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              </Row>
              <p>
                <FontAwesomeIcon
                  icon={'hand-holding-heart'}
                  className="text-primary"
                />{' '}
                Making A donation of
                <strong> £50</strong> will Save one life at least.
              </p>
              <Form.Check
                type="checkbox"
                id="Give_in_honor_or_In_memory"
                label="Give in honor or In memory"
              />
            </Card.Body>
          </Card>
          <Flex
            justifyContent="between"
            alignItems="center"
            className={'mt-3 flex-column flex-md-row'}
          >
            <Button
              variant="default"
              className="bg-soft-secondary h-52 hover-bg-primary hover-100 w-215px"
              onClick={() => {
                setShowContent(contents.donationCause);
              }}
            >
              Back
            </Button>
            <Button
              className="h-52 w-215px mt-3 mt-md-0"
              onClick={() => {
                if (Object.keys(selectedAmount).length !== 0) {
                  if (selectedAmount.value === 'custom') {
                    if (customAmount === 0) {
                      toast.error('Please input the amount');
                    } else {
                      setShow(true);
                    }
                  } else {
                    setShow(true);
                  }
                } else {
                  toast.error('Please select the amount');
                }
              }}
            >
              Continue
            </Button>
          </Flex>
        </>
      )}

      <ConfirmationModalDonation
        show={show}
        setShow={setShow}
        title="Make another donation?"
        subTitle="Would you like to make another contribution?"
        noBtnText="No, Skip"
        confirmText="Yes Donate Again"
        handleConfirm={() => {
          setDonationPackages([
            ...donationPackages,
            {
              selectedPackage,
              selectedAmount,
              customAmount,
              typeofDonation:
                selectedDonationCategory === 1 ? 'monthly' : 'one-off',
              donationType: donationType
            }
          ]);
          setSelectedPackage({});
          setShow(false);
          setCountry();
          setDonationType();
          setSelectedAmount({});
          setCustomAmount(0);
          setShowContent(contents.donationType);
        }}
        onHide={() => {
          setShow(false);
        }}
        onMoveToNextPage={() => {
          setDonationPackages([
            ...donationPackages,
            {
              selectedPackage,
              selectedAmount,
              customAmount,
              typeofDonation:
                selectedDonationCategory === 1 ? 'monthly' : 'one-off',
              donationType: donationType
            }
          ]);
          setStage(stages.Donation2);
        }}
      />
    </>
  );
};

Page1.propTypes = {
  setStage: PropTypes.func.isRequired,
  showContent: PropTypes.string.isRequired,
  setShowContent: PropTypes.func.isRequired
};

export default Page1;
