import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import {
  FormProvider,
  RHFControl,
  RHFDatePicker
} from 'components/common/form';

const schema = Yup.object().shape({
  startDate: Yup.date().required('Required Field'),
  startTime: Yup.string().required('Required Field'),
  endDate: Yup.date().required('Required Field'),
  endTime: Yup.string().required('Required Field'),
  text: Yup.string().required('Required Field')
});

function AddEventModal({ show, onSubmit, onHide }) {
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(schema)
  });
  const { handleSubmit, reset } = methods;
  const onSubmitHandle = data => {
    onSubmit(data);
    reset();
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmitHandle)}>
          <Flex gap={3} direction="column" className="mt-1">
            <RHFControl
              name="text"
              placeholder="Description"
              label={'Description'}
              required={true}
            />
            <RHFDatePicker
              name="startDate"
              label={'Start Date'}
              required={true}
              max={false}
            />
            <RHFDatePicker
              name="startTime"
              label={'Start Time'}
              required={true}
              type="time"
            />
            <RHFDatePicker
              name="endDate"
              label={'end Date'}
              required={true}
              max={false}
            />
            <RHFDatePicker
              name="endTime"
              label={'End Time'}
              required={true}
              type="time"
            />
            <LoadingButton type="submit" color="primary" className="w-100">
              Add
            </LoadingButton>
          </Flex>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
}

AddEventModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AddEventModal;
