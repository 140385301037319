import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import BasicECharts from 'components/common/BasicEChart';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import AuthContext from 'context/authContext';
import dayjs from 'dayjs';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
/* -------------------------------------------------------------------------- */
const GET_DONATION_AMOUNT = gql`
  query GetdonationAmount($where: donation_amount_bool_exp!) {
    donation_amount_aggregate(where: $where) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer
]);

const getOptions = data => ({
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    formatter: '{b0} : {c0}',
    transitionDuration: 0,
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-300'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1
  },
  xAxis: {
    type: 'category',
    data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    boundaryGap: false,
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  yAxis: {
    type: 'value',
    splitLine: { show: false },
    axisLine: { show: false },
    axisLabel: { show: false },
    axisTick: { show: false },
    axisPointer: { type: 'none' }
  },
  series: [
    {
      type: 'bar',
      showBackground: true,
      backgroundStyle: {
        borderRadius: 10
      },
      barWidth: '5px',
      itemStyle: {
        borderRadius: 10,
        color: getColor('primary')
      },
      data,
      z: 10
    }
  ],
  grid: { right: 5, left: 10, top: 0, bottom: 0 }
});

const MonthlyDonations = ({
  title = 'Title',
  tooltipText = 'tooltip text',
  // count = '4k',
  // percent = '4.5%',
  data,
  width,
  amountClassName
}) => {
  const { organization } = useContext(AuthContext);

  const now = dayjs();
  const startOfMonth = now.startOf('month').toISOString(); // Start of the month
  const endOfMonth = now.endOf('month').toISOString(); // End of the month

  const { data: donorsThisMonth } = useQuery(GET_DONATION_AMOUNT, {
    variables: {
      where: {
        organisation_id: {
          _eq: organization.id
        },
        created_at: { _gte: startOfMonth, _lte: endOfMonth }
      }
    }
  });

  return (
    <Card className="h-md-100">
      <FalconCardHeader
        title={
          <>
            {title}
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>{tooltipText}</Tooltip>}
            >
              <span>
                <FontAwesomeIcon
                  icon={['far', 'question-circle']}
                  transform="shrink-1"
                  className="ms-1 text-400"
                  id="weeklySalesTooltip"
                />
              </span>
            </OverlayTrigger>
          </>
        }
        light={false}
        titleTag="h6"
        className="pb-0"
        // endEl={<CardDropdown />}
      />

      <Card.Body as={Flex} alignItems="end" justifyContent="between">
        <div>
          <h2
            className={classNames(
              amountClassName,
              'mb-1 text-700 fw-normal lh-1'
            )}
          >
            {donorsThisMonth?.donation_amount_aggregate?.aggregate?.sum
              ?.amount || 0}
          </h2>
          {/* <SoftBadge pill bg="success" className="me-2 fs--2">
            {percent}
          </SoftBadge> */}
        </div>
        <BasicECharts
          echarts={echarts}
          options={getOptions(data)}
          style={{ width: width || '8.5rem', height: 60 }}
        />
      </Card.Body>
    </Card>
  );
};

MonthlyDonations.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.string,
  title: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  // count: PropTypes.string,
  // percent: PropTypes.string,
  amountClassName: PropTypes.string
};

export default MonthlyDonations;
