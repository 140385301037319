import React from 'react';
import { Spinner } from 'react-bootstrap';

function LoadingContainer() {
  return (
    <div className="d-flex align-items-center justify-content-center w-100 h-100 py-3">
      <Spinner size="lg" animation="border" variant="primary" />
    </div>
  );
}

export default LoadingContainer;
