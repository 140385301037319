import React, { useState, useEffect, useContext } from 'react';
import {
  Modal,
  Button,
  Form,
  InputGroup,
  ListGroup,
  Dropdown
} from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import CardDropdown from 'components/common/CardDropdown';
import PropTypes from 'prop-types';
import {
  CREATE_SPONSORSHIP_STATUS,
  DELETE_SPONSORSHIP_STATUS,
  GET_SPONSORSHIP_STATUS,
  UPDATE_SPONSORSHIP_STATUS
} from './beneficiary-options-queries';
import AuthContext from 'context/authContext';

function StatusModal({ show, handleClose }) {
  const { organization } = useContext(AuthContext);

  const { data, refetch } = useQuery(GET_SPONSORSHIP_STATUS, {
    fetchPolicy: 'network-only',
    variables: {
      organizationId: organization.id
    }
  });

  const [insertStatus] = useMutation(CREATE_SPONSORSHIP_STATUS, {
    variables: {
      organizationId: organization.id
    }
  });
  const [updateStatus] = useMutation(UPDATE_SPONSORSHIP_STATUS, {
    variables: {
      organizationId: organization.id
    }
  });

  const [deleteStatus] = useMutation(DELETE_SPONSORSHIP_STATUS);

  const [status, setStatus] = useState([]);
  const [newStatus, setNewStatus] = useState('');
  const [editIndex, setEditIndex] = useState(null);
  const [editStatus, setEditStatus] = useState('');

  useEffect(() => {
    if (data && data.sponsorship_status) {
      setStatus(data.sponsorship_status);
    }
  }, [data]);

  const handleAddStatus = async () => {
    if (newStatus.trim()) {
      await insertStatus({ variables: { status: newStatus } });
      refetch();
      setNewStatus('');
    }
  };

  const handleUpdateStatus = async id => {
    if (editStatus.trim()) {
      await updateStatus({ variables: { status: editStatus, id } });
      setEditIndex(null);
      setEditStatus('');
      refetch();
    }
  };

  const startEditStatus = index => {
    setEditIndex(index);
    setEditStatus(status[index].status);
  };

  const cancelEdit = () => {
    setEditIndex(null);
    setEditStatus('');
  };

  const handleDeleteStatus = async id => {
    await deleteStatus({ variables: { id } });
    refetch();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Sponsorship Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputGroup className="mb-4">
          <Form.Control
            type="text"
            placeholder="Add Status"
            value={newStatus}
            onChange={e => setNewStatus(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddStatus}>
            + Add
          </Button>
        </InputGroup>
        <ListGroup>
          {status.map((status, index) => (
            <ListGroup.Item
              key={status.id}
              className="d-flex justify-content-between align-items-center py-2 px-3"
              style={{
                border: '1px solid #ccc',
                borderRadius: '5px',
                marginBottom: '20px'
              }}
            >
              {editIndex === index ? (
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={editStatus}
                    onChange={e => setEditStatus(e.target.value)}
                  />
                  <Button
                    variant="outline-primary"
                    onClick={() => handleUpdateStatus(status.id)}
                  >
                    Save
                  </Button>
                  <Button variant="outline-secondary" onClick={cancelEdit}>
                    Cancel
                  </Button>
                </InputGroup>
              ) : (
                <>
                  <span>{status.status}</span>

                  <CardDropdown>
                    <div className="py-2">
                      <Dropdown.Item onClick={() => startEditStatus(index)}>
                        Edit
                      </Dropdown.Item>{' '}
                      <Dropdown.Item
                        className="text-danger"
                        onClick={() => handleDeleteStatus(status.id)}
                      >
                        Delete
                      </Dropdown.Item>
                    </div>
                  </CardDropdown>
                </>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} className="w-100">
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default StatusModal;

StatusModal.propTypes = {
  handleClose: PropTypes.func,
  show: PropTypes.bool
};
