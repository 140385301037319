import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import AuthContext from 'context/authContext';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { INSERT_MOSQUE } from '../mutations';
import MosqueForm from './MosqueForm';
import useUpload from 'hooks/useUpload';
import paths from 'routes/paths';

function AddMosque() {
  const { organization } = useContext(AuthContext);
  const navigate = useNavigate();
  const { upload, loading: uploadLoading } = useUpload();

  const [createMosque, { loading }] = useMutation(INSERT_MOSQUE, {
    onCompleted: () => {
      toast.success('Mosque Created Successfully');
      navigate(paths.mosque);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const submitAddMosque = async data => {
    if (data.document && typeof data.document !== 'string') {
      const document = await upload(data.document);
      data.document = document;
    }

    createMosque({
      variables: {
        ...data,
        organisation_id: organization.id
      }
    });
  };

  return (
    <MosqueForm onSubmit={submitAddMosque} loading={loading || uploadLoading} />
  );
}

export default AddMosque;
