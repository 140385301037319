import { defineAbility } from '@casl/ability';
import {
  ROLE_BRANCH_ADMIN,
  ROLE_BRANCH_STAFF,
  ROLE_ORGANIZATION_ADMIN
} from 'context/authContext';
import { permissionsKeyMap } from './permissionKeys';

export default function defineAbilityFor(role, permissions) {
  return defineAbility((can, cannot) => {
    if (!role) return;
    can('manage', permissionsKeyMap.settings.children.personal);
    if (role === ROLE_ORGANIZATION_ADMIN) {
      can('manage', 'all');
    } else if (role === ROLE_BRANCH_ADMIN) {
      can('manage', 'all');
      cannot(
        'manage',
        permissionsKeyMap.sponsorship.children.sponsorshipValues
      );
      cannot('manage', permissionsKeyMap.donation.children.donationSettings);
      cannot('manage', permissionsKeyMap.settings.children.organizationDetails);
      cannot('manage', permissionsKeyMap.settings.children.branches);
      cannot('manage', permissionsKeyMap.settings.children.smtp);
      cannot('manage', permissionsKeyMap.settings.children.users);
      cannot('manage', permissionsKeyMap.settings.children.payment);
    } else if (role === ROLE_BRANCH_STAFF) {
      const parsedPermissions = JSON.parse(permissions ?? '{}');
      cannot(
        'manage',
        permissionsKeyMap.sponsorship.children.sponsorshipValues
      );
      cannot('manage', permissionsKeyMap.donation.children.donationSettings);
      Object.entries(parsedPermissions).forEach(([subjectKey, actions]) => {
        Object.entries(actions)
          // eslint-disable-next-line no-unused-vars
          .filter(([_, value]) => value)
          .forEach(([actionKey]) => {
            can(actionKey, subjectKey);
          });
      });
    }
  });
}
