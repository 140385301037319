import React from 'react';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DonorCommunicationModal = ({ modalShow, setModalShow }) => {
  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter" as="h5">
          Details
        </Modal.Title>
      </Modal.Header>
      <hr className="mx-3 my-0" />
      <Modal.Body>
        <Row className="g-3">
          <Col md={12}>
            <div>
              <p className="fw-medium">
                <FontAwesomeIcon icon={'user'} className="text-primary me-2" />
                From :
                <a className="ms-1" href={'mailTo:test@test.test'}>
                  WeRaise (No-reply@WeRaise.com)
                </a>
              </p>
              <p className="fw-medium">
                <FontAwesomeIcon icon={'user'} className="text-primary me-2" />
                To :{' '}
                <a className="ms-1" href={'mailTo:test@test.test'}>
                  Example@email.com
                </a>
              </p>
              <p className="fw-medium">CC : None</p>
              <p className="fw-medium">BCC : None</p>

              <div
                className="overflow-scroll rounded"
                style={{ maxHeight: 300 }}
              >
                <Image
                  fluid
                  src="https://img.indiafilings.com/learn/wp-content/uploads/2021/11/12003509/about.jpg"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

DonorCommunicationModal.propTypes = {
  modalShow: PropTypes.bool.isRequired,
  setModalShow: PropTypes.func.isRequired
};

export default DonorCommunicationModal;
