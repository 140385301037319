export const HEAR_ABOUT_US_OPTIONS = [
  {
    value: 'Community Fundraising Team',
    label: 'Community Fundraising Team'
  },
  { value: 'Email', label: 'Email' },
  { value: 'Family/Friends', label: 'Family/Friends' },
  { value: 'Fundraising Dinner/Event', label: 'Fundraising Dinner/Event' },
  { value: 'Help Yateem Website', label: 'Help Yateem Website' },
  { value: 'Live Appeal', label: 'Live Appeal' },
  { value: 'Mosque', label: 'Mosque' },
  { value: 'Post', label: 'Post' },
  {
    value: 'Search Engine/Online Advert',
    label: 'Search Engine/Online Advert'
  },
  { value: 'SMS', label: 'SMS' },
  { value: 'Social Media', label: 'Social Media' },
  { value: 'TV Advert', label: 'TV Advert' },
  { value: 'Other', label: 'Other' }
];
