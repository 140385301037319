import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import TinymceEditor from 'components/common/TinymceEditor';
import {
  FormProvider,
  RHFControl,
  RHFDatePicker,
  RHFSelect
} from 'components/common/form';
import {
  getCitiesOptions,
  getCountriesOptions
} from 'data/options/countris-and-cities';
import { getSize } from 'helpers/utils';
import useCurrenciesOptions from 'hooks/useCurrenciesOptions';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { completionScheme } from './completionScheme';
// import { projectStatusOptions } from '../ProjectsList';

const CompletionForm = ({ onSubmit, loading, initialValues }) => {
  const { id } = useParams();
  const { currenciesOptions, loading: currenciesLoading } =
    useCurrenciesOptions();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles(acceptedFiles);
  }, [acceptedFiles]);

  const allFiles = files.map((file, index) => (
    <li
      className={classNames('list-group border p-2 px-3', {
        'mb-2': index !== files.length - 1
      })}
      key={file.path}
    >
      <Flex alignItems={'center'}>
        <FontAwesomeIcon
          className="me-3"
          icon={
            (file.type === 'image/png' && 'image') ||
            (file.type === 'image/jpeg' && 'image') ||
            (file.type === 'text/csv' && 'file') ||
            (file.type === 'application/pdf' && 'file-pdf') ||
            (file.type === 'application/zip' && 'file-archive') ||
            (file.type === 'video/mp4' && 'video') ||
            'paperclip'
          }
        />
        {file.path} - {getSize(file.size)}
        <Button
          variant="link"
          className="ms-auto p-0"
          onClick={() => handleRemove(file.path)}
        >
          <FontAwesomeIcon icon={'times'} />
        </Button>
      </Flex>
    </li>
  ));

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };
  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      completionDate: '',
      country: '',
      cities: '',
      numOfBeneficiaries: '',
      currency: '',
      administrationCost: '',
      projectCost: '',
      status: '',
      videoUrl: '',
      feedback: ''
    },
    resolver: yupResolver(completionScheme)
  });

  const { handleSubmit, watch, setValue, reset } = methods;
  const country = watch('country');
  const feedback = watch('feedback');

  useEffect(() => {
    if (initialValues) {
      const completionDate = new Date(initialValues.completion_date);
      reset({
        completionDate: completionDate,
        country: initialValues.country,
        cities: initialValues.cities,
        numOfBeneficiaries: initialValues.no_of_beneficiary,
        currency: initialValues.currency_id,
        administrationCost: initialValues.administration_cost,
        projectCost: initialValues.project_cost,
        status: initialValues.status,
        videoUrl: initialValues.video_url,
        feedback: initialValues.feedback
      });
    }
  }, [initialValues, reset]);

  const countriesOptions = useMemo(() => getCountriesOptions(), []);
  const citiesOptions = useMemo(() => {
    if (country) {
      return getCitiesOptions(country);
    }
    return [];
  }, [country]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card className="mb-3">
        <FalconCardHeader
          title={
            <h4 className="mt-2 fw-bold">
              <FontAwesomeIcon icon={'ticket-alt'} className="me-2" />
              Project Completion{' '}
            </h4>
          }
          light={false}
          titleTag="div"
          menuClassName="align-items-center"
          endEl={<p className="mb-0 test-600">#{id}</p>}
        />
        <Card.Body className="pt-0">
          <Row className="g-3">
            <Col md="6">
              <RHFDatePicker
                name="completionDate"
                label="Completion Date"
                required
              />
            </Col>
            <Col md="6">
              <RHFSelect
                name="country"
                label="Country"
                required
                options={countriesOptions}
              />
            </Col>
            <Col md="12">
              <RHFSelect
                name="cities"
                label="Cities"
                options={citiesOptions}
                isDisabled={citiesOptions.length === 0}
                required
                isMulti
              />
            </Col>
            <Col md="6">
              <RHFControl
                name="numOfBeneficiaries"
                placeholder="Number of Beneficiaries"
                label="Number of Beneficiaries"
                required
              />
            </Col>
            <Col md="6">
              <RHFSelect
                name="currency"
                placeholder="Currency"
                label="Currency"
                options={currenciesOptions}
                isLoading={currenciesLoading}
                required
              />
            </Col>
            <Col md="6">
              <RHFControl
                name="projectCost"
                placeholder="Total Project Cost"
                label="Total Project Cost"
                required
              />
            </Col>
            <Col md="6">
              <RHFControl
                name="administrationCost"
                placeholder="Total Administration Cost"
                label="Total Administration Cost"
                required
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header>
          <Card.Title className="fw-medium">
            <FontAwesomeIcon icon={'ticket-alt'} className="me-2" />
            Documentation
          </Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Row className="g-3">
            <Form.Group as={Col} md="12" controlId="Case Study">
              <Form.Label>
                Project Feedback <span className="text-danger">*</span>
              </Form.Label>
              <TinymceEditor
                value={feedback}
                handleChange={newValue => setValue('feedback', newValue)}
                height={'300'}
              />
            </Form.Group>
            <Col md="12">
              <RHFControl
                name="videoUrl"
                placeholder="Video URL"
                label="Video URL"
                required
              />
            </Col>
            <hr />
            <Col md={12} className="mt-0">
              <div className="mt-2">
                <div
                  {...getRootProps({
                    className: 'dropzone-area h-auto py-4'
                  })}
                >
                  <input {...getInputProps({ multiple: true })} />
                  <Flex justifyContent="center" alignItems={'center'}>
                    <img src={cloudUpload} alt="" width={30} className="me-2" />
                    <p className="fs-0 mb-0 text-700">Drop your file here</p>
                  </Flex>
                </div>
                <div className="mt-3">
                  {files.length > 0 && (
                    <div
                      style={{ backgroundColor: '#F9FAFD' }}
                      className="rounded-2"
                    >
                      <ul className="p-4">{allFiles}</ul>
                    </div>
                  )}
                </div>
              </div>
            </Col>
            {/* <Col md="12">
              <RHFSelect
                name="status"
                placeholder="Status"
                label="Status"
                options={projectStatusOptions}
                required
              />
            </Col> */}
          </Row>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <Flex
            justifyContent="between"
            alignItems="center"
            className="flex-column flex-md-row"
          >
            <p className="mb-0 fs-2 mb-2 mb-md-0 fw-medium">Progress Report</p>
            <LoadingButton className="w-215px" loading={loading} type="submit">
              {initialValues ? 'Update' : 'Submit'}
            </LoadingButton>
          </Flex>
        </Card.Body>
      </Card>
    </FormProvider>
  );
};

CompletionForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  initialValues: PropTypes.object
};

export default CompletionForm;
