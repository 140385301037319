import { useImperativeHandle, useRef, useState, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import CrudCardForm from './CrudCardForm';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import { userActions } from 'routes/permissions/permissionKeys';
import { Can } from 'routes/permissions/Can';

const CrudCard = forwardRef(
  (
    {
      inputs,
      formTitle,
      listTitle,
      list,
      title,
      sectionRef,
      sideNavLink,
      createData,
      editData,
      deleteData,
      createLoading,
      updateLoading,
      deleteLoading,
      hasOpenCheck,
      handleOpenChange,
      isOpen,
      subject
    },
    ref
  ) => {
    const [isEdit, setIsEdit] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const formRef = useRef(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const handleDelete = id => {
      setSelectedId(id);
      setShowDeleteConfirmation(true);
    };
    useImperativeHandle(ref, () => ({
      onEditSuccess() {
        setIsEdit(false);
        setSelectedId(null);
        formRef.current.resetForm();
      },
      onDeleteSuccess() {
        setShowDeleteConfirmation(false);
        setSelectedId(null);
      },
      onCreationSuccess() {
        formRef.current.resetForm();
      }
    }));
    const handleCancelEdit = () => {
      setIsEdit(false);
      setSelectedId(null);
    };
    return (
      <Card
        className="border shadow-none mb-3"
        ref={sectionRef}
        id={sideNavLink}
      >
        <ConfirmationModal
          show={showDeleteConfirmation}
          onHide={() => setShowDeleteConfirmation(false)}
          handleConfirm={() => deleteData(selectedId)}
          title={'Delete Confirmation'}
          body={'Are you sure you want to delete this item?'}
          loading={deleteLoading}
        />
        <Card.Header>
          <Row>
            <Col>
              <Card.Title>{title}</Card.Title>
            </Col>
            {hasOpenCheck && (
              <Col>
                <Card.Title style={{ textAlign: 'right' }}>
                  <Form.Group as={Row} className="mb-3">
                    <Col>
                      <Form.Check
                        type="switch"
                        id="custom-switch"
                        checked={isOpen}
                        onChange={e => handleOpenChange(e.target.checked)}
                      />
                    </Col>
                  </Form.Group>
                </Card.Title>
              </Col>
            )}
          </Row>
        </Card.Header>
        {(isOpen || !hasOpenCheck) && (
          <Card.Body>
            <Can I={userActions.create} a={subject}>
              <CrudCardForm
                inputs={inputs}
                title={formTitle}
                onSubmit={createData}
                loading={createLoading}
                ref={formRef}
              />
            </Can>
            <h5 className="pt-3 pb-2">{listTitle}</h5>
            <div className="spon_values_current_list_container">
              {list?.map(({ id, values }) => {
                const inputsWithValues = inputs.map(input => {
                  const value = values.find(({ name }) => name === input.name);
                  return {
                    ...input,
                    value: value?.value
                  };
                });
                return isEdit && selectedId === id ? (
                  <CrudCardForm
                    inputs={inputsWithValues}
                    title={formTitle}
                    isEdit={true}
                    onSubmit={data => editData({ id, data })}
                    loading={updateLoading}
                    ref={formRef}
                    key={id}
                    onCancel={handleCancelEdit}
                  />
                ) : (
                  <div key={id} className="px-2">
                    <div className="spon_values_current_list py-2">
                      <div>
                        <p className="mb-0 fw-normal fs-0">
                          {values[0].display ?? values[0].value}
                        </p>
                        {values.length > 1 && (
                          <p className="mb-0 text-muted fs--1">
                            {values[1].display ?? values[1].value}
                          </p>
                        )}
                      </div>

                      <div>
                        <Can I={userActions.delete} a={subject}>
                          <Button
                            variant="light"
                            className="shadow-none bg-transparent border-0 "
                            style={{
                              fontSize: 14
                            }}
                            onClick={() => handleDelete(id)}
                          >
                            <FontAwesomeIcon
                              as="span"
                              icon="trash"
                              color="#C11629"
                              style={{ marginRight: '5px' }}
                            />
                            Delete
                          </Button>
                        </Can>
                        <Can I={userActions.update} a={subject}>
                          <Button
                            variant="light"
                            className="shadow-none bg-transparent border-0"
                            style={{
                              fontSize: 14
                            }}
                            onClick={() => {
                              setIsEdit(true);
                              setSelectedId(id);
                            }}
                          >
                            <FontAwesomeIcon
                              icon="pen"
                              style={{ marginRight: '5px' }}
                            />
                            Edit
                          </Button>
                        </Can>
                      </div>
                    </div>
                  </div>
                );
              })}
              {list?.length === 0 && (
                <h5 className="text-center text-500">No data available</h5>
              )}
            </div>
          </Card.Body>
        )}
      </Card>
    );
  }
);

CrudCard.propTypes = {
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
      placeholder: PropTypes.string,
      validation: PropTypes.object,
      value: PropTypes.string
    })
  ),
  formTitle: PropTypes.string,
  listTitle: PropTypes.string,
  list: PropTypes.array,
  title: PropTypes.string,
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string,
  createData: PropTypes.func,
  editData: PropTypes.func,
  deleteData: PropTypes.func,
  createLoading: PropTypes.bool,
  updateLoading: PropTypes.bool,
  deleteLoading: PropTypes.bool,
  hasOpenCheck: PropTypes.bool,
  handleOpenChange: PropTypes.func,
  isOpen: PropTypes.bool,
  subject: PropTypes.string
};

export default CrudCard;
