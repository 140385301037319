import Locations, {
  locationTypesMap
} from 'components/donations/donation-settings/Locations';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Nav, Row } from 'react-bootstrap';
import useScrollSpy from 'react-use-scrollspy';
import Conditions from './Conditions';
import SponsorshipCosts from './SponsorshipCosts';
import SponsorshipTypes from './SponsorshipTypes';
// import SponsorshipStatus from './SponsorshipStatus';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

const SponsorshipValues = () => {
  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
    // useRef(null)
  ];

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -150
  });

  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 100) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, [window.scrollY]);

  const sideNavLinks = [
    { id: 1, href: '#sponsorType', title: 'Sponsor Types' },
    { id: 2, href: '#locations', title: 'Sponsorship Locations' },
    { id: 3, href: '#conditions', title: 'Conditions' },
    { id: 4, href: '#sponsorshipCost', title: 'Sponsorship Costs' }
    // { id: 5, href: '#sponsorshipStatus', title: 'Sponsorship Status' },
    // {
    //   id: 5,
    //   href: '#sponsorshipPriority',
    //   title: 'Sponsorship Priorities'
    // }
  ];

  return (
    <Row className="pt-3 gx-3" style={{ paddingBottom: 350 }}>
      <Col md={9}>
        <SponsorshipTypes
          sectionRef={sectionRefs[1]}
          sideNavLink={sideNavLinks[0]?.href?.substring(1)}
          subject={permissionsKeyMap.sponsorship.children.sponsorshipValues}
        />
        <Locations
          sectionRef={sectionRefs[2]}
          sideNavLink={sideNavLinks[1]?.href?.substring(1)}
          type={locationTypesMap.sponsorship}
          subject={permissionsKeyMap.sponsorship.children.sponsorshipValues}
        />
        <Conditions
          sectionRef={sectionRefs[3]}
          sideNavLink={sideNavLinks[2]?.href?.substring(1)}
          subject={permissionsKeyMap.sponsorship.children.sponsorshipValues}
        />
        <SponsorshipCosts
          sectionRef={sectionRefs[4]}
          sideNavLink={sideNavLinks[3]?.href?.substring(1)}
          subject={permissionsKeyMap.sponsorship.children.sponsorshipValues}
        />
        {/* <SponsorshipStatus
          sectionRef={sectionRefs[5]}
          sideNavLink={sideNavLinks[4]?.href?.substring(1)}
        /> */}
        {/* <SponsorshipPriorities
          sectionRef={sectionRefs[5]}
          sideNavLink={sideNavLinks[4]?.href?.substring(1)}
          subject={permissionsKeyMap.sponsorship.children.sponsorshipValues}
        /> */}
      </Col>

      <Col md={3} className="order-0 order-md-1 d-none d-md-block">
        <Card
          className={'mb-3 sticky-top border shadow-none'}
          style={{ top: stickyStatus ? '5rem' : '0' }}
        >
          <Card.Body>
            <div>
              <Nav className="flex-column" variant="pills">
                {sideNavLinks?.map(item => (
                  <Nav.Link
                    key={item.id}
                    href={item.href}
                    className={`${
                      activeSection === item.id
                        ? 'current custom-nav-link'
                        : 'custom-nav-link'
                    }`}
                    active={activeSection >= item.id}
                  >
                    {item.title}
                  </Nav.Link>
                ))}
              </Nav>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SponsorshipValues;
