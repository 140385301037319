import { gql, useMutation } from '@apollo/client';
import { convertBase64 } from 'helpers/utils';
import { toast } from 'react-toastify';

const UPLOAD_IMAGE = gql`
  mutation uploadImage($base64str: String = "") {
    uploadImage(base64str: $base64str) {
      url
    }
  }
`;

export default function useUpload() {
  const [uploadImage, { loading }] = useMutation(UPLOAD_IMAGE);

  const upload = async file => {
    const base64str = await convertBase64(file);
    try {
      const { data } = await uploadImage({
        variables: { base64str }
      });
      return data.uploadImage.url;
    } catch (error) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }
  };

  return {
    upload,
    loading
  };
}
