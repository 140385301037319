import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const DonationContext = createContext({
  donationPackages: [],
  setDonationPackages: () => {},
  urgentAppeal: [],
  setUrgentAppeal: () => {},
  giftAid: false,
  setGiftAid: () => {},
  transactionFee: false,
  setTransactionFee: () => {},
  transactionFeeAmount: 0,
  setTransactionFeeAmount: () => {},
  donor: {},
  setDonor: () => {}
});

export function DonationContextProvider({ children }) {
  const [donationPackages, setDonationPackages] = useState([]);
  const [urgentAppeal, setUrgentAppeal] = useState([]);
  const [giftAid, setGiftAid] = useState(false);
  const [transactionFee, setTransactionFee] = useState(false);
  const [donor, setDonor] = useState();
  const [transactionFeeAmount, setTransactionFeeAmount] = useState(0);
  return (
    <DonationContext.Provider
      value={{
        donationPackages,
        setDonationPackages,
        urgentAppeal,
        setUrgentAppeal,
        giftAid,
        setGiftAid,
        transactionFee,
        setTransactionFee,
        transactionFeeAmount,
        setTransactionFeeAmount,
        donor,
        setDonor
      }}
    >
      {children}
    </DonationContext.Provider>
  );
}

DonationContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useDonationContextProvider = () => {
  const context = useContext(DonationContext);
  if (context === undefined) {
    throw new Error('Context Problem.');
  }
  return context;
};

export default DonationContext;
