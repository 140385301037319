import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import LoadingButton from '../LoadingButton';

function ConfirmationModal({
  show,
  handleConfirm,
  onHide,
  title,
  subTitle,
  body,
  loading,
  children,
  confirmText = 'Confirm'
}) {
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="modal-header-title">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          {body ? body : null}
          {children ? children : null}
          {subTitle ? subTitle : null}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="falcon-danger" onClick={onHide}>
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            disabled={loading}
            onClick={handleConfirm}
          >
            {confirmText}
          </LoadingButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  handleConfirm: PropTypes.func,
  onHide: PropTypes.func,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  body: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.node,
  confirmText: PropTypes.string
};

export default ConfirmationModal;
