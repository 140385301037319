import CardDropdown from 'components/common/CardDropdown';
import SoftBadge from 'components/common/SoftBadge';
import CustomAdvanceTableGiftAid from 'components/common/advance-table/CustomAdvanceTableGiftAid';
import { useContext, useState, useMemo } from 'react';
// import { weTableSingleClaimListing } from 'data/weraise-data/sponsored-list-data';
import { Col, Row } from 'react-bootstrap';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
// import IconButton from 'components/common/IconButton';
import { gql, useMutation, useQuery } from '@apollo/client';
import LoadingContainer from 'components/common/LoadingContainer';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import DonorCounter from 'components/donor/DonorCounter';
import { format } from 'date-fns';
import { formatMoney } from 'helpers/utils';
import Dropdown from 'react-bootstrap/Dropdown';
import { toast } from 'react-toastify';
import UserContext from '../../../context/UserContext';
import {
  GIFT_AID_STATUS_ACCEPTED,
  GIFT_AID_STATUS_REJECTED,
  giftAidStatus
} from '../unclaimed-transactions';
import './../../../assets/scss/user.scss';
import {
  permissionsKeyMap,
  userActions
} from 'routes/permissions/permissionKeys';
import { Can } from 'routes/permissions/Can';

/* -------------------------------------------------------------------------- */
const GET_GIFT_AID_QUERY = gql`
  query GetGiftAid($gift_aid_application_id: uuid = "") {
    gift_aid(
      where: { gift_aid_application_id: { _eq: $gift_aid_application_id } }
      order_by: { created_at: desc }
    ) {
      sponsored_event
      aggregated_donation
      status
      amount
      created_at
      updated_at
      donar_id
      gift_aid_application_id
      id
      order_id
      order {
        donation_amount
      }
      donar {
        title
        first_name
        last_name
        address
      }
    }
    gift_aid_aggregate {
      aggregate {
        sum {
          amount
        }
        count
      }
      nodes {
        order {
          donation_amount
        }
      }
    }
    acceptedCount: gift_aid_aggregate(where: { status: { _eq: ${GIFT_AID_STATUS_ACCEPTED} } }) {
      aggregate {
        count
      }
    }
    rejectedCount: gift_aid_aggregate(where: { status: { _eq: ${GIFT_AID_STATUS_REJECTED} } }) {
      aggregate {
        count
      }
    }
  }
`;
const UPDATE_GIFT_AID_STATUS_MUTATION = gql`
  mutation UpdateGiftAidStatus($status: String = "", $id: uuid = "") {
    update_gift_aid_by_pk(pk_columns: { id: $id }, _set: { status: $status }) {
      sponsored_event
      aggregated_donation
      status
      amount
      created_at
      updated_at
      donar_id
      gift_aid_application_id
      id
      order_id
      order {
        donation_amount
      }
      donar {
        title
        first_name
        last_name
        address
      }
    }
  }
`;

const EXPORT_GIFT_AID = gql`
  mutation ExportGiftAid($gift_aid_application_id: ID = "") {
    ExportGiftAid(gift_aid_application_id: $gift_aid_application_id) {
      message
      path
    }
  }
`;
/* -------------------------------------------------------------------------- */

const ApplicationDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { giftAidData, setGiftAidData } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [isEdit, setIsEdit] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [editId, setEditId] = useState(null);
  const [exportLoading, setExportLoading] = useState(false);
  const [statusChangeState, setStatusChangeState] = useState({
    id: null,
    status: null
  });
  const [showChangeStatusConfirmation, setShowChangeStatusConfirmation] =
    useState(false);
  const { data, loading, error } = useQuery(GET_GIFT_AID_QUERY, {
    variables: {
      gift_aid_application_id: id
    }
  });
  const [updateGiftAidStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_GIFT_AID_STATUS_MUTATION,
    {
      onCompleted: () => {
        hideChangeStatusConfirmation();
        toast.success('Gift Aid status updated successfully');
      },
      onError: error => {
        toast.error(error.message);
      }
    }
  );
  const [exportGiftAid] = useMutation(EXPORT_GIFT_AID, {
    onCompleted: data => {
      window.location.href = data?.ExportGiftAid.path;
      setExportLoading(false);
    },
    onError: error => {
      setExportLoading(false);
      toast.error(error.message);
    }
  });

  const handleRowChange = (index, key, value) => {
    const temp = [...giftAidData.claimed];
    const updated = temp.map(singleClaimed => {
      if (singleClaimed.ref === searchParams.get('ref').trim()) {
        const tempListings = [...singleClaimed.listings];
        const tempObj = { ...tempListings[index] };
        if (key === 'donation_date') {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          });
          tempObj[key] = formattedDate;
        } else {
          tempObj[key] = value;
        }
        tempListings[index] = tempObj;
        singleClaimed.listings = tempListings;
        // setTableData(tempListings);
        return singleClaimed;
      }
      return singleClaimed;
    });
    giftAidData.claimed = updated;
    setGiftAidData(giftAidData);
  };

  const handleExport = () => {
    setExportLoading(true);
    exportGiftAid({ variables: { gift_aid_application_id: id } });
  };

  const handleSave = () => {
    setIsEdit(false);
    setIsAdd(false);
    setEditId(null);
  };

  const handleChangeStatus = (id, status) => {
    setShowChangeStatusConfirmation(true);
    setStatusChangeState({
      id,
      status
    });
  };

  const hideChangeStatusConfirmation = () => {
    setShowChangeStatusConfirmation(false);
    setStatusChangeState({
      id: null,
      status: null
    });
  };

  const handleChangeStatusConfirm = () => {
    updateGiftAidStatus({
      variables: {
        id: statusChangeState.id,
        status: statusChangeState.status
      }
    });
  };

  const handleRemove = index => {
    const temp = [...giftAidData.claimed];
    const updated = temp.map(singleClaimed => {
      if (singleClaimed.ref === searchParams.get('ref').trim()) {
        const tempListings = [...singleClaimed.listings];
        tempListings.splice(index, 1);
        singleClaimed.listings = tempListings;
        // setTableData(tempListings);
        return singleClaimed;
      }
      return singleClaimed;
    });
    giftAidData.claimed = updated;
    setGiftAidData(giftAidData);
  };

  const addManuallyRow = () => {
    const temp = [...giftAidData.claimed];
    const updated = temp.map(singleClaimed => {
      if (singleClaimed.ref === searchParams.get('ref').trim()) {
        const tempListings = [...singleClaimed.listings];
        const tempObj = {
          title: '',
          first_name: '',
          second_name: '',
          house_number: '',
          postcode: '',
          aggregated_donations: '',
          sponsored_event: '',
          donation_date: '01/01/1990',
          amount: '£0',
          status: '',
          variant: ''
        };
        tempListings.push(tempObj);
        singleClaimed.listings = tempListings;
        setIsEdit(true);
        setEditId(tempListings.length - 1);
        // setTableData(tempListings);
        setIsAdd(true);
        return singleClaimed;
      }
      return singleClaimed;
    });
    giftAidData.claimed = updated;
    setGiftAidData(giftAidData);
  };

  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'first_name',
      Header: 'First Name',
      cellProps: {
        className: 'py-2 fw-medium'
      }
    },
    {
      accessor: 'last_name',
      Header: 'Second Name',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'house_number',
      Header: 'House Number',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'postcode',
      Header: 'PostCode',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'aggregated_donations',
      Header: 'Aggregated Donations',
      cellProps: {
        className: 'fw-medium py-2 '
      }
    },
    {
      accessor: 'sponsored_event',
      Header: 'Sponsored Event',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'donation_date',
      Header: 'Donation Date',
      cellProps: {
        className: 'fw-medium py-2'
      }
    },
    {
      accessor: 'amount',
      Header: 'Amount',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { amount } = rowData.row.original;
        return <span>{formatMoney(amount, '£')}</span>;
      }
    },
    {
      accessor: 'status',
      Header: 'Status',
      cellProps: {
        className: 'fw-medium py-2'
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge pill bg={giftAidStatus[status].variant} className="me-2">
            {giftAidStatus[status].label}
          </SoftBadge>
        );
      }
    },

    {
      accessor: 'oid',
      Header: '',
      cellProps: {
        className: 'text-end fw-medium py-2'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { id } = rowData.row;
        const { status } = rowData.row.original;

        return (
          <CardDropdown>
            <Can
              I={userActions.update}
              a={permissionsKeyMap.giftAid.children.giftAid}
            >
              <div className="py-2">
                <Dropdown.Item
                  onClick={() => {
                    setEditId(id);
                    setIsEdit(true);
                  }}
                >
                  Edit
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleRemove(parseInt(id))}>
                  Remove
                </Dropdown.Item>
                {status !== GIFT_AID_STATUS_ACCEPTED && (
                  <Dropdown.Item
                    className={`text-${giftAidStatus[GIFT_AID_STATUS_ACCEPTED].variant}`}
                    onClick={() =>
                      handleChangeStatus(id, GIFT_AID_STATUS_ACCEPTED)
                    }
                  >
                    {giftAidStatus[GIFT_AID_STATUS_ACCEPTED].label}
                  </Dropdown.Item>
                )}
                {status !== GIFT_AID_STATUS_REJECTED && (
                  <Dropdown.Item
                    className={`text-${giftAidStatus[GIFT_AID_STATUS_REJECTED].variant}`}
                    onClick={() =>
                      handleChangeStatus(id, GIFT_AID_STATUS_REJECTED)
                    }
                  >
                    {giftAidStatus[GIFT_AID_STATUS_REJECTED].label}
                  </Dropdown.Item>
                )}
              </div>
            </Can>
          </CardDropdown>
        );
      }
    }
  ];

  const footerContent = (
    <Row class="justify-content-between">
      <Col>
        <Dropdown style={{ color: '#5D6E82' }}>
          <Dropdown.Toggle variant="light" id="dropdown-basic">
            Add Transactions
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              style={{ color: '#5D6E82' }}
              onClick={addManuallyRow}
            >
              Add Manually
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                navigate(
                  `/aid/unclaimed?newClaim=false&ref=${searchParams.get('ref')}`
                )
              }
              style={{ color: '#5D6E82' }}
            >
              Select From List
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col className="amount-content" style={{ textAlign: 'right' }}>
        <span>
          Total Amount :
          <span style={{ marginLeft: '40px' }}>
            {formatMoney(data?.gift_aid_aggregate?.aggregate?.sum?.amount, '£')}
          </span>
        </span>
      </Col>
    </Row>
  );
  const tableData = useMemo(() => {
    if (!data) return [];
    return data.gift_aid.map(singleClaimed => {
      const { donar } = singleClaimed;
      const formattedDate = format(
        new Date(singleClaimed.created_at),
        'dd/MM/yyyy'
      );
      const primaryAddress = donar.address.find(
        address => address.isPrimary === true
      );
      return {
        title: donar.title,
        first_name: donar.first_name,
        last_name: donar.last_name,
        house_number: primaryAddress.addressLine1,
        postcode: primaryAddress.postcode,
        aggregated_donations: singleClaimed.aggregated_donation,
        sponsored_event: singleClaimed.sponsored_event,
        amount: singleClaimed.amount,
        status: singleClaimed.status,
        donation_date: formattedDate,
        id: singleClaimed.id
      };
    });
  }, [data]);
  const listCounter = useMemo(() => {
    if (!data) return [];
    const { aggregate } = data.gift_aid_aggregate;
    const { sum, count: transactionsCount } = aggregate;
    const { count: acceptedCount } = data.acceptedCount.aggregate;
    const { count: rejectedCount } = data.rejectedCount.aggregate;
    const totalDonations = data.gift_aid_aggregate.nodes.reduce(
      (acc, curr) => acc + (curr?.order?.donation_amount ?? 0),
      0
    );
    return [
      {
        id: 1,
        title: 'Total Transactions',
        amount: transactionsCount,
        color: '#00D27B',
        showContent: false
      },
      {
        id: 2,
        title: 'Accepted',
        amount: acceptedCount,
        color: '#1E1E1E',
        showContent: false
      },
      {
        id: 3,
        title: 'Rejected',
        amount: rejectedCount,
        color: '#1E1E1E',
        showContent: false
      },
      {
        id: 4,
        title: 'Total Donations',
        amount: formatMoney(totalDonations, '£'),
        color: '#5D6E82',
        showContent: false
      },
      {
        id: 5,
        title: 'Gift Aid',
        amount: formatMoney(sum.amount, '£'),
        color: '#5D6E82',
        showContent: false
      }
    ];
  }, [data]);
  if (loading) return <LoadingContainer />;
  if (error) return <p>Error! {error.message}</p>;
  return (
    <>
      <ConfirmationModal
        show={showChangeStatusConfirmation}
        onHide={hideChangeStatusConfirmation}
        title="Change Status"
        loading={updateStatusLoading}
        handleConfirm={handleChangeStatusConfirm}
      >
        <p>
          Are you sure you want to change the status of this gift aid to{' '}
          <b>{giftAidStatus[statusChangeState.status]?.label}</b>?
        </p>
      </ConfirmationModal>
      <Row>
        <Col>
          <DonorCounter counterData={listCounter} />
        </Col>
      </Row>

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          <CustomAdvanceTableGiftAid
            data={tableData}
            columns={columns}
            perPage={10}
            showTablePagination={false}
            title={`Claim Ref ${id}`}
            showSearchInput
            searchInputPlaceHolder="Search"
            hideBulkActions={true}
            showFilterButton={false}
            // content={content}
            isEditable={isEdit}
            isAdd={isAdd}
            editId={editId}
            addNew={false}
            showFooterContent={true}
            footerContent={footerContent}
            handleRowChange={handleRowChange}
            handleSave={handleSave}
            handleExport={handleExport}
            exportLoading={exportLoading}
            subject={permissionsKeyMap.giftAid.children.giftAid}
          />
        </Col>
      </Row>
    </>
  );
};

export default ApplicationDetails;
