import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import {
  RHFControl,
  RHFDatePicker,
  RHFFileUploadRegistration,
  RHFSelect
} from 'components/common/form';
import PropTypes from 'prop-types';
import { genderOptions } from 'data/options/common';

export const ProfileInformation = ({ sectionRef, sideNavLink }) => {
  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header className="pb-0">
        <Card.Title className="" as="h5">
          Profile Information
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Container className="px-0 d-flex flex-column gap-3">
          <RHFFileUploadRegistration name="image" />
          <Row className="gx-3">
            <Col xs={12} sm={6} md={4}>
              <RHFControl name="firstName" label="First Name" required />
            </Col>
            <Col xs={12} sm={6} md={4}>
              <RHFControl name="middleName" label="Middle Name" />
            </Col>
            <Col xs={12} sm={6} md={4}>
              <RHFControl name="lastName" label="Last Name" required />
            </Col>
          </Row>
          <Row className="gx-3">
            <Col xs={12} sm={6} md={4}>
              <RHFSelect
                name="gender"
                label="Gender"
                required
                options={genderOptions}
              />
            </Col>
            <Col xs={12} sm={6} md={4}>
              <RHFDatePicker
                name="dateOfBirth"
                label="Date of Birth"
                required
              />
            </Col>
            <Col xs={12} sm={6} md={4}>
              <Form.Label>Age</Form.Label>
              <RHFControl name="age" disabled />
            </Col>
          </Row>
          <Row className="gx-3">
            <Col xs={12} sm={6} md={4}>
              <RHFControl name="language" label="Spoken Language" />
            </Col>
            <Col xs={12} sm={6} md={8}>
              <RHFControl name="address" label="Address" />
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

ProfileInformation.propTypes = {
  sectionRef: PropTypes.object,
  sideNavLink: PropTypes.string
};
