import * as Yup from 'yup';

export const projectScheme = Yup.object().shape({
  title: Yup.string().required('Required Field'),
  category: Yup.string().required('Required Field'),
  description: Yup.string().required('Required Field'),
  country: Yup.string().required('Required Field'),
  cities: Yup.array(Yup.string()).required('Required Field'),
  numOfBeneficiaries: Yup.number().required('Required Field'),
  currency: Yup.string().required('Required Field'),
  startDate: Yup.string().required('Required Field'),
  endDate: Yup.string().required('Required Field'),
  estimatedCost: Yup.number().required('Required Field'),
  averageBeneficiariesCost: Yup.number().required('Required Field'),
  caseStudy: Yup.string().required('Please input Case study'),
  feasibilityStudy: Yup.string().required('Please input Feasibility study'),
  projectCharter: Yup.string().required('Please input Project Charter'),
  type: Yup.string().required('Required Field'),
  manager: Yup.string().required('Required Field')
});
