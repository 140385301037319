import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
const MAX_FILE_SIZE = 10048576;

const branchScheme = Yup.object().shape({
  logo: Yup.mixed().test({
    message: "File too big, can't exceed 10MB",
    test: file => {
      if (typeof file !== 'string' && file) {
        const isValid = file?.size < MAX_FILE_SIZE;
        return isValid;
      }
      return true;
    }
  }),
  name: Yup.string().required('Required Field'),
  currency_id: Yup.string().required('Required Field'),
  address: Yup.string().required('Required Field'),
  location_id: Yup.string().required('Required Field'),
  // email: Yup.string().required('Required Field'),
  city: Yup.string().required('Required Field'),
  charityNumber: Yup.string().required('Required Field'),
  phoneNumber: Yup.string().required('Required Field')
});

const branchResolver = yupResolver(branchScheme);

export { branchResolver };
