import React from 'react';
import HospitalForm from './HospitalForm';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CONTACT, GET_HOSPITAL } from '../queries';
import { INSERT_CONTACT, UPDATE_CONTACT, UPDATE_HOSPITAL } from '../mutations';
import { toast } from 'react-toastify';
import ContactForm from '../school/ContactForm';
import useUpload from 'hooks/useUpload';
import paths from 'routes/paths';

function EditHospital() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { upload, loading: uploadLoading } = useUpload();

  const { data: hospitalData } = useQuery(GET_HOSPITAL, {
    variables: {
      id
    }
  });

  const { data: contactData } = useQuery(GET_CONTACT, {
    variables: {
      where: {
        hospital_id: { _eq: id }
      }
    }
  });

  const [updateHospital, { loading }] = useMutation(UPDATE_HOSPITAL, {
    onCompleted: () => {
      toast.success('Hospital Updated Successfully');
      navigate(paths.hospital);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.error(error);
    }
  });

  const [updateContact, { loading: updateContactLoading }] = useMutation(
    UPDATE_CONTACT,
    {
      onCompleted: () => {
        toast.success('Contact Updated Successfully');
        navigate(`/hospital/${id}/details`);
      },
      onError: error => {
        toast.error('Something went wrong');
        console.error(error);
      }
    }
  );

  const [insertContact, { loading: insertContactLoading }] = useMutation(
    INSERT_CONTACT,
    {
      onCompleted: () => {
        toast.success('Contact Added Successfully');
        navigate(`/hospital/${id}/details`);
      },
      onError: error => {
        toast.error('Something went wrong');
        console.error(error);
      }
    }
  );

  const submitEditHospital = async data => {
    if (data.hospital_document && typeof data.hospital_document !== 'string') {
      const hospital_document = await upload(data.hospital_document);
      data.hospital_document = hospital_document;
    }

    updateHospital({
      variables: {
        ...data,
        id: id,
        serial_number: data.serialNumber,
        name: data.hospitalName,
        phone: data.contact,
        document: data.hospital_document
      }
    });
  };

  const submitContact = values => {
    if (contactData?.contact?.length !== 0) {
      updateContact({
        variables: {
          ...values,
          where: { hospital_id: { _eq: id } }
        }
      });
    } else {
      insertContact({
        variables: {
          object: {
            ...values,
            hospital_id: id
          }
        }
      });
    }
  };

  return (
    <>
      <HospitalForm
        onSubmit={submitEditHospital}
        loading={loading || uploadLoading}
        initValues={hospitalData?.hospitals_by_pk || {}}
      />

      <ContactForm
        initValues={contactData?.contact}
        onSubmit={submitContact}
        loading={updateContactLoading || insertContactLoading}
      />
    </>
  );
}

export default EditHospital;
