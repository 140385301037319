import { Button, Form, Image } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import propTypes from 'prop-types';
import FileUploadPreview from '../custom/FileUploadPreview';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertBase64 } from 'helpers/utils';
import profilePictureAvatar from 'assets/img/placeholders/profile-picture-avatar.png';
import LogoUploadPreview from '../custom/LogoUploadPreview';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

export const RHFFileUpload = ({ name, label, ...rest }) => {
  const [productFile, setProductFile] = useState([]);
  const { control } = useFormContext();
  const { watch } = useFormContext();
  const value = watch(name);
  useEffect(() => {
    if (!value) {
      setProductFile([]);
    }
  }, [value, name]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // if value is url then set preview url
        let previewUrl = '';
        if (typeof value === 'string' && value?.includes('http')) {
          previewUrl = value;
        }
        return (
          <Form.Group>
            <Form.Label>{label}</Form.Label>
            <FileUploadPreview
              onChange={e => onChange(e.target.files[0])}
              files={productFile}
              setFiles={setProductFile}
              previewUrl={previewUrl}
              {...rest}
            />
            {error && (
              <div className="invalid-feedback-custom">{error.message}</div>
            )}
          </Form.Group>
        );
      }}
    />
  );
};

RHFFileUpload.propTypes = {
  name: propTypes.string,
  label: propTypes.string
};

export const RHFLogoUpload = ({ name, ...rest }) => {
  const [productFile, setProductFile] = useState([]);
  const { control } = useFormContext();
  const { watch } = useFormContext();
  const value = watch(name);
  useEffect(() => {
    if (!value) {
      setProductFile([]);
    }
  }, [value, name]);
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // if value is url then set preview url
        let previewUrl = '';
        if (typeof value === 'string' && value?.includes('http')) {
          previewUrl = value;
        }
        return (
          <Form.Group>
            {/* <Form.Label>{label}</Form.Label> */}
            <LogoUploadPreview
              onChange={e => onChange(e.target.files[0])}
              files={productFile}
              setFiles={setProductFile}
              previewUrl={previewUrl}
              label="Your Logo"
              {...rest}
            />
            {error && (
              <div className="invalid-feedback-custom">{error.message}</div>
            )}
          </Form.Group>
        );
      }}
    />
  );
};

RHFLogoUpload.propTypes = {
  name: propTypes.string
};

export const RHFAvatar = ({ name, ...rest }) => {
  const [base64, setBase64] = useState('');
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // if value is url then set preview url
        if (typeof value === 'string' && value?.includes('http')) {
          setBase64(value);
        }
        return (
          <>
            <div className="circular-image-center cursor-pointer">
              <div className="circular-image mb-2">
                <label className="circular-image" htmlFor="upload-button">
                  <Image
                    src={base64 ? base64 : profilePictureAvatar}
                    style={{
                      opacity: '.6',
                      borderRadius: '200px',
                      backgroundColor: 'none',
                      height: '100px',
                      width: '100px'
                    }}
                  />
                  <FontAwesomeIcon icon="camera" className="fs-1" />
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={async e => {
                    const file = e.target.files[0];
                    onChange(file);
                    const base64 = await convertBase64(file);
                    setBase64(base64);
                  }}
                  {...rest}
                />
              </div>
            </div>
            {error && (
              <div className="invalid-feedback-custom">{error.message}</div>
            )}
          </>
        );
      }}
    />
  );
};

RHFAvatar.propTypes = {
  name: propTypes.string,
  label: propTypes.string
};

export const RHFFileUploadRegistration = ({ name, label, ...rest }) => {
  const { control } = useFormContext();
  const [base64, setBase64] = useState('');

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        // if value is url then set preview url
        if (typeof value === 'string' && value?.includes('http')) {
          setBase64(value);
        }
        return (
          <Form.Group
            className="px-3 border border-300 rounded d-flex align-items-center"
            {...rest}
          >
            <div
              className="d-flex my-2 rounded-circle"
              style={{
                alignItems: 'center',
                overflow: 'hidden',
                width: 60,
                height: 60
              }}
            >
              <Image
                // style={{}}
                src={base64 ? base64 : profilePictureAvatar}
                className="w-100 h-100 text-secondary"
              />
            </div>
            <p className="mb-0 fw-medium ms-2">{label}</p>
            <input
              type="file"
              id="upload-button"
              style={{ display: 'none' }}
              accept="image/*"
              onChange={async e => {
                const file = e.target.files[0];
                onChange(file);
                const base64 = await convertBase64(file);
                setBase64(base64);
              }}
              {...rest}
            />
            <Button
              variant="outline-primary"
              className="file-selector my-2 ms-auto"
              onClick={() => {
                document.getElementById('upload-button').click();
              }}
            >
              <FontAwesomeIcon icon={faUpload} className="me-2" />
              Upload
              {/* <img src={arrowUp} className="ms-2" /> */}
            </Button>
            {error && (
              <div className="invalid-feedback-custom">{error.message}</div>
            )}
          </Form.Group>
        );
      }}
    />
  );
};

RHFFileUploadRegistration.propTypes = {
  name: propTypes.string,
  label: propTypes.string
};
