import { gql, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuthContext } from 'context/authContext';
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import paths from 'routes/paths';

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($hash: String = "", $user_id: ID = "") {
    VerifyEmail(hash: $hash, user_id: $user_id) {
      message
    }
  }
`;

export default function VerifyEmail() {
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [showFailureMessage, setShowFailureMessage] = useState(false);
  const { hash, userId } = useParams();
  const { initializeUser } = useAuthContext();

  const [loginMutation, { loading }] = useMutation(VERIFY_EMAIL, {
    onError: async ({ graphQLErrors }) => {
      // toast.error(graphQLErrors[0].message);
      console.log(graphQLErrors);
    },
    onCompleted: async () => {
      setShowSuccessMessage(true);
      await initializeUser();
    }
  });
  useEffect(() => {
    if (hash && userId) {
      // setShowFailureMessage(true);
      loginMutation({
        variables: {
          hash,
          user_id: userId
        }
      });
    }
  }, [hash, userId, loginMutation]);

  if (loading) {
    return (
      <div className="text-center w-100 py-3">
        <Spinner />
      </div>
    );
  }

  if (showSuccessMessage) {
    return (
      <div className="text-center">
        {/* <Image src={sessionImage} /> */}
        <FontAwesomeIcon icon="fa-solid fa-circle-check" />
        <p className="h4">Email Verified Successfully</p>
        <p>Please Login to continue.</p>
        <Button
          style={{ borderRadius: '30px' }}
          onClick={() => {
            navigate(paths.login);
          }}
        >
          Login
        </Button>
      </div>
    );
  }

  // if (showFailureMessage) {
  return (
    <div className="text-center">
      <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
      <p className="h4">Email Verification Failed</p>
      <p>Please try again.</p>
    </div>
  );
  // }

  // return (
  //   <div className="text-center w-100 py-3">
  //     <Spinner />
  //   </div>
  // );
}
