import { gql, useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable, {
  BULK_ACTION_EDIT,
  BULK_ACTION_DELETE
} from 'components/common/advance-table/CustomAdvanceTable';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useMemo, useState } from 'react';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import CommentFormModal from './CommentFormModal';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

/* -------------------------------------------------------------------------- */
const bulkActionsOptions = [
  {
    label: 'Delete',
    value: BULK_ACTION_DELETE
  }
];
/* -------------------------------------------------------------------------- */
export const GET_DONOR_COMMENTS = gql`
  query GetDonorComments($donorId: uuid!) {
    donor_comments(where: { donor_id: { _eq: $donorId } }) {
      reporter_id
      id
      donor_id
      description
      created_at
      user {
        first_name
        last_name
      }
    }
  }
`;

const DELETE_DONOR_COMMENTS = gql`
  mutation DeleteDonorComments($ids: [uuid!]!) {
    delete_donor_comments(where: { id: { _in: $ids } }) {
      returning {
        id
      }
    }
  }
`;

const EDIT_DONOR_COMMENT = gql`
  mutation EditDonorComment($id: uuid!, $description: String!) {
    update_donor_comments(
      where: { id: { _eq: $id } }
      _set: { description: $description }
    ) {
      returning {
        reporter_id
        id
        donor_id
        description
        created_at
        user {
          first_name
          last_name
        }
      }
    }
  }
`;

/* -------------------------------------------------------------------------- */

function DonorCommentsTable({ donorId, setShowCommentForm }) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const { loading: getCommentsLoading, data } = useQuery(GET_DONOR_COMMENTS, {
    variables: { donorId: donorId }
  });
  const [deleteDonorComments, { loading: deleteLoading }] = useMutation(
    DELETE_DONOR_COMMENTS,
    {
      onCompleted: () => {
        toast.success('Donor comments deleted successfully');
        handleDeleteModalHide();
      },
      onError: () => {
        toast.error('Something went wrong! Please try again');
      },
      update: (cache, { data: { delete_donor_comments } }) => {
        const { donor_comments } = cache.readQuery({
          query: GET_DONOR_COMMENTS,
          variables: { donorId: donorId }
        });
        if (!donor_comments) {
          return;
        }
        const newDonorComments = donor_comments.filter(
          item =>
            !delete_donor_comments.returning.some(({ id }) => id === item.id)
        );
        cache.writeQuery({
          query: GET_DONOR_COMMENTS,
          variables: { donorId: donorId },
          data: { donor_comments: newDonorComments }
        });
      }
    }
  );
  const [editDonorComment, { loading: editLoading }] = useMutation(
    EDIT_DONOR_COMMENT,
    {
      onCompleted: () => {
        toast.success('Donor comments updated successfully');
        setShowEditModal(false);
      },
      onError: () => {
        toast.error('Something went wrong! Please try again');
      }
    }
  );
  const columns = [
    {
      accessor: 'reporter',
      Header: 'reporter',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { first_name, last_name } = rowData.row.original.user;
        return (
          <p className="text-truncate mb-0" style={{ maxWidth: '400px' }}>
            {first_name} {last_name}
          </p>
        );
      }
    },
    {
      accessor: 'date',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        const data = format(new Date(created_at), 'dd/MM/yyyy');
        return data;
      }
    },
    {
      accessor: 'description',
      Header: 'Description',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => (
        <p className="text-truncate mb-0" style={{ maxWidth: '400px' }}>
          {rowData.row.original.description}
        </p>
      )
    },
    {
      accessor: 'id',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row;
        return (
          <TableRowAction
            onCLickEdit={() => handleEdit(id)}
            onCLickDelete={() => onDelete([id])}
            subject={permissionsKeyMap.donor.children.donors}
          />
        );
      }
    }
  ];
  const handleConfirmDelete = () => {
    deleteDonorComments({
      variables: {
        ids: selectedIds
      }
    });
  };
  const onDelete = ids => {
    setSelectedIds(ids);
    setShowDeleteModal(true);
  };
  const handleBulkAction = (action, ids) => {
    switch (action) {
      case BULK_ACTION_DELETE:
        onDelete(ids);
        break;
      case BULK_ACTION_EDIT:
        break;
      default:
        break;
    }
  };
  const handleDeleteModalHide = () => {
    setShowDeleteModal(false);
    setSelectedIds([]);
  };

  const handleEditModalHide = () => {
    setShowEditModal(false);
    setSelectedIds([]);
  };

  const handleEdit = id => {
    setSelectedIds([id]);
    setShowEditModal(true);
  };

  const handleEditSubmit = data => {
    editDonorComment({
      variables: {
        id: selectedIds[0],
        description: data.comment
      }
    });
  };

  const editInitialValues = useMemo(() => {
    if (!selectedIds.length) {
      return {};
    }
    const comment = data?.donor_comments.find(
      item => item.id === selectedIds[0]
    );
    const { description } = comment ?? { description: '' };
    return {
      comment: description
    };
  }, [selectedIds, data]);

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleDeleteModalHide}
        loading={deleteLoading}
        title="Delete Donor Comments"
        handleConfirm={handleConfirmDelete}
        body="Are you sure you want to delete selected donor comments?"
      />
      <CommentFormModal
        show={showEditModal}
        onHide={handleEditModalHide}
        onSubmit={handleEditSubmit}
        loading={editLoading}
        initialValues={editInitialValues}
        isEdit
      />
      {data?.donor_comments && (
        <CustomAdvanceTable
          data={data?.donor_comments}
          columns={columns}
          title="Comments"
          showSearchInput
          searchInputPlaceHolder="Search"
          addNew
          onClickAddNew={() => setShowCommentForm(true)}
          perPage={10}
          loading={getCommentsLoading}
          bulkActionsOptions={bulkActionsOptions}
          handleBulkAction={handleBulkAction}
        />
      )}
    </>
  );
}

DonorCommentsTable.propTypes = {
  donorId: PropTypes.string,
  setShowCommentForm: PropTypes.func
};

export default DonorCommentsTable;
