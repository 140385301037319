import { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const PNDonationSummary = ({ items, subtotal, transactionFees, total }) => {
  const [stickyStatus, setStickyStatus] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY < 10) {
        setStickyStatus(false);
      } else {
        setStickyStatus(true);
      }
    });
  }, []);

  return (
    <Card className="sticky-top" style={{ top: stickyStatus ? '5rem' : '0' }}>
      <Card.Header className="bg-100 fs-1 fw-semi-bold">
        Donation Summary
      </Card.Header>
      <Card.Body>
        {items?.map(({ name, location, amount, values }, index) => (
          <div key={`donation-item-${index}`}>
            <Flex justifyContent={'between'}>
              <div>
                <p className="fw-semi-bold mb-1">{name}</p>
                <p className="mb-0 text-600 fs--2">
                  {location} - {capitalizeFirstLetter(values?.billingCondition)}
                </p>
              </div>
              <p className="mb-0">${amount.toFixed(2)}</p>
            </Flex>
            <hr className="my-2" />
          </div>
        ))}
        <div>
          <Flex justifyContent={'between'}>
            <div>
              <p className="fw-semi-bold mb-1">Subtotal</p>
            </div>
            <p className="mb-0">${subtotal.toFixed(2)}</p>
          </Flex>
          <hr className="my-2" />
        </div>
        <div>
          <Flex justifyContent={'between'}>
            <div>
              <p className="fw-semi-bold mb-1">Transaction Fees</p>
            </div>
            <p className="mb-0">${transactionFees.toFixed(2)}</p>
          </Flex>
          <hr className="my-2" />
        </div>
        <div>
          <Flex justifyContent={'between'}>
            <div>
              <p className="fw-semi-bold mb-1">Total</p>
            </div>
            <p className="mb-0">${total.toFixed(2)}</p>
          </Flex>
          <hr className="my-2" />
        </div>
      </Card.Body>
      <Card.Footer className="bg-100 fs-1 fw-semi-bold">
        <Flex justifyContent={'between'}>
          <p className="fs-0 mb-0 fw-semi-bold text-700">Payable Total</p>
          <p className="fs-0 mb-0 fw-semi-bold text-700">${total.toFixed(2)}</p>
        </Flex>
      </Card.Footer>
    </Card>
  );
};

PNDonationSummary.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.object,
      amount: PropTypes.number,
      type: PropTypes.string,
      name: PropTypes.string,
      location: PropTypes.string
    })
  ),
  subtotal: PropTypes.number,
  transactionFees: PropTypes.number,
  total: PropTypes.number
};

export default PNDonationSummary;
