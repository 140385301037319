import { Card, Col, Container, Row } from 'react-bootstrap';
import BranchForm from './BranchForm';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import useUpload from 'hooks/useUpload';

// -------------------------------------

const GET_BRANCH = gql`
  query GetBranch($id: uuid = "") {
    branches_by_pk(id: $id) {
      address
      city
      created_at
      currency_id
      id
      location_id
      logo
      name
      organisation_id
      phone
    }
  }
`;

const UPDATE_BRANCH = gql`
  mutation UpdateBranch(
    $address: jsonb = ""
    $city: String = ""
    $currency_id: uuid = ""
    $location_id: uuid = ""
    $logo: String = ""
    $name: String = ""
    $organisation_id: uuid = ""
    $phone: String = ""
    $id: uuid = ""
  ) {
    update_branches_by_pk(
      _set: {
        address: $address
        city: $city
        currency_id: $currency_id
        phone: $phone
        organisation_id: $organisation_id
        name: $name
        logo: $logo
        location_id: $location_id
      }
      pk_columns: { id: $id }
    ) {
      address
      city
      created_at
      currency_id
      id
      location_id
      logo
      name
      phone
      organisation_id
    }
  }
`;

// -------------------------------------
export default function EditBranch() {
  const { id } = useParams();
  const { data, loading } = useQuery(GET_BRANCH, {
    variables: {
      id
    }
  });

  const navigate = useNavigate();
  const { upload, loading: uploadLoading } = useUpload();

  const [updateBrnach] = useMutation(UPDATE_BRANCH, {
    onCompleted: () => {
      toast.success('Branch updated successfully');
      navigate(paths.branchesSetting);
    }
  });

  const onSubmit = async values => {
    const image = values.logo;
    try {
      if (image && typeof image !== 'string') {
        const profileUrl = await upload(image);
        values.logo = profileUrl;
      }
    } catch (e) {
      toast.error('Something went wrong! Please Try again later!');
      return;
    }
    updateBrnach({
      variables: {
        ...values,
        address: '',
        logo: values.logo
      }
    });
  };
  return (
    <Container fluid className="px-0">
      <Row>
        <Col sm={12} md={7}>
          <Card>
            <Card.Body>
              <BranchForm
                loading={loading || uploadLoading}
                onSubmit={onSubmit}
                isEdit
                initValues={data?.branches_by_pk ? data?.branches_by_pk : {}}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
