import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-phone-input-2/lib/bootstrap.css';
import Layout from './layouts/Layout';
import { AuthContextProvider } from 'context/authContext';

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <AuthContextProvider>
        <Layout />
      </AuthContextProvider>
    </Router>
  );
};

export default App;
