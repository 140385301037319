import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import BasicECharts from 'components/common/BasicEChart';
import { gql, useQuery } from '@apollo/client';
import AuthContext from 'context/authContext';
import dayjs from 'dayjs';

/* -------------------------------------------------------------------------- */
const GET_DONARS_COUNT = gql`
  query GET_DONARS_COUNT(
    $startDate: timestamptz!
    $endDate: timestamptz!
    $organisationId: uuid!
  ) {
    donars: donars_aggregate(
      where: {
        organisation_id: { _eq: $organisationId }
        created_at: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      aggregate {
        count
      }
      nodes {
        created_at
      }
    }
  }
`;
/* -------------------------------------------------------------------------- */
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  CanvasRenderer
]);

const getOptions = data => ({
  series: [
    {
      type: 'line',
      data,
      areaStyle: {
        color: {
          type: 'linear',
          x: 0,
          y: 0,
          x2: 0,
          y2: 1,
          colorStops: [
            {
              offset: 0,
              color: rgbaColor(getColor('primary'), 0.25)
            },
            {
              offset: 1,
              color: rgbaColor(getColor('primary'), 0)
            }
          ]
        }
      }
    }
  ],
  grid: { bottom: '-10px' }
});

const NewDonors = () => {
  const { organization } = useContext(AuthContext);

  const now = dayjs();
  const endDate = now.endOf('day').toISOString();
  const startDate = now.subtract(6, 'day').startOf('day').toISOString();

  const { data: donarsCount } = useQuery(GET_DONARS_COUNT, {
    variables: {
      startDate,
      endDate,
      organisationId: organization.id
    }
  });

  // Process the data to get the counts for the last 7 days
  const donorsCountArray = Array(7).fill(0);
  const today = dayjs().startOf('day');

  if (donarsCount) {
    donarsCount.donars.nodes.forEach(donor => {
      const date = dayjs(donor.created_at).startOf('day');
      const dayIndex = today.diff(date, 'day');
      if (dayIndex >= 0 && dayIndex < 7) {
        donorsCountArray[dayIndex]++;
      }
    });
  }

  return (
    <Card className="h-md-100">
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-2"> New Donors </h6>
      </Card.Header>

      <Card.Body
        as={Flex}
        alignItems="end"
        justifyContent="between"
        className="pt-0"
      >
        <div>
          <h2 className="fw-normal text-700 mb-1 lh-1 fs-2">
            {' '}
            {donarsCount?.donars?.aggregate?.count}{' '}
          </h2>
          {/* <Badge pill bg="200" className="text-primary fs--2">
            <FontAwesomeIcon icon="caret-up" className="me-1" />
            13.6%
          </Badge> */}
        </div>
        <div className="ps-0">
          <BasicECharts
            echarts={echarts}
            options={getOptions(donorsCountArray)}
            style={{ width: '8.5rem', height: 87 }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

NewDonors.propTypes = { donarsCount: PropTypes.array.isRequired };

export default NewDonors;
