import { Document, View, Image, Page, Text } from '@react-pdf/renderer';
import classNames from 'classnames';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { useBeneficiaryContext } from 'context/beneficiaryContext';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import sponsorCard from '../../../assets/img/sponsor-card.jpg';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

export const OrphanDonationCard = ({ beneficiary }) => {
  return (
    <Document>
      <Page orientation="landscape">
        <View style={{ width: '100%', position: 'absolute' }}>
          <Image src={sponsorCard} cache={false} />
          {beneficiary.image ?? (
            <Image
              src={beneficiary.image}
              style={{
                height: '138px',
                width: '144px',
                position: 'absolute',
                top: '40px',
                left: '65px'
              }}
            />
          )}

          <Text
            style={{
              position: 'absolute',
              top: '57px',
              left: '230px'
            }}
          >
            {beneficiary.first_name +
              ' ' +
              beneficiary.middle_name +
              ' ' +
              beneficiary.last_name}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '110px',
              left: '230px'
            }}
          >
            B{beneficiary.simple_id}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '156px',
              left: '230px'
            }}
          >
            {beneficiary.country}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '230px',
              left: '65px'
            }}
          >
            {format(new Date(beneficiary.DOB), 'dd/MM/yyyy')}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '230px',
              left: '470px'
            }}
          >
            {beneficiary.gender}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '284px',
              left: '65px'
            }}
          >
            {beneficiary.health_notes}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '284px',
              left: '460px'
            }}
          >
            {beneficiary.language}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '335px',
              left: '65px'
            }}
          >
            {beneficiary.inspiration}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '383px',
              left: '65px'
            }}
          >
            {beneficiary.guardian.first_name +
              ' ' +
              beneficiary.guardian.last_name}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '383px',
              left: '460px'
            }}
          >
            {beneficiary.guardian.guardian_father_status?.name}
          </Text>
          <Text
            style={{
              position: 'absolute',
              top: '430px',
              left: '460px'
            }}
          >
            {beneficiary.guardian.guardian_mother_status?.name}
          </Text>
        </View>
      </Page>
    </Document>
  );
};
OrphanDonationCard.propTypes = {
  beneficiary: PropTypes.object
};

const ProfileBeneficiaryInfo = () => {
  const { beneficiary } = useBeneficiaryContext();

  const profileDetails = [
    {
      id: 1,
      title: 'Name',
      value:
        beneficiary.guardian.first_name +
        ' ' +
        beneficiary.guardian.middle_name +
        ' ' +
        beneficiary.guardian.last_name
    },
    {
      id: 2,
      title: 'Relationship',
      value: beneficiary.guardian.guardian_relationShip?.name
    },
    { id: 3, title: 'Contact Number', value: beneficiary.guardian.phone },
    { id: 4, title: 'Email', value: beneficiary.guardian.email },
    { id: 5, title: 'Address', value: beneficiary.guardian.address }
  ];
  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader
          title={<h4 className="fw-bold">More information</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
        />
        <Card.Body className="pb-0">
          <Row>
            <Col>
              <Flex>
                <div className="donor-info-title">
                  <p className="mb-2">Father's Status</p>
                  <p className="mb-0">Mother's Status</p>
                </div>
                <div>
                  <p className="mb-2">
                    {beneficiary.guardian.guardian_father_status?.name}
                  </p>
                  <p className="mb-2">
                    {beneficiary.guardian.guardian_mother_status?.name}
                  </p>
                </div>
              </Flex>
              <hr />
            </Col>
          </Row>
        </Card.Body>
        <FalconCardHeader
          title={<h4 className="fw-bold">Guardian Details</h4>}
          light={false}
          titleTag="div"
          className="pb-1"
          menuClassName="align-items-start"
        />

        <Card.Body>
          <Row>
            <Col md={8}>
              <Table size="sm" borderless className="font-sans-serif mb-0">
                <tbody>
                  {profileDetails?.map(detail => (
                    <tr key={detail.id}>
                      <td className="text-start ps-0">{detail.title}</td>
                      <td
                        className={classNames(
                          'text-start',
                          detail.valueClassName
                        )}
                      >
                        {detail.link ? (
                          <a href={detail.link}>{detail.value}</a>
                        ) : (
                          detail.value || '-'
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default ProfileBeneficiaryInfo;
