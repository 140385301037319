import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import LoadingButton from 'components/common/LoadingButton';
import { FormProvider, RHFControl } from 'components/common/form';

const schema = yup.object().shape({
  title: yup.string().required()
});

export default function LabelFormModal({ show, onHide, onSubmit, loading }) {
  const methods = useForm({
    defaultValues: {
      title: ''
    },
    resolver: yupResolver(schema)
  });
  const { handleSubmit } = methods;

  return (
    <Modal show={show} onHide={onHide}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Label</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <RHFControl name="title" placeholder="Label Name" autoFocus />
        </Modal.Body>
        <Modal.Footer>
          <LoadingButton loading={loading} type="submit">
            Add
          </LoadingButton>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  );
}

LabelFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};
