import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';
import TinymceEditor from 'components/common/TinymceEditor';
import { FormProvider, RHFControl } from 'components/common/form';
import { getSize } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { projectProposalScheme } from './projectProposalScheme';

const ProjectReportForm = ({ initialValues, loading, onSubmit }) => {
  const [files, setFiles] = useState([]);
  // const { organization } = useAuthContext();
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  // const { data: labelsData, loading: labelsLoading } = useQuery(GET_LABELS, {
  //   variables: {
  //     organizationId: organization.id
  //   }
  // });

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: {
      title: '',
      // labels: '',
      description: '',
      videoUrl: ''
    },
    resolver: yupResolver(projectProposalScheme)
  });

  const { handleSubmit, watch, setValue, reset } = methods;
  const description = watch('description');

  // const labelOptions = useMemo(() => {
  //   if (!labelsData) return [];
  //   return labelsData.labels.map(({ id, title }) => ({
  //     value: id,
  //     label: title
  //   }));
  // }, [labelsData]);

  useEffect(() => {
    if (initialValues) {
      reset({
        title: initialValues.title,
        labels: initialValues.labels,
        description: initialValues.description,
        videoUrl: initialValues.video_url
      });
    }
  }, [initialValues, reset]);

  useEffect(() => {
    setFiles(acceptedFiles);
  }, [acceptedFiles]);

  const allFiles = files.map((file, index) => (
    <li
      className={classNames('list-group border p-2 px-3', {
        'mb-2': index !== files.length - 1
      })}
      key={file.path}
    >
      <Flex alignItems={'center'}>
        <FontAwesomeIcon
          className="me-3"
          icon={
            (file.type === 'image/png' && 'image') ||
            (file.type === 'image/jpeg' && 'image') ||
            (file.type === 'text/csv' && 'file') ||
            (file.type === 'application/pdf' && 'file-pdf') ||
            (file.type === 'application/zip' && 'file-archive') ||
            (file.type === 'video/mp4' && 'video') ||
            'paperclip'
          }
        />
        {file.path} - {getSize(file.size)}
        <Button
          variant="link"
          className="ms-auto p-0"
          onClick={() => handleRemove(file.path)}
        >
          <FontAwesomeIcon icon={'times'} />
        </Button>
      </Flex>
    </li>
  ));

  const handleRemove = path => {
    setFiles(files.filter(file => file.path !== path));
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card className="mb-3">
        <Card.Header>
          <Card.Title className="fw-medium mb-0 d-flex align-items-center">
            <FontAwesomeIcon icon={'ticket-alt'} className="me-2" />
            Submit Progress Report
          </Card.Title>
        </Card.Header>
        <Card.Body className="pt-0">
          <Row className="g-3">
            <Col md={12}>
              <RHFControl
                name="title"
                placeholder="Report Title"
                label="Report Title"
                required
              />
            </Col>
            {/* <Col md={6}>
              <RHFSelect
                name="labels"
                label="Labels"
                options={labelOptions}
                loading={labelsLoading}
                required
                isMulti
              />
            </Col> */}
            <Form.Group as={Col} md="12" controlId="Case Study">
              <Form.Label>
                Report Description <span className="text-danger">*</span>
              </Form.Label>
              <TinymceEditor
                value={description}
                handleChange={newValue => setValue('description', newValue)}
                height={'200'}
              />
            </Form.Group>
            <Col md={12}>
              <RHFControl
                name="videoUrl"
                placeholder="Video URL"
                label="Video URL"
              />
            </Col>
            <hr />
            <Col md={12} className="mt-0">
              <div className="mt-2">
                <div
                  {...getRootProps({
                    className: 'dropzone-area h-auto py-4'
                  })}
                >
                  <input {...getInputProps({ multiple: true })} />
                  <Flex justifyContent="center" alignItems={'center'}>
                    <img src={cloudUpload} alt="" width={30} className="me-2" />
                    <p className="fs-0 mb-0 text-700">Drop your file here</p>
                  </Flex>
                </div>
                <div className="mt-3">
                  {files.length > 0 && (
                    <div
                      style={{ backgroundColor: '#F9FAFD' }}
                      className="rounded-2"
                    >
                      <ul className="p-4">{allFiles}</ul>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Flex
            justifyContent="between"
            className="flex-column flex-md-row align-items-center"
          >
            <p className="mb-3 mb-md-0 fs-2 fw-medium">Progress Report</p>
            <LoadingButton type="submit" loading={loading}>
              Submit
            </LoadingButton>
          </Flex>
        </Card.Body>
      </Card>
    </FormProvider>
  );
};

ProjectReportForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.func.object,
  loading: PropTypes.bool
};

export default ProjectReportForm;
