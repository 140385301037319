import classNames from 'classnames';
import PropTypes from 'prop-types';
import IconButton from 'components/common/IconButton';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import ReactFlagsSelect from 'react-flags-select';
import { RHFCheck, RHFControl, RHFSelect } from 'components/common/form';
import { useEffect, useState } from 'react';
import { COUNTRIES_LIST } from 'data/weraise-data/countries-data';
import Flex from 'components/common/Flex';
import LoadingButton from 'components/common/LoadingButton';

const AddressInputs = ({
  // addNewAddress,
  deleteAddress,
  setValue,
  addresses,
  isEdit
}) => {
  const [addressType, setAddressType] = useState(
    isEdit ? 'Non-UK_Address' : 'UK_Address'
  );
  const [postCode, setPostCode] = useState('');
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchAddresses = async postCode => {
    setIsLoading(true);

    try {
      const response = await fetch(
        `https://api.getaddress.io/autocomplete/${postCode}?api-key=${process.env.REACT_APP_GET_ADDRESS_APIKEY}&all=true`
      );

      const data = await response.json();
      setAddressList(data.suggestions);
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const addressOptions = addressList?.map(({ address, id }) => ({
    label: address,
    value: id
  }));

  const fetchAddressDetails = async addressId => {
    try {
      const response = await fetch(
        `https://api.getaddress.io/get/${addressId}?api-key=${process.env.REACT_APP_GET_ADDRESS_APIKEY}`
      );

      const data = await response.json();

      setSelectedAddress(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleManualAddressEntry = () => {
    setAddressType('Non-UK_Address');
    setSelectedAddress(null); // Clear the selected address when switching to manual entry
  };

  const handleAddressSelect = e => {
    fetchAddressDetails(e?.value);
  };

  useEffect(() => {
    if (selectedAddress) {
      setValue('addresses', [
        {
          addressLine1: selectedAddress.line_1,
          addressLine2: selectedAddress.line_2,
          city: selectedAddress.town_or_city,
          country: 'GB',
          postcode: selectedAddress?.postcode,
          isPrimary: true
        }
      ]);
    }
  }, [selectedAddress, setValue]);

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      fetchAddresses(postCode);
    }
  };

  return (
    <Card className="mb-3">
      {addresses.map((address, index) => (
        <Card key={`address-${index}`} className="shadow-none">
          <Card.Header>
            <Card.Title className="fw-medium d-flex justify-content-between align-items-center">
              <span>Donor Address</span>
              {index !== 0 && (
                <IconButton
                  variant="light"
                  icon="trash"
                  className={classNames(
                    'mb-1 bg-transparent border-0 shadow-none',
                    { 'v-hidden': address.defaultAddress }
                  )}
                  iconAlign="left"
                  transform="shrink-3"
                  iconClassName="text-danger me-2"
                  onClick={() => deleteAddress(index)}
                  disabled={address.defaultAddress}
                >
                  Delete
                </IconButton>
              )}
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <Flex direction="row" gap={2} className="mb-3">
              <Button
                variant={
                  addressType === 'UK_Address'
                    ? 'outline-primary'
                    : 'outline-secondary'
                }
                onClick={() => setAddressType('UK_Address')}
              >
                UK Address
              </Button>{' '}
              <Button
                variant={
                  addressType === 'Non-UK_Address'
                    ? 'outline-primary'
                    : 'outline-secondary'
                }
                onClick={() => setAddressType('Non-UK_Address')}
              >
                Non-UK Address
              </Button>{' '}
            </Flex>

            {addressType === 'UK_Address' && (
              <>
                <Form.Label> Post Code </Form.Label>
                <InputGroup className="mb-4">
                  <Form.Control
                    type="text"
                    placeholder="Enter Post Code"
                    onChange={e => setPostCode(e.target.value)}
                    onKeyDown={handleKeyDown}
                    required
                  />

                  <LoadingButton
                    loading={isLoading}
                    variant="primary"
                    onClick={() => fetchAddresses(postCode)}
                  >
                    Search
                  </LoadingButton>
                </InputGroup>

                {!!addressList.length && (
                  <RHFSelect
                    name="type"
                    label="Select your address"
                    options={addressOptions}
                    required
                    onChange={handleAddressSelect}
                    className="mb-2"
                  />
                )}
              </>
            )}

            {(addressType === 'Non-UK_Address' || selectedAddress) && (
              <>
                <Row className="g-3">
                  <Col md="12">
                    <RHFControl
                      name={`addresses.${index}.addressLine1`}
                      label="First Line Address"
                      placeholder="add Address Line 1"
                      required
                    />
                  </Col>
                  <Col md="12">
                    <RHFControl
                      name={`addresses.${index}.addressLine2`}
                      label="Second Line Address"
                      placeholder="add Address Line 2"
                    />
                  </Col>
                  <Col md="6">
                    <RHFControl
                      name={`addresses.${index}.city`}
                      label="City / Town"
                      placeholder="add City / Town"
                      required
                    />
                  </Col>
                  <Col md="6">
                    <RHFControl
                      name={`addresses.${index}.postcode`}
                      label="Post Code"
                      placeholder="add Zip/post Code"
                      required
                    />
                  </Col>
                  <Col md={12}>
                    <Form.Label className="text-600">Country</Form.Label>
                    <Row>
                      <Col md="6">
                        <ReactFlagsSelect
                          selected={
                            selectedAddress?.country ? 'GB' : address.country
                          }
                          onSelect={code =>
                            setValue(`addresses.${index}.country`, code)
                          }
                          className="react-select"
                          countries={Object.keys(COUNTRIES_LIST)}
                          selectButtonClassName="py-0"
                          selectedSize={17.3}
                          optionsSize={17.3}
                        />
                        <Form.Control.Feedback>
                          Looks good!
                        </Form.Control.Feedback>
                      </Col>
                      <Col md={6} className="mt-1">
                        <RHFCheck
                          name={`addresses.${index}.isPrimary`}
                          label="Primary"
                          onChange={e => {
                            setValue(
                              `addresses.${index}.isPrimary`,
                              e.target.checked
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Card.Body>

          {index !== addresses.length - 1 && <hr className="m-0" />}
        </Card>
      ))}

      {addressType === 'UK_Address' && (
        <Button
          variant="link"
          className="text-primary mx-auto"
          onClick={handleManualAddressEntry}
        >
          Enter Address Manually{' '}
        </Button>
      )}

      {/* <Flex direction="column" alignItems="start" className="m-4">
        <Button variant="primary" onClick={addNewAddress}>
          Add New Address
        </Button>
      </Flex> */}
    </Card>
  );
};

AddressInputs.propTypes = {
  addNewAddress: PropTypes.func.isRequired,
  deleteAddress: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.object).isRequired,
  isEdit: PropTypes.bool
};

export default AddressInputs;
