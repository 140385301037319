import { gql, useMutation } from '@apollo/client';
import { parseRouteParams } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import paths from 'routes/paths';
import CompletionForm from './CompletionForm';

/* -------------------------------------------------------------------------- */
const EDIT_PROJECT_COMPLETION_MUTATION = gql`
  mutation EditProjectCompletion(
    $id: uuid = ""
    $administration_cost: Int = 10
    $cities: jsonb = ""
    $completion_date: date = ""
    $currency_id: uuid = ""
    $no_of_beneficiary: Int = 10
    $project_cost: Int = 10
    $video_url: String = ""
    $feedback: String = ""
    $country: String = ""
  ) {
    update_project_completion_by_pk(
      pk_columns: { id: $id }
      _set: {
        administration_cost: $administration_cost
        cities: $cities
        completion_date: $completion_date
        currency_id: $currency_id
        no_of_beneficiary: $no_of_beneficiary
        project_cost: $project_cost
        video_url: $video_url
        feedback: $feedback
        country: $country
      }
    ) {
      administration_cost
      cities
      completion_date
      created_at
      currency_id
      id
      no_of_beneficiary
      project_cost
      project_id
      updated_at
      status
      video_url
      feedback
      country
      created_by
      admin_comment
    }
  }
`;
/* -------------------------------------------------------------------------- */

const EditProjectCompletion = ({ data }) => {
  const navigate = useNavigate();
  const [createProjectCompletion, { loading }] = useMutation(
    EDIT_PROJECT_COMPLETION_MUTATION,
    {
      onCompleted: () => {
        navigate(
          parseRouteParams(paths.completeProject, {
            id: data.project_id
          })
        );
        toast.success('Project completion updated successfully');
      },
      onError: error => {
        toast.error(error.message);
      }
    }
  );

  const onSubmit = values => {
    const formattedCompletionDate = new Date(
      values.completionDate
    ).toISOString();
    createProjectCompletion({
      variables: {
        administration_cost: values.administrationCost,
        cities: values.cities,
        completion_date: formattedCompletionDate,
        currency_id: values.currency,
        no_of_beneficiary: values.numOfBeneficiaries,
        project_cost: values.projectCost,
        status: values.status,
        video_url: values.videoUrl,
        feedback: values.feedback,
        country: values.country,
        id: data.id
      }
    });
  };

  return (
    <CompletionForm
      onSubmit={onSubmit}
      loading={loading}
      initialValues={data}
    />
  );
};

EditProjectCompletion.propTypes = {
  data: PropTypes.object
};

export default EditProjectCompletion;
