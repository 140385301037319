import { useMutation, useQuery } from '@apollo/client';
import TableRowAction from 'components/common/TableRowAction';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import AuthContext from 'context/authContext';
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';
import { DELETE_HOSPITAL_ORPHAN, INSERT_HOSPITAL_ORPHAN } from '../mutations';
import { GET_HOSPITAL_BY_ORPHAN } from '../queries';
import AddHospitalModal from './AddHospitalModal';

function BeneficiaryHospital() {
  const hospitalColumns = [
    {
      accessor: 'id',
      Header: 'ID',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'name',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'address',
      Header: 'Location',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'sponsor',
      Header: 'Sponsor',
      cellProps: {
        className: 'py-2'
      },
      Cell: () => {
        return <>{organization.organisation_name}</>;
      }
    },
    {
      accessor: 'created_at',
      Header: 'Date',
      cellProps: {
        className: 'py-2'
      },
      Cell: rowData => {
        const { created_at } = rowData.row.original;
        return <>{dayjs(created_at).format('MMM-DD-YYYY')}</>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <TableRowAction
            isNotEdit
            onCLickDelete={() => handleDeleteClick(id)}
            subject={permissionsKeyMap.sponsorship.children.hospital}
          />
        );
      }
    }
  ];

  const { organization } = useContext(AuthContext);
  const { id } = useParams();
  const [selectedId, setSelectedId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddHospital, setShowAddHospital] = useState(false);
  const { data: hospitalByOrphanListData, loading: hospitalOrphanLoading } =
    useQuery(GET_HOSPITAL_BY_ORPHAN, {
      fetchPolicy: 'network-only',
      variables: {
        id
      }
    });

  const [deleteHospital] = useMutation(DELETE_HOSPITAL_ORPHAN, {
    refetchQueries: ['GetHospitalByOrphan'],
    onCompleted: () => {
      toast.success('Hospital Deleted Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const [addHospitalOrphan] = useMutation(INSERT_HOSPITAL_ORPHAN, {
    refetchQueries: ['GetHospitalByOrphan'],
    onCompleted: () => {
      toast.success('Orphans Added Successfully');
      setShowAddHospital(false);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
      setShowAddHospital(false);
    }
  });

  const handleDeleteClick = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const onDelete = () => {
    // delete call
    deleteHospital({ variables: { id: selectedId } });
    setShowDeleteModal(false);
  };

  const submitAddHospital = data => {
    addHospitalOrphan({
      variables: { objects: [{ hospital_id: data.hospital, orphan_id: id }] }
    });
  };
  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        handleConfirm={onDelete}
        title="Delete Hospital"
        body="Are you sure you want to delete this Hospital?"
      />
      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {hospitalByOrphanListData?.hospital_orphan && (
            <CustomAdvanceTable
              data={
                hospitalByOrphanListData?.hospital_orphan.map(
                  (hospitalOrphan, index) => ({
                    ...hospitalOrphan?.hospital,
                    key: `${hospitalOrphan?.hospital?.id}-${index}`
                  })
                ) || []
              }
              columns={hospitalColumns}
              title="Hospital"
              addButtonLabel="Add Hospital"
              onClickAddNew={() => {
                setShowAddHospital(true);
              }}
              loading={hospitalOrphanLoading}
              addNew
              addButtonIcon={'plus'}
              subject={permissionsKeyMap.sponsorship.children.hospital}
            />
          )}
        </Col>
      </Row>

      {showAddHospital && (
        <AddHospitalModal
          show={showAddHospital}
          onSubmit={data => {
            submitAddHospital(data);
          }}
          onHide={() => {
            setShowAddHospital(false);
          }}
          title={'Add hospital'}
        />
      )}
    </>
  );
}

export default BeneficiaryHospital;
