import { Card, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { RHFControl } from 'components/common/form';

export const SuccessRedirection = ({
  sectionRef,
  sideNavLink,
  switchValue,
  handleSwitch
}) => {
  return (
    <Card className="border shadow-none mb-3" ref={sectionRef} id={sideNavLink}>
      <Card.Header>
        <Row>
          <Col>
            <Card.Title>Success Redirection</Card.Title>
          </Col>
          <Col className="d-flex justify-content-end">
            <Card.Title>
              <Form.Group as={Row} className="mb-3">
                <Col>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    checked={switchValue}
                    onChange={handleSwitch}
                  />
                </Col>
              </Form.Group>
            </Card.Title>
          </Col>
        </Row>
      </Card.Header>
      {switchValue ? (
        <Card.Body>
          <RHFControl
            name="redirectUrl"
            label="Donation Success Redirect Page"
            placeholder="https://www.example.com/success"
            required
          />
        </Card.Body>
      ) : null}
    </Card>
  );
};

SuccessRedirection.propTypes = {
  sectionRef: PropTypes.any,
  sideNavLink: PropTypes.any,
  hasSwitch: PropTypes.bool,
  switchValue: PropTypes.bool,
  handleSwitch: PropTypes.func
};
