import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import CustomAdvanceTable from 'components/common/advance-table/CustomAdvanceTable';
import Flex from 'components/common/Flex';
import TableRowAction from 'components/common/TableRowAction';
import ConfirmationModal from 'components/common/modals/ConfirmationModal';
import AddMedicalReportModal from './AddReportModal';
import {
  ADD_MEDICAL_REPORT,
  DELETE_MEDICAL_REPORT,
  UPDATE_MEDICAL_REPORT
} from '../mutations';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useAuthContext } from 'context/authContext';
import { useParams } from 'react-router-dom';
import { GET_MEDICAL_REPORT } from '../queries';
import { permissionsKeyMap } from 'routes/permissions/permissionKeys';

/* -------------------------------------------------------------------------- */
// export const GET_DONORS = gql`
//   query GetDonors($organizationId: uuid!) {
//     donars(
//       where: { organisation_id: { _eq: $organizationId } }
//       order_by: { created_at: desc }
//     ) {
//       address
//       consented_date
//       consented_via
//       contact_preferences
//       created_at
//       declaration_source
//       email
//       first_name
//       gift_aid_elgibility
//       gift_aid_file
//       id
//       last_name
//       lead_source
//       organisation_id
//       phone
//       remark
//       title
//       user_id
//     }
//   }
// `;
// const DELETE_DONOR = gql`
//   mutation DeleteDonor($id: uuid!) {
//     delete_donars_by_pk(id: $id) {
//       id
//     }
//   }
// `;
/* -------------------------------------------------------------------------- */
const MedicalReport = () => {
  // const { organization } = useContext(AuthContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editObj, setEditObj] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [addMedicalModalShow, setAddMedicalModalShow] = useState(false);

  const columns = [
    {
      accessor: 'hosptial',
      Header: 'Name',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'medical_note',
      Header: 'Comment',
      cellProps: {
        className: 'py-2'
      }
    },
    {
      accessor: 'inspection_date',
      Header: 'Inspection Date'
    },
    {
      accessor: 'review_date',
      Header: 'Review Date'
    },
    {
      accessor: 'user',
      Header: 'User',
      cellProps: {
        className: 'py-2'
      },
      Cell: () => {
        const user = { first_name: 'hamza', last_name: 'ahmed' };
        const name = user.first_name + ' ' + user.last_name;
        return <Flex alignItems="center">{name}</Flex>;
      }
    },
    {
      accessor: 'id_',
      Header: '',
      cellProps: {
        className: 'py-2 text-end'
      },
      disableSortBy: true,

      Cell: rowData => {
        const { id } = rowData.row.original;

        return (
          <TableRowAction
            onCLickEdit={() => handleMedicalReportEdit(rowData.row.original)}
            onCLickDelete={() => handleMedicalReportDelete(id)}
            subject={permissionsKeyMap.sponsorship.children.beneficiaries}
          />
        );
      }
    }
  ];
  const { organization, selectedBranchId, user } = useAuthContext();
  const { id } = useParams();

  const handleMedicalReportDelete = id => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const handleMedicalReportEdit = obj => {
    setEditObj(obj);
    setShowEditModal(true);
  };

  const handleDeleteModalHide = () => {
    setSelectedId(null);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    deleteMedicalReport({
      variables: {
        id: selectedId
      }
    });
  };

  const [addMedicalReport, { loading }] = useMutation(ADD_MEDICAL_REPORT, {
    onCompleted: () => {
      setAddMedicalModalShow(false);
      toast.success('Medical Report Created Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    },
    update: (cache, { data }) => {
      const existingSchool = cache.readQuery({
        query: GET_MEDICAL_REPORT,
        variables: {
          id
        }
      });
      if (!existingSchool) return;

      cache.writeQuery({
        query: GET_MEDICAL_REPORT,
        variables: {
          id
        },
        data: {
          medical_reports: [
            ...existingSchool.medical_reports,
            data?.insert_medical_reports_one
          ]
        }
      });
    }

    //   cache.writeQuery({
    //     query: GET_SCHOOLS,
    //     variables: {
    //       branchId: selectedBranchId,
    //       organizationId: organization.id
    //     },
    //     data: {
    //       schools: [...existingSchool?.schools, data?.insert_schools_one]
    //     }
    //   });
    // }
  });

  const [editMedicalReport, { editloading }] = useMutation(
    UPDATE_MEDICAL_REPORT,
    {
      onCompleted: () => {
        setAddMedicalModalShow(false);
        setShowEditModal(false);
        setEditObj(null);
        toast.success('Medical Report Updated Successfully');
      },
      onError: error => {
        toast.error('Something went wrong');
        console.log(error);
      }
    }
  );

  const [deleteMedicalReport] = useMutation(DELETE_MEDICAL_REPORT, {
    onCompleted: () => {
      setShowDeleteModal(false);
      toast.success('Medical Report Delete Successfully');
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    },
    update: cache => {
      const existingSchool = cache.readQuery({
        query: GET_MEDICAL_REPORT,
        variables: {
          id
        }
      });
      if (!existingSchool) return;

      cache.writeQuery({
        query: GET_MEDICAL_REPORT,
        variables: {
          id
        },
        data: {
          medical_reports: [
            ...existingSchool.medical_reports.filter(
              item => item.id !== selectedId
            )
          ]
        }
      });
    }
  });

  const { data: medicalReportData } = useQuery(GET_MEDICAL_REPORT, {
    variables: {
      id
    }
  });

  const onSubmit = values => {
    {
      showEditModal
        ? editMedicalReport({
            variables: {
              id: editObj.id,
              hosptial: values.hosptial,
              medical_note: values.comment,
              inspection_date: values.inspectionDate,
              review_date: values.reviewDate
            }
          })
        : addMedicalReport({
            variables: {
              branch_id: selectedBranchId,
              orphan_id: id,
              user_id: user.id,
              organisation_id: organization.id,
              hosptial: values.hosptial,
              medical_note: values.comment,
              inspection_date: values.inspectionDate,
              review_date: values.reviewDate
            }
          });
    }
  };

  return (
    <>
      <ConfirmationModal
        show={showDeleteModal}
        onHide={handleDeleteModalHide}
        // loading={deletseLoading}
        title="Delete Medical Report"
        handleConfirm={handleConfirmDelete}
        body="Are you sure you want to delete selected Medical Report?"
      />

      <Row>
        <Col md={12} xxl={12} className="h-md-100">
          {medicalReportData?.medical_reports && (
            <CustomAdvanceTable
              data={medicalReportData?.medical_reports}
              columns={columns}
              title="Medical Reports"
              addButtonLabel="Add "
              onClickAddNew={() => {
                setAddMedicalModalShow(true);
              }}
              addNew
              subject={permissionsKeyMap.sponsorship.children.beneficiaries}
            />
          )}
        </Col>
      </Row>
      {(showEditModal || addMedicalModalShow) && (
        <AddMedicalReportModal
          show={showEditModal || addMedicalModalShow}
          onSubmit={onSubmit}
          loading={loading || editloading}
          onHide={() => {
            setAddMedicalModalShow(false);
            setShowEditModal(false);
          }}
          initValues={editObj}
          title={
            showDeleteModal ? 'Add Medical Record' : 'Edit Modeical Record'
          }
        />
      )}
    </>
  );
};

export default MedicalReport;
