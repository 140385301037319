import { useMutation } from '@apollo/client';
import AuthContext from 'context/authContext';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { CREATE_SCHOOL } from '../mutations';
import SchoolForm from './SchoolForm';
import useUpload from 'hooks/useUpload';
import { useNavigate } from 'react-router-dom';
import paths from 'routes/paths';

function AddSchool() {
  const navigate = useNavigate();
  const { upload, loading: uploadLoading } = useUpload();
  const { organization, selectedBranchId } = useContext(AuthContext);

  const [createSchool, { loading }] = useMutation(CREATE_SCHOOL, {
    onCompleted: () => {
      toast.success('School Created Successfully');
      navigate(paths.school);
    },
    onError: error => {
      toast.error('Something went wrong');
      console.log(error);
    }
  });

  const submit = async values => {
    if (values.school_document && typeof values.school_document !== 'string') {
      const school_document = await upload(values.school_document);
      values.school_document = school_document;
    }

    createSchool({
      variables: {
        ...values,
        branch_id: selectedBranchId,
        organisation_id: organization.id,
        school_address: values.address,
        school_city: values.city,
        school_email: values.email,
        school_name: values.schoolName,
        serial_number: values.serialNumber,
        school_number: values.contact,
        school_headteacher: values.schoolHeadTeacher
      }
    });
  };

  return <SchoolForm onSubmit={submit} loading={loading || uploadLoading} />;
}

export default AddSchool;
