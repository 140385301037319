import { gql } from '@apollo/client';

export const GET_DONOR_CATEGORY = gql`
  query GetDonorCategory {
    donor_category {
      id
      title
      is_default
    }
  }
`;

export const DELETE_DONOR_CATEGORY = gql`
  mutation DeleteDonorCategory($id: uuid!) {
    delete_donor_category(where: { id: { _eq: $id } }) {
      returning {
        id
        title
        is_default
      }
    }
  }
`;

export const INSERT_DONOR_CATEGORY = gql`
  mutation InsertDonorCategory($title: String!, $isDefault: Boolean) {
    insert_donor_category_one(
      object: { title: $title, is_default: $isDefault }
    ) {
      id
      title
      is_default
    }
  }
`;

export const UPDATE_DONOR_CATEGORY = gql`
  mutation UpdateDonorCategory(
    $title: String!
    $isDefault: Boolean
    $id: uuid!
  ) {
    update_donor_category(
      where: { id: { _eq: $id } }
      _set: { title: $title, is_default: $isDefault }
    ) {
      returning {
        id
        title
        is_default
      }
    }
  }
`;
